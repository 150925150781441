import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import { $getSelection, $isRangeSelection, BaseSelection } from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import { fromPx, toPx } from 'utils/toPx';
import { FontPluginStyle } from './style';

export function $getBrandColor(colors: Models.BrandColorsList): Models.BrandColorMap | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  const HEX = $getSelectionStyleValueForProperty(selection, FontPluginStyle.BRAND_COLOR_HEX) || undefined;
  const name = $getSelectionStyleValueForProperty(selection, FontPluginStyle.BRAND_COLOR_NAME) || undefined;
  const tint = $getSelectionStyleValueForProperty(selection, FontPluginStyle.BRAND_COLOR_TINT) || undefined;

  if (!HEX) {
    return undefined;
  }

  const color: BrandDefinition.BrandColorRef = { HEX, name, tint: tint ? Number(tint) : undefined };

  return BrandDefinition.findBrandColor(colors, color);
}

export function $getBrandFont(): BrandDefinition.BrandFontRef | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  const fontName = $getSelectionStyleValueForProperty(selection, FontPluginStyle.BRAND_FONT_NAME) || Constants.DefaultCustomStyle.FONT_FAMILY;
  const characterStyleName = $getSelectionStyleValueForProperty(selection, FontPluginStyle.BRAND_FONT_CHARACTER_STYLE_NAME) || undefined;

  return { fontName, characterStyleName };
}

export function $getFontSize(): number | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }
  const value = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_SIZE);

  return value ? fromPx(value) : Constants.DefaultCustomStyle.FONT_SIZE;
}

export function $getInlineStyle(): Constants.InlineStyle[] {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return [];
  }
  const result: Constants.InlineStyle[] = [];

  const fontStyle = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_STYLE);
  if (fontStyle === 'italic') {
    result.push(Constants.InlineStyle.ITALIC);
  }

  const fontWeight = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_WEIGHT);
  if (fontWeight === 'bold' || Number(fontWeight) >= 700) {
    result.push(Constants.InlineStyle.BOLD);
  }

  if (selection.hasFormat('underline')) {
    result.push(Constants.InlineStyle.UNDERLINE);
  }

  return result;
}

export function $patchFontWeight(selection: BaseSelection, isBold: boolean): void {
  $patchStyleText(selection, {
    [FontPluginStyle.FONT_WEIGHT]: isBold ? Constants.InlineFontStyleNoPrefix.BOLD : Constants.InlineFontStyleNoPrefix.REGULAR,
  });
}

export function $patchFontStyle(selection: BaseSelection, isNormal: boolean): void {
  $patchStyleText(selection, {
    [FontPluginStyle.FONT_STYLE]: isNormal ? Constants.InlineFontStyleNoPrefix.NORMAL : Constants.InlineFontStyleNoPrefix.ITALIC,
  });
}

export function $patchFontSize(selection: BaseSelection, size: number): void {
  $patchStyleText(selection, {
    [FontPluginStyle.FONT_SIZE]: toPx(size) ?? null,
  });
}
