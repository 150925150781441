import { LinkNode } from '@lexical/link';
import { TextNode } from 'lexical';
import { CustomListItemNode } from '../nodes/CustomListItemNode';
import { CustomListNode } from '../nodes/CustomListNode';
import { CustomParagraphNode } from '../nodes/CustomParagraphNode';
import { ReferenceCitationNode } from '../nodes/ReferenceCitationNode';
import { LinkNodeWithExportDom } from './nodes/LinkNodeWithExportDom';
import { ReferenceCitationNodeWithExportDom } from './nodes/ReferenceCitationNodeWithExportDom';
import { withExportDom } from './withExportDom';

const CustomParagraphNodeWithExportDom = withExportDom(CustomParagraphNode);
const TextNodeWithExportDom = withExportDom(TextNode, true);
const CustomListNodeWithExportDom = withExportDom(CustomListNode);
const CustomListItemNodeWithExportDom = withExportDom(CustomListItemNode);

export const EXPORT_TRANSLATION_PACKAGE_NODES = [
  TextNodeWithExportDom,
  {
    replace: TextNode,
    with: (node: TextNode): InstanceType<typeof TextNodeWithExportDom> => {
      return new TextNodeWithExportDom(node.__text);
    },
    withKlass: TextNodeWithExportDom,
  },
  CustomParagraphNodeWithExportDom,
  {
    replace: CustomParagraphNode,
    with: (): InstanceType<typeof CustomParagraphNodeWithExportDom> => {
      return new CustomParagraphNodeWithExportDom();
    },
    withKlass: CustomParagraphNodeWithExportDom,
  },
  CustomListNodeWithExportDom,
  {
    replace: CustomListNode,
    with: (node: CustomListNode): InstanceType<typeof CustomListNodeWithExportDom> => {
      return new CustomListNodeWithExportDom(node.__listType, node.__start);
    },
    withKlass: CustomListNodeWithExportDom,
  },
  CustomListItemNodeWithExportDom,
  {
    replace: CustomListItemNode,
    with: (node: CustomListItemNode): InstanceType<typeof CustomListItemNodeWithExportDom> => {
      return new CustomListItemNodeWithExportDom(node.__value, node.__checked);
    },
    withKlass: CustomListItemNodeWithExportDom,
  },
  ReferenceCitationNodeWithExportDom,
  {
    replace: ReferenceCitationNode,
    with: (node: ReferenceCitationNode): ReferenceCitationNodeWithExportDom => {
      return new ReferenceCitationNodeWithExportDom(node.getReferenceId(), node.__text);
    },
    withKlass: ReferenceCitationNodeWithExportDom,
  },
  LinkNodeWithExportDom,
  {
    replace: LinkNode,
    with: (node: LinkNode): LinkNodeWithExportDom => {
      return new LinkNodeWithExportDom(node.__url);
    },
    withKlass: LinkNodeWithExportDom,
  },
];
