import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TextNode } from 'lexical';
import React from 'react';
import { ColourlessTextNode } from '../nodes/ColourlessTextNode';
import { ResetStylesPlugin } from '../Plugins/ResetStylesPlugin';
import { mainTheme } from '../Theme/mainTheme';
import { LEXICAL_TEXT_NODES } from './LexicalTextEditor';

const editorConfig = {
  namespace: 'text-preview-editor',
  editable: false,
  theme: mainTheme,
  onError: (error: Error): void => {
    throw error;
  },
  nodes: [
    ...LEXICAL_TEXT_NODES,
    ColourlessTextNode,
    {
      replace: TextNode,
      with: (node: TextNode): ColourlessTextNode => new ColourlessTextNode(node.__text),
    },
  ],
};

type Props = {
  editorState: string | null;
};

export const LexicalTextPreviewEditor = (props: Props): JSX.Element => {
  const { editorState } = props;

  return (
    <LexicalComposer initialConfig={{
      ...editorConfig,
      editorState,
    }} >
      <div className="editor-container">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ResetStylesPlugin/>
      </div>
    </LexicalComposer>
  );
};
