import { RenditionTypeValue } from 'const';
import { ImageInternalInfo } from 'models';
import { ImageCacheData, ImageCacheDataMap } from './models';

export class ImageCache {
  private static instance: ImageCache;

  private readonly data: ImageCacheDataMap;

  constructor() {
    this.data = {} as ImageCacheDataMap;
  }

  public static getInstance(): ImageCache {
    if (!ImageCache.instance) {
      ImageCache.instance = new ImageCache();
    }

    return ImageCache.instance;
  }

  private getDataKey(type: RenditionTypeValue, checksum: string): string {
    return `${type}#${checksum}`;
  }

  public getItem(type: RenditionTypeValue, checksum: string): ImageInternalInfo | string[] {
    const key = this.getDataKey(type, checksum);

    return this.data[key];
  }

  public setItem(type: RenditionTypeValue, checksum: string, cacheData: ImageCacheData): void {
    const key = this.getDataKey(type, checksum);

    this.data[key] = cacheData;
  }
}
