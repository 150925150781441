import { $toggleLink } from '@lexical/link';
import { $getSelectionStyleValueForProperty, getStyleObjectFromCSS } from '@lexical/selection';
import { $findMatchingParent } from '@lexical/utils';
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isTextNode,
  LexicalNode,
  PointType,
  TextNode,
} from 'lexical';
import * as Constants from 'const';
import { NO_WRAP_VALUE, WHITE_SPACE_PROP } from './style';
import { BaseProps } from './types';

function $getScriptStyleFromSelection(): BaseProps['scriptStyle'] {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  if (selection.hasFormat('superscript')) {
    return Constants.ScriptType.SUPERSCRIPT;
  }

  if (selection.hasFormat('subscript')) {
    return Constants.ScriptType.SUBSCRIPT;
  }

  return undefined;
}

function $getTextNoWrapFromSelection(): BaseProps['textNoWrap'] {
  const selection = $getSelection();
  if (selection && $isRangeSelection(selection)) {
    return $getSelectionStyleValueForProperty(selection, WHITE_SPACE_PROP) === NO_WRAP_VALUE;
  }

  return false;
}

export function $isNoWrapApplied(node: TextNode): boolean {
  const style = getStyleObjectFromCSS(node.getStyle());

  return style[WHITE_SPACE_PROP] === NO_WRAP_VALUE;
}

export function $getBaseProps(): BaseProps {
  return {
    scriptStyle: $getScriptStyleFromSelection(),
    textNoWrap: $getTextNoWrapFromSelection(),
  };
}

function $clearTextNodeFormatting(
  node: TextNode,
  idx: number,
  nodes: LexicalNode[],
  extractedNodes: LexicalNode[],
  anchor: PointType,
  focus: PointType,
): void {
  let textNode = node;

  if (idx === 0 && anchor.offset !== 0) {
    textNode = textNode.splitText(anchor.offset)[1] || textNode;
  }
  if (idx === nodes.length - 1) {
    textNode = textNode.splitText(focus.offset)[0] || textNode;
  }
  if (nodes.length === 1 && $isTextNode(extractedNodes[0])) {
    textNode = extractedNodes[0];
  }
  if (textNode.getStyle() !== '') {
    textNode.setStyle('');
  }
  if (textNode.getFormat() !== 0) {
    textNode.setFormat(0);
    const blockNode = $findMatchingParent(textNode, curNode => $isElementNode(curNode) && !curNode.isInline());
    if (!$isElementNode(blockNode)) {
      return;
    }
    blockNode.setFormat('');
  }
}

export function $clearNodesFormatting(): void {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return;
  }
  $toggleLink(null);
  const anchor = selection.anchor;
  const focus = selection.focus;
  if (anchor.key === focus.key && anchor.offset === focus.offset) {
    return;
  }
  const nodes = selection.getNodes();
  const extractedNodes = selection.extract();
  nodes.forEach((node, idx) => {
    if (!$isTextNode(node)) {
      return;
    }
    $clearTextNodeFormatting(node, idx, nodes, extractedNodes, anchor, focus);
  });
}
