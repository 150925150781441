import _ from 'lodash';
import { ProjectType, ProjectsConfig } from 'const';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { toPx } from 'utils/toPx';
import { ListIndents } from '../constants';
import { Schemas } from '../models';

function convertPaddingToPx(padding: string, fontSize: number): string {
  return _(padding)
    .split(' ')
    .map(value => toPx(parseFloat(value) * fontSize, 2))
    .join(' ');
}

export function convertIndentToPx(
  indent: Schemas.ListIndentStyles,
  fontSize: string | undefined
): Schemas.ListIndentStyles;
export function convertIndentToPx(
  indent: Schemas.ListIndentStyles,
  fontSize: number | undefined
): Schemas.ListIndentStyles;
export function convertIndentToPx(
  indent: Schemas.ListIndentStyles,
  value: string | number | undefined,
): Schemas.ListIndentStyles {
  if (!value) {
    return indent;
  }

  const fontSizeNumber = typeof value === 'number' ? value : getIntegerFromStyle(value);
  if (!fontSizeNumber) {
    return indent;
  }

  const { margin, padding } = indent || {};

  return {
    margin: convertPaddingToPx(margin, fontSizeNumber),
    padding: convertPaddingToPx(padding, fontSizeNumber),
  };
}

export function getListIndent(
  projectType: ProjectType,
  listItemParagraphs = [] as Schemas.ListItemParagraph[],
): Schemas.ListIndentStyles {
  const { convertListIndentToPx } = ProjectsConfig[projectType];
  const indent = ListIndents[projectType];

  if (!convertListIndentToPx) {
    return indent;
  }

  const fontSize = _.get(listItemParagraphs, '[0].fontSize', '');

  return convertIndentToPx(indent, fontSize);
}

