import Draft from 'draft-js';
import _ from 'lodash';
import * as Constants from 'const';
import { ScreensOrderedMap } from 'models';
import { getInlineStylesForExport } from 'utils/brandStyles';
import * as editorUtils from 'utils/editor';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { toPx } from 'utils/toPx';
import * as factories from '../factories';
import { BrandProps } from '../factories';
import { Schemas } from '../models';
import { getListIndent } from './getListIndent';
import { getParagraphAlign } from './getParagraphAlign';
import { getSegmentType } from './getSegmentType';

function createListParagraph(listItemParagraphs: Schemas.ListItemParagraph[], projectType: Constants.ProjectType): Schemas.ListParagraph {
  const indent = getListIndent(projectType, listItemParagraphs);

  return factories.createListParagraph({
    ...indent,
    paragraphs: listItemParagraphs,
  });
}

export type ConvertEditorStateOptions = {
  brandProps: BrandProps;
  defaultLineHeight: Constants.TextLineHeightValue;
  projectType: Constants.ProjectType;
  surfaces: ScreensOrderedMap;
};

export function convertEditorState(
  editorState: Draft.EditorState,
  {
    brandProps,
    defaultLineHeight,
    projectType,
    surfaces,
  }: ConvertEditorStateOptions,
): Schemas.ReferenceText {
  const currentContent = editorState.getCurrentContent();

  const paragraphs: (Schemas.Paragraph | Schemas.ListParagraph)[] = [];
  let listItemParagraphs: Schemas.ListItemParagraph[] = [];
  const brandInlineStyles = brandProps.brandStyle && getInlineStylesForExport(brandProps.brandStyle, brandProps.colors, brandProps.fonts);

  let prevListBlockType = false;
  let rootParagraphColor = null;
  currentContent.getBlockMap().forEach((block: Draft.ContentBlock) => {
    let prevCutIndex = 0;
    let prevStyles: Schemas.ExtendedTextStyles;
    const segments: Schemas.Segment[] = [];
    const lineHeight = String(block.getIn(['data', Constants.BlockDataKey.LINE_HEIGHT]) || defaultLineHeight);
    const blockCharList = block.getCharacterList();
    const firstCharEntityKey = blockCharList.size !== 0 ? blockCharList.first().getEntity() : null;
    const firstCharEntity = firstCharEntityKey ? currentContent.getEntity(firstCharEntityKey) : undefined;
    let prevSegmentType = getSegmentType(firstCharEntity);
    segments.push(factories.createSegment(
      { type: prevSegmentType },
      firstCharEntity,
      surfaces,
    ));

    blockCharList.forEach((
      char: Draft.CharacterMetadata,
      i: number,
      characters: Immutable.List<Draft.CharacterMetadata>,
    ) => {
      const charStyles = char.getStyle();
      const charEntityKey = char.getEntity();
      const charEntity = charEntityKey ? currentContent.getEntity(charEntityKey) : undefined;
      const currentSegmentType = getSegmentType(charEntity);
      const newSegmentType = prevSegmentType !== currentSegmentType;
      const isLastChar = i === characters.count() - 1;

      const currentStyles = factories.createPartTextSytles(
        charStyles,
        brandProps,
        lineHeight,
        charEntity,
      );
      const newStyles = prevStyles && !_.isEqual(prevStyles, currentStyles);
      const lastSegmentParts = segments[segments.length - 1]?.parts ?? [];

      if (newStyles || newSegmentType || isLastChar) {
        if (isLastChar && newSegmentType) {
          const text = block.getText().slice(prevCutIndex, i);
          lastSegmentParts.push(factories.createPart({ text, ...prevStyles }));
          segments.push(factories.createSegment(
            {
              type: currentSegmentType,
              parts: [factories.createPart({ text: block.getText().split('').pop(), ...currentStyles })],
            },
            charEntity,
            surfaces,
          ));
        } else if (isLastChar && newStyles) {
          const text = block.getText().slice(prevCutIndex, i);
          lastSegmentParts.push(factories.createPart({ text, ...prevStyles }));
          lastSegmentParts.push(factories.createPart({ text: block.getText().split('').pop(), ...currentStyles }));
        } else if (isLastChar && !prevStyles) {
          lastSegmentParts.push(factories.createPart({ text: block.getText().split('').pop(), ...currentStyles }));
        } else {
          const endIndex = isLastChar ? i + 1 : i;
          const text = block.getText().slice(prevCutIndex, endIndex);
          lastSegmentParts.push(factories.createPart({ text, ...prevStyles }));
          if (newSegmentType) {
            segments.push(factories.createSegment(
              { type: currentSegmentType },
              charEntity,
              surfaces,
            ));
          }
        }
        prevCutIndex = i;
      }
      prevStyles = currentStyles;
      prevSegmentType = currentSegmentType;
    });

    const anchor: boolean = block.getIn(['data', Constants.BlockDataKey.ANCHOR]);
    const align = getParagraphAlign(block);
    const { allowCustomRangeFontSizeSelection } = Constants.ProjectsConfig[projectType];
    const fontSizeStyle = !allowCustomRangeFontSizeSelection
      ? blockCharList.size > 0
        ? editorUtils.getNonScriptedFontSize(blockCharList as unknown as Immutable.List<Draft.CharacterMetadata>)
        : { fontSize: (brandInlineStyles && brandInlineStyles.fontSize) || toPx(Constants.DefaultCustomStyle.FONT_SIZE) }
      : null;
    const fontSize = getIntegerFromStyle(fontSizeStyle && fontSizeStyle.fontSize || '');

    // DO NOT set font-size because it recalculates heights for texts with line-height
    if (allowCustomRangeFontSizeSelection && brandInlineStyles) {
      delete brandInlineStyles.fontSize;
    }

    const resultParagraph = factories.createTextParagraph({
      segments,
      ...brandInlineStyles,
      ...fontSizeStyle,
      align,
      lineHeight: fontSize ? toPx(fontSize * Number(lineHeight), 2) : lineHeight,
      anchor,
    });

    // recalculate fontSize and lineHeightPx for sub/sup scripts
    resultParagraph.segments.forEach((segment) => {
      segment.parts.forEach((part) => {
        const { sub, sup } = part;
        if (sub || sup) {
          const partFontSize = part.fontSize === Constants.DEFAULT_SCRIPT_FONT_SIZE
            ? toPx(getIntegerFromStyle(resultParagraph.fontSize) * Constants.SCRIPT_BASIC_FONT_REDUCTION, 2)
            : part.fontSize;

          if (partFontSize) {
            part.fontSize = partFontSize;
            part.lineHeightPx = toPx(parseFloat(partFontSize) * Number(part.lineHeight), 2) as string;
          }
        }
      });
    });

    if (editorUtils.isListBlock(block)) {
      const depth = block.getDepth();
      const { listStyleType, marginLeft } = Constants.Styles.ListItemByDepth[depth];
      const paragraphColor = _.get(resultParagraph, 'segments[0].parts[0].bulletColor', resultParagraph.color);

      if (depth === 0) {
        rootParagraphColor = paragraphColor;
      }

      const resultListItemParagraph = factories.createListItemParagraph({
        ...resultParagraph,
        color: rootParagraphColor ?? paragraphColor,
        level: depth,
        bulletWidth: marginLeft,
        listStyleType,
      });

      listItemParagraphs.push(resultListItemParagraph);
    } else {
      if (prevListBlockType) {
        const listParagraph = createListParagraph(listItemParagraphs, projectType);

        paragraphs.push(listParagraph);

        listItemParagraphs = [];
      }

      paragraphs.push(resultParagraph);
    }

    prevListBlockType = editorUtils.isListBlock(block);
  });

  if (_.size(listItemParagraphs) > 0) {
    const listParagraph = createListParagraph(listItemParagraphs, projectType);

    paragraphs.push(listParagraph);
  }

  return factories.createText({ paragraphs });
}
