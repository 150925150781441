import Draft from 'draft-js';
import Immutable from 'immutable';
import _ from 'lodash';

import { updateReferenceCitationsOnComponent } from 'modules/draftjs/helpers';
import { createStyles } from 'factories/relationFactory';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

interface DeleteReferenceCitationFromComponentsResult {
  updatedDocuments: Models.TextComponents;
  updatedRelations: Models.LayeredRelations<Models.TextRelationStyles>;
}

// IN-PROGRESS:  should be implemented for Lexical
export function deleteReferenceCitationFromComponents(
  referenceCitationId: string,
  textComponents: Models.TextComponentsMap,
  layeredRelations: Models.LayeredRelationsMap,
  componentIds?: Immutable.List<string>,
): DeleteReferenceCitationFromComponentsResult {
  const updatedRelations: Models.LayeredRelations<Models.TextRelationStyles> = {};

  const textComponentIds = componentIds || textComponents.keySeq().toList();
  const textsToUpdate = textComponents.filter((textComponent, textComponentId) => (
    textComponent &&
    textComponent.get('referenceCitations').includes(referenceCitationId) &&
    textComponentIds.includes(textComponentId)),
  );

  const updatedDocuments: Models.TextComponents = textsToUpdate.map((textComponent, textComponentId) => {
    const componentRelations = layeredRelations
      .filter<Models.LayeredRegularRelationMap>(isRegularRelation)
      .filter(relation => relation.get('documentId').includes(textComponentId))
      .toJS() as Models.LayeredRegularRelations<Models.TextRelationStyles>;

    _.assign(updatedRelations, componentRelations);

    if (!textComponent || !textComponent.get('referenceCitations').includes(referenceCitationId)) {
      return textComponent;
    }

    let rawContent: Draft.RawDraftContentState = JSON.parse(textComponent.get('rawContent'));
    const editorState = Draft.EditorState.createWithContent(Draft.convertFromRaw(rawContent));

    _.forEach(componentRelations, (relation, relationId) => {
      const layer = _.findKey(relation.documentId, documentId => documentId === textComponentId);
      const styles = relation.styles[layer];
      const { fontColor, fontFamily, fontSize } = styles;

      let editorStateWithStyles = editorUtils.applyInlineStyleRanges(editorState, fontColor);
      editorStateWithStyles = editorUtils.applyInlineStyleRanges(editorStateWithStyles, fontFamily);
      editorStateWithStyles = editorUtils.applyInlineStyleRanges(editorStateWithStyles, fontSize);

      const contentState = removeReferencesFromContentState(editorStateWithStyles, rawContent, referenceCitationId);
      const newEditorState = Draft.EditorState.createWithContent(contentState);
      const {
        fontColor: newFontColor,
        fontSize: newFontSize,
        fontFamily: newFontFamily,
        fontStyle: newFontStyle,
      } = editorUtils.isolateInlineStyles(newEditorState);

      const updatedStyles = createStyles({
        ...styles,
        fontSize: newFontSize,
        fontFamily: newFontFamily,
        fontColor: newFontColor,
        fontStyle: newFontStyle,
      });

      (updatedRelations[relationId] as Models.LayeredRegularRelation).styles[layer] = updatedStyles;
    });

    const contentState = removeReferencesFromContentState(editorState, rawContent, referenceCitationId);
    rawContent = Draft.convertToRaw(contentState);

    // TODO: get rid of text property
    // TBC: There is no sense to support text property in case text has raw content!!!
    // actually text field is needed to support components from ASM7 storycards, is it right?
    // const text = stateToHTML(contentState, textOptions);

    const updatedTextComponent = textComponent.set('rawContent', JSON.stringify(rawContent));

    return updateReferenceCitationsOnComponent(updatedTextComponent);
  }).toJS() as Models.TextComponents;

  return {
    updatedDocuments,
    updatedRelations,
  };
}

function removeReferencesFromContentState(
  editorState: Draft.EditorState,
  rawContent: Draft.RawDraftContentState,
  referenceCitationId: string,
): Draft.ContentState {
  const { entityMap, blocks } = rawContent;
  let contentState = editorState.getCurrentContent();

  blocks.forEach((block) => {
    const entityRanges = block.entityRanges;
    // HACK: start from the end of array to prevent range changes after deleting references from text
    [...entityRanges].reverse().forEach((entityRange) => {
      const { key } = entityRange;
      const { [key]: entity } = entityMap;

      if (entity.data.id === referenceCitationId) {
        const { offset, length } = entityRange;
        const selection = new Draft.SelectionState({
          anchorKey: block.key,
          focusKey: block.key,
          anchorOffset: offset,
          focusOffset: offset + length,
        });
        contentState = Draft.Modifier.removeRange(contentState, selection, 'forward');
      }
    });
  });

  return contentState;
}
