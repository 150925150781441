import _ from 'lodash';
import * as React from 'react';

import SaveLayout from 'components/ModalWindows/Shared/SaveLayout';
import { DocumentClassification, DocumentClassificationMedcomms, DocumentSubtype, DocumentType, isVeevaMedcomms } from 'const';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { SaveReusableLayoutProps } from './models';

const SaveReusableLayout: React.FunctionComponent<SaveReusableLayoutProps> = ({
  documentTypes,
  saveReusableLayout,
  rootDocument,
  hideModal,
  modalWindowId,
  options,
  reusableLayoutsDocuments,
  reusableLayoutsNames,
  layoutPreview,
  generateLayoutPreview,
  updateAppState,
  cancelLayoutPreviewGeneration,
}) => {
  const layout = options.get('layout');
  const layoutId = layout.get('id');
  const layoutDocument = reusableLayoutsDocuments.get(layout.get('documentId'));
  const subtype = layoutDocument && layoutDocument.get('subtype');
  const defaultClassification = isVeevaMedcomms() ? DocumentClassificationMedcomms.HEADER : DocumentClassification.UNCLASSIFIED;
  const classification = subtype === DocumentSubtype.CONTENT_UNIT ? layoutDocument.get('classification') : defaultClassification;

  const getDefaultLayoutName = (count = 1): string => {
    const subtype = rootDocument.get('subtype');
    const sectionDisplayName = options.get('sectionDisplayName');
    const defaultLayoutName = `${subtype}_${sectionDisplayName ? `${sectionDisplayName}_` : ''}${count}`;

    if (reusableLayoutsNames.includes(defaultLayoutName)) {
      return getDefaultLayoutName(count + 1);
    }

    return defaultLayoutName;
  };

  const saveLayout = (name: string, classification: DocumentClassification): void => {
    saveReusableLayout({
      classification,
      layoutId,
      name,
      previewUrl: layoutPreview.get('s3Url'),
      subtype: DocumentSubtype.CONTENT_UNIT,
    });
  };
  const generatePreview = (): void => { generateLayoutPreview([layoutId]); };

  const getSortedClassifications = (classificationsMap: Models.DocumentClassificationsMap): string[] => {
    return classificationsMap
      ? _(classificationsMap.toJS() as Models.DocumentClassifications).sortBy('index').map('label').value()
      : [];
  };

  const documentType = documentTypes.get(DocumentType.COMPONENT);
  const documentSubtypesMap = documentType && documentType.get('subtypes');
  const documentSubtypeMap = documentSubtypesMap && documentSubtypesMap.get(DocumentSubtype.CONTENT_UNIT);
  const documentClassificationsMap = documentSubtypeMap && documentSubtypeMap.get('classifications');
  const documentClassifications = getSortedClassifications(documentClassificationsMap);

  return (
    <SaveLayout
      cancelPreviewGeneration={cancelLayoutPreviewGeneration}
      documentClassifications={documentClassifications}
      initialClassification={classification}
      initialLayoutName={getDefaultLayoutName()}
      generatePreview={generatePreview}
      hideModal={hideModal}
      layoutPreview={layoutPreview}
      modalWindowId={modalWindowId}
      saveLayout={saveLayout}
      title={intlGet('SaveReusableLayout', 'Title')}
      updateAppState={updateAppState}
    />
  );
};

export default SaveReusableLayout;
