import { LexicalEditor } from 'lexical';
import { $isCustomListItemNode, CustomListItemNode } from 'modules/Lexical/nodes/CustomListItemNode';
import { $isCustomListNode, CustomListNode } from 'modules/Lexical/nodes/CustomListNode';
import { CustomParagraphNode } from 'modules/Lexical/nodes/CustomParagraphNode';
import { $isSSIAnchorNode } from 'modules/Lexical/nodes/SSIAnchorNode';
import { mainTheme } from 'modules/Lexical/Theme/mainTheme';
import styles from 'styles/lexical.scss';

type ToggleParams = {
  editor: LexicalEditor;
  node: CustomParagraphNode | CustomListItemNode | CustomListNode;
  showPlaceholder: boolean;
};

export const $togglePlaceholder = (params: ToggleParams): void => {
  const { editor, node, showPlaceholder } = params;
  const nodeHasAnchor = Boolean(node.getChildren().find($isSSIAnchorNode));
  const dom = editor.getElementByKey(node.getKey());
  const isNestedListItem = dom?.classList.contains(mainTheme.list.nested.listitem);
  const needToShowAnchorPlaceholder = showPlaceholder && !nodeHasAnchor && !isNestedListItem;
  const css = styles.showAnchorPlaceholder;

  if (!dom) {
    return;
  }
  if (needToShowAnchorPlaceholder) {
    dom.classList.add(css);
  } else {
    dom.classList.remove(css);
  }
};

export const $togglePlaceholderForList = (params: ToggleParams): void => {
  const { node } = params;
  const children = node.getChildren();
  if ($isCustomListItemNode(node)) {
    $togglePlaceholder(params);
  }
  children.forEach((child) => {
    if ($isCustomListNode(child) || $isCustomListItemNode(child)) {
      $togglePlaceholderForList({ ...params, node: child });
    }
  });
};
