import { $isTextNode } from 'lexical';
import { BrandColorRef } from 'modules/BrandDefinition';
import * as Constants from 'const';
import { ParagraphType } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { toPx } from 'utils/toPx';
import { CustomListItemNode } from '../../nodes/CustomListItemNode';
import {
  CreateParagraphContext,
  getParagraphAlign, getParagraphAnchor, getParagraphFontFamily, getParagraphFontSize, getParagraphLineHeight,
} from './Paragraph';

type Context = CreateParagraphContext & {
  depth: number;
  bulletColor: BrandColorRef | undefined;
};

export function createParagraphForListItem(
  node: CustomListItemNode,
  context: Context,
  segments: Schemas.Segment[],
): Schemas.ListItemParagraph {
  const { depth, bulletColor, defaultStyles, defaultLineHeight, projectType } = context;

  const firstTextNode = node.getChildren().find($isTextNode);
  const fontSizeNumber = getParagraphFontSize(firstTextNode, defaultStyles, projectType);
  const { listStyleType, marginLeft } = Constants.Styles.ListItemByDepth[depth];

  return {
    type: ParagraphType.UNORDERED_LIST_ITEM,
    align: getParagraphAlign(node.getFormatType()),
    anchor: getParagraphAnchor(node),
    bulletWidth: marginLeft,
    color: bulletColor?.HEX ?? Constants.DefaultCustomStyle.FONT_COLOR,
    fontFamily: getParagraphFontFamily(defaultStyles),
    fontSize: toPx(fontSizeNumber ?? Constants.DefaultCustomStyle.FONT_SIZE),
    lineHeight: getParagraphLineHeight(node.getLineHeight() ?? 0, fontSizeNumber, defaultLineHeight),
    listStyleType,
    level: depth,
    segments,
  };
}
