import classNames from 'classnames';
import React from 'react';
import css from '../../styles.module.scss';

type Props = {
  position: number;
};

export const ScreenNumber: React.FC<Props> = ({ position }) => {
  return (
    <div className={classNames(css.screenNumber)}>{position}</div>
  );
};

