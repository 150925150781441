import Draft from 'draft-js';
import _ from 'lodash';
import { addReferenceCitationsOrder, removeReferenceCitationsOrder } from 'modules/draftjs/utils';
import { call, select } from 'redux-saga/effects';
import { EditorChangeType } from 'const';
import { documents as documentsSelector, referenceCitationsOrderByDocuments } from 'containers/Documents/selectors';
import { relations as relationsSelector } from 'containers/Relations/selectors';
import { createRelation } from 'factories/relationFactory';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { addReferenceCitationToComponent } from './addReferenceCitationToComponent';
import { applyDefaultStyles } from './applyDefaultStyles';
import { emulatePushOperation } from './emulatePushOperation';

interface ApplyStylesOnRelationsResult {
  component: Models.TextComponentMap;
  newReferenceCitationDocument?: Models.ReferenceCitationMap;
  updatedRelations: Models.Relations;
}

// returns updated component and relation
export function* applyStylesOnRelations(
  component: Models.TextComponentMap,
  relation: Models.RegularRelationMap<Models.TextRelationStyles>,
  operations = [] as Models.DraftEditorOperations,
): Generator<unknown, ApplyStylesOnRelationsResult> {
  const result = {} as ApplyStylesOnRelationsResult;

  const relations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
  const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
  const referenceCitationsOrder: ReturnTypeSaga<typeof referenceCitationsOrderByDocuments> = yield select(referenceCitationsOrderByDocuments);

  const componentId = component.get('id');
  const relationId = relation.get('id');

  // process data received from editor, save styling and update document
  const editorState = _.flow(
    editorUtils.convertTextComponentToRawEditorState,
    editorUtils.replaceLineBreaks,
  )(component);

  let updatedComponent = component.set('rawContent', editorUtils.getSerializedRawContent(editorState));
  const updatedRelation = relation.toJS() as Models.RegularRelation<Models.TextRelationStyles>;
  applyDefaultStyles(updatedComponent.toJS() as Models.TextComponent, updatedRelation);

  // remove inline styles from rawContent level not to affect other instances
  updatedComponent = updatedComponent.set('rawContent', _.flow(
    editorUtils.removeAllInlineStylesWithPrefix,
    editorUtils.getSerializedRawContent,
  )(editorState));

  // get all relationships for document excluding updated one
  const componentRelations = relations
    .delete(relationId)
    .filter<Models.RegularRelationMap>(isRegularRelation)
    .filter(rel => rel.get('documentId') === componentId);
  const updatedRelations = {} as Models.Relations;

  // get previous state of component
  const document = documents.get(componentId) as Models.TextComponentMap;
  const documentRawContent: Draft.RawDraftContentState = document && JSON.parse(document.get('rawContent'));
  const documentEditorState = documentRawContent && Draft.EditorState.createWithContent(Draft.convertFromRaw(documentRawContent));

  // do not update relations if there is no previous document (text was created in an empty cell on translation artboard)
  document && componentRelations.forEach((relation) => {
    const styles = relation.get('styles').toJS() as Models.TextRelationStyles;
    const currentRelationId = relation.get('id');
    const { fontColor, fontFamily, fontSize, fontStyle, fontWeight, characterStyleName } = styles;

    const numberOrder = referenceCitationsOrder.get(`${currentRelationId}-${componentId}`);

    let styledEditorState = editorUtils.applyInlineStyleRanges(documentEditorState, fontColor);
    styledEditorState = editorUtils.applyInlineStyleRanges(styledEditorState, fontFamily);
    styledEditorState = editorUtils.applyInlineStyleRanges(styledEditorState, fontStyle);
    styledEditorState = editorUtils.applyInlineStyleRanges(styledEditorState, fontWeight);
    styledEditorState = editorUtils.applyInlineStyleRanges(styledEditorState, characterStyleName);
    styledEditorState = editorUtils.applyInlineStyleRanges(styledEditorState, fontSize);
    styledEditorState = addReferenceCitationsOrder(styledEditorState, numberOrder);

    let contentState = styledEditorState.getCurrentContent();

    // emulate all operations
    operations.forEach((operation) => {
      switch (operation.type) {
        case EditorChangeType.UNDO: styledEditorState = Draft.EditorState.undo(styledEditorState); break;
        case EditorChangeType.REDO: styledEditorState = Draft.EditorState.redo(styledEditorState); break;
        default: styledEditorState = emulatePushOperation(styledEditorState, operation);
      }

      contentState = styledEditorState.getCurrentContent();
    });

    // Since we use Draft JS internal methods, result block map keys can differ
    // Update re-created content with keys of component editor state.
    const blockKeys = editorUtils.convertTextComponentToRawEditorState(updatedComponent).getCurrentContent().getBlockMap().keySeq().toArray();
    const blocks = contentState.getBlocksAsArray().map((block, idx) => block.set('key', blockKeys[idx])) as Draft.ContentBlock[];

    const currentContent = Draft.ContentState.createFromBlockArray(blocks);
    const editorStateWithReferenceOrder = Draft.EditorState.set(styledEditorState, { currentContent });
    const updatedEditorState = Draft.EditorState.createWithContent(removeReferenceCitationsOrder(editorStateWithReferenceOrder));
    const {
      fontColor: newFontColor,
      fontFamily: newFontFamily,
      fontSize: newFontSize,
      fontStyle: newFontStyle,
      fontWeight: newFontWeight,
      characterStyleName: newCharacterStyleName,
    } = editorUtils.isolateInlineStyles(updatedEditorState);

    updatedRelations[currentRelationId] = createRelation({
      ...relation.toJS(),
      id: currentRelationId,
      entityType: updatedComponent.get('entityType'),
      documentId: componentId,
      styles: {
        ...styles,
        fontColor: newFontColor,
        fontFamily: newFontFamily,
        fontSize: newFontSize,
        fontStyle: newFontStyle,
        fontWeight: newFontWeight,
        characterStyleName: newCharacterStyleName,
      } as Models.TextRelationStyles,
    });
  });

  const addReferenceOperation = operations.find(operation => operation.type === EditorChangeType.ADD_REFERENCE);

  if (addReferenceOperation) {
    const {
      updatedTextComponent,
      newReferenceCitationDocument,
    }: ReturnTypeSaga<typeof addReferenceCitationToComponent> = yield call(
      addReferenceCitationToComponent,
      updatedComponent,
      addReferenceOperation.reference,
    );
    updatedComponent = updatedTextComponent;
    result.newReferenceCitationDocument = newReferenceCitationDocument;
  }

  result.component = updatedComponent;
  result.updatedRelations = _.assign({ [updatedRelation.id]: updatedRelation }, updatedRelations);

  return result;
}
