import { Reducer } from 'redux';

export enum EditorMode {
  DRAFTJS = 'draftjs',
  LEXICAL = 'lexical',
  BOTH = 'both',
}

const SET_EDITOR_MODE = 'SET_EDITOR_MODE';

type SetEditorModeAction = {
  type: typeof SET_EDITOR_MODE;
  payload: EditorMode;
};

const editorModeReducer: Reducer<EditorMode, SetEditorModeAction> = (
  state: EditorMode | undefined,
  action: SetEditorModeAction,
): EditorMode => {
  const currentState = state ?? EditorMode.DRAFTJS;

  if (action.type === SET_EDITOR_MODE) {
    return action.payload;
  }

  return currentState;
};

export default editorModeReducer;

export const setEditorMode = (mode: EditorMode): SetEditorModeAction => ({
  type: SET_EDITOR_MODE,
  payload: mode,
});
