import { mergeRegister } from '@lexical/utils';
import {
  $isRangeSelection,
  $getSelection,
  COMMAND_PRIORITY_EDITOR,
  FORMAT_ELEMENT_COMMAND,
  LexicalEditor,
  createCommand,
} from 'lexical';
import * as Constants from 'const';
import { getFormatTypeForBlockType } from './utils/horizontal-alignment';
import { $applyLineHeight, $hasLineHeight } from './utils/line-height';

export const TEXT_BLOCK_COMMAND = {
  BLOCK_LINE_HEIGHT: createCommand<Constants.TextLineHeightValue>(),
  BLOCK_TYPE: createCommand<Constants.TextHorizontalAlignmentType | undefined>(),
} as const;

export function registerEditorPropsCommands(editor: LexicalEditor): () => void {
  return mergeRegister(

    editor.registerCommand(
      TEXT_BLOCK_COMMAND.BLOCK_LINE_HEIGHT,
      (value) => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          selection.getNodes()
            .map(node => $hasLineHeight(node) ? node : node.getParent())
            .forEach(node => $applyLineHeight(node, value));
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      TEXT_BLOCK_COMMAND.BLOCK_TYPE,
      (value) => {
        const format = value && getFormatTypeForBlockType(value);
        if (format) {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, format);
        } else {
          // eslint-disable-next-line no-console
          console.error(`unknown block type ${value ?? '-'}`);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),
  );
}
