import _ from 'lodash';
import { RefObject, useCallback, useEffect } from 'react';
import * as Constants from 'const';
import { getContainerSize } from 'utils/getContainerSize';
import { CTAStyles, CTAStylesSetters } from '../utils/styles';

export function useResizeObserverCTA(
  cellContainerRef: RefObject<HTMLDivElement>,
  editorWrapperRef: RefObject<HTMLDivElement>,
  styles: CTAStyles,
  stylesSetters: CTAStylesSetters,
  editMode: boolean, // editorWrapperRef will be updated during editMode toggling so we need to resubscribe ResizeObserver
  isDraggingAsset: boolean,
): void {

  const handler = useCallback(
    _.debounce<ResizeObserverCallback>(
      (entries) => {
        if (_.size(entries) === 0 || !styles.assetPadding) {
          return;
        }
        const editorContentHeight = entries[0].target.scrollHeight;
        const wrapperEditorHeight = styles.height
        - styles.assetPadding.get(Constants.BoxPropertySide.TOP)
        - styles.assetPadding.get(Constants.BoxPropertySide.BOTTOM);
        const cellContainerAvailableHeight = cellContainerRef.current ? getContainerSize(cellContainerRef.current).height : wrapperEditorHeight;
        const extraHeight = editorContentHeight - wrapperEditorHeight;
        if (extraHeight > 0) {
          stylesSetters.height(styles.height + extraHeight);
        }

        const isCellSmallerThanWrapper = (cellContainerAvailableHeight > 0) && (cellContainerAvailableHeight < wrapperEditorHeight);
        const isCellLargerThanContent = cellContainerAvailableHeight > editorContentHeight;
        if (isCellSmallerThanWrapper && isCellLargerThanContent && !isDraggingAsset) {
          stylesSetters.height(cellContainerAvailableHeight);
        }
      },
      100,
    ),
    [styles, stylesSetters, cellContainerRef],
  );

  useEffect(() => {
    const { current } = editorWrapperRef;

    if (!current) {
      return;
    }

    const resizeObserver = new ResizeObserver(handler);
    resizeObserver.observe(current);

    return (): void => resizeObserver.unobserve(current);
  }, [editMode, handler]);
}
