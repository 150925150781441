import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserHistory } from 'history';
import Immutable from 'immutable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
// at first we have to load global styles
import 'styles/main.scss';

import store from 'store/store';
import App from 'containers/App';
import { isDevelopment } from 'utils/isDevelopment';
import './containers/EditorToggle/globalEditorMode';

if (isDevelopment()) {
  // eslint-disable-next-line import/no-extraneous-dependencies
  void import('immutable-devtools').then(({ default: installDevTools }) => {
    installDevTools(Immutable);
  });
}

const history = createBrowserHistory();

const render = (): void => {
  ReactDOM.render(
    (
      <Provider store={store}>
        <App history={history} />
      </Provider>
    ),
    document.getElementById('root'),
  );
};

render();

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    render();
  });
}

window.addEventListener('error', (event) => {
  // eslint-disable-next-line no-console
  console.error(`Error occurred: ${JSON.stringify(event, Object.getOwnPropertyNames(event))}.`, event);

  return false;
});

window.addEventListener('unhandledrejection', (event) => {
  // eslint-disable-next-line no-console
  console.error(`Error occurred (promise): ${JSON.stringify(event.reason)}.`);
});

// HACK for Safari: https://issues.merck.com/browse/DCC-3136
// In some cases for some text components Draft.js throws error while trying to do manipulations with Editor State
// the root cause is that Draft.js doesn't handle incorrect selection
// https://github.com/facebook/draft-js/issues/1188
// https://github.com/facebook/draft-js/pull/1190
// https://github.com/facebook/draft-js/pull/1084

// Check for includes('[native code]') in order to avoid a repetitive replacement during hot reload
if (Selection.prototype.extend.toString().includes('[native code]')) {
  const nativeSelectionExtend = Selection.prototype.extend;

  Selection.prototype.extend = function (...args) {
    try {
      return nativeSelectionExtend.apply(this, args);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Selection error.', error);
    }
  };
}
