import { List } from 'immutable';
import * as Models from 'models';
import { lexicalRawConverter } from '../LexicalRawConverter/lexicalRawConverter';

export function getLexicalStateFromCTAComponent(
  ctaComponent: Models.CallToAction,
): string {
  const lexicalState = ctaComponent.lexicalState;
  if (lexicalState) {
    return lexicalState;
  }

  const rawContent = ctaComponent.rawContent;
  if (!rawContent) {
    return '';
  }

  const rawDraftContentState = JSON.parse(rawContent);
  const { editorState } = lexicalRawConverter(rawDraftContentState, List(), List());

  return JSON.stringify(editorState);
}
