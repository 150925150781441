import { $generateNodesFromDOM } from '@lexical/html';
import { $dfs } from '@lexical/utils';
import { $getRoot, $insertNodes, createEditor } from 'lexical';
import _ from 'lodash';
import { editorConfig } from 'modules/Lexical/components/LexicalTextEditor';
import { createLexicalTextEditor } from 'modules/Lexical/editors';
import { updateReferenceCitationsOnComponent } from 'modules/Lexical/helpers';
import { $createReferenceCitationNode } from 'modules/Lexical/nodes/ReferenceCitationNode';
import {
  $getReferenceCitationNodes,
} from 'modules/Lexical/Plugins/ReferenceCitationPlugin/utils/getReferenceCitationNodes';
import * as Models from 'models';
import { toImmutable, toJS } from 'utils/immutable';
import { REFERENCE_CITATION_MARKER } from '../nodes/ReferenceCitationNodeWithExportDom';

export function replaceReferenceCitationsWithinComponent(
  textComponent: Models.TextComponent,
  translatedReferenceIdsByOriginal: Record<string, string>,
): Models.TextComponent {
  let updatedTextComponent = toImmutable(textComponent);
  const serializedState = updatedTextComponent.get('lexicalState');

  if (!serializedState) {
    return textComponent;
  }

  const editor = createLexicalTextEditor(serializedState);
  editor.update(() => {
    $getReferenceCitationNodes($getRoot()).forEach((node) => {
      const oldReferenceCitationId = node.getReferenceId();
      const newReferenceCitationId = translatedReferenceIdsByOriginal[oldReferenceCitationId];

      if (newReferenceCitationId) {
        node.setReferenceId(newReferenceCitationId);
      }
    });
  }, { discrete: true });

  updatedTextComponent = updatedTextComponent.set('lexicalState', JSON.stringify(editor.getEditorState()));

  return toJS(updateReferenceCitationsOnComponent(updatedTextComponent)) as unknown as Models.TextComponent;
}

const processReferenceCitations = (
  referenceCitationIds: string[],
  getActualReferenceCitationId: (referenceId: string) => string,
): string[] => {
  const actualReferenceCitationIds: string[] = [];

  const nodesArray = $dfs();
  nodesArray.forEach((dfsNode) => {
    const textContent = dfsNode.node.getTextContent();
    if (textContent.trim() === REFERENCE_CITATION_MARKER) {
      const referenceCitationId = referenceCitationIds.shift();
      const actualReferenceCitationId = getActualReferenceCitationId(referenceCitationId ?? '');

      if (actualReferenceCitationId) {
        actualReferenceCitationIds.push(actualReferenceCitationId);
        dfsNode.node.replace($createReferenceCitationNode(actualReferenceCitationId));
      }
    }
  });

  return actualReferenceCitationIds;
};

export const replaceReferenceMarkersByEntities = (
  textComponent: Models.TextComponent,
  getActualReferenceCitationId: (referenceId: string) => string,
): Models.TextComponent => {
  const actualTextComponent = _.cloneDeep(textComponent);
  let actualReferenceCitationIds: string[] = [];

  const { text, referenceCitations: referenceCitationIds } = actualTextComponent;

  if (!text) {
    return actualTextComponent;
  }

  const editor = createEditor(editorConfig);
  editor.update(() => {
    // In the browser you can use the native DOMParser API to parse the HTML string.
    const parser = new DOMParser();
    const dom = parser.parseFromString(text, 'text/html');

    // Generate LexicalNodes
    const nodes = $generateNodesFromDOM(editor, dom);

    // Remove automatically created paragraph
    const emptyParagraph = $getRoot().getFirstChild();
    if (emptyParagraph) {
      emptyParagraph.remove();
    }

    // Select the root
    $getRoot().select();

    // Insert LexicalNodes at a selection
    $insertNodes(nodes);

    // Process Reference Citations
    if (!_.isEmpty(referenceCitationIds)) {
      actualReferenceCitationIds = processReferenceCitations(referenceCitationIds, getActualReferenceCitationId);
    }
  }, { discrete: true });

  actualTextComponent.lexicalState = JSON.stringify(editor.getEditorState());

  if (_.isEmpty(referenceCitationIds)) {
    actualTextComponent.text = text?.replaceAll(REFERENCE_CITATION_MARKER, '');
  }

  actualTextComponent.referenceCitations = actualReferenceCitationIds;

  return actualTextComponent;
};
