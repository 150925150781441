import { $isReferenceCitationNode, ReferenceCitationNode } from '../../../nodes/ReferenceCitationNode';

export function $reorderNodes(
  startNode: ReferenceCitationNode,
  numbersMap: Record<string, number>,
): void {
  const list: ReferenceCitationNode[] = [startNode];

  let prev = startNode.getPreviousSibling();
  while ($isReferenceCitationNode(prev)) {
    list.unshift(prev);
    prev = prev.getPreviousSibling();
  }
  let next = startNode.getNextSibling();
  while ($isReferenceCitationNode(next)) {
    list.push(next);
    next = next.getNextSibling();
  }

  if (list.length === 1) {
    return;
  }

  const getNumber = (node: ReferenceCitationNode): number => numbersMap[node.getReferenceId()] ?? Infinity;
  list.sort((a, b) => getNumber(b) - getNumber(a));

  let current = list[0];
  list.slice(1).forEach((node) => {
    if (node.getPreviousSibling() !== current) {
      node.insertAfter(current);
    }
    current = node;
  });

}
