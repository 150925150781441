import React from 'react';
import { ProjectsConfig } from 'const';
import { useProjectType } from 'hooks/useProjectType';
import { ReferenceCitationMap } from 'models';
import { getReferenceHtmlAsString } from 'utils/getReferenceHtmlAsString';
import ReferenceCitationActions from '../../../../components/ReferenceCitationActions';
import styles from './styles.module.scss';

interface IReferenceCitationPreviewProps {
  reference: ReferenceCitationMap;
  index: number;
  setCurrentReferenceId: (id: string, event: React.MouseEvent) => void;
  deleteReferenceFromText: (referenceId: string) => void;
}

const ReferenceCitationPreview: React.FunctionComponent<IReferenceCitationPreviewProps> = ({
  reference,
  index,
  setCurrentReferenceId,
  deleteReferenceFromText,
}) => {
  const projectType = useProjectType();
  const { useBoldNumberingInReferences } = ProjectsConfig[projectType];

  const id = reference.get('id');
  const referenceText = reference.get('text') as string;
  const referenceHtml = getReferenceHtmlAsString(index + 1, referenceText, useBoldNumberingInReferences);

  const setCurrentReference: React.MouseEventHandler = event => setCurrentReferenceId(id, event);
  const deleteReference = (): void => deleteReferenceFromText(id);

  return (
    <ReferenceCitationActions deleteReference={deleteReference}>
      <div className={styles.ReferenceCitationPreview} onMouseDown={setCurrentReference}>
        <div className={styles.preview} dangerouslySetInnerHTML={{ __html: referenceHtml }} />
      </div>
    </ReferenceCitationActions>
  );
};

export default ReferenceCitationPreview;
