import Draft from 'draft-js';
import { Map } from 'immutable';
import * as Models from 'models';
import { removeTags } from '../../../utils/removeTags';

export function getCleanText(textComponent: Models.TextComponent | Models.TextComponentMap): string {
  let rawContent: string | undefined;
  let text: string | undefined;
  if (Map.isMap(textComponent)) {
    const comonent = textComponent as Models.TextComponentMap;
    rawContent = comonent.get('rawContent');
    text = comonent.get('text');
  } else {
    ({ rawContent, text } = textComponent as Models.TextComponent);
  }

  return rawContent
    ? Draft.EditorState.createWithContent(Draft.convertFromRaw(JSON.parse(rawContent))).getCurrentContent().getPlainText()
    : removeTags(text ?? '');
}
