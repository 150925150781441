import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CallToAction from 'components/ArtboardAssets/CallToAction';
import { CallToActionActions, CallToActionOwnProps, CallToActionSelectors } from 'components/ArtboardAssets/CallToAction/models';
import { isDraggingAsset, isResizingRow } from 'containers/App/selectors';
import { brandStylesByRelationId } from 'containers/Common/selectors';
import { imagesForAssetsPanel } from 'containers/Documents/selectors';
import { currentCountry, currentLanguage } from 'containers/Project/selectors';
import { updateLayeredRelationsSilently } from 'containers/Relations/actions';

const createMapStateToProps = createStructuredSelector({
  currentCountry,
  currentLanguage,
  images: imagesForAssetsPanel,
  isResizingRow,
  brandStylesByRelationId,
  isDraggingAsset,
});

const mapDispatchToProps = {
  updateRelationsSilently: updateLayeredRelationsSilently,
};

export default connect<CallToActionSelectors, CallToActionActions, CallToActionOwnProps>(createMapStateToProps, mapDispatchToProps)(CallToAction);
