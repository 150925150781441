import { DecoratorNode, LexicalNode, NodeKey, SerializedLexicalNode } from 'lexical';
import { SSIAnchorWithContext } from 'modules/SSIAnchors/components/SSIAnchorWithContext';
import React from 'react';

export type SSIAnchorOptions = {
  id?: string;
  offsetTop?: number;
};
export type SerializedSSIAnchorNode = SerializedLexicalNode & SSIAnchorOptions;

function generateId(): string {
  return Math.random().toString(36).slice(2, 8);
}

export class SSIAnchorNode extends DecoratorNode<JSX.Element> {
  private readonly __id: string;

  private __offsetTop: number | undefined;

  constructor(options: SSIAnchorOptions, key?: NodeKey) {
    super(key);
    this.__id = options.id ?? generateId();
    this.__offsetTop = options.offsetTop;
  }

  static getType(): string {
    return 'ssi-anchor';
  }

  static clone(node: SSIAnchorNode): SSIAnchorNode {
    return new SSIAnchorNode({
      id: node.__id,
      offsetTop: node.__offsetTop,
    }, node.__key);
  }

  static importJSON(serializedNode: SerializedSSIAnchorNode): SSIAnchorNode {
    return new SSIAnchorNode(serializedNode);
  }

  exportJSON(): SerializedSSIAnchorNode {
    return {
      ...super.exportJSON(),
      id: this.getId(),
      offsetTop: this.getLatest().__offsetTop,
    };
  }

  getId(): string {
    return this.getLatest().__id;
  }

  setOffset(offsetTop: SSIAnchorNode['__offsetTop']): this {
    const writable = this.getWritable();
    writable.__offsetTop = offsetTop;

    return this;
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return (<SSIAnchorWithContext anchorBlockKey={this.__id} offsetTop={this.__offsetTop ?? 0} />);
  }
}

export function $createSSIAnchorNode(options: SSIAnchorOptions): SSIAnchorNode {
  return new SSIAnchorNode(options);
}

export function $isSSIAnchorNode(node: LexicalNode | null | undefined): node is SSIAnchorNode {
  return node instanceof SSIAnchorNode;
}
