import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { EditorState, LexicalEditor, ParagraphNode, TextNode } from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import { CustomListItemNode } from 'modules/Lexical/nodes/CustomListItemNode';
import { CustomListNode } from 'modules/Lexical/nodes/CustomListNode';
import { CustomParagraphNode } from 'modules/Lexical/nodes/CustomParagraphNode';
import { ReferenceCitationNode } from 'modules/Lexical/nodes/ReferenceCitationNode';
import { SSIAnchorNode } from 'modules/Lexical/nodes/SSIAnchorNode';
import { AbbreviationPlugin } from 'modules/Lexical/Plugins/AbbreviationPlugin';
import { BasePropsPlugin } from 'modules/Lexical/Plugins/BasePropsPlugin';
import { CustomListPlugin } from 'modules/Lexical/Plugins/CustomListPlugin';
import { EditModePlugin } from 'modules/Lexical/Plugins/EditModePlugin';
import { EditorRefPlugin } from 'modules/Lexical/Plugins/EditorRefPlugin';
import { FontBrandStylePlugin } from 'modules/Lexical/Plugins/FontBrandStylePlugin';
import { FontPlugin } from 'modules/Lexical/Plugins/FontPlugin';
import { HistoryPluginChangeHandler, HistoryWithExtraStatePlugin } from 'modules/Lexical/Plugins/HistoryWithExtraStatePlugin';
import { LinkNodePlugin } from 'modules/Lexical/Plugins/LinkNodePlugin';
import { PlaceholderPlugin } from 'modules/Lexical/Plugins/PlaceholderPlugin';
import { ReferenceCitationPlugin } from 'modules/Lexical/Plugins/ReferenceCitationPlugin';
import { SellectAllAutoFocusPlugin } from 'modules/Lexical/Plugins/SellectAllAutoFocusPlugin';
import { SSIAnchorPlugin } from 'modules/Lexical/Plugins/SSIAnchorPlugin';
import { TextBlockPlugin } from 'modules/Lexical/Plugins/TextBlockPlugin';
import { TextContentPlugin } from 'modules/Lexical/Plugins/TextContentPlugin';
import { mainTheme } from 'modules/Lexical/Theme/mainTheme';
import { SSIAnchorContextProvider } from 'modules/SSIAnchors/context/SSIAnchorContext';
import React, { forwardRef, RefObject, useCallback, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMap } from 'typings/DeepIMap';
import TextPlaceholder from 'components/ArtboardAssets/TextPlaceholder';
import { DefaultCustomStyle, DefaultLineHeight, SSIPosition } from 'const';
import { DefaultTextBrandStyle } from 'const/Styles';
import { isDraggingAnchor } from 'containers/App/selectors';
import { setDocument } from 'containers/Documents/actions';
import { BrandProps } from 'hooks/useBrandProps';
import * as Models from 'models';
import { LexicalOnChange } from '../hooks/useLexicalTextEditor';
import { EditorStateUpdatePlugin } from '../Plugins/EditorStateUpdatePlugin';

export const LEXICAL_TEXT_NODES = [
  TextNode,
  CustomParagraphNode,
  {
    replace: ParagraphNode,
    with: (): CustomParagraphNode => new CustomParagraphNode(),
  },
  HeadingNode,
  CustomListNode,
  {
    replace: ListNode,
    with: (node: ListNode): CustomListNode => new CustomListNode(node.__listType, node.__start),
  },
  CustomListItemNode,
  {
    replace: ListItemNode,
    with: (node: ListItemNode): CustomListItemNode => {
      return new CustomListItemNode(node.__value, node.__checked);
    },
    withKlass: CustomListItemNode,
  },
  QuoteNode,
  MarkNode,
  AutoLinkNode,
  LinkNode,
  ReferenceCitationNode,
  SSIAnchorNode,
];

export const editorConfig = {
  namespace: 'text-editor',
  editable: false,
  theme: mainTheme,
  onError: (error): void => {
    throw error;
  },
  nodes: LEXICAL_TEXT_NODES,
};

export type LexicalTextEditorRef = {
  editor: LexicalEditor;
};

type Props<T> = {
  id: string;
  editorState: string | null;
  wrapperRef: RefObject<HTMLDivElement> | undefined;
  onChange: LexicalOnChange;
  editMode: boolean;
  brandStyle: Models.BrandStyleMap | undefined;
  brandStyleChanged: boolean | undefined;
  brandProps: BrandProps;
  historyExtraState: T;
  historyExtraStateSetter: (value: T) => void;
  historyOnChange: HistoryPluginChangeHandler;
  referencesMap: Record<string, number>;
  ssiPosition: SSIPosition | false | undefined;
  textDocument: IMap<Models.TextComponent> | undefined;
  relation: IMap<Models.LayeredRegularRelation<Models.TextRelationStyles>>;
  saveHandler: () => void;
};

const PLACEHOLDER_MIN_HEIGHT = DefaultCustomStyle.FONT_SIZE * DefaultLineHeight;

export const LexicalTextEditor = forwardRef(function <T extends object>(props: Props<T>, ref: React.Ref<LexicalEditor>) {
  const {
    id,
    editorState,
    wrapperRef,
    editMode,
    brandStyle,
    brandStyleChanged,
    brandProps,
    onChange,
    historyExtraState,
    historyExtraStateSetter,
    historyOnChange,
    referencesMap,
    ssiPosition,
    textDocument,
    relation,
    saveHandler,
  } = props;

  const isDraggingAnchorNow = useSelector(isDraggingAnchor);

  const editorRef = useRef<LexicalEditor>(null);
  useImperativeHandle(ref, () => editorRef.current as LexicalEditor);

  const isSSIAnchorsVisible = Boolean(ssiPosition && textDocument?.get('id'));
  const dispatch = useDispatch();

  const modifyDocument = useCallback((state: EditorState): void => {
    if (!textDocument) {
      return;
    }
    dispatch(
      setDocument(
        textDocument.set('lexicalState', JSON.stringify(state)),
      ),
    );
  }, [textDocument]);

  return (
    <div id={id} ref={wrapperRef} style={{ minHeight: PLACEHOLDER_MIN_HEIGHT }}>
      <SSIAnchorContextProvider
        documentId={textDocument?.get('id')}
        relationId={relation.get('id')}
        isVisible={isSSIAnchorsVisible}
      >
        <LexicalComposer initialConfig={{
          ...editorConfig,
          editable: editMode,
          editorState,
        }}>
          <div className="editor-container">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            {editMode && <HistoryWithExtraStatePlugin
              extraState={historyExtraState}
              extraStateSetter={historyExtraStateSetter}
              mergeExtraStateKeys={['brandStyleChanged']}
              onChange={historyOnChange}
            />}
            <ListPlugin />
            <LinkPlugin />
            <EditorRefPlugin ref={editorRef} />
            <EditModePlugin editMode={editMode} />
            <TabIndentationPlugin maxIndent={5} />
            <BasePropsPlugin onChange={onChange.baseProps} />
            <TextBlockPlugin
              brandStyle={brandStyle || brandProps.brandStyles.get(DefaultTextBrandStyle)}
              brandStyleChanged={brandStyleChanged}
              onChange={onChange.textBlock}
            />
            <CustomListPlugin
              onChange={onChange.bulletToggle}
              colors={brandProps.colors}
              brandStyle={brandStyle}
              brandStyleChanged={brandStyleChanged}
            />
            <FontPlugin
              defaultBrandColor={brandStyle ? BrandDefinition.getDefaultBrandColor(brandStyle, brandProps.colors) : undefined}
              onChange={onChange.font}
              colors={brandProps.colors}
              fonts={brandProps.fonts}
            />
            <FontBrandStylePlugin
              brandStyle={brandStyle}
              brandStyleChanged={brandStyleChanged}
              colors={brandProps.colors}
              fonts={brandProps.fonts}
            />
            <AbbreviationPlugin onChange={onChange.abbreviations} />
            <LinkNodePlugin onChange={onChange.link} />
            <TextContentPlugin onChange={onChange.textContent} />
            <ReferenceCitationPlugin
              referencesMap={referencesMap}
              saveHandler={saveHandler}
            />
            <SellectAllAutoFocusPlugin />
            <PlaceholderPlugin placeholder={<TextPlaceholder />} />
            {isSSIAnchorsVisible &&
            <SSIAnchorPlugin showPlaceholder={isDraggingAnchorNow} modifyDocument={modifyDocument} />}
            <EditorStateUpdatePlugin editorState={editorState} />
          </div>
        </LexicalComposer>
      </SSIAnchorContextProvider>
    </div>
  );
});
