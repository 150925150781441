import Immutable from 'immutable';
import guid from 'uuid';

import { ComponentSize, DocumentsSource, EntityType } from 'const';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';

export const callToActionFactory = ({
  id = guid(),
  name = intlGet('Artboard.CallToAction', 'Title'),
  text = '',
  rawContent = '',
  lexicalState = '',
  link = '',
  size = ComponentSize.SMALL,
  documentSource = [DocumentsSource.ONLINE],
  language = [],
  country = [],
  translatable = true,
  isMockDocument = false,
} = {} as Partial<Models.CallToAction>): Models.CallToAction => ({
  entityType: EntityType.CALL_TO_ACTION,
  id,
  language,
  country,
  link,
  text,
  name,
  rawContent,
  lexicalState,
  size,
  documentSource,
  translatable,
  isMockDocument,
});

export const callToActionMapFactory = (
  callToAction: Partial<Models.CallToAction>,
): Models.CallToActionMap => Immutable.fromJS(callToActionFactory(callToAction));
