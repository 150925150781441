/* eslint-disable no-use-before-define */
import { ListNode, ListType, SerializedListNode } from '@lexical/list';
import { $applyNodeReplacement, EditorConfig, LexicalEditor, LexicalNode, LexicalUpdateJSON, NodeKey } from 'lexical';
import { BrandColorRef } from 'modules/BrandDefinition';

export type SerializedCustomListNode = SerializedListNode & {
  color: BrandColorRef | undefined;
};

const CSS_PROPERTY_FOR_BULLET_COLOR = '--bullet-color';
export class CustomListNode extends ListNode {
  __color?: BrandColorRef;

  static getType(): string {
    return 'custom-list';
  }

  constructor(listType: ListType, start: number, color?: BrandColorRef, key?: NodeKey) {
    super(listType, start, key);
    this.__color = color;
  }

  static clone(node: CustomListNode): CustomListNode {
    return new CustomListNode(node.__listType, node.__start, node.__color, node.__key);
  }

  setColor(color: BrandColorRef | undefined): this {
    this.getWritable().__color = color;

    return this;
  }

  getColor(): BrandColorRef | undefined {
    return this.getLatest().__color;
  }

  createDOM(config: EditorConfig, _editor?: LexicalEditor): HTMLElement {
    const element = super.createDOM(config, _editor);
    element.style.setProperty(CSS_PROPERTY_FOR_BULLET_COLOR, this.__color ? this.__color.HEX : null);

    return element;
  }

  updateDOM(prevNode: CustomListNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode as this, dom, config);
    dom.style.setProperty(CSS_PROPERTY_FOR_BULLET_COLOR, this.__color ? this.__color.HEX : null);

    return isUpdated;
  }

  exportJSON(): SerializedCustomListNode {
    const data = super.exportJSON() as unknown as SerializedCustomListNode;
    data.type = CustomListNode.getType();
    data.color = this.getColor();

    return data;
  }

  updateFromJSON(
    serializedNode: LexicalUpdateJSON<SerializedCustomListNode>,
  ): this {
    return this
      .setColor(serializedNode.color);
  }

  static importJSON(serializedNode: SerializedCustomListNode): CustomListNode {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
    return $createCustomListNode(serializedNode.listType, serializedNode.start).updateFromJSON(serializedNode);
  }
}

export function $isCustomListNode(node: LexicalNode | null | undefined): node is CustomListNode {
  return node instanceof CustomListNode;
}

export function $createCustomListNode(listType: ListType, start: number, color?: BrandColorRef): CustomListNode {
  return $applyNodeReplacement(new CustomListNode(listType, start, color));
}
