import { LinkNode, SerializedLinkNode } from '@lexical/link';
import { SerializedLexicalNode } from 'lexical';
import { DraftEntityData } from '../types';

export function createLinkNode(
  entityData: DraftEntityData,
  children: SerializedLexicalNode[],
): SerializedLinkNode {
  const { data } = entityData;

  return {
    type: LinkNode.getType(),
    rel: null,
    target: '_blank',
    title: null,
    url: data?.url ?? '',
    children,
    direction: 'ltr',
    format: '',
    version: 1,
    indent: 0,
  };
}
