import { getStyleObjectFromCSS } from '@lexical/selection';
import { TextNode } from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import { CSSProperties } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import { DefaultFontFamily } from 'services/ArtboardConverter/constants';
import { BrandProps } from 'services/ArtboardConverter/factories';
import { Schemas } from 'services/ArtboardConverter/models';
import { getDesktopBrandStyles } from 'utils/brandStyles';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { toPx } from 'utils/toPx';
import { FontPluginStyle, getBrandColorRefFromStyleObject, getBrandFontRefFromStyleObject } from '../../Plugins/FontPlugin/style';

function updateFontFamily(
  fontFamily: string,
  fontWeight: string = Constants.InlineFontStyleNoPrefix.REGULAR,
  fontStyle: string = Constants.InlineFontStyleNoPrefix.NORMAL,
): string {
  // IN-PROGRESS: seems like it will not work for lexical
  const needToApplyArtificialItalic = false;

  const fontFamilies = fontFamily.split(',');
  const fallBackFonts = fontFamilies.slice(1);
  const unwrappedMainFontFamily = fontFamilies[0].trim().replace(/'/g, '');
  const isUniqueFontFamilyNeeded = fontWeight !== Constants.InlineFontStyleNoPrefix.REGULAR
      && fontWeight !== Constants.InlineFontStyleNoPrefix.BOLD;
  const updatedFontStyle = needToApplyArtificialItalic
    ? Constants.InlineFontStyleNoPrefix.NORMAL
    : fontStyle;
  let updatedUnwrappedFontFamily;

  if (isUniqueFontFamilyNeeded) {
    updatedUnwrappedFontFamily = unwrappedMainFontFamily.includes(' ')
      ? `'${unwrappedMainFontFamily}-${updatedFontStyle}-${fontWeight}'`
      : `${unwrappedMainFontFamily}-${updatedFontStyle}-${fontWeight}`;
  } else {
    updatedUnwrappedFontFamily = unwrappedMainFontFamily.includes(' ')
      ? `'${unwrappedMainFontFamily}'`
      : `${unwrappedMainFontFamily}`;
  }

  return [updatedUnwrappedFontFamily, ...fallBackFonts].join(', ').replace(/\s+/g, ' ');
}

// textTransform property will be overrided on the upper level if needed
function getTextTransformNoneValue(
  node: TextNode,
  brandStyle: Models.BrandStyleMap | undefined,
): CSSProperties['textTransform'] | undefined {
  // IN-PROGRESS: correct types should be used
  const typesWithDisabledTextTransform = [
    // Constants.DraftEntity.ABBREVIATION,
    // Constants.DraftEntity.LINK,
    // Constants.DraftEntity.REFERENCE,
  ] as string[];

  if (typesWithDisabledTextTransform.includes(node.getType()) && brandStyle) {
    const breakpointStyles = getDesktopBrandStyles(brandStyle);
    const textTransform = breakpointStyles?.get(Models.BrandStyleProp.TEXT_TRANSFORM);
    if (textTransform && textTransform !== 'none') {
      return 'none';
    }
  }

  return undefined;
}

export type CreatePartParentStyles = {
  lineHeight: number;
  bulletColor?: BrandDefinition.BrandColorRef;
};

export function createPart(
  node: TextNode,
  parentStyles: CreatePartParentStyles,
  brandProps: BrandProps,
): Schemas.Part {
  const { colors, fonts, brandStyle } = brandProps;
  const { lineHeight, bulletColor: bulletColorRef } = parentStyles;
  const style = getStyleObjectFromCSS(node.getStyle()) as Record<string, string | undefined>;

  // ensures that actual brandStyle colors are taken into account
  const colorRef = getBrandColorRefFromStyleObject(style);
  const color = colorRef ? BrandDefinition.getBrandColorValueHEX(colors, colorRef) : undefined;

  // ensures that actual brandStyle fonts are taken into account
  const fontRef = getBrandFontRefFromStyleObject(style);
  const fontFamily = BrandDefinition.getBrandFontValueFontFamily(fonts, fontRef);
  const fontWeight = BrandDefinition.getBrandFontValueFontWeight(fonts, fontRef) ?? style[FontPluginStyle.FONT_WEIGHT];
  const fontStyle = BrandDefinition.getBrandFontValueFontStyle(fonts, fontRef) ?? style[FontPluginStyle.FONT_STYLE];

  const fontSize = style[FontPluginStyle.FONT_SIZE] ?? toPx(Constants.DefaultCustomStyle.FONT_SIZE);
  let fontSizeNumber = getIntegerFromStyle(fontSize);
  if (node.hasFormat('subscript') || node.hasFormat('superscript')) {
    fontSizeNumber *= Constants.SCRIPT_BASIC_FONT_REDUCTION;
  }

  const bulletColor = bulletColorRef ? BrandDefinition.getBrandColorValueHEX(colors, bulletColorRef) : undefined;

  return {
    bold: fontWeight === Constants.InlineFontStyleNoPrefix.BOLD,
    italic: fontStyle === Constants.InlineFontStyleNoPrefix.ITALIC,
    sub: node.hasFormat('subscript'),
    sup: node.hasFormat('superscript'),
    underline: node.hasFormat('underline'),
    fontFamily: fontFamily ? updateFontFamily(fontFamily, fontWeight, fontStyle) : DefaultFontFamily,
    fontSize: toPx(fontSizeNumber, 2),
    color: color ?? Constants.DefaultCustomStyle.FONT_COLOR,
    text: node.getTextContent(),
    textTransform: getTextTransformNoneValue(node, brandStyle),
    lineHeight: lineHeight.toString(),
    lineHeightPx: toPx(fontSizeNumber * lineHeight, 2),
    bulletColor: bulletColor ?? Constants.DefaultCustomStyle.FONT_COLOR,
  };
}
