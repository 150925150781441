import Draft from 'draft-js';
import { getReferenceNumberOrder } from 'modules/References/utils/getReferenceNumberOrder';
import * as DocumentModels from 'containers/Documents/models';
import * as Models from 'models';
import { applyEditorStateStyles, convertTextComponentToRawEditorState } from 'utils/editor';
import { compositeDecorator } from '../decorators';
import { addReferenceCitationsOrder } from './addReferenceCitationsOrder';

// TODO: revise logic
// incapsulate utils/editor/style/applyFontStylesForBrandStyles function
// revise components/ArtboardAssets/Text component to use getEditorStateFromTextComponent
export const getEditorStateFromTextComponent = (
  document: Models.TextComponentMap,
  relation: Models.RegularRelationMap<Models.TextRelationStyles>,
  referenceOrder: DocumentModels.ReferenceCitationsOrderByDocumentsMap,
  editMode = false,
): Draft.EditorState => {
  let editorState = convertTextComponentToRawEditorState(document);

  if (!document) {
    return editorState;
  }

  const styles = relation.get('styles').toJS() as Models.TextRelationStyles;
  styles.bulletColor = styles.bulletColor?.filter(({ blockKey }) => !!editorState.getCurrentContent().getBlockForKey(blockKey));
  const referencesNumbersOrder = getReferenceNumberOrder(relation, referenceOrder);

  editorState = applyEditorStateStyles(editorState, styles);
  editorState = addReferenceCitationsOrder(editorState, referencesNumbersOrder, editMode);

  // TODO: decorators should not be set in utils, remove it
  return Draft.EditorState.set(editorState, { decorator: compositeDecorator });
};
