import { EditorState, Modifier, RawDraftContentState, RawDraftEntity, SelectionState } from 'draft-js';
import Immutable from 'immutable';

import { DraftEditorStateChangeType, DraftEntity } from 'const';
import { BlockEntityMap } from 'models';
import { getSelectedEntities, trimSelection } from './selection';

export function removeEntities(editorState: EditorState): EditorState {
  const contentState = editorState.getCurrentContent();
  const contentWithoutEntities = Modifier.applyEntity(
    contentState,
    editorState.getSelection(),
    null,
  );

  return EditorState.push(
    editorState,
    contentWithoutEntities,
    DraftEditorStateChangeType.APPLY_ENTITY,
  );
}

export const reselectEntities = (editorState: EditorState): SelectionState => {
  const selection = trimSelection(editorState);
  const selectedEntities = getSelectedEntities(selection, editorState.getCurrentContent())
    .skipWhile(entity => entity.get('type') === DraftEntity.REFERENCE)
    .takeUntil(entity => entity.get('type') === DraftEntity.REFERENCE);

  const notSetEntity: BlockEntityMap = Immutable.Map({
    block: selection.getStartKey(),
    startOffset: selection.getStartOffset(),
    endOffset: selection.getStartOffset(),
    type: null,
    key: null,
  });

  const firstEntity = selectedEntities.first(notSetEntity);
  const lastEntity = selectedEntities.last(notSetEntity);

  return selection.merge({
    anchorKey: firstEntity.get('block'),
    anchorOffset: firstEntity.get('startOffset'),
    focusKey: lastEntity.get('block'),
    focusOffset: lastEntity.get('endOffset'),
    isBackward: false,
  }) as SelectionState;
};

export function iterateEntities(rawContent: RawDraftContentState, cb: (entity: RawDraftEntity) => void): void {
  const { blocks, entityMap } = rawContent;

  blocks.forEach(({ entityRanges }) => {
    entityRanges.forEach(({ key }) => {
      const entity = entityMap[key];
      entity && cb(entity);
    });
  });
}
