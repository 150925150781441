import { connect } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { createStructuredSelector } from 'reselect';

import Text from 'components/ArtboardAssets/Text';
import { TextActions, TextOwnProps, TextSelectors } from 'components/ArtboardAssets/Text/models';
import { setLastEditedLayoutId } from 'containers/App/actions';
import { isDraggingAsset, isResizingRow } from 'containers/App/selectors';
import { addTextToCell } from 'containers/ArtboardCell/actions';
import { activeSSI } from 'containers/Artboards/selectors';
import { removeAnchor, setDocument } from 'containers/Documents/actions';
import { imagesForAssetsPanel, referenceCitationsOrderByDocuments } from 'containers/Documents/selectors';
import { updateProjectState } from 'containers/Project/actions';
import { currentCountry, currentLanguage, placeholderMinHeight, projectType } from 'containers/Project/selectors';

const createMapStateToProps = createStructuredSelector({
  country: currentCountry,
  images: imagesForAssetsPanel,
  isDraggingAsset,
  isResizingRow,
  language: currentLanguage,
  placeholderMinHeight,
  projectType,
  referenceOrder: referenceCitationsOrderByDocuments,
  ssi: activeSSI,
});

const mapDispatchToProps = {
  addTextToCell,
  batchActions,
  removeAnchor,
  setLastEditedLayoutId,
  updateDocument: setDocument,
  updateProjectState,
};

export default connect<TextSelectors, TextActions, TextOwnProps>(createMapStateToProps, mapDispatchToProps)(Text);
