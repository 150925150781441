import {
  Spread,
  LexicalNode,
  TextNode,
  SerializedTextNode,
  LexicalUpdateJSON,
  IS_SUPERSCRIPT,
  IS_SUBSCRIPT,
  $applyNodeReplacement,
} from 'lexical';

export type SerializedReferenceCitationNode = Spread<{
  referenceId: string;
}, Omit<SerializedTextNode, 'mode'>>;

// lexical is not exporting constant IS_TOKEN
const IS_TOKEN = 1;

function fixFormat(format: number): number {
  return (format | IS_SUPERSCRIPT) & ~IS_SUBSCRIPT;
}

export class ReferenceCitationNode extends TextNode {
  static readonly TEXT_FOR_MISSING_NUMBER = '?';

  private __referenceId: string;

  constructor(referenceId: string, referenceNumber?: string, key?: string) {
    super(referenceNumber ?? ReferenceCitationNode.TEXT_FOR_MISSING_NUMBER, key);

    this.__referenceId = referenceId;
    this.__mode = IS_TOKEN;
  }

  static getType(): string {
    return 'reference-citation';
  }

  static clone(node: ReferenceCitationNode): ReferenceCitationNode {
    return new ReferenceCitationNode(node.__referenceId, node.__text, node.__key);
  }

  setMode(): this {
    // intentionally prevent change
    return this;
  }

  getReferenceId(): string {
    return this.getLatest().__referenceId;
  }

  setReferenceId(val: string): this {
    this.getWritable().__referenceId = val;

    return this;
  }

  createDOM(config, editor): HTMLElement {
    if (Object.getOwnPropertyDescriptor(this, '__format')?.writable) {
      const originalFormat = this.__format;
      this.__format = fixFormat(originalFormat);
      const dom = super.createDOM(config, editor);
      this.__format = originalFormat;

      return dom;
    } else {
      const clone = ReferenceCitationNode.importJSON({
        ...this.exportJSON(),
        format: fixFormat(this.__format),
      });

      return clone.createDOM(config, editor);
    }
  }

  updateDOM(prevNode, dom, config): boolean {
    if (Object.getOwnPropertyDescriptor(this, '__format')?.writable) {
      const originalFormat = this.__format;
      this.__format = fixFormat(originalFormat);
      const shouldUpdateDOM = super.updateDOM(prevNode, dom, config);
      this.__format = originalFormat;

      return shouldUpdateDOM;
    } else {
      const clone = ReferenceCitationNode.importJSON({
        ...this.exportJSON(),
        format: fixFormat(this.__format),
      });

      return clone.updateDOM(prevNode, dom, config);
    }
  }

  static importJSON(
    serializedNode: SerializedReferenceCitationNode,
  ): ReferenceCitationNode {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
    return $createReferenceCitationNode(serializedNode.referenceId).updateFromJSON(serializedNode);
  }

  // @ts-expect-error: intentionnaly violated as some props are skipped
  updateFromJSON(
    serializedNode: LexicalUpdateJSON<SerializedReferenceCitationNode>,
  ): this {
    this.setTextContent(serializedNode.text)
      .setDetail(serializedNode.detail)
      .setFormat(serializedNode.format)
      .setStyle(serializedNode.style);

    return this;
  }

  // @ts-expect-error: intentionnaly violated as some props are skipped
  exportJSON(): SerializedReferenceCitationNode {
    const { mode, ...other } = super.exportJSON();

    return {
      ...other,
      referenceId: this.getReferenceId(),
    };
  }
}

export function $createReferenceCitationNode(
  referenceId: string,
): ReferenceCitationNode {
  return $applyNodeReplacement(new ReferenceCitationNode(referenceId));
}

export function $isReferenceCitationNode(
  node: LexicalNode | null | undefined,
): node is ReferenceCitationNode {
  return node instanceof ReferenceCitationNode;
}
