import _ from 'lodash';
import guid from 'uuid';

import { ComponentSize, EntityType, Layer } from 'const';
import { LayoutColumnsMap, createLayeredLayout } from 'factories/layoutFactory';
import { createColumnRelation, createLayeredRelation, createRelation, createRowRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { createLayers } from './layers';

interface CreateLayoutAndRelationsResult {
  layout: Models.LayeredLayout;
  relations: Models.Relations;
}

interface CreateLayoutAndLayeredRelationsResult {
  layout: Models.LayeredLayout;
  relations: Models.LayeredRelations;
}

interface BaseOptions<S extends Models.CombinedRelationStyles = Models.CombinedRelationStyles> {
  regularRelationStyles?: S;
  layoutWidth?: number;
  size?: ComponentSize;
  height?: number;
}

interface CreateLayoutAndRelationsOptions<S extends Models.CombinedRelationStyles = Models.CombinedRelationStyles> extends BaseOptions<S> {
  createLayeredRelations?: false;
}

interface CreateLayoutAndLayeredRelationsOptions<S extends Models.CombinedRelationStyles = Models.CombinedRelationStyles> extends BaseOptions<S> {
  createLayeredRelations?: true;
  layer?: Layer;
}

export function createLayoutAndRelations(
  layoutData?: Partial<Models.LayeredLayout>,
  options?: CreateLayoutAndRelationsOptions,
): CreateLayoutAndRelationsResult;
export function createLayoutAndRelations(
  layoutData?: Partial<Models.LayeredLayout>,
  options?: CreateLayoutAndLayeredRelationsOptions,
): CreateLayoutAndLayeredRelationsResult;
export function createLayoutAndRelations(
  layoutData?: Partial<Models.LayeredLayout>,
  options?: CreateLayoutAndRelationsOptions | CreateLayoutAndLayeredRelationsOptions,
): CreateLayoutAndRelationsResult | CreateLayoutAndLayeredRelationsResult {
  const result = {
    relations: {},
  } as CreateLayoutAndRelationsResult | CreateLayoutAndLayeredRelationsResult;

  const {
    regularRelationStyles,
    createLayeredRelations,
    layoutWidth,
    size,
    layer,
  } = _.defaults(options as CreateLayoutAndRelationsOptions & CreateLayoutAndLayeredRelationsOptions, { createLayeredRelations: false });

  const layout = createLayeredLayout(layoutData);
  const { relationId, type } = layout;
  result.layout = layout;

  const layoutRowRelation = createRowRelation(
    { relationIds: LayoutColumnsMap[type].map(() => guid()) },
    { width: layoutWidth },
  );

  const columnRelation = createColumnRelation({ id: relationId, relationIds: [layoutRowRelation.id], height: options?.height });

  const [styles, layeredStyles] = regularRelationStyles
    ? [regularRelationStyles, createLayers({ [layer]: regularRelationStyles })]
    : [undefined, undefined];

  layoutRowRelation.relationIds.forEach((relationId, index) => {
    const relationTypes = LayoutColumnsMap[type][index] as EntityType[] | EntityType;

    if (Array.isArray(relationTypes)) {
      const cellRelations = relationTypes.map((entityType => createLayeredRelations
        ? createLayeredRelation(
          { entityType, styles: layeredStyles } as Models.LayeredRegularRelation,
          { size, layer },
        )
        : createRelation({ entityType, styles } as Models.RegularRelation, size)
      ));

      const columnRelation = createColumnRelation({
        relationIds: cellRelations.map(({ id }) => id),
        id: relationId,
      });

      _.assign(result.relations, _.keyBy(cellRelations, ({ id }) => id), { [relationId]: columnRelation });
    } else {
      const cellRelation = createLayeredRelations
        ? createLayeredRelation(
          { entityType: relationTypes, id: relationId, styles: layeredStyles } as Models.LayeredRelation,
          { size, layer },
        )
        : createRelation({ entityType: relationTypes, id: relationId, styles } as Models.RegularRelation, size);

      _.assign(result.relations, { [relationId]: cellRelation });
    }

  });
  _.assign(result.relations, { [layoutRowRelation.id]: layoutRowRelation });
  _.assign(result.relations, { [columnRelation.id]: columnRelation });

  return result;
}

interface CreateLayoutsAndRelationsResult {
  layouts: Models.LayeredLayouts;
  relations: Models.Relations;
}

interface CreateLayoutsAndLayeredRelationsResult {
  layouts: Models.LayeredLayouts;
  relations: Models.LayeredRelations;
}

export function createLayoutsAndRelations(
  layoutData?: Partial<Models.LayeredLayout>[] | Record<string, Partial<Models.LayeredLayout>>,
  options?: CreateLayoutAndRelationsOptions,
): CreateLayoutsAndRelationsResult;
export function createLayoutsAndRelations(
  layoutData?: Partial<Models.LayeredLayout>[] | Record<string, Partial<Models.LayeredLayout>>,
  options?: CreateLayoutAndLayeredRelationsOptions,
): CreateLayoutsAndLayeredRelationsResult;
export function createLayoutsAndRelations(
  layoutsData?: Partial<Models.LayeredLayout>[] | DeepPartial<Models.LayeredLayout>,
  options?: CreateLayoutAndRelationsOptions | CreateLayoutAndLayeredRelationsOptions,
): CreateLayoutsAndRelationsResult | CreateLayoutsAndLayeredRelationsResult {
  return _.reduce(
    layoutsData || [undefined],
    ({ layouts: createdLayouts, relations: createdRelations }, initialLayoutData: Partial<Models.LayeredLayout>) => {
      const { layout, relations } = createLayoutAndRelations(
        initialLayoutData,
        options as CreateLayoutAndRelationsOptions,
      ) as CreateLayoutAndRelationsResult | CreateLayoutAndLayeredRelationsResult;

      return {
        layouts: { ...createdLayouts, [layout.id]: layout },
        relations: { ...createdRelations, ...relations },
      } as CreateLayoutsAndRelationsResult | CreateLayoutsAndLayeredRelationsResult;
    },
    {} as CreateLayoutsAndRelationsResult | CreateLayoutsAndLayeredRelationsResult,
  );
}
