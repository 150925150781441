import Immutable from 'immutable';
import { $getRoot } from 'lexical';
import _ from 'lodash';
import { TextComponentMap } from 'models';
import { createLexicalTextEditor } from '../editors';
import { $getReferenceCitationNodes } from '../Plugins/ReferenceCitationPlugin/utils/getReferenceCitationNodes';

export function updateReferenceCitationsOnComponent(textComponent: TextComponentMap): TextComponentMap {
  const textComponentReferences = textComponent.get('referenceCitations').toJS() as string[];
  let referencesIds = [] as string[];

  const serializedState = textComponent.get('lexicalState');
  if (serializedState) {
    const editor = createLexicalTextEditor(serializedState);
    referencesIds = editor.read(
      () => $getReferenceCitationNodes($getRoot()).map(node => node.getReferenceId()),
    );
  }

  // textComponentReferences is an array of previous reference citation ids
  if (_.isEqual(textComponentReferences, referencesIds)) {
    return textComponent;
  }

  return textComponent.set('referenceCitations', Immutable.List<string>(referencesIds));
}

export function replaceReferenceCitationIdOnComponent(
  textComponent: TextComponentMap,
  referenceId: string,
  newReferenceCitationId: string,
): TextComponentMap {
  const serializedState = textComponent.get('lexicalState');
  if (!serializedState) {
    return textComponent;
  }

  const editor = createLexicalTextEditor(serializedState);
  editor.update(() => {
    $getReferenceCitationNodes($getRoot()).forEach((node) => {
      if (node.getReferenceId() === referenceId) {
        node.setReferenceId(newReferenceCitationId);
      }
    });
  }, { discrete: true });

  return textComponent.set('lexicalState', JSON.stringify(editor.getEditorState()));
}

