import { useCallback, useMemo, useRef } from 'react';
import { CTAStyles } from '../utils/styles';
import { StylesHook } from './useStyles';

export type HistoryExtraState = CTAStyles & {
  link: string;
  cellHeight: number;
  cellWidth: number;
  isAutoFitContent: boolean;
};

type Props = {
  isAutoFitContent: boolean;
  toggleAutoFitContent: () => void;
  cellHeight: number;
  cellWidth: number;
  toggleRowHeight: (newHeight: number) => number;
  toggleColumnWidth: (newWidth: number) => number;
};

type OtherProps = {
  link: string;
  toggleLink: (value: string) => void;
  height: number;
  width: number;
};

export type HistoryExtraStateHook = {
  extraState: HistoryExtraState;
  extraStateSetter: (value: HistoryExtraState) => void;
};

export function useHistoryExtraState(
  props: Props,
  stylesHook: StylesHook,
  other: OtherProps,
): HistoryExtraStateHook {
  const {
    isAutoFitContent,
    toggleAutoFitContent,
    cellHeight,
    cellWidth,
    toggleRowHeight,
    toggleColumnWidth,
  } = props;
  const { styles, setters: stylesSetters } = stylesHook;
  const { link, toggleLink, height, width } = other;

  const propsRef = useRef({ isAutoFitContent });
  propsRef.current = { isAutoFitContent };

  const extraState = useMemo((): HistoryExtraState => {
    // should be deep cloned on push data to state
    return {
      ...styles,
      height,
      width,
      cellHeight,
      cellWidth,
      isAutoFitContent,
      link,
    };
  }, [
    styles,
    cellHeight, cellWidth, isAutoFitContent,
    link, height, width,
  ]);

  const extraStateSetter = useCallback((state: HistoryExtraState) => {
    // styles
    stylesSetters.alignment(state.alignment);
    if (state.assetBackgroundGradient) {
      stylesSetters.assetBackgroundGradient(state.assetBackgroundGradient, state.assetBackgroundColor);
    } else {
      stylesSetters.assetBackgroundColor(state.assetBackgroundColor);
    }
    stylesSetters.assetBackgroundOpacity(state.assetBackgroundOpacity);
    stylesSetters.assetBorderRadius(state.assetBorderRadius);
    stylesSetters.assetPadding(state.assetPadding);
    if (state.backgroundGradient) {
      stylesSetters.backgroundGradient(state.backgroundGradient, state.backgroundColor);
    } else {
      stylesSetters.backgroundColor(state.backgroundColor);
    }
    stylesSetters.backgroundColorOpacity(state.backgroundColorOpacity);
    stylesSetters.backgroundImage(state.backgroundImage);
    stylesSetters.border(state.border);
    stylesSetters.borderRadius(state.borderRadius);
    stylesSetters.fitToCell(state.fitToCell);
    stylesSetters.padding(state.padding);
    stylesSetters.textAlignment(state.textAlignment);

    stylesSetters.height(state.height);
    stylesSetters.width(state.width);

    // props
    if (propsRef.current.isAutoFitContent !== state.isAutoFitContent) {
      toggleAutoFitContent();
    }
    toggleRowHeight(state.cellHeight);
    toggleColumnWidth(state.cellWidth);

    // other
    toggleLink(state.link);
  }, [
    stylesSetters,
    toggleAutoFitContent, toggleRowHeight, toggleColumnWidth,
    toggleLink,
  ]);

  return { extraState, extraStateSetter };
}
