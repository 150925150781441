import { $isTextNode } from 'lexical';
import * as Constants from 'const';
import { ParagraphType } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { toPx } from 'utils/toPx';
import { CustomParagraphNode } from '../../nodes/CustomParagraphNode';
import {
  CreateParagraphContext,
  getParagraphAlign, getParagraphAnchor, getParagraphFontFamily, getParagraphFontSize, getParagraphLineHeight,
} from './Paragraph';

export function createParagraphForText(
  node: CustomParagraphNode,
  context: CreateParagraphContext,
  segments: Schemas.Segment[],
): Schemas.TextParagraph {
  const { defaultStyles, defaultLineHeight, projectType } = context;
  const { color } = defaultStyles || {};

  const firstTextNode = node.getChildren().find($isTextNode);
  const fontSizeNumber = getParagraphFontSize(firstTextNode, defaultStyles, projectType);

  return {
    type: ParagraphType.PLAINT_TEXT,
    align: getParagraphAlign(node.getFormatType()),
    anchor: getParagraphAnchor(node),
    color: color ?? Constants.DefaultCustomStyle.FONT_COLOR,
    fontFamily: getParagraphFontFamily(defaultStyles),
    fontSize: toPx(fontSizeNumber ?? Constants.DefaultCustomStyle.FONT_SIZE),
    lineHeight: getParagraphLineHeight(node.getLineHeight() ?? 0, fontSizeNumber, defaultLineHeight),
    segments,
  };
}
