import { SerializedLinkNode } from '@lexical/link';
import { SerializedMarkNode } from '@lexical/mark/MarkNode';
import { RawDraftEntityRange, RawDraftInlineStyleRange } from 'draft-js';
import { SerializedReferenceCitationNode } from '../nodes/ReferenceCitationNode';
import { SerializedSSIAnchorNode } from '../nodes/SSIAnchorNode';

export type TCombination = {
  position: number[];
  style: string[] | null;
  type: string | null;
  data?: unknown;
};

export type DraftEntityData = {
  text: string;
  styles: string[];
  type?: string;
  data?: { url?: string; id?: string };
};

export enum ConvertStylesEnum {
  UNDERLINE = 'UNDERLINE',
  SUB = 'SUBSCRIPT',
  SUP = 'SUPERSCRIPT',
}

export enum LexicalFormatCodeEnum {
  BOLD = 1,
  ITALIC = 2,
  UNDERLINE = 8,
  DEFAULT = 0,
  SUB = 32,
  SUP = 64,
}

export type TRanges = RawDraftEntityRange & RawDraftInlineStyleRange;

export type RawDraftContentBlockData = {
  lineHeight?: number;
  [key: string]: unknown;
};

export type SerializedLexicalInlineNode = SerializedSSIAnchorNode
| SerializedMarkNode
| SerializedLinkNode
| SerializedReferenceCitationNode;
