import * as Constants from 'const';
import * as Models from 'models';
import { Schemas } from 'services/ArtboardConverter/models';
import { getDesktopBrandStyles } from 'utils/brandStyles';
import * as editorUtils from 'utils/editor';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { toPx } from 'utils/toPx';
import { createFont } from './createFont';
import { createScriptFormatting } from './createScriptFormatting';
import { createTextFormatting } from './createTextFormatting';

export const createPart = (values: Partial<Schemas.Part> = {}): Schemas.Part => {
  const {
    bulletColor = '',
    text = '',
    lineHeight,
    lineHeightPx,
    ...args
  } = values;

  return {
    ...createScriptFormatting(args),
    ...createTextFormatting(args),
    ...createFont(args),
    text,
    textTransform: args.textTransform,
    lineHeight: lineHeight as string,
    lineHeightPx: lineHeightPx as string,
    bulletColor,
  };
};

export type BrandProps = {
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
  brandStyle: Models.BrandStyleMap | undefined;
};

export function createPartTextSytles(
  charStyles: Draft.DraftInlineStyle,
  brandProps: BrandProps,
  lineHeight: string,
  entity: Draft.EntityInstance | undefined,
): Schemas.ExtendedTextStyles {
  const { colors, fonts, brandStyle } = brandProps;

  const {
    fontFamily,
    fontStyle,
    fontWeight,
    fontSize,
    color,
    bulletColor,
    needToApplyArtificialItalic,
  } = editorUtils.getCustomInlineStyle(colors, fonts)(charStyles);

  const recalculateFontSize = fontSize === Constants.DEFAULT_SCRIPT_FONT_SIZE;

  let updatedFontFamily: string | undefined;
  if (fontFamily) {
    const fontFamilies = fontFamily.split(',');
    const fallBackFonts = fontFamilies.slice(1);
    const unwrappedMainFontFamily = fontFamilies[0].trim().replace(/[']/g, '');
    const isUniqueFontFamilyNeeded = fontWeight !== Constants.InlineFontStyleNoPrefix.REGULAR
      && fontWeight !== Constants.InlineFontStyleNoPrefix.BOLD;
    const updatedFontStyle = needToApplyArtificialItalic
      ? Constants.InlineFontStyleNoPrefix.NORMAL
      : fontStyle;
    let updatedUnwrappedFontFamily;

    if (isUniqueFontFamilyNeeded) {
      updatedUnwrappedFontFamily = unwrappedMainFontFamily.includes(' ')
        ? `'${unwrappedMainFontFamily}-${updatedFontStyle}-${fontWeight}'`
        : `${unwrappedMainFontFamily}-${updatedFontStyle}-${fontWeight}`;
    } else {
      updatedUnwrappedFontFamily = unwrappedMainFontFamily.includes(' ')
        ? `'${unwrappedMainFontFamily}'`
        : `${unwrappedMainFontFamily}`;
    }
    updatedFontFamily = [updatedUnwrappedFontFamily, ...fallBackFonts].join(', ');
  }

  const fontStyles = createFont({
    fontFamily: updatedFontFamily?.replace(/\s+/g, ' '),
    ...(!recalculateFontSize && { fontSize }),
    color,
  });

  // convert fontSize reduction from 75% to px value for sub/sup scripts
  if (recalculateFontSize) {
    fontStyles.fontSize = editorUtils.getScriptFontsize(getIntegerFromStyle(fontStyles.fontSize));
  }

  const { isBold, isItalic } = editorUtils.checkStylesForBoldOrItalic(charStyles);

  const styles: Schemas.ExtendedTextStyles = {
    ...fontStyles,
    ...createScriptFormatting({
      sup: charStyles.includes(Constants.ScriptType.SUPERSCRIPT),
      sub: charStyles.includes(Constants.ScriptType.SUBSCRIPT),
    }),
    ...createTextFormatting({
      bold: isBold,
      italic: isItalic,
      underline: charStyles.includes(Constants.InlineStyle.UNDERLINE),
    }),
    bulletColor,
    lineHeight,
    lineHeightPx: toPx(getIntegerFromStyle(fontStyles.fontSize) * Number(lineHeight), 2),
  };

  const typesWithDisabledTextTransform = [
    Constants.DraftEntity.ABBREVIATION,
    Constants.DraftEntity.LINK,
    Constants.DraftEntity.REFERENCE,
  ] as string[];
  const entityType = entity?.getType();

  if (entityType && typesWithDisabledTextTransform.includes(entityType) && brandStyle) {
    const breakpointStyles = getDesktopBrandStyles(brandStyle);
    const textTransform = breakpointStyles?.get(Models.BrandStyleProp.TEXT_TRANSFORM);
    if (textTransform && textTransform !== 'none') {
      styles.textTransform = 'none';
    }
  }

  return styles;
}
