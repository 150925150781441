import { LexicalEditor } from 'lexical';
import { RefObject, useMemo, useRef } from 'react';

export function useLexicalStateCache(
  editorRef: RefObject<LexicalEditor>,
  editMode: boolean,
  lexicalState: string | null,
): string | null {
  const editModeRef = useRef(editMode);

  const cachedLexicalState = useMemo(() => ({ current: lexicalState }), [lexicalState]);

  // The logic should not be placed inside the effect. Otherwise it will cause unwanted dummy render
  if (editModeRef.current !== editMode && !editMode && editorRef.current) {
    cachedLexicalState.current = JSON.stringify(editorRef.current.getEditorState());
  }
  editModeRef.current = editMode;

  return cachedLexicalState.current;
}
