import _ from 'lodash';
import * as Models from 'models';
import { isReusableLayoutDocument } from './entityType';

export function deleteLexicalState<T extends { documents: Models.CombinedDocuments }>(arg: T): T {
  function deleteDocumentLexicalState<S extends Models.CombinedDocument>(document: S): S {
    if (!document) {
      return document;
    }

    const keysToRemove = ['lexicalState'];

    return _(document)
      .omit(keysToRemove)
      .thru((doc: unknown) => isReusableLayoutDocument(doc as Models.CombinedDocument)
        ? deleteLexicalState(doc as { documents: Models.CombinedDocuments })
        : doc,
      )
      .value() as S;
  }

  const documents = _.mapValues(arg.documents, deleteDocumentLexicalState);

  return {
    ...arg,
    documents,
  };
}
