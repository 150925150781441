import Draft from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import * as editorUtils from 'utils/editor';

export const convertRawContentToXliff = (rawContent: string | undefined): string => {
  if (rawContent) {
    const rawContentState: Draft.RawDraftContentState = JSON.parse(rawContent);
    const draftContentState = Draft.convertFromRaw(rawContentState);

    return stateToHTML(draftContentState, editorUtils.getXliffTextStateToHTMLOptions());
  }

  return '';
};
