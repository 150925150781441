import Draft from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import Immutable from 'immutable';
import _ from 'lodash';
import * as Constants from 'const';
import * as Models from 'models';
import { colorToValue, fontColorFromRelation } from 'utils/converters';
import { applyEditorStateStyles, isolateEditorStateStyles, resetEditorStateStyles } from 'utils/editor/style';
import { mergeObjectsDeep } from 'utils/mergeObjectsDeep';
import * as editorUtils from './index';
import { getValues } from '../getter';
import { replaceBlocksKeys } from './blockType';
import { toggleBrandStyleColor, toggleBrandStyleFontFamily, toggleFontSize } from './brandStyle';
import { setFullSelection } from './selection';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createEditorStateWithContent = (contentBlocks: Draft.ContentBlock[], entityMap: any): Draft.EditorState => {
  if (!contentBlocks || contentBlocks.length === 0) {
    return Draft.EditorState.createEmpty();
  }

  return Draft.EditorState.createWithContent(
    Draft.ContentState.createFromBlockArray(contentBlocks, entityMap),
  );
};

/**
 * Converts Text Component html format to DraftJS Editor State using `html-to-draftjs` library.
 */
export const htmlToEditorState = (html = ''): Draft.EditorState => {
  // HACK: we must replace <u> tags with <ins> due to `html-to-draftjs` doesn't support regular underline tags
  // https://github.com/jpuri/html-to-draftjs/blob/3da7a604761f519caf25a6cdd7bbfe5e1d9f8974/src/library/processInlineTag.js
  const precessedHtml = html
    .replace(/([<|\/])u(>)/gi, '$1ins$2')
    // remove all whitespace characters between UL tags to avoid extra whitespaces in content (only for lists)
    .replace(/(>)\s+(<\/?(ul|li))/gi, '$1$2')
    .replace(/\n\s*(<\/?(ul|li))/gi, '$1');

  const { contentBlocks, entityMap } = htmlToDraft(precessedHtml);

  return createEditorStateWithContent(contentBlocks, entityMap);
};

/**
 * Converts Reference Citation text (html format) to DraftJS Editor State.
 * NOTE: `Draft.convertFromHTML` is used instead of `html-to-draftjs` library.
 * Reasons:
 * - `html-to-draftjs` does NOT support `u` tags
 * - `convertFromHTML` supports all HTML nodes available for reference citation text styling
 */
export const convertReferenceCitationToEditorState = (text = ''): Draft.EditorState => {
  const { contentBlocks, entityMap } = Draft.convertFromHTML(text);

  return createEditorStateWithContent(contentBlocks, entityMap);
};

export const getEditorStateFromCallToAction = (
  document: Models.CallToActionMap,
  relation: Models.RegularRelationMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
  projectType: string,
): Draft.EditorState => {
  const { text = '', name = '', rawContent = '' } = getValues(document, ['text', 'name', 'rawContent']);
  const isLegacyCallToAction = name === text || !rawContent;

  if (!isLegacyCallToAction) {
    return editorUtils.applyFontStyles(convertTextComponentToRawEditorState(document), fonts, projectType);
  }

  const fontDecoration = relation.getIn(['styles', 'fontDecoration']);
  const fontSize = relation.getIn(['styles', 'fontSize']);
  const fontColor = fontColorFromRelation(colors, relation);
  const color = colorToValue(fontColor, Constants.DefaultCallToActionFontColor);
  const fontFamilyStyle = relation.getIn(['styles', 'fontFamily']);
  const fontStyleStyle = relation.getIn(['styles', 'fontStyle']);

  let editorState = Draft.EditorState.createWithContent(Draft.ContentState.createFromText(name));
  editorState = setFullSelection(editorState);

  if (fontDecoration) {
    editorState = fontDecoration.get(Constants.FontDecoration.BOLD) ? Draft.RichUtils.toggleInlineStyle(editorState, 'BOLD') : editorState;
    editorState = fontDecoration.get(Constants.FontDecoration.ITALIC) ? Draft.RichUtils.toggleInlineStyle(editorState, 'ITALIC') : editorState;
    editorState = fontDecoration.get(Constants.FontDecoration.UNDERLINE) ? Draft.RichUtils.toggleInlineStyle(editorState, 'UNDERLINE') : editorState;
  }
  editorState = toggleFontSize(editorState, fontSize);
  editorState = toggleBrandStyleColor(editorState, color, colors);
  editorState = toggleBrandStyleFontFamily(editorState, fontFamilyStyle, fontStyleStyle, fonts);

  return editorUtils.applyFontStyles(editorState, fonts, projectType);
};

export const convertTextComponentToRawEditorState = (
  component: Models.TextComponent | Models.TextComponentMap,
): Draft.EditorState => {
  const { rawContent = '', text = '' } = getValues(component, ['rawContent', 'text']);

  if (rawContent) {
    return Draft.EditorState.createWithContent(Draft.convertFromRaw(JSON.parse(rawContent)));
  }

  return htmlToEditorState(text);
};

export const hasText = (editorState: Draft.EditorState): boolean => {
  return !!editorState.getCurrentContent().getPlainText().trim();
};

interface PasteContentStyle {
  inlineStyles: Draft.DraftInlineStyle;
  blockAlignment: Constants.TextHorizontalAlignmentType;
  blockLineHeight: Constants.TextLineHeightValue;
}

export const insertText = (
  content: Draft.ContentState,
  selection: Draft.SelectionState,
  html: string,
  pasteOnlineStyle: PasteContentStyle,
): Draft.ContentState => {
  const { blockAlignment, blockLineHeight, inlineStyles } = pasteOnlineStyle;
  let { contentBlocks } = Draft.convertFromHTML(html);

  // During copy-pasting text we need to save current selection inline style, blockType and blockData.
  // Draft.convertFromHTML creates blocks without inline styles
  // Draft.Modifier.replaceWithText doesn't cleanup HTML tags that provides by source we copy from. [DO NOT USE replaceWithText]
  contentBlocks = contentBlocks && contentBlocks.reduce(
    (acc, block) => {
      if (block.getText().trim()) {
        const data = block.getData().merge({ [Constants.BlockDataKey.LINE_HEIGHT]: blockLineHeight });
        const characterList: Draft.CharacterMetadata[] = [];

        block.getCharacterList().forEach(() => {
          const charMeta = Draft.CharacterMetadata.create({ style: inlineStyles });
          characterList.push(charMeta);
        });

        acc.push(block.merge({
          characterList: Immutable.List(characterList),
          data,
          type: blockAlignment,
        }) as Draft.ContentBlock);
      }

      return acc;
    },
    [] as Draft.ContentBlock[],
  );

  const fragment = Draft.ContentState.createFromBlockArray(contentBlocks);

  return Draft.Modifier.replaceWithFragment(content, selection, fragment.getBlockMap());
};

export function getSerializedRawContent(editorState: Draft.EditorState): string {
  return JSON.stringify(Draft.convertToRaw(editorState.getCurrentContent()));
}

export const adjustStylesForTextComponentDuplicate = (
  styles: Models.TextRelationStyles,
  textComponent: Models.TextComponent | Models.TextComponentMap,
  textComponentDuplicate: Models.TextComponent | Models.TextComponentMap,
): Models.TextRelationStyles => {
  const oldEditorState = convertTextComponentToRawEditorState(textComponent);
  const newEditorState = convertTextComponentToRawEditorState(textComponentDuplicate);

  const editorStateStyles = _.flow(
    editorState => applyEditorStateStyles(editorState, styles),
    editorState => replaceBlocksKeys(editorState, newEditorState),
    isolateEditorStateStyles,
  )(oldEditorState);

  return mergeObjectsDeep({}, resetEditorStateStyles(styles), editorStateStyles);
};

function isEntityOnKeyOfType(entityKey: string, entityType: Constants.DraftEntity): boolean {
  const entity = entityKey && Draft.Entity.get(entityKey);

  return Boolean(entity && entity.getType() === entityType);
}

export function isInsertingSpaceAfterLink(editorState: Draft.EditorState, chars: string): boolean {
  const selection = editorState.getSelection();
  const block = editorState.getCurrentContent().getBlockForKey(selection.getEndKey());
  const endOffset = selection.getEndOffset();

  const leftSymbolKey = block.getEntityAt(endOffset - 1);
  const isLeftSymbolKeyEntityLink = isEntityOnKeyOfType(leftSymbolKey, Constants.DraftEntity.LINK);
  const rightSymbolKey = block.getEntityAt(endOffset);
  const isRightSymbolKeyEntityLink = isEntityOnKeyOfType(rightSymbolKey, Constants.DraftEntity.LINK);

  return (chars === Constants.Character.SPACE && isLeftSymbolKeyEntityLink && !isRightSymbolKeyEntityLink);
}
export function hasToken(editorState: Draft.EditorState): boolean;
export function hasToken(textContent: string): boolean;
export function hasToken(value: Draft.EditorState | string): boolean {
  const currentContent = typeof value === 'string' ? value : value.getCurrentContent().getPlainText();

  return _(Constants.TextToken)
    .values()
    .some(token => currentContent.includes(token));
}
