import { $getSelection, $isRangeSelection, LexicalNode } from 'lexical';
import { $isCustomListItemNode, CustomListItemNode } from 'modules/Lexical/nodes/CustomListItemNode';
import { $isCustomParagraphNode, CustomParagraphNode } from 'modules/Lexical/nodes/CustomParagraphNode';
import * as Constants from 'const';
import { TextBlockProps } from '../types';

export type NodeWithLineHeight = CustomParagraphNode | CustomListItemNode;

export function $hasLineHeight(node: LexicalNode | null): node is NodeWithLineHeight {
  if (!node) {
    return false;
  }

  return $isCustomParagraphNode(node) || $isCustomListItemNode(node);
}

export function $getBlockLineHeightFromSelection(): TextBlockProps['blockLineHeight'] {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const heights = selection.getNodes()
      .map(node => $hasLineHeight(node) ? node : node.getParent())
      .filter($hasLineHeight)
      .map((node: NodeWithLineHeight) => node.getLineHeight() ?? Constants.DefaultLineHeight);

    if (heights.length) {
      const firstHeight = heights[0];
      const uniqueHeights = new Set(heights);

      return uniqueHeights.size === 1 ? firstHeight : undefined;
    }
  }

  return Constants.DefaultLineHeight;
}

export function $applyLineHeight(
  node: LexicalNode | null,
  value: Constants.TextLineHeightValue | undefined,
): void {
  if (!value || !$hasLineHeight(node)) {
    return;
  }
  node.setLineHeight(value);
}
