import Immutable from 'immutable';
import { useCallback } from 'react';
import { IMap } from 'typings/DeepIMap';
import * as Models from 'models';
import { ContentHeightCache } from 'services/contentHeightCache';
import { getExtraHeight } from 'utils/getExtraHeight';
import { StylesHook } from './useStyles';

type ResetHeightProps = {
  cellHeight: number;
  isResizingRow: boolean;
  relation: Models.LayeredRegularRelationMap<Models.CallToActionStyles>;
  toggleRowAndNeighborsHeight: (newHeight: number, currentLayeredRelation?: Models.LayeredRelationsMap) => void;
  isAutoFitContent: boolean;
  layoutRelations: IMap<Models.LayeredRelations<Models.CombinedRelationStyles>>;
};

export type ResetHeightGroup = {
  resetHeightInSetters: (minHeight: number) => void;
  resetHeightOnEffect: () => void;
};

export const useResetHeight = (
  props: ResetHeightProps,
  stylesHook: StylesHook,
  cellHeightBeforeEdit: number,
): ResetHeightGroup => {

  const { styles, setters: stylesSetters } = stylesHook;

  const {
    cellHeight,
    isResizingRow,
    relation,
    toggleRowAndNeighborsHeight,
    isAutoFitContent,
    layoutRelations,
  } = props;

  const resetHeight = useCallback((
    heightValue: number,
    paddingValue: Models.PaddingMap,
    borderValue?: Models.BorderMap,
    currentLayeredRelation?: Models.LayeredRelationsMap,
  ): void => {
    const relationId = relation.get('id');
    const style = Immutable.Map({
      padding: paddingValue,
      border: borderValue || { width: { top: 0, right: 0, bottom: 0, left: 0 } },
    }) as Models.CommonStylesMap;
    const extraHeight = getExtraHeight(style);
    const newVerifiedHeight = heightValue + extraHeight;

    const contentHeights = ContentHeightCache.getInstance();
    contentHeights.setItem(relationId, heightValue + extraHeight);

    const shouldUpdateCellHeight = isAutoFitContent && !isResizingRow
      ? newVerifiedHeight !== cellHeight
      : newVerifiedHeight > cellHeight;

    if (cellHeight && shouldUpdateCellHeight) {
      toggleRowAndNeighborsHeight(newVerifiedHeight, currentLayeredRelation);
    }
  }, [isAutoFitContent, isResizingRow, cellHeight]);

  const resetHeightInSetters = useCallback((minHeight: number) => {
    if (minHeight > styles.height) {
      resetHeight(styles.height, styles.padding, styles.border);
      stylesSetters.height(minHeight);
    }
  }, [styles, stylesSetters]);

  const resetHeightOnEffect = useCallback(() => {
    if (cellHeightBeforeEdit < styles.height || isAutoFitContent) {
      resetHeight(styles.height, styles.padding, styles.border, layoutRelations);
    }
  }, [cellHeightBeforeEdit, styles.height, isAutoFitContent, styles.padding, styles.border]);

  return {
    resetHeight,
    resetHeightInSetters,
    resetHeightOnEffect,
  };
};
