import sanitizeHtml from 'sanitize-html';

export function cleanUpHtml(html: string): string {
  return sanitizeHtml(html, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'u', 'sup', 'sub', 'strike', 's'],
    allowedAttributes: { a: ['href', 'target', 'title'] },
    transformTags: {
      i: 'em',
    },
  });
}
