import React, { createContext, ReactNode, useContext } from 'react';
import { AssetAlignment } from 'models';

type ResizeContextType = {
  alignment: AssetAlignment;
  container: HTMLDivElement | undefined;
  startResizing?: (currentWidth: number, currentHeight: number) => void;
  finishResizing: (actualWidth: number, actualHeight: number) => void;
  onResize: (newHeight: number) => void;
  width: number;
  height: number;
  minHeight: number;
};

const ResizeContext = createContext<ResizeContextType | null>(null);

export const useResizeContext = (): ResizeContextType => {
  const context = useContext(ResizeContext);

  if (!context) {
    throw new Error('useResizeContext must be used within a ResizeProvider');
  }

  return context;
};

interface ResizeProviderProps {
  value: ResizeContextType;
  children: ReactNode;
}

export const ResizeProvider: React.FC<ResizeProviderProps> = ({ children, value }) => {
  return <ResizeContext.Provider value={value}>{children}</ResizeContext.Provider>;
};
