import { $patchStyleText } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection, $isRangeSelection,
  COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_HIGH, FORMAT_TEXT_COMMAND,
  LexicalEditor, createCommand,
} from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import { getStyleObjectForBrandColor, getStyleObjectForBrandFont } from './style';
import { $getInlineStyle, $patchFontSize, $patchFontStyle, $patchFontWeight } from './utils';

export const FONT_COMMAND = {
  BRAND_COLOR: createCommand<Models.BrandColorMap | undefined>(),
  BRAND_FONT: createCommand<{
    brandFont: Models.BrandFontMap;
    characterStyle?: Models.CharacterStyleMap;
  }>(),
  SIZE: createCommand<number>(),
  INLINE_STYLE: createCommand<Constants.InlineStyle>(),
} as const;

export function registerCommands(
  editor: LexicalEditor,
  fonts: Models.BrandFontsList,
  defaultBrandColor: BrandDefinition.BrandColorRef | undefined,
): () => void {
  return mergeRegister(

    editor.registerCommand(
      FONT_COMMAND.BRAND_COLOR,
      (color) => {
        const selection = $getSelection();
        if (!selection || !$isRangeSelection(selection)) {
          return false;
        }
        $patchStyleText(
          selection,
          color ? getStyleObjectForBrandColor(color) : getStyleObjectForBrandColor(defaultBrandColor),
        );

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FONT_COMMAND.BRAND_FONT,
      ({ brandFont, characterStyle }) => {
        const selection = $getSelection();
        if (!selection || !$isRangeSelection(selection)) {
          return false;
        }
        const fontRef = { fontName: brandFont.get('name'), characterStyleName: characterStyle?.get('name') };
        $patchStyleText(selection, getStyleObjectForBrandFont(fonts, fontRef));

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FONT_COMMAND.SIZE,
      (size) => {
        const selection = $getSelection();
        if (selection && $isRangeSelection(selection)) {
          $patchFontSize(selection, size);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FONT_COMMAND.INLINE_STYLE,
      (style) => {
        if (style === Constants.InlineStyle.UNDERLINE) {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');

          return true;
        }

        const selection = $getSelection();
        if (!selection && !$isRangeSelection(selection)) {
          return false;
        }

        const inlineStyles = $getInlineStyle();
        switch (style) {
          case Constants.InlineStyle.BOLD:
            $patchFontWeight(selection, !inlineStyles.includes(Constants.InlineStyle.BOLD));
            break;
          case Constants.InlineStyle.ITALIC:
            $patchFontStyle(selection, inlineStyles.includes(Constants.InlineStyle.ITALIC));
            break;
          default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-console
            console.error(`unknown style ${style}`);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FORMAT_TEXT_COMMAND,
      (formatType) => {
        if (formatType === 'bold') {
          editor.dispatchCommand(FONT_COMMAND.INLINE_STYLE, Constants.InlineStyle.BOLD);

          return true;
        }
        if (formatType === 'italic') {
          editor.dispatchCommand(FONT_COMMAND.INLINE_STYLE, Constants.InlineStyle.ITALIC);

          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH,
    ),
  );
}
