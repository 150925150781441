import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OrderedSet } from 'immutable';
import { $getNodeByKey, TextNode } from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import { useEffect } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import { $switchCharacterStyle } from './characterStyle';
import { registerCommands } from './commands';
import { $getBrandColor, $getBrandFont, $getFontSize, $getInlineStyle } from './utils';

export { FONT_COMMAND } from './commands';

export type FontProps = {
  brandColor: Models.BrandColorMap | undefined;
  brandFont: BrandDefinition.BrandFontRef | undefined;
  size: number | undefined;
  inlineStyle: OrderedSet<Constants.InlineStyle>;
};

type Props = {
  defaultBrandColor?: BrandDefinition.BrandColorRef;
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
  onChange: (props: FontProps) => void;
};

export function FontPlugin(props: Props): null {
  const { onChange, colors, fonts, defaultBrandColor } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => registerCommands(editor, fonts, defaultBrandColor),
    [editor, fonts, defaultBrandColor],
  );

  useEffect(() => {
    return editor.registerMutationListener(
      TextNode,
      (mutatedNodes) => {
        editor.update(() => {
          for (const [nodeKey, mutation] of mutatedNodes) {
            if (mutation !== 'updated') {
              continue;
            }
            const node = $getNodeByKey<TextNode>(nodeKey);
            if (!node) {
              continue;
            }
            $switchCharacterStyle(node, fonts);
          }
        });
      },
      { skipInitialization: true },
    );
  }, [editor]);

  useEffect(() => {
    const $readAndNotifyChange = (): void => onChange({
      brandColor: $getBrandColor(colors),
      brandFont: $getBrandFont(),
      size: $getFontSize(),
      inlineStyle: OrderedSet($getInlineStyle()),
    });

    editor.getEditorState().read($readAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read($readAndNotifyChange);
    });
  }, [editor, onChange, colors]);

  return null;
}
