import classNames from 'classnames';
import React from 'react';

import Column from 'components/ArtboardLayout/components/Column';
import ArtboardCell from 'containers/ArtboardCell';
import RowResizer from 'containers/RowResizer';
import * as Models from 'models';
import { getMinColumnWidth } from 'utils/relations/getMinColumnWidth';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { RowProps } from './models';
import styles from './styles.module.scss';

const Row: React.FunctionComponent<RowProps> = (props) => {
  const {
    activeLayoutIds,
    columnRelationId: parentColumnRelationId,
    disableCellWidthEditing = false,
    editMode,
    height,
    isLast,
    isLayoutEmpty,
    isOpenToolbar,
    isResizingRow,
    isReusableLayout,
    isSectionActive,
    layout,
    layoutId,
    layoutRelations,
    level,
    positionWithinColumn,
    relation,
    sectionStyles,
    setRowHeightSilently,
    showRowResizer = false,
    ssi,
    toggleExpandedMode,
    toggleLayoutRelations,
  } = props;
  const rowRelationId = relation.get('id');
  const relationIds = relation.get('relationIds');
  const columnsWidth = relation.getIn(['styles', 'columnsWidth']);
  const container = React.useRef<HTMLDivElement>();
  const hasHeightBeenDispatched = React.useRef<boolean>(false);

  React.useEffect(
    () => {
      if (height || !container.current) {
        return;
      }
      const { clientHeight: newHeight } = container.current;

      if (newHeight === height) {
        hasHeightBeenDispatched.current = false;

        return;
      }

      if (hasHeightBeenDispatched.current) {
        return;
      }

      setRowHeightSilently(parentColumnRelationId, positionWithinColumn, newHeight);
      hasHeightBeenDispatched.current = true;
    },
    [],
  );

  return (
    <div
      className={classNames(styles.Row, {
        [styles.stretchable]: isLast && !height,
      })}
      ref={container}
    >
      {
        // initially sliders are shown only within active section,
        // but when user resizes row on border of two section and move away from active one we should not hide slider
        (isSectionActive || isResizingRow) && showRowResizer &&
        <RowResizer
          container={container}
          height={height}
          layoutId={layoutId}
          relationIsBeingResizedId={relation.get('id')}
          relations={layoutRelations}
          toggleRelations={toggleLayoutRelations}
        />
      }
      {relationIds.map((columnRelationId, columnPosition) => {
        const currentRelation = layoutRelations.get(columnRelationId) as Models.ColumnRelationMap | Models.LayeredRegularRelationMap;
        const isNotLastColumn = columnPosition !== relationIds.size - 1;
        const showColumnResizer = isNotLastColumn && !isOpenToolbar;
        const columnWidth = columnsWidth.get(columnPosition);
        const columnsCount = relationIds.size;

        if (isRegularRelation(currentRelation)) {
          // All cells are changed along with the next one except the last one. The last cell changes along with the previous one.
          const isIncreasingWidthDisabled = isNotLastColumn
            ? getMinColumnWidth(relationIds.get(columnPosition + 1), layoutRelations) >= columnsWidth.get(columnPosition + 1)
            : getMinColumnWidth(relationIds.get(columnPosition - 1), layoutRelations) >= columnsWidth.get(columnPosition - 1);

          return (
            <ArtboardCell
              cellPosition={0}
              cellsCount={1}
              columnRelationId={parentColumnRelationId}
              columnsCount={columnsCount}
              disableCellWidthEditing={disableCellWidthEditing}
              height={height}
              isIncreasingWidthDisabled={isIncreasingWidthDisabled}
              isLayoutEmpty={isLayoutEmpty}
              isReusableLayout={isReusableLayout}
              isSectionActive={isSectionActive}
              key={columnRelationId}
              layout={layout}
              layoutId={layoutId}
              layoutRelations={layoutRelations}
              notEditable={!!ssi}
              parentRelationId={rowRelationId}
              positionWithinColumn={positionWithinColumn}
              relation={currentRelation}
              sectionStyles={sectionStyles}
              setRowHeightSilently={setRowHeightSilently}
              showColumnResizer={showColumnResizer}
              showRowResizer={false}
              toggleExpandedMode={toggleExpandedMode}
              toggleLayoutRelations={toggleLayoutRelations}
              width={columnWidth}
            />
          );
        }

        return (
          <Column
            activeLayoutIds={activeLayoutIds}
            columnsCount={columnsCount}
            disableCellWidthEditing={disableCellWidthEditing}
            editMode={editMode}
            height={height}
            isLayoutEmpty={isLayoutEmpty}
            isOpenToolbar={isOpenToolbar}
            isReusableLayout={isReusableLayout}
            isSectionActive={isSectionActive}
            key={columnRelationId}
            layout={layout}
            layoutId={layoutId}
            layoutRelations={layoutRelations}
            level={level + 1}
            relation={currentRelation}
            sectionStyles={sectionStyles}
            setRowHeightSilently={setRowHeightSilently}
            showColumnResizer={showColumnResizer}
            ssi={ssi}
            toggleExpandedMode={toggleExpandedMode}
            toggleLayoutRelations={toggleLayoutRelations}
            width={columnWidth}
          />
        );
      })}
    </div>
  );
};

export default Row;
