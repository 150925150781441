import { getStyleObjectFromCSS } from '@lexical/selection';
import { ElementFormatType, ElementNode, TextNode } from 'lexical';
import { $isSSIAnchorNode } from 'modules/Lexical/nodes/SSIAnchorNode';
import * as Constants from 'const';
import { DefaultAlign, DefaultFontFamily } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { ExportDefaultStyles } from 'utils/brandStyles';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { toPx } from 'utils/toPx';
import { FontPluginStyle } from '../../Plugins/FontPlugin/style';

export type CreateParagraphContext = {
  defaultStyles: ExportDefaultStyles | undefined;
  defaultLineHeight: Constants.TextLineHeightValue;
  projectType: Constants.ProjectType;
};

export function getParagraphFontFamily(
  defaultStyles: ExportDefaultStyles | undefined,
): string {
  return defaultStyles?.fontFamily ?? DefaultFontFamily;
}

export function getParagraphFontSize(
  firstTextNode: TextNode | undefined,
  defaultStyles: ExportDefaultStyles | undefined,
  projectType: Constants.ProjectType,
): number | undefined {
  const { allowCustomRangeFontSizeSelection } = Constants.ProjectsConfig[projectType];

  if (allowCustomRangeFontSizeSelection) {
    return undefined;
  }

  if (!firstTextNode) {
    return defaultStyles?.fontSize
      ? getIntegerFromStyle(defaultStyles.fontSize)
      : Constants.DefaultCustomStyle.FONT_SIZE;
  }

  const firstTextNodeStyle = getStyleObjectFromCSS(firstTextNode.getStyle());
  const value = firstTextNodeStyle[FontPluginStyle.FONT_SIZE] as string | undefined;

  return value ? getIntegerFromStyle(value) : undefined;
}

export function getParagraphLineHeight(
  nodeLineHeight: number,
  fontSizeNumber: number | undefined,
  defaultLineHeight: number,
): string {
  const lineHeight = nodeLineHeight > 0 ? nodeLineHeight : defaultLineHeight;

  return fontSizeNumber ? toPx(fontSizeNumber * lineHeight, 2) : toPx(lineHeight);
}

export function getParagraphAlign(
  nodeFormatType: ElementFormatType,
): Schemas.Align['align'] {
  const formatTypeToAlignMap: Record<Exclude<ElementFormatType, ''>, string> = {
    left: 'left',
    start: 'left',
    center: 'center',
    right: 'right',
    end: 'right',
    justify: 'justify',
  };
  const align = nodeFormatType ? formatTypeToAlignMap[nodeFormatType] : undefined;

  return align ?? DefaultAlign;
}

export function getParagraphAnchor(
  node: ElementNode,
): boolean {
  return Boolean(node.getChildren().find($isSSIAnchorNode));
}
