import Draft, { RawDraftContentBlock } from 'draft-js';
import { SerializedLexicalNode } from 'lexical';
import * as Models from 'models';
import { convertEntities, createEditorState, createListHierarchy, SerializedDocument } from './builders';
import { createParagraphNode } from './builders/Paragraph';
import { createSerializedSSIAnchorNode } from './builders/SSIAnchor';
import { extractBlockEntities } from './parser';
import { RawDraftContentBlockData, SerializedLexicalInlineNode } from './types';

export const lexicalRawConverter = (
  rawContent: Draft.RawDraftContentState,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): SerializedDocument => {
  const { blocks, entityMap } = rawContent;

  const rootNodes: SerializedLexicalNode[] = [];
  const listItems: [RawDraftContentBlock, SerializedLexicalInlineNode[]][] = [];

  blocks.forEach((block) => {
    const { inlineStyleRanges, entityRanges, type: blockType } = block;
    const entities = extractBlockEntities(inlineStyleRanges, entityRanges, entityMap, block);
    const children = convertEntities(entities, colors, fonts);
    const { anchor } = block.data as RawDraftContentBlockData;
    if (anchor) {
      children.push(createSerializedSSIAnchorNode(block));
    }

    if (blockType === 'unordered-list-item') {
      listItems.push([block, children]);
    } else {
      if (listItems.length) {
        rootNodes.push(createListHierarchy(listItems));
        listItems.length = 0;
      }
      const node = createParagraphNode(block, children);
      rootNodes.push(node);
    }
  });

  if (listItems.length) {
    rootNodes.push(createListHierarchy(listItems));
    listItems.length = 0;
  }

  return createEditorState(rootNodes);
};

