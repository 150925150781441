import { RawDraftContentBlock } from 'draft-js';
import { SerializedEditorState, SerializedLexicalNode } from 'lexical';
import { SerializedCustomListItemNode } from 'modules/Lexical/nodes/CustomListItemNode';
import { SerializedCustomListNode } from 'modules/Lexical/nodes/CustomListNode';
import { IList } from 'typings/DeepIMap';
import { DraftEntity } from 'const';
import * as Models from 'models';
import { DraftEntityData, SerializedLexicalInlineNode } from '../types';
import { createLinkNode } from './Link';
import { createListNode } from './List';
import { createListItemNode } from './ListItem';
import { createMarkNode } from './Mark';
import { createReferenceCitationNode } from './ReferenceCitation';
import { createNoWrapTextNode, createTextNode } from './Text';

export interface SerializedDocument {
  /** The serialized editorState produced by editorState.toJSON() */
  editorState: SerializedEditorState;
  /** The time this document was created in epoch milliseconds (Date.now()) */
  lastSaved: number;
  /** The source of the document, defaults to Lexical */
  source: string;
}

export function convertEntities(
  entities: DraftEntityData[],
  colors: IList<Models.BrandColor>,
  fonts: IList<Models.BrandFont>): SerializedLexicalInlineNode[] {
  return entities.map((chunk) => {
    const { type } = chunk;

    switch (type) {
      case DraftEntity.ABBREVIATION:
        return createMarkNode(chunk, [createTextNode(chunk, colors, fonts)]);
      case DraftEntity.LINK:
        return createLinkNode(chunk, [createTextNode(chunk, colors, fonts)]);
      case DraftEntity.NO_WRAP:
        return createNoWrapTextNode(chunk, colors, fonts);
      case DraftEntity.REFERENCE:
        return createReferenceCitationNode(chunk, colors, fonts);
      default:
        return createTextNode(chunk, colors, fonts);
    }
  }, []);
}

export function createListHierarchy(
  listBlocks: [RawDraftContentBlock, SerializedLexicalInlineNode[]][],
): SerializedCustomListNode {
  const listMap: Map<number, SerializedCustomListNode> = new Map();
  listMap.set(0, createListNode(listBlocks[0][0], []));

  listBlocks.forEach(([block, children]) => {
    const listItem = createListItemNode(block, children);
    const depth = block.depth;

    if (listMap.has(depth)) {
      listMap.get(depth)?.children.push(listItem);
    } else {
      const closestDepth = Math.max(...Array.from(listMap.keys()).filter(d => d < depth));
      let latestList = listMap.get(closestDepth);

      // Create intermediate leaves between closest node depth and current node depth
      for (let i = closestDepth + 1; i <= depth; i++) {
        const li: SerializedCustomListItemNode = { ...listItem, indent: i, children: [] };
        latestList?.children.push(li);
        const ul = createListNode(block, []);
        li.children.push(ul);
        listMap.set(i, ul);
        latestList = ul;
      }

      // Add the item with content to the last list
      latestList?.children.push(listItem);
    }

    // clean previous list nodes with depth gte than current node depth
    for (const key of Array.from(listMap.keys())) {
      if (key > listItem.indent) {
        listMap.delete(key);
      }
    }
  });

  return listMap.get(0);
}

export function createEditorState(children: SerializedLexicalNode[]): SerializedDocument {
  return {
    editorState: {
      root: {
        children,
        direction: 'ltr',
        indent: 0,
        type: 'root',
        format: '',
        version: 1,
      },
    },
    lastSaved: Date.now(),
    source: 'DraftJS',
  };
}
