import Draft from 'draft-js';
import * as BrandDefinition from 'modules/BrandDefinition';
import { IList } from 'typings/DeepIMap';
import * as Constants from 'const';
import * as Models from 'models';
import { Schemas } from 'services/ArtboardConverter/models';
import { getAlignment } from 'services/ArtboardConverter/utils/getAlignment';
import * as editorUtils from 'utils/editor';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { toPx } from 'utils/toPx';
import { AbbreviationsMapping, AbbreviationsListRelationMap, AbbreviationsListSchemaCell } from './types';
import { abbreviationsListToHTML } from './utils/abbreviationsListToHTML';

// IN-PROGRESS create lexical version, rename file to AartboardConverter

type EditorStateConverter = (
  editorState: Draft.EditorState,
  relationId: string,
  options?: {
    brandStyleId?: string;
    defaultLineHeight?: Constants.TextLineHeightValue;
  },
) => Schemas.Text;

export function createAbbreviationsListCellSchema(
  baseCellSchema: Schemas.Cell,
  abbreviationsMapping: AbbreviationsMapping,
  relation: AbbreviationsListRelationMap,
  fonts: IList<Models.BrandFont>,
  convertEditorState: EditorStateConverter,
  projectType: Constants.ProjectType,
): AbbreviationsListSchemaCell | null {
  const relationId = relation.get('id');

  const styles = {
    alignment: relation.getIn(['styles', 'alignment']),
    fontColor: relation.getIn(['styles', 'fontColor']),
    fontFamily: BrandDefinition.getCSSFontFamilyFromBrandFont(
      relation.getIn(['styles', 'fontFamily']),
      relation.getIn(['styles', 'fontStyle']),
      fonts,
    ),
    fontSize: relation.getIn(['styles', 'fontSize']),
    lineHeight: relation.getIn(['styles', 'lineHeight']),
  };

  const abbreviationsList = abbreviationsMapping[relationId];

  if (!abbreviationsList.length) {
    return null;
  }

  let editorState = editorUtils.htmlToEditorState(abbreviationsListToHTML(abbreviationsList));
  editorState = editorUtils.applyFontStyles(editorState, fonts, projectType);
  const textSchema = convertEditorState(editorState, relationId);

  textSchema.paragraphs.forEach((paragraph: Schemas.TextParagraph) => {
    Object.assign(paragraph, {
      ...getAlignment(styles.alignment),
      color: styles.fontColor,
      fontFamily: styles.fontFamily,
      fontSize: toPx(styles.fontSize),
      lineHeight: String(styles.lineHeight || Constants.TextLineHeightValue.ONE_POINT_FIVE),
    });

    paragraph.segments.forEach((segment) => {
      segment.parts.forEach((part) => {
        const partFontSize = part.sub || part.sup
          ? editorUtils.getScriptFontsize(Number(styles.fontSize))
          : toPx(styles.fontSize);

        Object.assign(part, {
          color: styles.fontColor,
          fontFamily: styles.fontFamily,
          fontSize: partFontSize,
          lineHeight: styles.lineHeight,
          lineHeightPx: partFontSize
            ? toPx(getIntegerFromStyle(partFontSize) * Number(styles.lineHeight), 2)
            : styles.lineHeight,
        });
      });
    });
  });

  return {
    ...baseCellSchema,
    ...textSchema,
    isReferenceCitation: abbreviationsList.length === 0,
  };
}
