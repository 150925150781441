import classNames from 'classnames';
import React from 'react';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import ImagePlaceholder from 'components/ArtboardAssets/ImagePlaceholder';
import ResizeComponent from 'components/ResizeComponent';
import Toolbar from 'components/Toolbar/Image';
import UndoPortal from 'components/UndoRedo/UndoPortal';
import { DEFAULT_IMAGE_ALT_TAG } from 'const';
import { useProjectType } from 'hooks/useProjectType';
import { useUndoRedo } from 'hooks/useUndoRedo';
import { AssetAlignment } from 'models';
import { getMinHeight } from 'utils/styles';
import { StackToggler } from '../utils/stack';
import { useImage } from './hooks';
import { ImageProps } from './models';
import styles from './styles.module.scss';
import { ImageStack, wrapUndoMiddleware } from './undo';

const Image: React.FunctionComponent<ImageProps> = (props) => {
  const {
    canDrop,
    cellHeight,
    cellPosition,
    cellWidth,
    cellsCount,
    disableCellWidthEditing,
    documentNumber,
    docImageHeight,
    docImageWidth,
    imageSrc,
    editMode,
    isIncreasingWidthDisabled,
    layoutId,
    maxCellHeight,
    minCellHeight,
    placeholderMinHeight,
    rootDocument,
    shrinkable,
    toggleColumnWidth,
    toggleRowHeight,
    updateAppState,
    isAutoFitContent,
    saveAppState,
    cancel,
  } = props;

  const {
    container,
    getStyle,
    onResize,
    toggleAutoFitContentWithRelation,
    imageStyles,
    imageStylesSetters,
    imageCSS,
    cellInnerWidth,
    handleImageLoad,
  } = useImage(props);

  const togglersMap: StackToggler<ImageStack> = {
    cellHeight: toggleRowHeight,
    cellWidth: toggleColumnWidth,
    mobileSettingsApplied: imageStylesSetters.mobileSettingsApplied,
    height: imageStylesSetters.height,
    width: imageStylesSetters.width,
    isAutoFitContent: props.toggleAutoFitContent,
    alignment: imageStylesSetters.alignment,
    mobileAlignment: imageStylesSetters.mobileAlignment,
    altTag: imageStylesSetters.altTag,
    backgroundColor: imageStylesSetters.backgroundColor,
    backgroundColorOpacity: imageStylesSetters.backgroundColorOpacity,
    backgroundImage: imageStylesSetters.backgroundImage,
    backgroundGradient: ([gradient, color]) => imageStylesSetters.backgroundGradient(gradient, color),
    border: imageStylesSetters.border,
    link: imageStylesSetters.link,
    padding: imageStylesSetters.padding,
    mobileScale: imageStylesSetters.mobileScale,
    scale: imageStylesSetters.scale,
    mobileViewImage: imageStylesSetters.mobileViewImage,
    borderRadius: imageStylesSetters.borderRadius,
    abbreviations: imageStylesSetters.abbreviations,
  };

  const currentStackData: ImageStack = {
    cellHeight,
    cellWidth,
    height: imageStyles.height,
    width: imageStyles.width,
    isAutoFitContent,
    alignment: imageStyles.alignment,
    mobileAlignment: imageStyles.mobileAlignment,
    altTag: imageStyles.altTag,
    backgroundColor: imageStyles.backgroundGradient ? undefined : imageStyles.backgroundColor,
    backgroundColorOpacity: imageStyles.backgroundColorOpacity,
    backgroundImage: imageStyles.backgroundImage,
    backgroundGradient: imageStyles.backgroundGradient
      ? [imageStyles.backgroundGradient, imageStyles.backgroundColor]
      : [undefined, undefined],
    border: imageStyles.border,
    borderRadius: imageStyles.borderRadius,
    link: imageStyles.link,
    padding: imageStyles.padding,
    mobileScale: imageStyles.mobileScale,
    mobileSettingsApplied: imageStyles.mobileSettingsApplied,
    scale: imageStyles.scale,
    mobileViewImage: imageStyles.mobileViewImage,
    abbreviations: imageStyles.abbreviations,
  };

  const {
    undoStack,
    redoStack,
    undo,
    redo,
    undoStackMiddleware,
  } = useUndoRedo<ImageStack>({ togglersMap, editMode, currentStackData, saveAppState, cancel });

  const projectType = useProjectType();
  const title = imageStyles.altTag;
  const alt = title || DEFAULT_IMAGE_ALT_TAG;
  const maxContentHeight = cellInnerWidth / docImageWidth * docImageHeight;
  const noMinHeight = imageSrc || shrinkable || cellHeight;
  const imageStylesSettersWithUndo = wrapUndoMiddleware(undoStackMiddleware, imageStylesSetters);

  const finishResizing = (scale: number, currentContainerWidth?: number | undefined): void => {
    imageStylesSetters.scale(scale, currentContainerWidth);
    onResize(scale, true);
  };

  return (
    <AccessibleDiv
      className={classNames(styles.Image, {
        [styles.last]: cellPosition + 1 === cellsCount,
        [styles.severalRows]: cellsCount > 1,
        [styles.noMinHeight]: noMinHeight,
      })}
      ref={container}
      style={{
        ...imageCSS,
        ...(imageSrc && noMinHeight ? {} : getMinHeight(placeholderMinHeight)),
      }}
      tabIndex={0}
      // attribute data-document-number is used by automatic tests
      data-document-number={documentNumber}
    >
      {
        editMode &&
        <>
          <UndoPortal
            undo={(): void => undo(currentStackData)}
            redo={(): void => redo(currentStackData)}
            isRedoDisabled={!redoStack.length}
            isUndoDisabled={!undoStack.length}
          />
          <Toolbar
            styles={imageStyles}
            setters={imageStylesSettersWithUndo}
            projectType={projectType}
            alignmentDisabled={!imageSrc}
            altTagDisabled={!imageSrc}
            cellHeight={cellHeight}
            maxCellHeight={maxCellHeight}
            minCellHeight={minCellHeight}
            toggleCellHeight={undoStackMiddleware(toggleRowHeight, 'cellHeight') as (newHeght: number) => number}
            cellWidth={cellWidth}
            toggleColumnWidth={undoStackMiddleware(toggleColumnWidth, 'cellWidth') as (newWidth: number) => number}
            disableCellWidthEditing={disableCellWidthEditing}
            isIncreasingWidthDisabled={isIncreasingWidthDisabled}
            maxContentHeight={maxContentHeight}
            maxContentWidth={cellInnerWidth}
            linkDisabled={!imageSrc}
            updateAppState={updateAppState}
            layoutId={layoutId}
            height={imageSrc ? imageStyles.height : 0}
            width={imageSrc ? imageStyles.width : 0}
            mobileSettingsDisabled={!imageSrc}
            rootDocument={rootDocument}
            toggleAutoFitContent={undoStackMiddleware(toggleAutoFitContentWithRelation, 'isAutoFitContent')}
            isAutoFitContent={isAutoFitContent}
          />
        </>
      }
      {
        imageSrc && (
          editMode
            ?
            <ResizeComponent
              alignment={imageStyles.alignment.toJS() as AssetAlignment}
              container={container.current ?? undefined}
              finishResizing={finishResizing}
              startResizing={undoStackMiddleware(() => {}, 'scale', true)}
              isComponentWide={docImageWidth > docImageHeight}
              isResponsive={true}
              getStyle={getStyle}
              onResize={onResize}
              scale={imageStyles.scale}
            >
              <img src={imageSrc} alt={alt} title={title} onLoad={(): void => handleImageLoad(false)} />
            </ResizeComponent>
            : <img
              src={imageSrc}
              alt={alt}
              title={title}
              style={getStyle(imageStyles.scale)}
              onLoad={(): void => handleImageLoad(true)}
            />
        )
      }
      {!imageSrc && <ImagePlaceholder showIcon={cellHeight >= 75} canDrop={canDrop} />}
    </AccessibleDiv>
  );
};

export default Image;
