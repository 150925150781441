import { MarkNode } from '@lexical/mark';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { EditorState, LexicalEditor, ParagraphNode, TextNode } from 'lexical';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import * as Constants from 'const';
import { BrandProps } from 'hooks/useBrandProps';
import { LexicalOnChangeCTA } from '../hooks/useLexicalCallToActionEditor';
import { CustomParagraphNode } from '../nodes/CustomParagraphNode';
import { AbbreviationPlugin } from '../Plugins/AbbreviationPlugin';
import { BasePropsPlugin } from '../Plugins/BasePropsPlugin';
import { CustomAutoFocusPlugin } from '../Plugins/CustomAutoFocusPlugin';
import { EditModePlugin } from '../Plugins/EditModePlugin';
import { EditorRefPlugin } from '../Plugins/EditorRefPlugin';
import { EditorStateUpdatePlugin } from '../Plugins/EditorStateUpdatePlugin';
import { EditorStopEditingPlugin } from '../Plugins/EditorStopEditingPlugin';
import { FontPlugin } from '../Plugins/FontPlugin';
import { HistoryPluginChangeHandler, HistoryWithExtraStatePlugin } from '../Plugins/HistoryWithExtraStatePlugin';
import { mainTheme } from '../Theme/mainTheme';

const TEXT_LINE_HEIGHT = Constants.TextLineHeightValue.ONE_POINT_FIFTEEN;

export const editorConfig = {
  namespace: 'call-to-action-editor',
  editable: false,
  theme: mainTheme,
  onError: (error): void => {
    throw error;
  },
  nodes: [
    TextNode,
    CustomParagraphNode,
    {
      replace: ParagraphNode,
      with: (): CustomParagraphNode => new CustomParagraphNode(TEXT_LINE_HEIGHT),
      withKlass: CustomParagraphNode,
    },
    MarkNode,
  ],
};

type Props<T> = {
  editorState: string | null;
  editMode: boolean;
  onChange: LexicalOnChangeCTA;
  onStopEditing: (state: EditorState) => void;
  brandProps: Omit<BrandProps, 'brandStyles'>;
  historyExtraState: T;
  historyExtraStateSetter: (value: T) => void;
  historyOnChange: HistoryPluginChangeHandler;
};

export const LexicalCallToActionEditor = forwardRef(function <T extends object>(props: Props<T>, ref: React.Ref<LexicalEditor>) {
  const {
    editorState,
    editMode,
    brandProps,
    onChange,
    onStopEditing,
    historyExtraState,
    historyExtraStateSetter,
    historyOnChange,
  } = props;

  const editorRef = useRef<LexicalEditor>(null);
  useImperativeHandle(ref, () => editorRef.current as LexicalEditor);

  return (
    <LexicalComposer initialConfig={{
      ...editorConfig,
      editable: editMode,
      editorState,
    }}>
      <div className="editor-container">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        {editMode && <HistoryWithExtraStatePlugin
          extraState={historyExtraState}
          extraStateSetter={historyExtraStateSetter}
          onChange={historyOnChange}
        />}
        <EditorRefPlugin ref={editorRef} />
        <EditModePlugin editMode={editMode} />
        <BasePropsPlugin onChange={onChange.baseProps} />
        <FontPlugin
          onChange={onChange.font}
          colors={brandProps.colors}
          fonts={brandProps.fonts}
        />
        <AbbreviationPlugin onChange={onChange.abbreviations} />
        <CustomAutoFocusPlugin editMode={editMode}/>
        <EditorStopEditingPlugin onStopEditing={onStopEditing} />
        <EditorStateUpdatePlugin editorState={editorState} />
      </div>
    </LexicalComposer>
  );
});
