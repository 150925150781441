import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import React, { useEffect, useState, ReactNode } from 'react';

interface Props {
  placeholder: ReactNode;
}

export const PlaceholderPlugin: React.FunctionComponent<Props> = ({ placeholder }) => {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor);
  const editMode = editor.isEditable();
  const [isShow, setIsShow] = useState(!editMode && isEmpty);

  useEffect(() => {
    setIsShow(!editMode && isEmpty);
  }, [editMode, isEmpty]);

  return isShow ? <>{placeholder}</> : null;
};
