import { LexicalNode } from 'lexical';
import { $isReferenceCitationNode, ReferenceCitationNode } from 'modules/Lexical/nodes/ReferenceCitationNode';

function getNumber(
  node: LexicalNode | null | undefined,
  numbersMap: Record<string, number>,
): number | undefined {
  if (!$isReferenceCitationNode(node)) {
    return undefined;
  }

  return numbersMap[node.getReferenceId()] ?? -1;
}

const setText = (
  node: ReferenceCitationNode,
  text: string,
): void => {
  if (text !== node.getTextContent()) {
    node.setTextContent(text);
  }
};

export function $setNodeText(
  node: ReferenceCitationNode,
  numbersMap: Record<string, number>,
): void {
  const current = getNumber(node, numbersMap) as number;
  const currentText = current > 0 ? current.toString() : ReferenceCitationNode.TEXT_FOR_MISSING_NUMBER;
  const next = getNumber(node.getNextSibling(), numbersMap);
  setText(node, next && next >= 0 ? `${currentText},` : currentText);
}

function createText(
  current: number,
  prev: number | undefined,
  next: number | undefined,
  afterNext: number | undefined,
): string {
  const currentText = current > 0 ? current.toString() : ReferenceCitationNode.TEXT_FOR_MISSING_NUMBER;

  // current citation is the last number in the list
  if (typeof next === 'undefined') {
    return currentText;
  }
  // current citation number or next one is missing, the actual position in the list doesn't matter
  if (current <= 0 || next <= 0) {
    return `${currentText},`;
  }
  // current citatation is in the middle of the list
  if (prev === current - 1) {
    return next === current + 1 ? '' : `${currentText},`;
  }
  // check if the current ciration is a begging of the consequent list
  if (next === current + 1 && afterNext === current + 2) {
    return `${currentText}-`;
  }

  return `${currentText},`;
}

export function $setNodeTextWithNumbersRange(
  node: ReferenceCitationNode,
  numbersMap: Record<string, number>,
): void {
  const current = getNumber(node, numbersMap) as number;
  const prev = getNumber(node.getPreviousSibling(), numbersMap);
  const next = getNumber(node.getNextSibling(), numbersMap);
  const afterNext = getNumber(node.getNextSibling()?.getNextSibling(), numbersMap);

  setText(node, createText(current, prev, next, afterNext));
}
