import Immutable from 'immutable';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { IMap } from 'typings/DeepIMap';
import { Layer } from 'const';
import { CallToActionStyles, LayeredRegularRelationMap } from 'models';
import * as Models from 'models';
import { getExtraWidth } from 'utils/getExtraWidth';
import {
  CTAStyles, CTAStylesConfig, CTAStylesContext, CTAStylesSetters,
  ctaStylesFromSource, ctaStylesToCSSForContainer, ctaStylesToCSSForElement,
} from '../utils/styles';

type CSS = {
  element: CSSProperties;
  container: CSSProperties;
};

export type GetMaxWidth = (currentPudding?: IMap<Models.BoxProperty>, currentBorder?: IMap<Models.Border<Models.BrandColor>> | undefined) => number;

export type StylesHook = { styles: CTAStyles; setters: CTAStylesSetters; css: CSS; getMaxWidth: GetMaxWidth };

export function useStyles(
  cta: LayeredRegularRelationMap<CallToActionStyles>,
  layer: Layer,
  context: CTAStylesContext,
  config: CTAStylesConfig,
  cellWidth: number,
): StylesHook {
  const { colors, images, defaultAssetBackgroundColor } = context;
  const { allowBackgroundOpacity } = config;

  const [styles, setStyles] = useState<CTAStyles>(
    () => ctaStylesFromSource(cta.getIn(['styles', layer]), colors),
  );

  useEffect(() => {
    const source = cta.getIn(['styles', layer]);

    if (source) {
      setStyles(ctaStylesFromSource(source, colors));
    }
  }, [cta, layer, colors]);

  const setters = useMemo<CTAStylesSetters>(() => {
    const props = [
      'alignment',
      'assetBackgroundOpacity',
      'assetBorderRadius',
      'assetPadding',
      'backgroundColorOpacity',
      'backgroundImage',
      'border',
      'borderRadius',
      'fitToCell',
      'height',
      'padding',
      'textAlignment',
      'width',
    ] as const;

    const propsSetters = Object.fromEntries(
      props.map(
        <T extends keyof CTAStyles>(key: T) => [
          key,
          (value: CTAStyles[T]): void => setStyles(state => ({ ...state, [key]: value })),
        ],
      ),
    ) as { [P in (typeof props)[number]]: (value: CTAStyles[P]) => void };

    return {
      ...propsSetters,
      assetBackgroundColor: color => setStyles(state => ({
        ...state,
        assetBackgroundColor: color,
        assetBackgroundGradient: undefined,
      })),
      assetBackgroundGradient: (gradient, backupColor) => setStyles(state => ({
        ...state,
        assetBackgroundColor: backupColor,
        assetBackgroundGradient: gradient,
      })),
      backgroundColor: color => setStyles(state => ({
        ...state,
        backgroundColor: color,
        backgroundGradient: undefined,
      })),
      backgroundGradient: (gradient, backupColor) => setStyles(state => ({
        ...state,
        backgroundColor: backupColor,
        backgroundGradient: gradient,
      })),
    };
  }, []);

  const css = useMemo<CSS>(
    () => ({
      element: ctaStylesToCSSForElement(
        styles,
        { defaultAssetBackgroundColor },
        { allowBackgroundOpacity },
      ),
      container: ctaStylesToCSSForContainer(styles, { images }, { allowBackgroundOpacity }),
    }),
    [styles, images, allowBackgroundOpacity],
  );

  const getMaxWidth = (currentPudding = styles.padding, currentBorder = styles.border): number => {
    const extraWidth = getExtraWidth(Immutable.fromJS({ border: currentBorder, padding: currentPudding }));

    return Math.max(cellWidth - extraWidth, 0);
  };

  return { styles, setters, css, getMaxWidth };
}
