import React, { createContext, PropsWithChildren, useMemo } from 'react';

type ContextType = {
  documentId?: string;
  relationId?: string;
  isVisible: boolean;
};

export const SSIAnchorContext = createContext<ContextType>({ isVisible: false });

type ProviderProps = PropsWithChildren<{
  documentId?: string;
  relationId?: string;
  isVisible: boolean;
}>;

export const SSIAnchorContextProvider: React.FC<ProviderProps> = (props) => {
  const {
    documentId,
    relationId,
    isVisible,
    children,
  } = props;

  const value = useMemo(() => ({
    documentId,
    relationId,
    isVisible,
  }), [documentId, relationId, isVisible]);

  return <SSIAnchorContext.Provider value={value}>{children}</SSIAnchorContext.Provider>;
};
