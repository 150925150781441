import { getCSSFromStyleObject, getStyleObjectFromCSS } from '@lexical/selection';
import { $getRoot, TextNode } from 'lexical';
import { isNil, omitBy } from 'lodash';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Models from 'models';
import { getTextStylesFromBrandStyle } from 'utils/brandStyles';
import {
  FontPluginStyle,
  getStyleObjectForFontSize,
  getBrandColorRefFromStyleObject,
  getStyleObjectForBrandColor,
  getStyleObjectForBrandFont,
  getBrandFontRefFromStyleObject,
} from '../FontPlugin/style';

export function $applyBrandStyleForFont(
  brandStyle: Models.BrandStyleMap | undefined,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): void {
  const styles = getTextStylesFromBrandStyle(brandStyle, colors, fonts);
  // getTextStylesFromBrandStyle will currently return only the hex value instead of BrandColor
  const colorRef = BrandDefinition.tryToGetBrandColorRefByHEX(colors, styles[Models.TextBrandStyleField.FONT_COLOR]);
  const size = styles[Models.TextBrandStyleField.FONT_SIZE];
  const fontName = styles[Models.TextBrandStyleField.FONT_FAMILY];
  const characterStyleName = styles[Models.TextBrandStyleField.CHARACTER_STYLE];
  const fontRef = fontName ? { fontName, characterStyleName } : undefined;

  const styleObj = {
    ...getStyleObjectForBrandFont(fonts, fontRef),
    ...getStyleObjectForBrandColor(colors, colorRef),
    ...getStyleObjectForFontSize(size),
  };

  $getRoot().getAllTextNodes()
    .forEach((node: TextNode) => {
      const updatedStyles = omitBy({
        ...getStyleObjectFromCSS(node.getStyle()),
        ...styleObj,
      }, isNil);

      node.setStyle(getCSSFromStyleObject(updatedStyles));
    });
}

export function $verifyStyleForFont(
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): void {
  $getRoot().getAllTextNodes()
    .forEach((node) => {
      const style = getStyleObjectFromCSS(node.getStyle());
      const newStyle: Record<string, string> = {};
      const pushStyle = (name: string, value: string | undefined): void => {
        if (!value || style[name] === value) {
          return;
        }
        newStyle[name] = value;
      };

      const colorRef = getBrandColorRefFromStyleObject(style);
      if (colorRef) {
        pushStyle(FontPluginStyle.COLOR, BrandDefinition.getBrandColorValueHEX(colors, colorRef));
      }

      const fontRef = getBrandFontRefFromStyleObject(style);
      pushStyle(FontPluginStyle.FONT_FAMILY, BrandDefinition.getBrandFontValueFontFamily(fonts, fontRef));
      pushStyle(FontPluginStyle.FONT_STYLE, BrandDefinition.getBrandFontValueFontStyle(fonts, fontRef));
      pushStyle(FontPluginStyle.FONT_WEIGHT, BrandDefinition.getBrandFontValueFontWeight(fonts, fontRef));

      if (Object.keys(newStyle).length) {
        node.setStyle(getCSSFromStyleObject({ ...style, ...newStyle }));
      }
    });
}
