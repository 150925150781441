import { List, Map } from 'immutable';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import { toPx } from 'utils/toPx';

export enum FontPluginStyle {
  BRAND_COLOR_HEX = 'brand-color-hex', // CUSTOM property
  BRAND_COLOR_NAME = 'brand-color-name', // CUSTOM property
  BRAND_COLOR_TINT = 'brand-color-tint', // CUSTOM property
  COLOR = 'color',
  BRAND_FONT_NAME = 'brand-font-name', // CUSTOM property
  BRAND_FONT_CHARACTER_STYLE_NAME = 'brand-character-style-name', // CUSTOM property
  FONT_FAMILY = 'font-family',
  FONT_SIZE = 'font-size',
  FONT_WEIGHT = 'font-weight',
  FONT_STYLE = 'font-style',
}

export function getBrandColorRefFromStyleObject(
  style: Record<string, string | undefined>,
): BrandDefinition.BrandColorRef | undefined {
  const {
    [FontPluginStyle.BRAND_COLOR_HEX]: HEX,
    [FontPluginStyle.BRAND_COLOR_NAME]: name,
    [FontPluginStyle.BRAND_COLOR_TINT]: tint,
  } = style;
  if (!HEX) {
    return undefined;
  }

  return { HEX, name, tint: tint ? Number(tint) : undefined };
}

type StyleForColor = { [FontPluginStyle.COLOR]: string } & Record<string, string | null>;

export function getStyleObjectForBrandColor(
  colors: Models.BrandColorsList,
  colorRef: BrandDefinition.BrandColorRef | undefined,
): StyleForColor;
export function getStyleObjectForBrandColor(
  color: Models.BrandColorMap | undefined
): StyleForColor;
// try to avoid the usage of the last signature - it should be removed
export function getStyleObjectForBrandColor(
  color: BrandDefinition.BrandColorRef | undefined
): StyleForColor;
export function getStyleObjectForBrandColor(...args: unknown[]): StyleForColor {
  let brandColor: Models.BrandColor | undefined = undefined;

  if (List.isList(args[0])) {
    const colors = args[0] as Models.BrandColorsList;
    const colorRef = args[1] as BrandDefinition.BrandColorRef | undefined;
    if (colorRef) {
      brandColor = BrandDefinition.findBrandColor(colors, colorRef)?.toJS();
    }
  } else if (Map.isMap(args[0])) {
    const color = args[0] as Models.BrandColorMap | undefined;
    brandColor = color?.toJS();
  } else {
    // this branch should be avoided and removed in future
    const color = args[0] as BrandDefinition.BrandColorRef | undefined;
    brandColor = color as Models.BrandColor | undefined;
  }

  return {
    [FontPluginStyle.COLOR]: brandColor?.HEX ? brandColor.HEX : Constants.DefaultCustomStyle.FONT_COLOR,
    [FontPluginStyle.BRAND_COLOR_HEX] : brandColor?.HEX ?? null,
    [FontPluginStyle.BRAND_COLOR_NAME] : brandColor?.name ?? null,
    [FontPluginStyle.BRAND_COLOR_TINT] : brandColor?.tint?.toString() ?? null,
  };
}

export function getStyleObjectForFontSize(value: number): Record<string, string | null> {
  const px = toPx(value);

  return {
    [FontPluginStyle.FONT_SIZE]: px ?? null,
  };
}

export function getBrandFontRefFromStyleObject(
  style: Record<string, string | undefined>,
): BrandDefinition.BrandFontRef | undefined {
  const fontName = style[FontPluginStyle.BRAND_FONT_NAME];
  const characterStyleName = style[FontPluginStyle.BRAND_FONT_CHARACTER_STYLE_NAME];

  return fontName ? { fontName, characterStyleName } : undefined;
}

const STYLE_OBJECT_FOR_MISSING_BRAND_FONT = {
  [FontPluginStyle.BRAND_FONT_NAME]: null,
  [FontPluginStyle.BRAND_FONT_CHARACTER_STYLE_NAME]: null,
  [FontPluginStyle.FONT_FAMILY]: null,
  [FontPluginStyle.FONT_STYLE]: null,
  [FontPluginStyle.FONT_WEIGHT]: null,
};

export function getStyleObjectForBrandFont(
  fonts: Models.BrandFontsList,
  fontRef: BrandDefinition.BrandFontRef | undefined,
): Record<string, string | null> {
  if (!fontRef) {
    return STYLE_OBJECT_FOR_MISSING_BRAND_FONT;
  }
  const { fontName, characterStyleName } = fontRef;
  const brandFont = fonts.find(font => font?.get('name') === fontName);
  if (!brandFont) {
    return STYLE_OBJECT_FOR_MISSING_BRAND_FONT;
  }

  return {
    [FontPluginStyle.BRAND_FONT_NAME]: fontName,
    [FontPluginStyle.BRAND_FONT_CHARACTER_STYLE_NAME]: characterStyleName ?? null,
    [FontPluginStyle.FONT_FAMILY]: BrandDefinition.getBrandFontValueFontFamily(fonts, fontRef) ?? null,
    [FontPluginStyle.FONT_STYLE]: BrandDefinition.getBrandFontValueFontStyle(fonts, fontRef) ?? null,
    [FontPluginStyle.FONT_WEIGHT]: BrandDefinition.getBrandFontValueFontWeight(fonts, fontRef) ?? null,
  };
}
