import { isImmutable } from 'immutable';
import _ from 'lodash';
import { UNDO_STACK_LIMIT_EDIT_MODE } from 'const/UndoRedoControl';
import { toImmutable } from 'utils/immutable';

type toggler<T = unknown> = (value: T) => unknown;

export type StackToggler<StackData> = { [key in keyof StackData]: toggler<StackData[key]> };

export const isEqual = (a: unknown, b: unknown): boolean => {
  if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
    return a === b;
  }
  const convertedA = isImmutable(a) ? a : toImmutable(a);
  const convertedB = isImmutable(b) ? b : toImmutable(b);

  return convertedA.equals ? convertedA.equals(convertedB) : _.isEqual(convertedA.toJS(), convertedB.toJS());
};

export const toggleProps = <StackData>(
  prevData: StackData,
  newData: StackData,
  togglersMap: StackToggler<StackData>,
): void => {
  Object.entries(prevData).forEach(([name, prevVal]) => {
    const changeValue: toggler = togglersMap[name];
    const newVal = newData[name];

    if (changeValue && !isEqual(prevVal, newVal)) {
      (changeValue as toggler)(newVal);
    }
  });
};

export const pushStateToStack = <Stack>(stack: Stack[], data: Stack): Stack[] =>
  stack.concat(data).slice(-UNDO_STACK_LIMIT_EDIT_MODE);

export const removeLastFromStack = <Stack>(stack: Stack[]): Stack[] =>
  stack.slice(0, stack.length - 1);

export const getLastFromStack = <Stack>(stack: Stack[]): Stack =>
  stack[stack.length - 1];
