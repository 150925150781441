import Immutable from 'immutable';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ProjectType, ProjectsConfig } from 'const';
import * as LayoutsActions from 'containers/Layouts/actions';
import * as RelationsActions from 'containers/Relations/actions';
import * as RelationsModels from 'containers/Relations/models';
import { layoutStylesStateToSource, useLayoutStyles } from 'hooks/layout/useLayoutStyles';
import { CreateCustomClickHandlerWithToolbar, Priority, useClickOutsideWithToggle } from 'hooks/useClickOutside';
import { useRelations } from 'hooks/useRelations';
import * as Models from 'models';
import { brandBorderFromSource } from 'utils/converters';
import { isEmptyLayout } from 'utils/layouts/isLayoutEmpty';
import { updateBorder } from 'utils/layouts/updateBorder';
import { updatePadding } from 'utils/layouts/updatePadding';
import { updateScrollable } from 'utils/layouts/updateScrollable';
import { getMinColumnWidth } from 'utils/relations/getMinColumnWidth';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import { useResize } from './useResize';

type Options = {
  artboardLayoutContainer: HTMLDivElement;
  documents: Models.CombinedDocumentsMap;
  flatColorsByLayoutId: Models.BrandColorsMap;
  isOpenToolbar: boolean;
  layout: Models.LayoutMap;
  layoutId: string;
  relationsByRegularLayoutId: Immutable.Map<string, Models.LayeredRelationsMap>;
  sectionName: string;
  sectionsWidth: Models.MasterScreen.SectionsWidthMap;
  ssi: Models.SSIMap;
  projectType: ProjectType;
};

export const useLayout = (props: Options) => {
  const dispatch = useDispatch();

  const {
    artboardLayoutContainer,
    documents,
    flatColorsByLayoutId,
    isOpenToolbar,
    layout,
    layoutId,
    projectType,
    relationsByRegularLayoutId,
    sectionName,
    sectionsWidth,
    ssi,
  } = props;
  const isFirstRendering = React.useRef(true);
  const colors = flatColorsByLayoutId.get(layoutId);

  const layoutDocument = documents.get(layout.get('documentId')) as Models.ReusableLayoutMap;
  const layoutDocumentName = layoutDocument && layoutDocument.get('name');
  const layoutDocumentNumber = layoutDocument && layoutDocument.get('number');

  const createCustomClickHandler: CreateCustomClickHandlerWithToolbar<HTMLDivElement> = (artboardLayout, toolbar, toggleEditModeOff, editMode) => {
    return (event) => {
      if (!editMode || !artboardLayoutContainer || !artboardLayout.current) {
        return;
      }

      const eventTarget = event.target as Node;
      const clickOutside = !artboardLayoutContainer.contains(eventTarget) && !toolbar.contains(eventTarget);
      const clickInLayout = artboardLayout.current.contains(eventTarget);

      if (clickOutside || clickInLayout) {
        toggleEditModeOff();
      }
    };
  };

  const {
    container,
    on: editMode,
    toggle,
    toggleOn: toggleEditModeOn,
  } = useClickOutsideWithToggle<HTMLDivElement>(Priority.TOOLBAR, { createCustomClickHandler });

  const enableEditMode = (): void => {
    if (!isOpenToolbar) {
      toggleEditModeOn();
    } else {
      toggle();
    }
  };

  const [styles, stylesSetters, stylesCSS] = useLayoutStyles(
    layout.get('styles'),
    { colors, images: documents as Models.ImagesMap, container },
    ProjectsConfig[projectType],
  );

  const { scrollable } = styles;
  const [isExpanded, setIsExpanded] = React.useState(false);

  const updateRelationsSilently = useCallback<RelationsModels.ActionCreator.UpdateLayeredRelationsSilently>(
    (relations, id) => dispatch(RelationsActions.updateLayeredRelationsSilently(relations, id)),
    [dispatch],
  );

  const layoutWithStylesFromState = React.useMemo(
    () => layout.update('styles', values => layoutStylesStateToSource(styles, values)),
    [styles, layout],
  );

  const sectionWidth = sectionsWidth && sectionName && sectionsWidth.get(sectionName);
  const layoutRelations = relationsByRegularLayoutId.get(layoutId);
  const {
    relations: layoutRelationsWithStylesFromState,
    toggleRelations: toggleLayoutRelationsWithStylesFromState,
    columnsWidth,
    toggleColumnsWidth,
    setRowHeightSilently,
  } = useRelations(layoutRelations, layoutWithStylesFromState, ssi, sectionsWidth, sectionWidth, updateRelationsSilently);
  const relationId = layoutWithStylesFromState.get('relationId');
  const relationWithStylesFromState = layoutRelationsWithStylesFromState.get(relationId) as Models.ColumnRelationMap;

  React.useEffect(
    () => {
      if (isFirstRendering.current) {
        isFirstRendering.current = false;
      } else if (!editMode) {
        dispatch(LayoutsActions.setLayoutAndInstances(layoutWithStylesFromState));

        const layoutRelationChildIds = relationWithStylesFromState.get('relationIds');
        const firstChildId = layoutRelationChildIds.first<string>();
        const firstLayoutChildRelation = layoutRelationsWithStylesFromState.get(firstChildId) as Models.RowRelationMap | Models.RegularRelationMap;

        if (layoutRelationChildIds.size === 1 && isRowRelation(firstLayoutChildRelation)) {
          dispatch(RelationsActions.updateLayeredRelations(layoutRelationsWithStylesFromState));
        }
      }
    },
    [editMode, dispatch],
  );

  // for top level columns only
  const getLayoutMinColumnWidthByPosition = (position: number): number => {
    if (!isRowRelation(relationWithStylesFromState)) {
      return null;
    }

    return getMinColumnWidth(relationWithStylesFromState.getIn(['relationsIds', position]), layoutRelationsWithStylesFromState);
  };

  const {
    calcHeight,
    onResize,
  } = useResize({
    calcMaxHeight: () => container.current?.scrollHeight ?? 0,
    height: styles.height ?? 0,
    minHeight: styles.minHeight,
    toggleHeight: stylesSetters.height,
    getClientRect: () => container.current?.getBoundingClientRect() || new DOMRect(),
  });

  const toggleExpandedMode = useCallback((isExpandedMode: boolean): void => {
    if (scrollable) {
      setIsExpanded(isExpandedMode);
    }
  }, [scrollable, setIsExpanded]);

  return {
    columnsWidth,
    container,
    editMode,
    enableEditMode,
    getMinColumnWidth: getLayoutMinColumnWidthByPosition,
    isEmpty: isEmptyLayout(layoutWithStylesFromState, layoutRelations, documents),
    isExpanded,
    isReusableLayout: isReusableLayout(layout, documents),
    layout: layoutWithStylesFromState,
    layoutDocumentName,
    layoutDocumentNumber,
    layoutRelations: layoutRelationsWithStylesFromState,
    onResize,
    setRowHeightSilently,
    styles,
    stylesSetters: {
      ...stylesSetters,
      border: (value: Models.BorderMap): void => {
        const {
          layout: updatedLayout,
          columnsWidth: updatedColumnsWidth,
        } = updateBorder(layoutWithStylesFromState, columnsWidth, value, getLayoutMinColumnWidthByPosition);

        toggleColumnsWidth(updatedColumnsWidth);
        stylesSetters.border(brandBorderFromSource(colors, updatedLayout.getIn(['styles', 'border'])));
      },
      padding: (value: Models.PaddingMap): void => {
        const {
          layout: updatedLayout,
          columnsWidth: updatedColumnsWidth,
        } = updatePadding(layoutWithStylesFromState, columnsWidth, value, getLayoutMinColumnWidthByPosition);

        toggleColumnsWidth(updatedColumnsWidth);
        stylesSetters.padding(updatedLayout.getIn(['styles', 'padding']));
      },
      scrollable: (value: boolean): void => {
        const updatedLayout = updateScrollable(layoutWithStylesFromState, value);
        dispatch(LayoutsActions.setLayout(updatedLayout));
        const scrollableValue = updatedLayout.getIn(['styles', 'scrollable']);
        stylesSetters.height(scrollableValue ? calcHeight() : undefined);
        stylesSetters.scrollable(scrollableValue);
      },
    },
    stylesCSS,
    toggleColumnsWidth,
    toggleExpandedMode,
    toggleLayoutRelations: toggleLayoutRelationsWithStylesFromState,
  };
};
