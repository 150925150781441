import { EditorConfig, LexicalEditor, LexicalNode, SerializedTextNode, TextNode } from 'lexical';

export class ColourlessTextNode extends TextNode {
  static getType(): string {
    return 'custom-colourless-text';
  }

  createDOM(config: EditorConfig, editor?: LexicalEditor): HTMLElement {
    const element = super.createDOM(config, editor);
    element.style.color = '';

    return element;
  }

  updateDOM(prevNode: TextNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);
    dom.style.color = '';

    return isUpdated;

  }

  static clone(node: ColourlessTextNode): ColourlessTextNode {
    return new ColourlessTextNode(node.__text, node.__key);
  }

  exportJSON(): SerializedTextNode {
    return {
      ...super.exportJSON(),
      type: ColourlessTextNode.getType(),
    };
  }

  static importJSON(serializedNode: SerializedTextNode): ColourlessTextNode {
    return super.importJSON(serializedNode);
  }

}

export function $createColourlessTextNode(text: string): ColourlessTextNode {
  return new ColourlessTextNode(text);
}

export function $isColourlessTextNode(node: LexicalNode | null | undefined): node is ColourlessTextNode {
  return node instanceof ColourlessTextNode;
}
