import { $isElementNode, LexicalNode } from 'lexical';
import { $isSSIAnchorNode, SSIAnchorNode } from 'modules/Lexical/nodes/SSIAnchorNode';

export const $getSSIAnchorNodes = (node: LexicalNode): SSIAnchorNode[] => {
  const ssiAnchors: SSIAnchorNode[] = [];

  if ($isSSIAnchorNode(node)) {
    ssiAnchors.push(node);
  }

  if ($isElementNode(node)) {
    node.getChildren().forEach((child) => {
      ssiAnchors.push(...$getSSIAnchorNodes(child));
    });
  }

  return ssiAnchors;
};
