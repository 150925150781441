import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNodeByKey } from 'lexical';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ReferenceCitationNode } from '../../nodes/ReferenceCitationNode';
import { createDropHandler } from './utils/dropHandler';
import { $reorderNodes } from './utils/reorderNodes';
import { $setNodeText, $setNodeTextWithNumbersRange } from './utils/setNodeText';

type Props = {
  referencesMap: Record<string, number>;
  saveHandler: () => void;
};

export const ReferenceCitationPlugin = (props: Props): null => {
  const { referencesMap, saveHandler } = props;
  const referencesMapRef = useRef(referencesMap);
  referencesMapRef.current = referencesMap;
  const saveHandlerRef = useRef(saveHandler);
  saveHandlerRef.current = saveHandler;

  const isMounted = useRef(false);

  const [editor] = useLexicalComposerContext();
  const editMode = editor.isEditable();
  const editModeRef = useRef(editMode);
  editModeRef.current = editMode;

  const root = editor.getRootElement();
  const nodesCache = useMemo<Set<string>>(() => new Set(), [editor]);

  const $updateReferenceNodes = useCallback((key: string): void => {
    const node = $getNodeByKey<ReferenceCitationNode>(key);
    if (!node) {
      return;
    }
    $reorderNodes(node, referencesMapRef.current);
    if (editModeRef.current) {
      $setNodeText(node, referencesMapRef.current);
    } else {
      $setNodeTextWithNumbersRange(node, referencesMapRef.current);
    }
  }, []);

  useEffect(() => {
    if (!root) {
      return;
    }
    const dropHandler = createDropHandler(editor, () => saveHandlerRef.current());
    root.addEventListener('drop', dropHandler);

    return () => {
      root.removeEventListener('drop', dropHandler);
    };
  }, [root, editor]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    editor.update(() => nodesCache.forEach($updateReferenceNodes));
  }, [editor, referencesMap, editMode]);

  useEffect(() => {
    isMounted.current = true;

    return editor.registerMutationListener(
      ReferenceCitationNode,
      (mutatedNodes) => {
        for (const [key, mutation] of mutatedNodes) {
          if (mutation === 'created') {
            nodesCache.add(key);
            editor.update(() => $updateReferenceNodes(key));
          } else if (mutation === 'destroyed') {
            nodesCache.delete(key);
          }
        }
      },
      { skipInitialization: false },
    );
  }, [editor]);

  return null;
};
