import classNames from 'classnames';
import { LexicalCallToActionEditor } from 'modules/Lexical/components/LexicalCallToActionEditor';
import React from 'react';
import { useLexicalCTAEditorContext } from '../context/LexicalCTAEditor';

const LexicalButton = (): JSX.Element => {
  const {
    id,
    editorState,
    editorRef,
    onChange,
    onStopEditing,
    editMode,
    editorWrap,
    styles,
    cssButton,
    brandProps,
    returnFocusToEditor,
    history,
    historyOnChange,
  } = useLexicalCTAEditorContext();

  return (
    <button
      id={id}
      onClick={returnFocusToEditor}
      onDoubleClick={returnFocusToEditor}
      className={classNames('reset-button', cssButton)}
      style={styles}
    >
      <div ref={editorWrap}>
        <LexicalCallToActionEditor
          editorState={editorState}
          ref={editorRef}
          onChange={onChange}
          onStopEditing={onStopEditing}
          editMode={editMode}
          brandProps={brandProps}
          historyExtraState={history.extraState}
          historyExtraStateSetter={history.extraStateSetter}
          historyOnChange={historyOnChange}
        />
      </div>
    </button>
  );
};

export default LexicalButton;
