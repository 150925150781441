import { DOMExportOutput, isHTMLElement, LexicalEditor, LexicalNode } from 'lexical';

type LexicalNodeClass = {
  new (a?: unknown, b?: unknown, c?: unknown, d?: unknown): LexicalNode;
  getType: () => string;
};

// for the Translated Package Export
export const withExportDom = (CustomLexicalNodeClass: LexicalNodeClass, textOnly = false): LexicalNodeClass => {
  return class extends CustomLexicalNodeClass {
    static getType(): string {
      return CustomLexicalNodeClass.getType() + '-export';
    }

    exportDOM(editor: LexicalEditor): DOMExportOutput {
      const { element } = super.exportDOM(editor);

      if (textOnly) {
        return { element: new Text(element?.textContent ?? '') };
      }

      if (isHTMLElement(element)) {
        element.getAttributeNames().forEach(attr => element.removeAttribute(attr));
      }

      return { element };
    }
  };
};
