import { RawDraftContentBlock } from 'draft-js';
import { SerializedSSIAnchorNode, SSIAnchorNode } from 'modules/Lexical/nodes/SSIAnchorNode';

export function createSerializedSSIAnchorNode(contentBlock: RawDraftContentBlock): SerializedSSIAnchorNode {
  return {
    type: SSIAnchorNode.getType(),
    version: 1,
    id: contentBlock.key,
  };
}
