import React from 'react';

import { intlGet } from 'utils/intlGet';
import { TextPlaceholderProps } from './models';
import styles from './styles.module.scss';

const TextPlaceholder: React.FunctionComponent<TextPlaceholderProps> = (props) => {
  const { show = true } = props;
  if (!show) {
    return null;
  }

  return (
    <div className={styles.TextPlaceholder}>
      {intlGet('Artboard.Layout.Text', 'Hint')}
    </div>
  );
};

export default TextPlaceholder;
