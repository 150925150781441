import classNames from 'classnames';
import { EditorState } from 'draft-js';
import { List } from 'immutable';
import _ from 'lodash';
import { TextDetailsEditor } from 'modules/draftjs/components/TextDetailsEditor';
import { compositeDecorator } from 'modules/draftjs/decorators';
import { addReferenceCitationsOrder } from 'modules/draftjs/utils';
import { LexicalTextPreviewEditor } from 'modules/Lexical/components/LexicalTextPreviewEditor';
import { useLexicalState } from 'modules/Lexical/hooks/useLexicalState';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import editorWrapperCss from 'components/ArtboardAssets/editor-wrapper.module.scss';
import { Details } from 'components/Details';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { useEditorToggle } from 'containers/EditorToggle/useEditorToggle';
import { hideModal } from 'containers/ModalManager/actions';
import { referencesDataForDocumentOnAssetPanel, selectedTextComponent } from 'containers/ProjectPanel/selectors';
import * as editorUtils from 'utils/editor';
import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

type Props = { modalWindowId: string };

const TextComponentDetails = (props: Props): JSX.Element => {
  const { modalWindowId } = props;

  const dispatch = useDispatch();
  const referencesNumbersOrder = useSelector(referencesDataForDocumentOnAssetPanel());
  const selectedTextComponentData = useSelector(selectedTextComponent);

  const { isLexicalMode, isDraftjsMode, isBothMode } = useEditorToggle();
  const onClose = (): void => {
    dispatch(hideModal(modalWindowId));
  };

  const editorState = _.flow(
    editorUtils.convertTextComponentToRawEditorState,
    currentEditorState => addReferenceCitationsOrder(currentEditorState, referencesNumbersOrder),
    currentEditorState => EditorState.set(currentEditorState, { decorator: compositeDecorator }),
  )(selectedTextComponentData);

  const editorStateLexical = useLexicalState({
    draftjsContent: editorState.getCurrentContent(),
    colors: List([]),
    fonts: List([]),
    lexicalState: selectedTextComponentData.get('lexicalState'),
  });

  return (
    <div className={styles.TextComponentDetails}>
      <Details
        onClose={onClose}
        title={intlGet('TextComponentDetails.Value', 'Title')}
      >
        <div className={styles.textInfo}>
          <Icon type={IconType.TEXT} size="sm-md" color="primary" />
          <div className={styles.htmlText}>
            <div className={classNames({
              [editorWrapperCss.editorModeBothWrapper]: isBothMode,
              [editorWrapperCss.editorModeLexicalWrapper]: isLexicalMode,
            })}>

              {(isDraftjsMode || isBothMode) && <TextDetailsEditor editorState={editorState} />}

              {(isLexicalMode || isBothMode) && <LexicalTextPreviewEditor editorState={editorStateLexical}/>}
            </div>
          </div>
        </div>
      </Details>
    </div>
  );
};

export default TextComponentDetails;
