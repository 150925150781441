import Immutable, { OrderedSet } from 'immutable';
import { LexicalEditor } from 'lexical';
import { RefObject, useMemo, useRef } from 'react';
import * as Constants from 'const';
import { BASE_PROPS_COMMAND, BaseProps } from '../Plugins/BasePropsPlugin';
import { LINK_COMMAND, LinkProps } from '../Plugins/LinkNodePlugin';
import { REFERENCES_FONT_COMMAND, ReferencesFontProps } from '../Plugins/ReferencesFontPlugin';
import { usePropsUpdater } from './usePropsUpdater';

export type LexicalOnChange = {
  baseProps: (values: BaseProps) => void;
  font: (values: ReferencesFontProps) => void;
  link: (values: LinkProps) => void;
  textContent: (value: string) => void;
};

type Props = {
  inlineStyle: OrderedSet<Constants.ReferencesInlineStyle>;
  link: string | undefined;
  linkApplicable: boolean;
  scriptStyle?: Constants.ScriptType;
  hasTextContent: boolean;
};

type Setters = {
  inlineStyle: (style: Constants.ReferencesInlineStyle) => void; // will toggle one of inline styles
  link: (value: string | undefined) => void;
  scriptStyle: (style: Constants.ScriptType) => void;
  clearFormatting: () => void;
};

type LexicalReferencesEditorHook = {
  disableLink: boolean;
  fontStyles: Immutable.OrderedSet<string>;
  link: string;
  toggleLink: (link: string) => void;
  clearFormatting: () => void;
  toggleFontStyle: (style: string) => void;
  toggleScriptStyle: (style: Constants.ScriptType) => void;
  ref: RefObject<LexicalEditor>;
  onChange: LexicalOnChange;
  hasTextContent: boolean;
};

export function useLexicalReferencesEditor(): LexicalReferencesEditorHook {
  const ref = useRef<LexicalEditor>(null);

  const { props, updateProps } = usePropsUpdater<Props>({
    inlineStyle: OrderedSet<Constants.ReferencesInlineStyle>([]),
    link: undefined,
    linkApplicable: false,
    scriptStyle: undefined,
    hasTextContent: false,
  });

  const setters = useMemo((): Setters => ({
    inlineStyle: (style: Constants.ReferencesInlineStyle) => ref.current?.dispatchCommand(
      REFERENCES_FONT_COMMAND.INLINE_STYLE,
      style,
    ),
    link: (value: string | undefined) => ref.current?.dispatchCommand(LINK_COMMAND, value),
    scriptStyle: (value: Constants.ScriptType) => ref.current?.dispatchCommand(BASE_PROPS_COMMAND.SCRIPT_STYLE, value),
    clearFormatting: () => ref.current?.dispatchCommand(BASE_PROPS_COMMAND.CLEAR_FORMATTING, undefined),
  }), []);

  const onChange: LexicalOnChange = useMemo(() => ({
    baseProps: (values: BaseProps) => updateProps({
      scriptStyle: values.scriptStyle,
    }),
    font: values => updateProps({
      inlineStyle: values.inlineStyle,
    }),
    link: values => updateProps({
      link: values.link,
      linkApplicable: values.linkApplicable,
    }),
    textContent: value => updateProps({
      hasTextContent: Boolean(value.length),
    }),
  }), [updateProps]);

  return {
    disableLink: !props.linkApplicable,
    ref,
    fontStyles: (props.inlineStyle as unknown as Immutable.OrderedSet<string>).add(props.scriptStyle as string),
    link: props.link ?? '',
    hasTextContent: props.hasTextContent,
    onChange,
    toggleLink: (link: string) => setters.link(link || undefined),
    clearFormatting: (): void => {
      setters.clearFormatting();
    },
    toggleFontStyle: (value: string): void => {
      setters.inlineStyle(value as Constants.ReferencesInlineStyle);
    },
    toggleScriptStyle: (style: Constants.ScriptType): void => {
      setters.scriptStyle(style);
    },
  };
}
