import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import * as Models from 'models';
import { $verifyStyleForFont, $applyBrandStyleForFont } from './brandStyle';

type Props = {
  brandStyle: Models.BrandStyleMap | undefined;
  brandStyleChanged: boolean | undefined;
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
};

export function FontBrandStylePlugin(props: Props): null {
  const { brandStyle, colors, fonts, brandStyleChanged } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      if (brandStyleChanged) {
        return;
      }
      $applyBrandStyleForFont(brandStyle, colors, fonts);
    });
  }, [editor, brandStyle, brandStyleChanged]);

  useEffect(() => {
    editor.update(() => {
      $verifyStyleForFont(colors, fonts);
    });
  }, [editor, colors, fonts]);

  return null;
}
