import classNames from 'classnames';
import React from 'react';

import Dropdown from 'components/Toolbar/Shared/Dropdown';
import {
  KeyboardKey,
  LAST_VISITED_SLIDE,
  NEXT_SLIDE,
  PREVIOUS_SLIDE,
  ProjectsConfig,
  SCREEN_LINK_PREFIX,
  UUID_PATTERN,
  HandleValue,
} from 'const';
import { validateEventKey } from 'containers/UndoRedoControl/utils';
import { ScreensMap } from 'models';
import { appendProtocol } from 'utils/appendProtocol';
import { intlGet } from 'utils/intlGet';
import { isStartingWithProtocol } from 'utils/isStartingWithProtocol';
import { removeZeroWidthSpace } from 'utils/replaceChars';
import { LinkDropdownProps } from './models';
import styles from './styles.module.scss';

const editorToolbarLink = 'EditorToolbar.Link';

const isInternalLink = (link: string, screens: ScreensMap): boolean => {
  const screenIdFromLink = link.replace(SCREEN_LINK_PREFIX, '');

  return !isStartingWithProtocol(link) && (
    [NEXT_SLIDE, PREVIOUS_SLIDE, LAST_VISITED_SLIDE].includes(link) ||
    screens.keySeq().toArray().includes(screenIdFromLink)
  );
};

const LinkDropdown: React.FunctionComponent<LinkDropdownProps> = (props) => {
  const {
    activeScreenId,
    enableInternalLinks = true,
    link,
    projectType,
    screens,
    toggleDropdown,
    toggleLink,
  } = props;
  const { enableProjectLinks, enableLinkProtocolAppending, externalLinkPlaceholder } = ProjectsConfig[projectType];
  const isExternalLink = !isInternalLink(link, screens);
  const activeScreenIndex = screens.keySeq().findIndex(id => id === activeScreenId);
  const showInternalLinks = enableInternalLinks && enableProjectLinks && screens.size > 1;
  const showLinkToPreviousScreen = activeScreenIndex > 0;
  const showLinkToNextScreen = activeScreenIndex < screens.size - 1;

  const [linkText, setLinkText] = React.useState(isExternalLink ? link : '');
  const screensRef = React.useRef(screens);
  const linkRef = React.useRef(linkText);

  React.useEffect(() => {
    setLinkText(link);
  }, [link]);

  React.useEffect(
    () => { linkRef.current = linkText; screensRef.current = screens; },
    [linkText, screens],
  );

  React.useEffect(
    () => () => {
      const { current: link } = linkRef;
      const { current: screens } = screensRef;

      const processedLink = !isInternalLink(link, screens) && enableLinkProtocolAppending
        ? appendProtocol(link)
        : link;

      if (props.link !== processedLink) {
        toggleLink(processedLink);
      }
    },
    [],
  );

  React.useEffect(
    () => {
      const uuidRegExp = new RegExp(`${SCREEN_LINK_PREFIX}${UUID_PATTERN.source}`, 'ig');
      isExternalLink && uuidRegExp.test(link) && toggleLink('');
    },
    [],
  );

  const onEnterPress = (event: React.KeyboardEvent): void => {
    if (event.key === KeyboardKey.ENTER) {
      event.isPropagationStopped();
      event.preventDefault();
      toggleDropdown();
    }
  };

  const onKeyDown = (event: any): Draft.DraftHandleValue => {
    if ((event.ctrlKey || event.metaKey) && validateEventKey(event)) {
      event.stopPropagation();

      return HandleValue.HANDLED;
    }

    return HandleValue.NOT_HANDLED;
  };

  const saveLink = (): void => {
    toggleLink(linkText);
  };

  const onExternalLinkClick = (): void => {
    !isExternalLink && toggleLink('');
  };

  const onInternalLinkClick = (screenLink: string): React.ReactEventHandler => () => {
    toggleLink(link === screenLink ? '' : screenLink);
  };

  const renderInternalLinks = (): JSX.Element[] => {

    return screens.valueSeq().toArray().map((screen, index) => {
      const name = screen.get('name');
      const id = screen.get('id');
      const screenNumber = index + 1;
      const isActive = link.includes(id);

      return (
        <button
          key={name}
          onClick={onInternalLinkClick(`${SCREEN_LINK_PREFIX}${id}`)}
          className={classNames('reset-button', styles.optionItem, styles.screen, { [styles.active]: isActive })}
        >
          <div className={classNames(styles.screenItem)}>
            <div className={classNames(styles.screenNumber)}>{ isActive ? null : screenNumber}</div>
            <div >{id === activeScreenId ? `${name} ${intlGet(editorToolbarLink, 'CurrentScreen')}` : name}</div>
          </div>
        </button>
      );
    });
  };

  return (
    <Dropdown className={styles.LinkDropdown} stopClickPropagation={true}>
      <div className={styles.title}>{intlGet(editorToolbarLink, 'Title')}</div>
      <div className={styles.container}>
        {enableInternalLinks && <label className={styles.label}>{intlGet(editorToolbarLink, 'URL')}</label>}
        <div
          className={classNames(styles.optionItem, styles.inputOption, { [styles.active]: isExternalLink })}
          onClick={onExternalLinkClick}
        >
          <input
            autoFocus={true}
            className={styles.input}
            onBlur={saveLink}
            onChange={(e): void => setLinkText(removeZeroWidthSpace(e.target.value.trim()))}
            onKeyPress={onEnterPress}
            onKeyDown={onKeyDown}
            placeholder={externalLinkPlaceholder}
            value={linkText}
          />
        </div>
        {showInternalLinks &&
          <>
            <label className={styles.label}>{intlGet(editorToolbarLink, 'SelectDestination')}</label>
            <div className={styles.optionsList}>
              {showLinkToPreviousScreen &&
                <div
                  className={classNames(styles.optionItem, { [styles.active]: link === PREVIOUS_SLIDE })}
                  onClick={onInternalLinkClick(PREVIOUS_SLIDE)}
                >
                  {intlGet(editorToolbarLink, 'Previous')}
                </div>
              }
              {showLinkToNextScreen &&
                <div
                  className={classNames(styles.optionItem, { [styles.active]: link === NEXT_SLIDE })}
                  onClick={onInternalLinkClick(NEXT_SLIDE)}
                >
                  {intlGet(editorToolbarLink, 'Next')}
                </div>
              }
              <div
                className={classNames(styles.optionItem, { [styles.active]: link === LAST_VISITED_SLIDE })}
                onClick={onInternalLinkClick(LAST_VISITED_SLIDE)}
              >
                {intlGet(editorToolbarLink, 'LastVisited')}
              </div>
            </div>
            <label className={styles.label}>{intlGet(editorToolbarLink, 'Screens')}</label>
            <div className={styles.optionsList}>
              {renderInternalLinks()}
            </div>
          </>
        }
      </div>
    </Dropdown>
  );
};

export default LinkDropdown;
