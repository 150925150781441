import Draft from 'draft-js';
import { TCombination, TRanges, DraftEntityData } from './types';

const createCombinationsReducer = (
  entityMap: { [key: string]: Draft.RawDraftEntity },
) => (acc: TCombination[], curr: TRanges): TCombination[] => {
  const index = acc.length > 0 && curr.style
    ? acc.findIndex(
      el => el.position[0] === curr.offset && el.position[1] === curr.length,
    )
    : -1;

  if (index >= 0) {
    acc[index] = {
      ...acc[index],
      style: [...(acc[index].style || []), curr.style],
    };

    return acc;
  }

  const child: TCombination = {
    type: curr.key !== undefined ? entityMap[curr.key].type : null,
    style: curr.style ? [curr.style] : null,
    position: [curr.offset, curr.length],
    data: curr.key !== undefined ? entityMap[curr.key].data : null,
  };

  return [...acc, child];
};

export function extractBlockEntities(
  inlineStyleRanges: Draft.RawDraftInlineStyleRange[],
  entityRanges: Draft.RawDraftEntityRange[],
  entityMap: { [key: string]: Draft.RawDraftEntity },
  line: Draft.RawDraftContentBlock): DraftEntityData[] {
  const combinations = [...inlineStyleRanges, ...entityRanges]
    .reduce(createCombinationsReducer(entityMap), [] as TCombination[]);

  const offsets: number[] = combinations.reduce((acc, item) => {
    const value = [item.position[0], item.position[0] + item.position[1]];

    return [...(new Set([...acc, ...value]))];
  }, []).sort((a, b) => (a - b));
  offsets.pop();

  const chunks: DraftEntityData[] = [];

  offsets.forEach((offset, i: number) => {
    const styles = combinations.reduce((acc, curr) => {
      const start = curr.position[0];
      const end = start + curr.position[1];

      return curr.style && (start <= offset && end > offset)
        ? [...acc, ...curr.style]
        : acc;
    }, []);

    const text = line.text.slice(offset, offsets[i + 1]);
    const entityRange = entityRanges.find(range => range.offset <= offset && (range.offset + range.length) > offset);

    if (entityRange) {
      const { type, data } = entityMap[entityRange.key];
      chunks.push({ styles, text, type, data });
    } else {
      chunks.push({ styles, text });
    }
  });

  return chunks;
}
