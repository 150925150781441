import Draft from 'draft-js';
import { useMemo } from 'react';
import { useEditorToggle } from 'containers/EditorToggle/useEditorToggle';
import * as Models from 'models';
import { lexicalRawConverter } from '../LexicalRawConverter/lexicalRawConverter';

type Props = {
  draftjsContent?: Draft.ContentState;
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
  lexicalState?: string;
};

export function useLexicalState(props: Props): string | null {
  const { isDraftjsMode, isBothMode } = useEditorToggle();
  const { draftjsContent, colors, fonts, lexicalState } = props;

  return useMemo(() => {
    if (isDraftjsMode) {
      return null;
    }
    if (!isBothMode && lexicalState) {
      return lexicalState;
    }
    if (!draftjsContent) {
      return null;
    }
    const rawContent = Draft.convertToRaw(draftjsContent);
    const formatedData = lexicalRawConverter(rawContent, colors, fonts);

    return JSON.stringify(formatedData.editorState);
  }, [draftjsContent, colors, fonts, lexicalState, isDraftjsMode, isBothMode]);
}
