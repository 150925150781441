import { EditorSetters } from '../editor';
import { UndoMiddleware } from '../hooks/useUndo';
import { CTAStylesSetters } from './styles';

export function wrapUndoMiddleware(
  middleware: UndoMiddleware,
  setters: CTAStylesSetters,
): CTAStylesSetters {
  return {
    ...setters,
    alignment: middleware(setters.alignment, 'alignment'),
    assetBackgroundColor: middleware(setters.assetBackgroundColor, 'assetBackgroundColor'),
    assetBackgroundOpacity: middleware(setters.assetBackgroundOpacity, 'assetBackgroundOpacity'),
    assetBackgroundGradient: middleware(setters.assetBackgroundGradient, 'assetBackgroundGradient'),
    assetBorderRadius: middleware(setters.assetBorderRadius, 'assetBorderRadius'),
    assetPadding: middleware(setters.assetPadding, 'assetPadding'),
    backgroundColor: middleware(setters.backgroundColor, 'backgroundColor'),
    backgroundColorOpacity: middleware(setters.backgroundColorOpacity, 'backgroundColorOpacity'),
    backgroundGradient: middleware(setters.backgroundGradient, 'backgroundGradient'),
    backgroundImage: middleware(setters.backgroundImage, 'backgroundImage'),
    border: middleware(setters.border, 'border'),
    borderRadius: middleware(setters.borderRadius, 'borderRadius'),
    fitToCell: middleware(setters.fitToCell, 'fitToCell'),
    height: middleware(setters.height, 'height'),
    padding: middleware(setters.padding, 'padding'),
    textAlignment: middleware(setters.textAlignment, 'textAlignment'),
    width: middleware(setters.width, 'width'),
  };
}

export function wrapEditorSettersWithUndo(
  middleware: UndoMiddleware,
  setters: EditorSetters,
): EditorSetters {
  return {
    ...setters,
    fontColor: middleware(setters.fontColor),
    fontFamily: middleware(setters.fontFamily),
    fontSize: middleware(setters.fontSize),
    inlineStyle: middleware(setters.inlineStyle),
    scriptStyle: middleware(setters.scriptStyle),
  };
}
