import { uploadJson } from 'services/api';
import { deleteInternalInfo } from 'utils/deleteInternalInfo';
import { deleteLexicalState } from 'utils/deleteLexicalState';
import { UploadProjectFlowArg } from '../models';

export async function uploadJsonToProject(arg: UploadProjectFlowArg): Promise<void> {
  const { rootDocumentId, projectAssets } = arg;
  const { data: projectFileDocument } = await uploadJson(rootDocumentId,
    deleteLexicalState(deleteInternalInfo(projectAssets)));
  projectAssets.documents[projectFileDocument.id] = projectFileDocument;
}
