import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { saveAppState, cancel } from 'containers/UndoRedoControl/actions';

export function useHistoryGlobalState(
  editMode: boolean,
  isUndoDisabled: boolean,
): void {
  const dispatch = useDispatch();
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    if (editMode) {
      dispatch(saveAppState());
    } else if (isUndoDisabled) {
      dispatch(cancel());
    }
  }, [editMode]);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);
}
