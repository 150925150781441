import { $isListItemNode } from '@lexical/list';
import { $createTextNode,
  $getNearestNodeFromDOMNode,
  $getRoot,
  $isParagraphNode,
  $isTextNode,
  EditorState,
  LexicalEditor,
  LexicalNode,
  TextNode,
} from 'lexical';
import { $createSSIAnchorNode, $isSSIAnchorNode } from 'modules/Lexical/nodes/SSIAnchorNode';
import { $getSSIAnchorNodes } from './getSSIAnchorNodes';

const $getNearestTextNode = (node: Node): TextNode | undefined => {
  const lexicalNode: LexicalNode | undefined | null = $getNearestNodeFromDOMNode(node);

  if (!lexicalNode) {
    return;
  }

  let textNode: TextNode | undefined;

  if ($isTextNode(lexicalNode)) {
    return lexicalNode;
  }

  if ($isListItemNode(lexicalNode)) {
    textNode = lexicalNode.getChildren().find($isTextNode);

    if (!textNode) {
      textNode = $createTextNode();
      lexicalNode.append(textNode);
    }
  }
  if ($isParagraphNode(lexicalNode)) {
    const isEmpty = lexicalNode.getChildren().length === 0;
    if (isEmpty) {
      textNode = $createTextNode();
      lexicalNode.append(textNode);
    }
  }

  return textNode;
};

export function createDropHandler(
  editor: LexicalEditor,
  modifyDocumentFn: (state: EditorState) => void,
) {
  return (event: DragEvent): boolean => {
    event.preventDefault();
    const data = event.dataTransfer?.getData('text/plain');
    if (!data) {
      return false;
    }

    const { anchorBlockKey }: { anchorBlockKey: string } = JSON.parse(data);

    editor.update(() => {
      const targetNode = event.target;
      if (!targetNode || !(targetNode instanceof Node)) {
        return;
      }

      const textNode = $getNearestTextNode(targetNode);

      if (!textNode) {
        return;
      }
      // remove existing anchor
      textNode.getParent()?.getChildren().find($isSSIAnchorNode)?.remove();

      let anchorNode = $getSSIAnchorNodes($getRoot()).find(ssiAnchor => ssiAnchor.getId() === anchorBlockKey);
      if (!anchorNode) {
        const { offsetTop } = event.target as HTMLElement;

        anchorNode = $createSSIAnchorNode({
          id: anchorBlockKey,
          offsetTop,
        });
      }
      textNode.insertBefore(anchorNode);
    }, { discrete: true });

    const state = editor.read(() => {
      return editor.getEditorState();
    });
    modifyDocumentFn(state);

    return true;
  };
}
