import Immutable from 'immutable';
import * as Models from 'models';
import { ActionTypes } from './constants';
import { State } from './models';

const initialState: State.IState = {
  abbreviationList: Immutable.fromJS({}),
};

const reducer: Models.Reducer<State.IState> = (state: State.IState | undefined, action) => {
  const currentState = state ?? initialState;

  if (action.type === ActionTypes.SET_TEXT_ABBREVIATIONS) {
    return {
      ...currentState,
      abbreviationList: Immutable.fromJS(action.payload),
    };
  }

  return currentState;
};

export default reducer;
