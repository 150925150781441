import * as Constants from 'const';
import * as DocumentsModels from 'containers/Documents/models';
import * as Models from 'models';
import { Schemas } from 'services/ArtboardConverter/models';
import { convertEditorState } from 'services/ArtboardConverter/utils/convertEditorState';
import { getTextStylesFromBrandStyle } from 'utils/brandStyles';
import * as editorUtils from 'utils/editor';
import { getEditorStateFromTextComponent } from '../utils';

type Context = {
  projectType: Constants.ProjectType;
  globalColors: Models.BrandColorsList;
  relationColors: Models.BrandColorsList;
  relationFonts: Models.BrandFontsList;
  relationBrandStyle: Models.BrandStyleMap | undefined;
  defaultBrandStyle: Models.BrandStyleMap | undefined;
  citationsOrderByDocuments: DocumentsModels.ReferenceCitationsOrderByDocumentsMap;
  surfaces: Models.ScreensOrderedMap;
};

export function createTextCellSchema(
  relation: Models.RegularRelationMap<Models.TextRelationStyles>,
  document: Models.TextComponentMap,
  context: Context,
): Schemas.ReferenceText & { isCellAutoHeight: boolean } {
  const {
    projectType, globalColors,
    relationColors, relationFonts, relationBrandStyle, defaultBrandStyle,
    citationsOrderByDocuments, surfaces,
  } = context;

  let editorState = getEditorStateFromTextComponent(
    document,
    relation,
    citationsOrderByDocuments,
  );
  const isCellAutoHeight = editorUtils.hasToken(editorState);

  const styles = relation.get('styles');
  const brandStyleChanged = styles.get('brandStyleChanged');
  const brandStyleId = styles.get('brandStyleId');
  if (!brandStyleChanged && brandStyleId) {
    const brandStyleToApply = !relationBrandStyle ? defaultBrandStyle : relationBrandStyle;
    const textStyles = getTextStylesFromBrandStyle(brandStyleToApply, globalColors, relationFonts);
    editorState = editorUtils.toggleBrandStyle(editorState, textStyles, globalColors, relationFonts);
  }

  if (Constants.ProjectsConfig[projectType].applyFontStyleForBrandStyles) {
    editorState = editorUtils.applyFontStylesForBrandStyles(editorState, relationFonts);
  }
  editorState = editorUtils.applyFontStyles(editorState, relationFonts, projectType);

  const converterOptions = {
    brandProps: { brandStyle: relationBrandStyle, colors: relationColors, fonts: relationFonts },
    defaultLineHeight: Constants.TextLineHeightValue.ONE_POINT_FIVE,
    projectType,
    surfaces,
  };
  const schema = convertEditorState(editorState, converterOptions);

  return { ...schema, isCellAutoHeight };
}
