import Draft, { EditorState } from 'draft-js';
import { useCallback, useRef } from 'react';
import { IMap } from 'typings/DeepIMap';
import { StackToggler } from 'components/ArtboardAssets/utils/stack';
import { UndoRedoMiddleware, useUndoRedo } from 'hooks/useUndoRedo';
import * as Models from 'models';
import { HistoryExtraState } from './useHistoryExtraState';
import { useStyles } from './useStyles';

type CallToActionStack = Omit<HistoryExtraState, 'assetBackgroundGradient' | 'backgroundGradient'> & {
  assetBackgroundGradient: [Models.BrandColorGradientMap | undefined, IMap<Models.BrandColor> | undefined];
  backgroundGradient: [Models.BrandColorGradientMap | undefined, IMap<Models.BrandColor> | undefined];
  editorState: EditorState;
};

export type UndoMiddleware = UndoRedoMiddleware<CallToActionStack>;

export type UndoHook = {
  isUndoDisabled: boolean;
  isRedoDisabled: boolean;
  undo: () => void;
  redo: () => void;
  undoStackMiddleware: UndoMiddleware;
  fillUndoStackIfEmpty: () => void;
};

type State = {
  undoStack: CallToActionStack[];
  redoStack: CallToActionStack[];
};

type EditorHook = {
  editorState: Draft.EditorState;
  onEditorChange: (editorState: Draft.EditorState) => void;
};

type OtherOptions = {
  toggleAutoFitContent: () => void;
  toggleLink: (value: string) => void;
  toggleRowHeight: (newHeight: number) => number;
  toggleColumnWidth: (newWidth: number) => number;
};

export function useUndo(
  editMode: boolean,
  editorHook: EditorHook,
  stylesHook: ReturnType<typeof useStyles>,
  other: OtherOptions,
  extraState: HistoryExtraState,
): UndoHook {

  const { editorState, onEditorChange } = editorHook;
  const { styles, setters: stylesSetters } = stylesHook;

  const currentStackData: CallToActionStack = {
    ...extraState,
    assetBackgroundGradient: styles.assetBackgroundGradient
      ? [styles.assetBackgroundGradient, styles.assetBackgroundColor]
      : [undefined, undefined],
    backgroundColor: styles.backgroundGradient ? undefined : styles.backgroundColor,
    backgroundGradient: styles.backgroundGradient
      ? [styles.backgroundGradient, styles.backgroundColor]
      : [undefined, undefined],
    editorState,
  };
  const currentStackDataRef = useRef(currentStackData);
  currentStackDataRef.current = currentStackData;

  const togglersMap: StackToggler<CallToActionStack> = {
    alignment: stylesSetters.alignment,
    assetBackgroundColor: stylesSetters.assetBackgroundColor,
    assetBackgroundOpacity: stylesSetters.assetBackgroundOpacity,
    assetBackgroundGradient: ([gradient, color]) => stylesSetters.assetBackgroundGradient(gradient, color),
    assetBorderRadius: stylesSetters.assetBorderRadius,
    assetPadding: stylesSetters.assetPadding,
    backgroundColor: stylesSetters.backgroundColor,
    backgroundColorOpacity: stylesSetters.backgroundColorOpacity,
    backgroundGradient: ([gradient, color]) => stylesSetters.backgroundGradient(gradient, color),
    backgroundImage: stylesSetters.backgroundImage,
    border: stylesSetters.border,
    borderRadius: stylesSetters.borderRadius,
    fitToCell: stylesSetters.fitToCell,
    padding: stylesSetters.padding,
    textAlignment: stylesSetters.textAlignment,
    height: stylesSetters.height,
    width: stylesSetters.width,
    editorState: onEditorChange,
    link: other.toggleLink,
    isAutoFitContent: other.toggleAutoFitContent,
    cellHeight: other.toggleRowHeight,
    cellWidth: other.toggleColumnWidth,
  };

  const {
    undoStack,
    redoStack,
    undo,
    redo,
    undoStackMiddleware,
    updateUndoRedoBeforeChange,
  } = useUndoRedo<CallToActionStack>({
    togglersMap,
    currentStackData,
    editMode,
  });

  const stateRef = useRef<State>({ undoStack, redoStack });
  stateRef.current = { undoStack, redoStack };

  const fillUndoStackIfEmpty = useCallback((): void => {
    if (!stateRef.current.undoStack.length) {
      updateUndoRedoBeforeChange(currentStackDataRef.current);
    }
  }, [updateUndoRedoBeforeChange]);

  return {
    isUndoDisabled: !undoStack.length,
    isRedoDisabled: !redoStack.length,
    undo: (): void => undo(currentStackData),
    redo: (): void => redo(currentStackData),
    fillUndoStackIfEmpty,
    undoStackMiddleware,
  };
}
