import { $getRoot, EditorState } from 'lexical';
import { CellEntityType } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { ConvertEditorStateOptions } from 'services/ArtboardConverter/utils/convertEditorState';
import { getInlineStylesForExport } from 'utils/brandStyles';
import { CustomListItemNode } from '../nodes/CustomListItemNode';
import { $isCustomListNode } from '../nodes/CustomListNode';
import { $isCustomParagraphNode } from '../nodes/CustomParagraphNode';
import { createSegments } from './createSegmetns';
import { createParagraphForText, createParagraphForListItem, createParagraphForList } from './factories';

export function convertLexicalEditorState(
  editorState: EditorState,
  options: ConvertEditorStateOptions,
): Schemas.ReferenceText {
  const { brandProps, defaultLineHeight, projectType } = options;
  const brandInlineStyles = brandProps.brandStyle && getInlineStylesForExport(brandProps.brandStyle, brandProps.colors, brandProps.fonts);
  const createParagraphContext = { projectType, defaultLineHeight, defaultStyles: brandInlineStyles };

  return editorState.read(() => {

    const paragraphs: (Schemas.Paragraph | Schemas.ListParagraph)[] = [];
    $getRoot().getChildren().forEach((node) => {

      if ($isCustomParagraphNode(node)) {
        const segments = createSegments(node, options, { lineHeight: node.getLineHeight() ?? defaultLineHeight });
        paragraphs.push(createParagraphForText(node, createParagraphContext, segments));

        return;
      }

      if ($isCustomListNode(node)) {
        const bulletColor = node.getColor();
        // IN-PROGRESS nested lists
        const depth = 0;
        const nestedParagraphs = node.getChildren().map(
          (listItemNode: CustomListItemNode) => {
            const segments = createSegments(
              listItemNode,
              options,
              { lineHeight: listItemNode.getLineHeight() ?? defaultLineHeight, bulletColor: node.getColor() },
            );

            return createParagraphForListItem(
              listItemNode,
              { depth, bulletColor, ...createParagraphContext },
              segments,
            );
          },
        );

        paragraphs.push(createParagraphForList(node, createParagraphContext, nestedParagraphs));

        return;
      }

      throw Error(`Wrong node type: ${node.getType()}`);
    });

    return {
      entityType: CellEntityType.TEXT,
      paragraphs,
      isReferenceCitation: false,
    };
  });
}
