import { LinkNode } from '@lexical/link';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { LexicalEditor, ParagraphNode, TextNode } from 'lexical';
import { mainTheme } from 'modules/Lexical/Theme/mainTheme';
import React, { forwardRef } from 'react';
import { LexicalOnChange } from '../hooks/useLexicalReferencesEditor';
import { BasePropsPlugin } from '../Plugins/BasePropsPlugin';

import { BlurControlPlugin } from '../Plugins/BlurControlPlugin';
import { EditModePlugin } from '../Plugins/EditModePlugin';
import { EditorRefPlugin } from '../Plugins/EditorRefPlugin';
import { HtmlContentPlugin } from '../Plugins/HtmlContentPlugin';
import { LinkNodePlugin } from '../Plugins/LinkNodePlugin';
import { ReferencesFontPlugin } from '../Plugins/ReferencesFontPlugin';
import { TextContentPlugin } from '../Plugins/TextContentPlugin';

const editorConfig = {
  namespace: 'references-editor',
  editable: false,
  theme: mainTheme,
  onError: (error): void => {
    throw error;
  },
  nodes: [
    TextNode,
    ParagraphNode,
    LinkNode,
  ],
};

export type LexicalReferencesEditorRef = {
  editor: LexicalEditor;
};

type Props = {
  onChange: LexicalOnChange;
  editMode: boolean;
  html?: string;
};

export const LexicalReferencesEditor = forwardRef<LexicalEditor, Props>((props, ref) => {
  const {
    editMode,
    onChange,
    html,
  } = props;

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className='editor-container' style={{ height: '100%' }}>
        <RichTextPlugin
          contentEditable={<ContentEditable style={{ height: '100%' }} className='editor-input' />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <AutoFocusPlugin />
        <LinkPlugin />
        <EditorRefPlugin ref={ref} />
        <EditModePlugin editMode={editMode} />
        <BasePropsPlugin onChange={onChange.baseProps} />
        <ReferencesFontPlugin
          onChange={onChange.font}
        />
        <TextContentPlugin onChange={onChange.textContent} />
        <LinkNodePlugin onChange={onChange.link} />
        <HtmlContentPlugin html={html} />
        <BlurControlPlugin/>
      </div>
    </LexicalComposer>
  );
});
