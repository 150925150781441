import classNames from 'classnames';
import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import { TextEditor, wrapperOnMouseDownHackStrict } from 'modules/draftjs';
import useDraftjsTextEditor from 'modules/draftjs/hooks/useDraftjsTextEditor';
import { useLexicalState } from 'modules/Lexical/hooks/useLexicalState';
import { useLexicalTextEditor } from 'modules/Lexical/hooks/useLexicalTextEditor';
import { useLexicalUndo } from 'modules/Lexical/hooks/useLexicalUndo';
import { useReferencesMap } from 'modules/References/hooks/useReferencesMap';
import React, { MutableRefObject, useEffect, useRef, lazy, Suspense } from 'react';
import { IMap } from 'typings/DeepIMap';
import EditorToolbar from 'components/EditorToolbar';
import UndoPortal from 'components/UndoRedo/UndoPortal';
import { BoxPropertySide } from 'const';
import { useEditorToggle } from 'containers/EditorToggle/useEditorToggle';
import { getActiveLayer } from 'hooks/useActiveLayer';
import useBrandProps from 'hooks/useBrandProps';
import { useHistoryGlobalState } from 'hooks/useHistoryGlobalState';
import * as Models from 'models';
import { toImmutable } from 'utils/immutable';
import { intlGet } from 'utils/intlGet';
import { areThereHorizontalNeighbors } from 'utils/rowsHeight/areThereHorizontalNeighbors';
import editorWrapperCss from '../editor-wrapper.module.scss';
import { TextEditorHook } from './hooks/TextEditorHook';
import useBrandStyle from './hooks/useBrandStyle';
import useCell from './hooks/useCell';
import useDraftjsTextUndo, { cancelUndoStack } from './hooks/useDraftjsTextUndo';
import useEditMode from './hooks/useEditMode';
import { useHistoryExtraState } from './hooks/useHistoryExtraState';
import useResizeObserver from './hooks/useResizeObserver';
import useSave from './hooks/useSave';
import useStyles from './hooks/useStyles';
import { TextProps } from './models';
import css from './styles.module.scss';
import { wrapEditorSettersWithUndoMiddleware, wrapStylesSettersWithUndoMiddleware } from './utils/draftjs-undo';
import { getContentMinHeight } from './utils/getContentMinHeight';
import { stylesToCSS } from './utils/styles';

const LexicalTextEditor = lazy(
  () => import('modules/Lexical/components/LexicalTextEditor')
    .then(module => ({ default: module.LexicalTextEditor })),
);

export function getTextDOMId(relationId: string): string {
  return `text-cell-${relationId}`;
}

// IN-PROGRESS complexity will be automatically decreased after draftjs removal
// eslint-disable-next-line sonarjs/cognitive-complexity
const Text: React.FunctionComponent<TextProps> = (props) => { // NOSONAR
  const {
    layoutId,
    projectType,
    relation,
    sectionStyles,
    images,
    placeholderMinHeight,
    shrinkable,
    editMode,
    notEditable,
    isDraggingAsset,
    layoutRelations,
    ssi,
    document,
    activeLayer,
  } = props;

  const { isLexicalMode, isBothMode, isDraftjsMode } = useEditorToggle();

  const brandProps = useBrandProps(relation?.get('id'));
  const stylesHook = useStyles(props, brandProps);

  const draftjsOnBeforeEditorStateChangeRef = useRef<(() => void)>();
  const draftjsHook = useDraftjsTextEditor(
    props,
    brandProps,
    stylesHook,
    draftjsOnBeforeEditorStateChangeRef.current,
  );
  const lexicalHook = useLexicalTextEditor(stylesHook.stylesSetters.brandStyleChanged);
  const editorHook: TextEditorHook = isLexicalMode ? lexicalHook : draftjsHook;

  const wrapperRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const cellHook = useCell(
    props,
    wrapperRef,
    editorHook,
    stylesHook.styles,
  );

  const brandStyleHook = useBrandStyle(
    props,
    brandProps,
    editorHook,
    stylesHook,
  );

  useResizeObserver(wrapperRef.current, () => cellHook.resetHeight());
  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) { return; }
    wrapper.addEventListener('beforeinput', cancelUndoStack);

    return (): void => wrapper.removeEventListener('beforeinput', cancelUndoStack);
  }, [wrapperRef.current]);

  useEffect(() => {
    const activeLayer = getActiveLayer();
    const oldPadding = relation.getIn(['styles', activeLayer, 'padding']);
    let relationsWithUpdatedStyles;
    if (oldPadding.get(BoxPropertySide.TOP) !== stylesHook.styles.padding?.get(BoxPropertySide.TOP)
      || oldPadding.get(BoxPropertySide.BOTTOM) !== stylesHook.styles.padding?.get(BoxPropertySide.BOTTOM)) {
      // update relation with new padding before performing calculation to ensure right height
      const newPadding = toImmutable({
        bottom: stylesHook.styles.padding?.get(BoxPropertySide.BOTTOM),
        left: stylesHook.styles.padding?.get(BoxPropertySide.LEFT),
        right: stylesHook.styles.padding?.get(BoxPropertySide.RIGHT),
        top: stylesHook.styles.padding?.get(BoxPropertySide.TOP),
      });
      const relationWithUpdatedPadding = relation.setIn(['styles', activeLayer, 'padding'], newPadding);
      relationsWithUpdatedStyles = layoutRelations.set(
        relation.get('id'),
        relationWithUpdatedPadding as unknown as IMap<Models.LayeredRegularRelation<Models.CombinedRelationStyles>>);
    }

    cellHook.resetHeight(relationsWithUpdatedStyles);
  }, [
    stylesHook.styles.padding?.get(BoxPropertySide.TOP),
    stylesHook.styles.padding?.get(BoxPropertySide.BOTTOM),
  ]);

  const saveHandler = useSave(
    props,
    editorHook,
    stylesHook,
    cellHook,
    isLexicalMode,
  );
  useEditMode(
    props,
    brandProps,
    editorHook,
    stylesHook,
    cellHook,
    brandStyleHook.setBrandStyleAndAutofit,
    saveHandler,
  );

  const history = useHistoryExtraState(props, stylesHook, cellHook);
  const lexicalUndo = useLexicalUndo(lexicalHook.ref);
  const draftjsUndo = useDraftjsTextUndo(props, draftjsHook, history.extraState, history.extraStateSetter);
  const undoHook = isLexicalMode ? lexicalUndo : draftjsUndo;
  useHistoryGlobalState(editMode, undoHook.isUndoDisabled);

  draftjsOnBeforeEditorStateChangeRef.current = draftjsUndo.updateUndoRedoBeforeChange;

  useCellActionsExecutor(CellAction.SET_CURSOR_ON_ABBREVIATION, editorHook.setCursorOnAbbreviation);
  useCellActionsExecutor(CellAction.APPLY_SELECTION, editorHook.applySelection);

  const referencesMap = useReferencesMap(
    document,
    relation,
    activeLayer,
  );

  const { colors, fonts } = brandProps;

  const editorState = useLexicalState({
    draftjsContent: draftjsHook.editorState.getCurrentContent(),
    colors,
    fonts,
    lexicalState: document?.get('lexicalState'),
  });

  if (!colors || !fonts) {
    return null;
  }

  const isMultiColumn = areThereHorizontalNeighbors(relation.get('id'), layoutRelations);
  const isAutoHeight = editorHook.hasCustomToken && !isMultiColumn;
  const notShowPlaceholder = notEditable || editMode || editorHook.hasTextContent;
  const currentClientHeight = wrapperRef?.current?.clientHeight || props.minCellHeight;
  const contentMinHeight = getContentMinHeight(stylesHook.styles, currentClientHeight);

  return (
    <div
      role='presentation'
      className={classNames(css.Text, {
        [css.severalColumns]: cellHook.props.cellsCount > 1,
        [css.noMinHeight]: editorHook.hasTextContent || editMode || shrinkable || cellHook.props.cellHeight,
        [css.fullHeight]: cellHook.props.isLastCell || cellHook.props.cellHeight,
      })}
      style={stylesToCSS(stylesHook.styles, { images, placeholderMinHeight })}
      onMouseDown={wrapperOnMouseDownHackStrict}
    >
      {editMode && (
        <>
          <UndoPortal
            undo={undoHook.undo}
            redo={undoHook.redo}
            isRedoDisabled={undoHook.isRedoDisabled}
            isUndoDisabled={undoHook.isUndoDisabled}
          />
          <EditorToolbar
            layoutId={layoutId}
            projectType={projectType}
            returnFocusToEditor={editorHook.returnFocusToEditor}
            editorProps={editorHook.props}
            editorSetters={
              isLexicalMode
                ? editorHook.setters
                : wrapEditorSettersWithUndoMiddleware(draftjsHook.setters, draftjsUndo.undoStackMiddleware)
            }
            styles={stylesHook.styles}
            stylesSetters={
              isLexicalMode
                ? stylesHook.stylesSetters
                : wrapStylesSettersWithUndoMiddleware(stylesHook.stylesSetters, draftjsUndo.undoStackMiddleware)
            }
            cellProps={cellHook.props}
            toggleCellHeight={
              isLexicalMode
                ? cellHook.toggleCellHeight
                : draftjsUndo.undoStackMiddleware(cellHook.toggleCellHeight, 'cellHeight')
            }
            toggleColumnWidth={
              isLexicalMode
                ? cellHook.toggleCellWidth
                : draftjsUndo.undoStackMiddleware(cellHook.toggleCellWidth, 'cellWidth')
            }
            isAutoFitContent={props.isAutoFitContent}
            toggleAutoFitContent={stylesHook.toggleAutoFitContentWithBrandStyleChange}
            isAutoHeight={isAutoHeight}
            beforeAutoFitMouseDown={draftjsUndo.updateUndoRedoBeforeChange}
            setBrandStyle={
              isLexicalMode
                ? brandStyleHook.setBrandStyle
                : draftjsUndo.undoStackMiddleware(brandStyleHook.setBrandStyle, 'brandStyle')
            }
            sectionStyles={sectionStyles}
            contentMinHeight={contentMinHeight}
          />
        </>
      )}
      <div className={classNames({
        [editorWrapperCss.editorModeBothWrapper]: isBothMode,
        [editorWrapperCss.editorModeLexicalWrapper]: isLexicalMode,
      })}>
        {(isBothMode || isDraftjsMode) && (
          <TextEditor
            id={getTextDOMId(relation.get('id'))}
            relation={relation}
            ref={draftjsHook.editorRef}
            wrapperRef={wrapperRef}
            editorState={draftjsHook.editorState}
            onEditorChange={draftjsHook.onEditorChange}
            returnFocusToEditor={draftjsHook.returnFocusToEditor}
            setEditorState={draftjsHook.setEditorState}
            setEditorStateAndOperations={draftjsHook.setEditorStateAndOperations}
            addOperation={draftjsHook.addOperation}
            editMode={editMode}
            placeholder={notShowPlaceholder ? undefined : intlGet('Artboard.Layout.Text', 'Hint')}
            brandProps={brandProps}
            isDraggingAsset={isDraggingAsset}
            undoStackMiddleware={draftjsUndo.undoStackMiddleware}
            undo={draftjsUndo.undo}
            redo={draftjsUndo.redo}
            fillUndoStackIfEmpty={draftjsUndo.fillUndoStackIfEmpty}
            projectType={projectType}
            dropOptions={props} // IN-PROGRESS it will be nide to pass only dropOptions
            storeTextCallback={saveHandler}
            ssiPosition={notEditable && ssi?.get('position')}
            document={document}
          />
        )}
        {(isBothMode || isLexicalMode) && (
          <Suspense fallback={<div>Loading...</div>}>
            <LexicalTextEditor
              id={getTextDOMId(relation.get('id'))}
              wrapperRef={wrapperRef}
              ref={lexicalHook.ref}
              editorState={editorState}
              editMode={editMode && isLexicalMode}
              brandStyle={stylesHook.styles.brandStyle}
              brandStyleChanged={stylesHook.styles.brandStyleChanged}
              brandProps={brandProps}
              onChange={lexicalHook.onChange}
              historyExtraState={history.extraState}
              historyExtraStateSetter={history.extraStateSetter}
              historyOnChange={lexicalUndo.onChange}
              referencesMap={referencesMap}
              ssiPosition={notEditable && isLexicalMode && ssi?.get('position')}
              textDocument={document}
              relation={relation}
              saveHandler={saveHandler}
            />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default Text;
