import { RawDraftContentBlock } from 'draft-js';
import { SerializedLexicalNode } from 'lexical/LexicalNode';
import { CustomListItemNode, SerializedCustomListItemNode } from 'modules/Lexical/nodes/CustomListItemNode';
import * as Constants from 'const';
import { getInlineStylesValue } from 'utils/inlineStyles';
import { RawDraftContentBlockData } from '../types';

export function createListItemNode(
  contentBlock: RawDraftContentBlock,
  children: SerializedLexicalNode[] = [],
): SerializedCustomListItemNode {
  const { inlineStyleRanges, depth, data } = contentBlock;
  const styles = inlineStyleRanges.map(range => range.style);
  const { lineHeight } = data as RawDraftContentBlockData;

  return {
    children,
    direction: 'ltr',
    type: CustomListItemNode.getType(),
    version: 1,
    textFormat: 0,
    indent: depth,
    lineHeight,
    format: '',
    checked: false,
    value: 0,
    fontSize: getInlineStylesValue(styles, Constants.StylePrefix.FONT_SIZE, 'number'),
  };
}
