import { ListItemNode, SerializedListItemNode } from '@lexical/list';
import {
  $applyNodeReplacement,
  EditorConfig,
  LexicalNode,
  LexicalUpdateJSON,
  NodeKey,
} from 'lexical';
import { toPx } from 'utils/toPx';

export type SerializedCustomListItemNode = SerializedListItemNode & { lineHeight: number | undefined; fontSize: number | undefined };

/**
* Custom List item node with dynamic line-height functionality
*/
export class CustomListItemNode extends ListItemNode {
  __lineHeight: number | undefined;

  __fontSize: number | undefined;

  constructor(value?: number, checked?: boolean, key?: NodeKey, fontSize?: number, lineHeight?: number) {
    super(value, checked, key);
    this.__lineHeight = lineHeight;
    this.__fontSize = fontSize;
  }

  static getType(): string {
    return 'custom-list-item';
  }

  static clone(node: CustomListItemNode): CustomListItemNode {
    return new CustomListItemNode(node.__value, node.__checked, node.__key, node.__fontSize, node.__lineHeight);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    if (this.__lineHeight) {
      element.style.lineHeight = this.__lineHeight.toString();
    }

    if (this.__fontSize) {
      element.style.fontSize = toPx(this.__fontSize) ?? '';
    }

    return element;
  }

  updateDOM(prevNode: CustomListItemNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);

    const prevLineHeight = prevNode.__lineHeight;
    const nextLineHeight = this.__lineHeight;
    const prevSize = prevNode.__fontSize;
    const nextSize = this.__fontSize;

    if (prevLineHeight !== nextLineHeight) {
      dom.style.lineHeight = nextLineHeight ? nextLineHeight.toString() : '';
    }

    if (prevSize !== nextSize) {
      dom.style.fontSize = nextSize ? toPx(nextSize) ?? '' : '';
    }

    return isUpdated;
  }

  updateFromJSON(
    serializedNode: LexicalUpdateJSON<SerializedCustomListItemNode>,
  ): this {
    return this
      .setFontSize(serializedNode.fontSize)
      .setLineHeight(serializedNode.lineHeight);
  }

  static importJSON(
    serializedNode: SerializedCustomListItemNode,
  ): CustomListItemNode {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
    return $createCustomListItemNode().updateFromJSON(serializedNode);
  }

  exportJSON(): SerializedCustomListItemNode {
    const json = super.exportJSON();

    return {
      ...json,
      lineHeight: this.__lineHeight ?? undefined,
      fontSize: this.__fontSize ?? undefined,
    };
  }

  setLineHeight(lineHeight: number | undefined): this {
    this.getWritable().__lineHeight = lineHeight;

    return this;
  }

  getLineHeight(): number | undefined {
    return this.getLatest().__lineHeight;
  }

  setFontSize(fontSize: number | undefined): this {
    this.getWritable().__fontSize = fontSize;

    return this;
  }

  getFontSize(): number | undefined {
    return this.getLatest().__fontSize;
  }
}

export function $isCustomListItemNode(
  node: LexicalNode | null | undefined,
): node is CustomListItemNode {
  return node instanceof CustomListItemNode;
}

export function $createCustomListItemNode(fontSize?: number, lineHeight?: number, checked?: boolean): CustomListItemNode {
  return $applyNodeReplacement(new CustomListItemNode(undefined, checked, undefined, fontSize, lineHeight));
}
