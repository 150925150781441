import { $isLinkNode, LinkNode } from '@lexical/link';
import { $isTextNode } from 'lexical';
import { SegmentType } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { ConvertEditorStateOptions } from 'services/ArtboardConverter/utils/convertEditorState';
import { CustomListItemNode } from '../nodes/CustomListItemNode';
import { CustomParagraphNode } from '../nodes/CustomParagraphNode';
import { $isNoWrapApplied } from '../Plugins/BasePropsPlugin/utils';
import { CreatePartParentStyles, createPart, createSegmentForText, createSegmentForLink } from './factories';

function createPartsForLink(
  node: LinkNode,
  brandProps: ConvertEditorStateOptions['brandProps'],
  parentStyles: CreatePartParentStyles,
): Schemas.Part[] {
  return node.getChildren().map((child) => {
    if (!$isTextNode(child)) {
      throw new Error('Wrong nested child');
    }

    return createPart(child, parentStyles, brandProps);
  });
}

const EMPTY_SEGMENT: Schemas.Segment = {
  parts: [],
  type: SegmentType.TEXT,
};

type Context = {
  brandProps: ConvertEditorStateOptions['brandProps'];
  surfaces: ConvertEditorStateOptions['surfaces'];
};

export function createSegments(
  node: CustomParagraphNode | CustomListItemNode,
  context: Context,
  parentStyles: CreatePartParentStyles,
): Schemas.Segment[] {
  const { brandProps, surfaces } = context;

  const segments: Schemas.Segment[] = [];
  let lastSegment: Schemas.Segment | undefined = undefined;

  node.getChildren().forEach((child) => {

    if ($isTextNode(child)) {
      const hasNoWrap = $isNoWrapApplied(child);
      const type = hasNoWrap ? SegmentType.NO_WRAP : SegmentType.TEXT;
      if (lastSegment?.type !== type) {
        lastSegment = createSegmentForText(hasNoWrap, []);
        segments.push(lastSegment);
      }
      lastSegment.parts.push(createPart(child, parentStyles, brandProps));

      return;
    }

    if ($isLinkNode(child)) {
      const url = child.getURL();
      if (lastSegment?.type !== SegmentType.LINK || (lastSegment as Schemas.LinkSegment).href !== url) {
        lastSegment = createSegmentForLink(url, [], surfaces);
        segments.push(lastSegment);
      }
      lastSegment.parts.push(...createPartsForLink(child, brandProps, parentStyles));

      return;
    }

    // IN-PROGRESS: could we have other nodes?
    throw new Error(`Not implemented for ${node.getType()}`);
  });

  // empty segment should be added to have the same output as Draftjs
  if (!segments.length) {
    segments.push(EMPTY_SEGMENT);
  }

  return segments;
}
