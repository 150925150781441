import { RawDraftContentBlock } from 'draft-js';
import { SerializedLexicalNode } from 'lexical/LexicalNode';
import { CustomParagraphNode, SerializedCustomParagraphNode } from 'modules/Lexical/nodes/CustomParagraphNode';
import { getFormatTypeForBlockType } from 'modules/Lexical/Plugins/TextBlockPlugin/utils/horizontal-alignment';
import { TextHorizontalAlignmentType } from 'const';
import { RawDraftContentBlockData } from '../types';

export function createParagraphNode(
  contentBlock: RawDraftContentBlock,
  children: SerializedLexicalNode[],
): SerializedCustomParagraphNode {
  const { depth, data, type } = contentBlock;
  const { lineHeight } = data as RawDraftContentBlockData;

  return {
    children,
    direction: 'ltr',
    type: CustomParagraphNode.getType(),
    format: getFormatTypeForBlockType(type as TextHorizontalAlignmentType) ?? 'left',
    version: 1,
    textFormat: 0,
    indent: depth,
    lineHeight,
    textStyle: '',
  };
}
