import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { SerializedEditorState, SerializedLexicalNode } from 'lexical';
import { useEffect, useMemo } from 'react';

const EMPTY_EDITOR_STATE: SerializedEditorState<SerializedLexicalNode> = {
  root: {
    children: [{ type: 'paragraph', version: 1 }],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
};

type Props = {
  editorState: string | null;
};

export const EditorStateUpdatePlugin: React.FunctionComponent<Props> = (props) => {
  const { editorState } = props;
  const [editor] = useLexicalComposerContext();

  const editorIsMountedRef = useMemo(() => ({
    current: false,
  }), [editor]);

  useEffect(() => {
    if (!editorIsMountedRef.current) {
      return;
    }

    //https://ah-mahir.medium.com/understanding-reacts-flushsync-warning-and-how-to-handle-it-with-settimeout-9442f6dd4ed9
    setTimeout(() => {
      editor.setEditorState(editor.parseEditorState(editorState ?? EMPTY_EDITOR_STATE));
    });
  }, [editor, editorState]);

  useEffect(() => {
    editorIsMountedRef.current = true;
  }, [editor]);

  return null;
};
