import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setEditorMode, EditorMode } from './reducer';
import { selectEditorMode, isLexicalMode, isDraftjsMode, isBothMode } from './selectors';

type EditorToggleHook = {
  editorMode: EditorMode;
  isLexicalMode: boolean;
  isDraftjsMode: boolean;
  isBothMode: boolean;
  setMode: (newMode: EditorMode) => void;
};

const editorModeSelector = createStructuredSelector({
  editorMode: selectEditorMode,
  isLexicalMode,
  isDraftjsMode,
  isBothMode,
});

export const useEditorToggle = (): EditorToggleHook => {
  const dispatch = useDispatch();
  const state = useSelector(editorModeSelector);

  return {
    ...state,
    setMode: (newMode: EditorMode) => dispatch(setEditorMode(newMode)),
  };
};
