import { $isListItemNode } from '@lexical/list';
import { $getSelection, $isRangeSelection, BaseSelection } from 'lexical';
import { findBrandColor } from 'modules/BrandDefinition';
import { $isCustomListNode, CustomListNode } from 'modules/Lexical/nodes/CustomListNode';
import * as Models from 'models';

export const $getSelectionForListItem = (): BaseSelection | undefined => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  return $isListItemNode(selection.getNodes()[0].getParent()) ? selection : undefined;
};

export const $getBulletColorForFirstList = (colors: Models.BrandColorsList): Models.BrandColorMap | undefined => {
  const selection = $getSelection();

  if (!selection) {
    return undefined;
  }

  const parents = selection.getNodes()[0]?.getParents();
  const customListNode = parents?.find(item => $isCustomListNode(item)) as unknown as CustomListNode | undefined;
  const nodeColor = customListNode?.getColor();

  return findBrandColor(colors, nodeColor);
};

export const $getTopLevelCustomListFromSelection = (): CustomListNode | undefined => {
  const selection = $getSelection();
  if (selection || $isRangeSelection(selection)) {
    const parent = selection.getNodes()[0].getTopLevelElement();
    if ($isCustomListNode(parent)) {
      return parent;
    }
  }

  return undefined;
};
