import * as Models from 'models';
import { SegmentType } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { getLinks } from 'services/ArtboardConverter/utils/getLinks';
import { removeScriptTag } from 'utils/removeScriptTag';

export function createSegmentForText(
  isNoWrapApplied: boolean,
  parts: Schemas.Part[],
): Schemas.Segment {
  return {
    type: isNoWrapApplied ? SegmentType.NO_WRAP : SegmentType.TEXT,
    parts,
  };
}

export function createSegmentForLink(
  url: string,
  parts: Schemas.Part[],
  surfaces: Models.ScreensOrderedMap,
): Schemas.Segment {
  const links = getLinks(url, surfaces);
  if (links.href) {
    links.href = removeScriptTag(links.href);
  }

  return {
    type: SegmentType.LINK,
    parts,
    ...links,
  };
}
