import { getLexicalStateFromTextComponent } from 'modules/Lexical/editors';
import { removeSSIAnchor } from 'modules/Lexical/Plugins/SSIAnchorPlugin/utils/removeSSIAnchor';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import {
  flatColorsByRelationId as flatColorsByRelationIdSelector,
  flatFontsByRelationId as flatFontsByRelationIdSelector,
} from 'containers/Common/selectors';
import { isLexicalMode } from 'containers/EditorToggle/selectors';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { convertTextComponentToRawEditorState, getSerializedRawContent, setBlockAnchorValue } from 'utils/editor';
import { setDocument } from '../actions';
import { Action } from '../models';
import { documents as documentsSelector } from '../selectors';

export function* removeAnchor(action: Action.IRemoveAnchor): SagaIterator {
  try {
    const { documentId, anchorBlockKey, relationId } = action.payload;
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
    const document = documents.get(documentId) as Models.TextComponentMap;

    const lexicalMode = yield select(isLexicalMode);

    if (lexicalMode) {
      const colorsByRelationId: ReturnTypeSaga<typeof flatColorsByRelationIdSelector> = yield select(flatColorsByRelationIdSelector);
      const fontsByRelationId: ReturnTypeSaga<typeof flatFontsByRelationIdSelector> = yield select(flatFontsByRelationIdSelector);
      const fonts = fontsByRelationId.get(relationId);
      const colors = colorsByRelationId.get(relationId);

      const lexicalStateRaw = getLexicalStateFromTextComponent(document, colors, fonts);

      const newLexicalState = removeSSIAnchor(lexicalStateRaw, anchorBlockKey);
      yield put(setDocument(document.set('lexicalState', newLexicalState)));
    } else {
      const editorState = convertTextComponentToRawEditorState(document);
      const newEditorState = setBlockAnchorValue(editorState, anchorBlockKey, false);
      const rawContent = getSerializedRawContent(newEditorState);

      yield put(setDocument(document.set('rawContent', rawContent)));
    }

  } catch (error) {
    yield call(handleSagaError, error, 'Documents.removeAnchor', 'RemoveAnchor');
  }
}
