import { getReferenceNumberOrder } from 'modules/References/utils/getReferenceNumberOrder';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPrioritizedRelation } from 'components/ArtboardAssets/Text/utils/relation';
import * as Constants from 'const';
import { referenceCitationsOrderByDocuments } from 'containers/Documents/selectors';
import * as Models from 'models';

export function useReferencesMap(
  document: Models.TextComponentMap | undefined,
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>,
  activeLayer: Constants.Layer,
): Record<string, number> {
  const referencesOrder = useSelector(referenceCitationsOrderByDocuments);

  return useMemo(() => {
    const idList: string[] = document?.get('referenceCitations')?.toJS() ?? [];

    const numbersList: number[] = getReferenceNumberOrder(
      getPrioritizedRelation({ relation, activeLayer }),
      referencesOrder,
    ).toJS();

    return idList.reduce(
      (map, id, idx) => ({ ...map, [id]: numbersList[idx] }),
      {} as Record<string, number>,
    );
  }, [document, relation, activeLayer, referencesOrder]);
}
