import _ from 'lodash';

import { toPx } from 'utils/toPx';

type GetImageSizeArg<T extends boolean> = {
  cellInnerHeight?: number;
  cellInnerWidth: number;
  docImageHeight: number;
  docImageWidth: number;
  scale: number;
  isOnResize?: boolean;
  toPx?: T;
};

export type GetImageSizeResult<T> = T extends false ? { width: number; height: number } : { width: string; height: string };

// tobe simplified
export function getImageSize<T extends boolean = false>({
  cellInnerHeight,
  cellInnerWidth,
  docImageHeight,
  docImageWidth,
  isOnResize,
  scale,
  toPx: convertToPx = false as T,
}: GetImageSizeArg<T>): GetImageSizeResult<T> {
  // Calculations below implies that docImageHeight and docImageWidth are not undefined.
  // Temporary added this warning to be able to catch all calls with invalid params.
  if (!docImageHeight || !docImageWidth) {
    // eslint-disable-next-line no-console
    console.warn('recalculateImageSize: Image height and width should be numeric and gte 0', docImageHeight, docImageWidth);
  }

  let currentImageWidth = _.max([_.round(cellInnerWidth * scale), 1]) as number;
  let currentImageHeight = _.max([_.round(currentImageWidth * docImageHeight / docImageWidth), 1]) as number;

  if (cellInnerHeight && currentImageHeight > cellInnerHeight && !isOnResize) {
    currentImageHeight = cellInnerHeight;
    currentImageWidth = currentImageHeight * docImageWidth / docImageHeight;
  }

  if (cellInnerHeight && currentImageHeight < cellInnerHeight && !isOnResize) {
    currentImageWidth = currentImageHeight * docImageWidth / docImageHeight;
  }

  return {
    width: convertToPx ? toPx(currentImageWidth) : currentImageWidth,
    height: convertToPx ? toPx(currentImageHeight) : currentImageHeight,
  } as GetImageSizeResult<T>;
}
