import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IMap } from 'typings/DeepIMap';
import * as Constants from 'const';
import { BrandProps } from 'hooks/useBrandProps';
import * as Models from 'models';
import { getChangedStylesToBeKept } from 'utils/styles/getStylesToBeKept';
import { getPrioritizedRelation } from '../utils/relation';
import { Styles, StylesSetters, getInitialStyles, stylesFromSource } from '../utils/styles';

const emptyRelationStyles = new Map();

type HookProps = {
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  activeLayer: Constants.Layer;
  isAutoFitContent: boolean;
  toggleAutoFitContent: () => void;
};

function getSource(props: Omit<HookProps, 'isAutoFitContent' | 'toggleAutoFitContent'>): IMap<Models.TextRelationStyles> {
  return props.relation.getIn(['styles', props.activeLayer]) || emptyRelationStyles;
}

type StylesHook = {
  styles: Styles;
  stylesSetters: StylesSetters;
  ensureStylesToBeKept: () => void;
  applyBrandStyle: (
    brandStyle: Models.BrandStyleMap,
    brandStyleValues: Models.TextBrandStyles,
    brandStyleChanged: boolean,
  ) => void;
  toggleAutoFitContentWithBrandStyleChange: () => void;
};

export default function useStyles(
  props: HookProps,
  brandProps: BrandProps,
): StylesHook {
  const propsRef = useRef(props);
  propsRef.current = props;
  const prevPropsRef = useRef(props);
  const brandPropsRef = useRef(brandProps);
  brandPropsRef.current = brandProps;
  const source = getSource(props);
  const [styles, setStyles] = useState<Styles>(() => getInitialStyles(source, brandProps));

  const setters = useMemo((): StylesSetters => {
    return {
      backgroundColor: color => setStyles(prev => ({
        ...prev,
        backgroundColor: color,
        backgroundGradient: undefined,
        brandStyleChanged: true,
      })),
      backgroundColorOpacity: backgroundColorOpacity => setStyles(prev => ({
        ...prev,
        backgroundColorOpacity,
        brandStyleChanged: true,
      })),
      backgroundGradient: (gradient, backupColor) => setStyles(prev => ({
        ...prev,
        backgroundColor: backupColor,
        backgroundGradient: gradient,
        brandStyleChanged: true,
      })),
      backgroundImage: backgroundImage => setStyles(prev => ({ ...prev, backgroundImage })),
      border: border => setStyles(prev => ({ ...prev, border })),
      borderRadius: borderRadius => setStyles(prev => ({ ...prev, borderRadius })),
      brandStyle: (brandStyle, iterator?: number) => setStyles(prev => ({
        ...prev,
        brandStyle,
        brandStyleVersion: iterator ?? prev.brandStyleVersion + 1,
      })),
      brandStyleChanged: (value = true) => setStyles(prev => ({ ...prev, brandStyleChanged: value })),
      padding: padding => setStyles(prev => ({
        ...prev,
        padding,
        brandStyleChanged: true,
      })),
      verticalAlignment: verticalAlignment => setStyles(prev => ({
        ...prev,
        verticalAlignment,
        brandStyleChanged: true,
      })),
    };
  }, []);

  // TODO: it looks like overhead
  const ensureStylesToBeKept = useCallback((): void => {
    const { relation, activeLayer } = propsRef.current;
    const { fonts, colors, brandStyles } = brandPropsRef.current;

    const newStyles = stylesFromSource(
      relation.getIn(['styles', activeLayer]),
      brandStyles,
      colors,
    );

    const {
      backgroundColor,
      backgroundColorTint,
      backgroundColorOpacity,
      backgroundGradient,
      backgroundImage,
      border,
      padding,
    } = getChangedStylesToBeKept(
      getPrioritizedRelation({ relation, activeLayer }),
      brandStyles,
      colors,
      fonts,
    );

    if (backgroundGradient) {
      setters.backgroundGradient(newStyles?.backgroundGradient, newStyles?.backgroundColor);
    } else if (backgroundColor || backgroundColorTint) {
      setters.backgroundColor(newStyles?.backgroundColor);
    }

    if (backgroundColorOpacity) {
      setters.backgroundColorOpacity(backgroundColorOpacity);
    }

    if (backgroundImage) {
      setters.backgroundImage(newStyles?.backgroundImage);
    }

    if (border) {
      setters.border(newStyles?.border);
    }

    if (padding) {
      setters.padding(newStyles?.padding);
    }
  }, [setters]);

  const applyBrandStyle = useCallback((
    brandStyle: Models.BrandStyleMap,
    brandStyleValues: Models.TextBrandStyles,
    brandStyleChanged: boolean,
  ) => setStyles(prev => ({
    ...prev,
    padding: brandStyleValues.padding,
    verticalAlignment: brandStyleValues.verticalAlignment,
    backgroundColor: brandStyleValues.backgroundColor,
    backgroundGradient: undefined, // IN-PROGRESS: should be refactored
    brandStyle,
    brandStyleVersion: prev.brandStyleVersion + 1,
    brandStyleChanged,
  })), [setStyles]);

  const { isAutoFitContent, toggleAutoFitContent } = props;
  const toggleAutoFitContentWithBrandStyleChange = useCallback(() => {
    toggleAutoFitContent();
    if (isAutoFitContent) {
      setters.brandStyleChanged(true);
    }
  }, [isAutoFitContent, toggleAutoFitContent, setters]);

  useEffect(() => {
    // skip run on mount
    if (prevPropsRef.current === props) {
      return;
    }

    // we check that the styles is changed without autofit content as in some cases it causes unnecessary triggers of state sync
    const preparedCurrentStyles = source.has('isAutoFitContent') ? source.delete('isAutoFitContent') : source;
    const prevSource = getSource(prevPropsRef.current);
    const preparedPrevStyles = prevSource.has('isAutoFitContent') ? prevSource.delete('isAutoFitContent') : prevSource;
    // @ts-expect-error: immutable types confusion
    if (preparedCurrentStyles.equals(preparedPrevStyles)) {
      return;
    }

    const brandStyleChanged = !isAutoFitContent || source.get('brandStyleChanged');

    setStyles(prev => ({
      ...stylesFromSource(source, brandProps.brandStyles, brandProps.colors),
      brandStyle: brandProps.brandStyles.get(source.get('brandStyleId') || Constants.Styles.DefaultTextBrandStyle),
      brandStyleVersion: prev.brandStyleVersion + 1,
      brandStyleChanged,
    }));
  }, [source, props.relation.get('id'), props.activeLayer]);

  useEffect(() => {
    prevPropsRef.current = props;
  });

  return {
    styles,
    stylesSetters: setters,
    ensureStylesToBeKept,
    applyBrandStyle,
    toggleAutoFitContentWithBrandStyleChange,
  };
}
