import { List } from 'immutable';
import { LexicalEditor, createEditor } from 'lexical';
import * as Models from 'models';
import { isImmutable } from 'utils/immutable';
import { editorConfig } from '../components/LexicalTextEditor';
import { lexicalRawConverter } from '../LexicalRawConverter/lexicalRawConverter';

export function createLexicalTextEditor(lexicalStateRaw: string): LexicalEditor {
  const editor = createEditor(editorConfig);

  if (lexicalStateRaw) {
    editor.setEditorState(editor.parseEditorState(lexicalStateRaw));
  }

  return editor;
}

export function getLexicalStateFromTextComponent(
  textComponent: Models.TextComponentMap | Models.TextComponent,
  colors: Models.BrandColorsList = List(),
  fonts: Models.BrandFontsList = List(),
): string {
  const isImmutableMap = isImmutable(textComponent);

  const lexicalState = isImmutableMap ? textComponent.get('lexicalState') : textComponent.lexicalState;
  if (lexicalState) {
    return lexicalState;
  }

  const rawDraftContentState = JSON.parse(isImmutableMap ?
    textComponent.get('rawContent') as string :
    textComponent.rawContent as string);

  const { editorState } = lexicalRawConverter(rawDraftContentState, colors, fonts);

  return JSON.stringify(editorState);
}

