import _ from 'lodash';
import { getLexicalStateFromCTAComponent, getLexicalStateFromTextComponent } from 'modules/Lexical/editors';
import {
  convertLexicalCTAEditorStateToHtml,
  convertLexicalTextEditorStateToHtml,
} from 'modules/Lexical/Translation';
import { call, put, select, spawn } from 'redux-saga/effects';

import { Layer, MethodName, ProcessType } from 'const';
import * as AppActions from 'containers/App/actions';
import { relationsOnScreens } from 'containers/Documents/selectors';
import { isLexicalMode } from 'containers/EditorToggle/selectors';
import { setActiveLayer as createSetActiveLayerAction } from 'containers/Project/actions';
import { setActiveLayer } from 'containers/Project/sagas/setActiveLayer';
import { activeLayer } from 'containers/Project/selectors';
import { getDocumentsOnArtboards } from 'containers/Project/services/getDocumentsOnArtboards';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import * as Models from 'models';
import { getTranslationPackage } from 'services/api';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import { isCallToAction, isLayoutRendition, isTextComponent } from 'utils/entityType';
import { getTranslationPackageName } from 'utils/getPackageName';
import { getProjectPdfName } from 'utils/getProjectPdfName';
import { intlGet } from 'utils/intlGet';
import { convertRawContentToXliff } from '../services/convertRawContentToXliff';
import { downloadFileByUrl } from '../services/downloadFileByUrl';
import { generateProjectPdf } from '../services/generateProjectPdf';

export function* generateTranslationPackage(): Generator {
  const logId = logger.performanceStart();
  const methodName = MethodName.TRANSLATION_EXPORT;
  let previousActiveLayer: ReturnTypeSaga<typeof activeLayer> ;

  try {
    previousActiveLayer = yield select(activeLayer);
    const usedRelations: ReturnTypeSaga<typeof relationsOnScreens> = yield select(relationsOnScreens);
    const isLayoutRenditionOnArtboards = usedRelations.some(relation => isLayoutRendition(relation) && relation.get('documentId').some(Boolean));

    if (isLayoutRenditionOnArtboards) {
      yield spawn([logger, logger.performanceCancel], logId);

      yield call(
        [Notifications, Notifications.showNotification],
        {
          title: intlGet('Notification.Translation.ReusableLayoutPreview', 'Title'),
          message: intlGet('Notification.Translation.ReusableLayoutPreview', 'Message'),
        },
      );

      return;
    }

    yield put(AppActions.lockProjectContent(ProcessType.EXPORTING_FILES));

    // to fetch correct documents from RLs
    yield call(setActiveLayer, createSetActiveLayerAction(Layer.ORIGINAL));

    const lexicalMode = yield select(isLexicalMode);
    const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
    const rootDocumentName = rootDocument.get('name');
    const projectPdfName: ReturnTypeSaga<typeof getProjectPdfName> = yield call(getProjectPdfName, rootDocumentName);
    const documents: Models.CombinedDocuments = (yield call(getDocumentsOnArtboards, ProcessType.EXPORTING_FILES)).toJS();
    const archiveName: ReturnTypeSaga<typeof getTranslationPackageName> = yield call(
      getTranslationPackageName,
      rootDocumentName,
      rootDocument.getIn(['language', '0']),
    );

    const textComponentHtmlById = _(documents)
      .pickBy<Models.TextComponent>(isTextComponent)
      .mapValues((textComponent) => {
        if (lexicalMode) {
          return convertLexicalTextEditorStateToHtml(getLexicalStateFromTextComponent(textComponent));
        }

        return convertRawContentToXliff(textComponent.rawContent);
      })
      .value();

    const callToActionsInnerTextById = _(documents)
      .pickBy<Models.CallToAction>(isCallToAction)
      .mapValues((ctaComponent) => {
        if (lexicalMode) {
          return convertLexicalCTAEditorStateToHtml(getLexicalStateFromCTAComponent(ctaComponent)) ??
          ctaComponent.name;
        }

        return ctaComponent.rawContent ? convertRawContentToXliff(ctaComponent.rawContent) : ctaComponent.name;
      })
      .value();

    const projectPdfUrl: ReturnTypeSaga<typeof generateProjectPdf> = yield call(generateProjectPdf, projectPdfName);
    const { data: url }: ReturnTypeSaga<typeof getTranslationPackage> = yield call(
      getTranslationPackage,
      archiveName,
      rootDocument.toJS() as Models.RootDocument,
      documents,
      textComponentHtmlById,
      callToActionsInnerTextById,
      projectPdfUrl,
    );

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    yield call(downloadFileByUrl, url, archiveName);
    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'ExportTranslationPackage'),
    );
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'Artboards.generateTranslationPackage', 'GenerateTranslationPackage');
  } finally {
    yield put(AppActions.unlockProjectContent());
    // revert user active layer
    yield call(setActiveLayer, createSetActiveLayerAction(previousActiveLayer));
  }
}
