import { Map } from 'immutable';
import { $getRoot } from 'lexical';
import * as Models from 'models';
import { createLexicalTextEditor } from '../editors';

export function getCleanTextLexical(textComponent: Models.TextComponent | Models.TextComponentMap): string {
  let lexicalState: string | undefined;
  if (Map.isMap(textComponent)) {
    lexicalState = (textComponent as Models.TextComponentMap).get('lexicalState');
  } else {
    ({ lexicalState } = textComponent as Models.TextComponent);
  }

  if (!lexicalState) {
    return '';
  }

  const editor = createLexicalTextEditor(lexicalState);

  return editor.read(() => $getRoot().getTextContent());
}
