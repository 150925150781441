import { useCallback, useMemo } from 'react';
import { Styles } from '../utils/styles';
import useCell from './useCell';
import useStyles from './useStyles';

export type HistoryExtraState = Styles & {
  // cell
  cellHeight: number;
  cellWidth: number;
  // cell other
  isAutoFitContent: boolean;
};

type Props = {
  isAutoFitContent: boolean;
};

type Hook = {
  extraState: HistoryExtraState;
  extraStateSetter: (value: HistoryExtraState) => void;
};

export function useHistoryExtraState(
  props: Props,
  stylesHook: ReturnType<typeof useStyles>,
  cellHook: ReturnType<typeof useCell>,
): Hook {
  const { styles, stylesSetters } = stylesHook;
  const { props: { cellHeight, cellWidth }, toggleProps } = cellHook;
  const { isAutoFitContent } = props;

  const extraState = useMemo(() => {
    // should be deep cloned on push data to state
    return {
      ...styles,
      cellHeight,
      cellWidth,
      isAutoFitContent,
    };
  }, [styles, cellHeight, cellWidth, isAutoFitContent]);

  const extraStateSetter = useCallback((state: HistoryExtraState) => {
    // styles
    const {
      backgroundColor,
      backgroundColorOpacity,
      backgroundGradient,
      backgroundImage,
      border,
      borderRadius,
      brandStyle,
      brandStyleVersion,
      brandStyleChanged,
      padding,
      verticalAlignment,
    } = state;

    stylesSetters.backgroundColor(backgroundColor);
    stylesSetters.backgroundColorOpacity(backgroundColorOpacity);
    stylesSetters.backgroundGradient(backgroundGradient, backgroundColor);
    stylesSetters.backgroundImage(backgroundImage);
    stylesSetters.border(border);
    stylesSetters.borderRadius(borderRadius);
    stylesSetters.brandStyle(brandStyle, brandStyleVersion);
    stylesSetters.padding(padding);
    stylesSetters.verticalAlignment(verticalAlignment);
    stylesSetters.brandStyleChanged(brandStyleChanged); // should be last otherwise we have to remove setters for custom props

    // cell
    toggleProps(cellHeight, cellWidth, isAutoFitContent);

  }, [stylesSetters, toggleProps]);

  return { extraState, extraStateSetter };
}
