import { fromJS } from 'immutable';
import { CSSProperties } from 'react';
import { IList, IMap } from 'typings/DeepIMap';
import {
  AssetAlignmentMap,
  BackgroundImageMap,
  Border,
  BorderRadiusMap,
  BoxPropertyMap,
  BrandColor,
  BrandColorGradientMap,
  BrandColorMap,
  BrandColorsList,
  CombinedDocumentsMap,
  ImageRelationStyles,
} from 'models';
import { brandBorderFromSource, brandBorderToSource, colorFromSource, colorToSource, colorToSourceTint } from 'utils/converters';
import { brandColorGradientFromSource, brandColorGradientToSource } from 'utils/converters/colorGradient';
import { getExtraHeight } from 'utils/getExtraHeight';
import { getExtraWidth } from 'utils/getExtraWidth';
import { getAlignment, getBorderCSSProperties, getPadding, getBorderRadius } from 'utils/styles';
import { getCSSBackgroundBrand } from 'utils/styles/getBackground';

export type ImageStylesConfig = {
  allowBackgroundImage: boolean;
  allowBackgroundOpacity: boolean;
};

export type ImageStyles = {
  alignment: AssetAlignmentMap;
  altTag: string;
  backgroundColor?: BrandColorMap;
  backgroundColorOpacity: number;
  backgroundGradient?: BrandColorGradientMap;
  backgroundImage?: BackgroundImageMap;
  border?: IMap<Border<BrandColor>>;
  borderRadius?: BorderRadiusMap;
  extraHeight: number;
  extraWidth: number;
  height: number;
  link: string;
  mobileAlignment: AssetAlignmentMap;
  mobileScale: number;
  mobileSettingsApplied: boolean;
  mobileViewImage: BackgroundImageMap;
  padding: BoxPropertyMap;
  scale: number;
  width: number;
  abbreviations: IList<string>;
};

type SpecialSetters = {
  backgroundGradient: (gradient?: BrandColorGradientMap, backupColor?: BrandColorMap) => void;
  extraHeight: never;
  extraWidth: never;
};

export type ImageStylesSetters = {
  [K in keyof Omit<ImageStyles, keyof SpecialSetters>]-?: (value: ImageStyles[K]) => void;
} & {
  [K2 in keyof SpecialSetters as SpecialSetters[K2] extends never ? never : K2]: SpecialSetters[K2];
};

export function imageStylesFromSource(
  source: IMap<ImageRelationStyles>,
  colors: BrandColorsList,
  cellWidth?: number,
): ImageStyles {
  const padding = source.get('padding');
  const border = brandBorderFromSource(colors, source.get('border'));
  const extraWidth = getExtraWidth(fromJS({ padding, border }));
  const extraHeight = getExtraHeight(fromJS({ padding, border }));

  return {
    alignment: source.get('alignment'),
    altTag: source.get('altTag'),
    backgroundColor: colorFromSource(colors, source.get('backgroundColor'), source.get('backgroundColorTint')),
    backgroundColorOpacity: source.get('backgroundColorOpacity'),
    backgroundGradient: brandColorGradientFromSource(colors, source.get('backgroundGradient')),
    backgroundImage: source.get('backgroundImage'),
    border,
    borderRadius: source.get('borderRadius'),
    extraHeight,
    extraWidth,
    height: source.get('height'),
    link: source.get('link'),
    mobileAlignment: source.get('mobileAlignment'),
    mobileScale: source.get('mobileScale'),
    mobileSettingsApplied: source.get('mobileSettingsApplied'),
    mobileViewImage: source.get('mobileViewImage'),
    padding,
    scale: source.get('scale'),
    width: cellWidth ? (cellWidth - extraWidth) : source.get('width'), // TODO: negative value?
    abbreviations: source.get('abbreviations'),
  };
}

export function imageStylesToSource(
  styles: ImageStyles,
  source: IMap<ImageRelationStyles>,
): IMap<ImageRelationStyles> {
  let updatedSource = source.withMutations(values => values
    .set('alignment', styles.alignment)
    .set('altTag', styles.altTag)
    .set('backgroundColor', colorToSource(styles.backgroundColor))
    .set('backgroundColorTint', colorToSourceTint(styles.backgroundColor))
    .set('backgroundColorOpacity', styles.backgroundColorOpacity)
    .set('backgroundImage', styles.backgroundImage)
    .set('backgroundGradient', brandColorGradientToSource(styles.backgroundGradient))
    .set('border', brandBorderToSource(styles.border))
    .set('link', styles.link)
    .set('mobileAlignment', styles.mobileAlignment)
    .set('mobileScale', styles.mobileScale)
    .set('mobileSettingsApplied', styles.mobileSettingsApplied)
    .set('mobileViewImage', styles.mobileViewImage)
    .set('padding', styles.padding)
    .set('scale', styles.scale)
    .set('borderRadius', styles.borderRadius)
    .set('abbreviations', styles.abbreviations),
  );

  if (styles.height && styles.height > 1) {
    updatedSource = updatedSource.withMutations(values => values.set('height', styles.height));
  }

  if (styles.width && styles.width > 1) {
    updatedSource = updatedSource.withMutations(values => values.set('width', styles.width));
  }

  return updatedSource;
}

type CSSContext = {
  source?: string;
  images: CombinedDocumentsMap;
};

export function imageStylesToCSS(
  styles: ImageStyles,
  context: CSSContext,
  config: ImageStylesConfig,
): CSSProperties {
  const { source, images } = context;
  const { allowBackgroundImage, allowBackgroundOpacity } = config;

  const props: CSSProperties = {
    ...(source ? getAlignment(styles.alignment) : {}),
    ...getBorderCSSProperties(styles.border),
    ...getPadding(styles.padding),
    ...getCSSBackgroundBrand(
      styles.backgroundColor,
      allowBackgroundOpacity ? styles.backgroundColorOpacity : undefined,
      styles.backgroundGradient,
      allowBackgroundImage ? styles.backgroundImage : undefined,
      images,
    ),
    ...getBorderRadius(styles.borderRadius),
  };

  return props;
}
