import { List } from 'immutable';
import { $getRoot, $isElementNode, ElementFormatType, ElementNode, LexicalNode, RootNode } from 'lexical';
import { BrandColorRef, tryToGetBrandColorRefByHEX, verifyBrandColorRefValueHEX } from 'modules/BrandDefinition';
import { TextHorizontalAlignmentType } from 'const';
import * as Models from 'models';
import { getTextStylesFromBrandStyle } from 'utils/brandStyles';
import { $createCustomListItemNode, $isCustomListItemNode } from '../../nodes/CustomListItemNode';
import { $createCustomListNode, $isCustomListNode, CustomListNode } from '../../nodes/CustomListNode';
import { $createCustomParagraphNode, $isCustomParagraphNode } from '../../nodes/CustomParagraphNode';

export function $verifyStyleForList(
  colors: Models.BrandColorsList,
): void {
  $getRoot().getChildren()
    .filter(node => $isCustomListNode(node))
    .forEach((node: CustomListNode) => {
      const nodeColor = node.getColor();
      if (nodeColor) {
        node.setColor(verifyBrandColorRefValueHEX(colors, nodeColor));
      }
    });
}

const isTextOrListNode = (node: LexicalNode): boolean => $isCustomListNode(node) || $isCustomParagraphNode(node) || $isCustomListItemNode(node);

const updateNodeStyles = (node: LexicalNode, color: BrandColorRef | undefined, size: number, lineHeight: number): void => {
  if ($isCustomListNode(node)) {
    node.setColor(color);
  } else if ($isCustomListItemNode(node)) {
    node.setFontSize(size);
    node.setLineHeight(lineHeight);
  }
};

const rebuildHierarchy = (
  originRoot: RootNode,
  leaves: LexicalNode[][],
  shouldBeConvertedToList: boolean,
  color: BrandColorRef | undefined,
  size: number,
  lineHeight: number,
  textAlign: TextHorizontalAlignmentType,
): void => {
  originRoot.clear();
  let root: ElementNode = originRoot;
  if (shouldBeConvertedToList) {
    root = $createCustomListNode('bullet', 0, color);
    originRoot.append(root);
  }

  const reversed = [...leaves].reverse();
  for (const children of reversed) {
    let parent: ElementNode;

    if (shouldBeConvertedToList) {
      parent = $createCustomListItemNode(size, lineHeight);
    } else {
      parent = $createCustomParagraphNode();
      parent.setFormat(textAlign as ElementFormatType);
    }

    parent.append(...children);
    root.append(parent);
  }
};

export const $applyBrandStyleForList = (
  brandStyle: Models.BrandStyleMap | undefined,
  colors: Models.BrandColorsList,
): void => {
  const styles = getTextStylesFromBrandStyle(brandStyle, colors, List([]));
  // Bullet color is defined either in the brand definition or in the style list file.
  // If this value includes a reference, it will be replaced by the corresponding hex value on the back-end side
  const bulletColorHex = styles[Models.TextBrandStyleField.BULLET_COLOR];
  const textAlign = styles[Models.TextBrandStyleField.TEXT_ALIGN];
  const size = styles[Models.TextBrandStyleField.FONT_SIZE];
  const lineHeight = styles[Models.TextBrandStyleField.LINE_HEIGHT];
  const shouldBeConvertedToList = textAlign === TextHorizontalAlignmentType.UNORDERED_LIST;
  const color = tryToGetBrandColorRefByHEX(colors, bulletColorHex);
  const originRoot = $getRoot();
  const stack: LexicalNode[] = [originRoot];
  const leaves: LexicalNode[][] = [];

  // Already a list, therefore there is no need to rebuild hierarchy. Update node styles ONLY in this case.
  const updateOnly = shouldBeConvertedToList && originRoot.getChildren().every($isCustomListNode);

  while (stack.length > 0) {
    const node = stack.pop();
    if (!$isElementNode(node)) {
      continue;
    }

    if (updateOnly) {
      updateNodeStyles(node, color, size, lineHeight);
    }

    const nodeChildren: LexicalNode[] = node.getChildren() || [];
    if (nodeChildren.every(isTextOrListNode)) {
      stack.push(...nodeChildren);
    } else {
      leaves.push(nodeChildren);
    }
  }

  if (updateOnly) {
    return;
  }

  rebuildHierarchy(originRoot, leaves, shouldBeConvertedToList, color, size, lineHeight, textAlign);
};
