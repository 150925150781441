import { CallToActionEditor } from 'modules/draftjs';
import React from 'react';
import { useDraftjsCTAEditorContext } from '../context/DraftjsCTAEditor';

const DraftjsButton = (): JSX.Element => {
  const {
    editorRef,
    id,
    editMode,
    editorWrap,
    editorState,
    onEditorChange,
    returnFocusToEditor,
    styles,
    cssButton,
    brandProps,
    undoHook,
  } = useDraftjsCTAEditorContext();

  return (
    <CallToActionEditor
      ref={editorRef}
      id={id}
      className={cssButton}
      styles={styles}
      wrapperRef={editorWrap}
      editorState={editorState}
      onEditorChange={onEditorChange}
      returnFocusToEditor={returnFocusToEditor}
      editMode={editMode}
      fillUndoStackIfEmpty={undoHook.fillUndoStackIfEmpty}
      undoStackMiddleware={undoHook.undoStackMiddleware}
      brandProps={brandProps}
    />);

};

export default DraftjsButton;
