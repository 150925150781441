import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLocked } from 'containers/App/selectors';
import * as Models from 'models';
import { ContentHeightCache } from 'services/contentHeightCache';
import { CellProps, CellState, cellIsLast } from '../utils/cell';
import { getContentMinHeight } from '../utils/getContentMinHeight';
import { Styles } from '../utils/styles';
import { TextEditorHook } from './TextEditorHook';

type HookProps = Omit<CellProps, keyof CellState | 'isIncreasingWidthDisabled' | 'isLastCell'> & {
  isIncreasingWidthDisabled?: boolean;
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  editMode: boolean;
  toggleColumnWidth: (newWidth: number) => number;
  toggleRowHeight: (newHeight: number) => number;
  toggleRowAndNeighborsHeight: (newHeight: number, relations?: Models.LayeredRelationsMap) => void;
  isAutoFitContent: boolean;
  toggleAutoFitContent: () => void;
};

function readCellProps(props: HookProps, cellState: CellState): CellProps {
  return {
    cellHeightChanged: cellState.cellHeightChanged,
    cellWidthChanged: cellState.cellWidthChanged,
    cellHeight: props.cellHeight,
    cellWidth: props.cellWidth,
    cellPosition: props.cellPosition,
    cellsCount: props.cellsCount,
    disableCellWidthEditing: props.disableCellWidthEditing,
    maxCellHeight: props.maxCellHeight,
    minCellHeight: props.minCellHeight,
    isResizingRow: props.isResizingRow,
    isIncreasingWidthDisabled: Boolean(props.isIncreasingWidthDisabled),
    isLastCell: cellIsLast(props),
  };
}

type CellHook = {
  props: CellProps;
  toggleCellWidth: (newWidth: number) => number;
  toggleCellHeight: (newHeight: number) => number;
  toggleProps: (cellHeight: number, cellWidth: number, isAutoFitContent: boolean) => void;
  resetHeight: (relations?: Models.LayeredRelationMap) => void;
  resetCellWidhtAndHeightChange: () => void;
};

export default function useCell(
  props: HookProps,
  wrapperRef: MutableRefObject<HTMLDivElement>,
  editorHook: TextEditorHook,
  styles: Styles,
): CellHook {

  const [state, setState] = useState<CellState>({
    cellHeightChanged: false,
    cellWidthChanged: false,
  });
  const isFirstRendering = useRef(true);
  const isProjectLocked = useSelector(isLocked);
  const propsRef = useRef(props);
  const prevHeight = useRef(props.cellHeight);
  propsRef.current = props;

  const stylesRef = useRef(styles);
  stylesRef.current = styles;

  const { hasTextContent, hasCustomToken } = editorHook;

  const resetCellWidhtAndHeightChange = useCallback(() => setState({
    cellHeightChanged: false,
    cellWidthChanged: false,
  }), []);

  const {
    toggleColumnWidth,
    toggleRowHeight,
    toggleAutoFitContent,
    toggleRowAndNeighborsHeight,
  } = props;

  const width = useCallback((newWidth: number): number => {
    setState(prev => ({ ...prev, cellWidthChanged: true }));

    return toggleColumnWidth(newWidth);
  }, [toggleColumnWidth]);

  const height = useCallback((newHeight: number): number => {
    setState(prev => ({ ...prev, cellHeightChanged: true }));

    return toggleRowHeight(newHeight);
  }, [toggleRowHeight]);

  // IN-PROGRESS: use 3 functions insteas of one
  const toggleProps = useCallback((cellHeight, cellWidth, isAutoFitContent): void => {
    if (cellHeight !== propsRef.current.cellHeight) {
      // TODO: aproach differs from other props
      height(cellHeight);
    }
    if (cellWidth !== propsRef.current.cellWidth) {
      toggleColumnWidth(cellWidth);
    }
    if (isAutoFitContent !== propsRef.current.isAutoFitContent) {
      toggleAutoFitContent();
    }
  }, [height, toggleColumnWidth, toggleAutoFitContent]);

  /**
   * recompute height of cells
   */
  const resetHeight = useCallback((relations?: Models.LayeredRelationsMap): void => {
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return;
    }

    const newCellHeight = getContentMinHeight(stylesRef.current, wrapper.clientHeight);

    const contentHeights = ContentHeightCache.getInstance();
    contentHeights.setItem(propsRef.current.relation.get('id'), newCellHeight);

    const { isAutoFitContent, cellHeight } = propsRef.current;

    if (!cellHeight || (!hasTextContent && !propsRef.current.editMode)) {
      return;
    }

    /*
        TODO: revise hasCustomToken condition.
        Check the issue DCC-7138 (ER is incorrect).
        Original ticket is DCC-6167
    */
    const shouldUpdateCellHeight = (isAutoFitContent || hasCustomToken)
      && !propsRef.current.isResizingRow
      ? newCellHeight !== cellHeight
      : newCellHeight > cellHeight;

    if (shouldUpdateCellHeight) {
      toggleRowAndNeighborsHeight(newCellHeight, relations);
    }
  }, [toggleRowAndNeighborsHeight, hasTextContent, hasCustomToken]);

  const { isAutoFitContent } = props;
  useEffect(() => {
    // will reset cell height when autofit has been changed to true
    if (isAutoFitContent) {
      resetHeight();
    }
  }, [isAutoFitContent]);
  useEffect(() => {
    // fix for RL layouts that have their height updated before ready for review upload (locked state)
    if (isProjectLocked
      && !isFirstRendering.current
      && !propsRef.current.isResizingRow
      && prevHeight.current !== props.cellHeight
    ) {
      resetHeight();
    } else {
      isFirstRendering.current = false;
    }

    prevHeight.current = props.cellHeight;
  }, [props.cellHeight, isProjectLocked]);

  return {
    props: readCellProps(props, state),
    toggleCellWidth: width,
    toggleCellHeight: height,
    toggleProps,
    resetHeight,
    resetCellWidhtAndHeightChange,
  };
}
