import React from 'react';
import Anchor from 'containers/Anchor';

type Props = {
  documentId: string;
  editor: HTMLDivElement;
  appliedAnchorKeys: string[];
  relationId: string;
};

const AnchorsList: React.FunctionComponent<Props> = (props) => {
  const { editor, appliedAnchorKeys, documentId, relationId } = props;
  const blocks: HTMLElement[] = Array.from(editor.querySelectorAll('[data-block=true]'));

  return (
    <>
      {blocks.map((block) => {
        const { offsetTop } = block;
        const key = block.getAttribute('data-offset-key');
        const anchorBlockKey = appliedAnchorKeys.find(appliedKey => key?.startsWith(appliedKey));

        return (
          <Anchor
            key={key}
            anchorBlockKey={anchorBlockKey}
            documentId={documentId}
            offsetTop={offsetTop}
            relationId={relationId}
          />
        );
      })}
    </>
  );
};

export default AnchorsList;
