import { cloneDeep, isEqual } from 'lodash';
import { undoRedoKeyBindings } from 'modules/draftjs';
import useDraftjsTextEditor from 'modules/draftjs/hooks/useDraftjsTextEditor';
import { useHandleKeyCommand } from 'modules/draftjs/hooks/useHandleKeyCommand';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as Models from 'models';
import { customCallback } from 'models/UndoRedo';
import { eventEmitter, EMITTER_EVENTS } from 'utils/eventEmitter';
import { getLastFromStack, pushStateToStack, removeLastFromStack } from '../../utils/stack';
import { HistoryExtraState } from './useHistoryExtraState';

type TextStateStack = HistoryExtraState & {
  editorState: Draft.EditorState;
  operations: Models.DraftEditorOperations;
  activeFontFamily: Models.FontFamily;
};

enum UndoInputType {
  HISTORY_UNDO = 'historyUndo',
  HISTORY_REDO = 'historyRedo',
}

// [DCC-7844]: fixed bugs with numeric imput and undo/redo
const undoStackCancelInput = {
  type: '',
};

export function cancelUndoStack(event: InputEvent): void {
  if (event.inputType === UndoInputType.HISTORY_UNDO
    || event.inputType === UndoInputType.HISTORY_REDO) {
    undoStackCancelInput.type = event.inputType;
  } else {
    undoStackCancelInput.type = '';
  }
}

export type UndoMiddleware = <T extends customCallback>(
  callback: T,
  prop?: keyof TextStateStack
) => (...args: Parameters<T>) => ReturnType<T> | null;

type State = {
  undoStack: TextStateStack[];
  redoStack: TextStateStack[];
};

type HookProps = {
  editMode: boolean;
};

type UndoHook = {
  isRedoDisabled: boolean;
  isUndoDisabled: boolean;
  undo: () => boolean;
  redo: () => boolean;
  undoStackMiddleware: UndoMiddleware;
  updateUndoRedoBeforeChange: () => void;
  fillUndoStackIfEmpty: () => void;
};

export default function useDraftjsTextUndo(
  props: HookProps,
  editorHook: ReturnType<typeof useDraftjsTextEditor>,
  extraState: HistoryExtraState,
  extraStateSetter: (state: HistoryExtraState) => void,
): UndoHook {
  const { editMode } = props;
  const [state, setState] = useState<State>({ undoStack: [], redoStack: [] });
  const stateRef = useRef(state);
  stateRef.current = state;

  // deep clone will be applied on push data to state
  const currentStackData: TextStateStack = {
    // editor
    editorState: editorHook.editorState,
    operations: editorHook.operations,
    activeFontFamily: editorHook.activeFontFamily,
    // extraState
    ...extraState,
  };
  const currentStackRef = useRef(currentStackData);
  currentStackRef.current = currentStackData;

  const { onEditorChange, setOperations, setActiveFontFamily } = editorHook;

  const applyStack = useCallback((stack: TextStateStack) => {
    const { editorState, operations, activeFontFamily } = stack;
    onEditorChange(editorState);
    setOperations(operations);
    setActiveFontFamily(activeFontFamily);

    extraStateSetter(stack);
  }, [
    onEditorChange,
    setOperations,
    setActiveFontFamily,
    extraStateSetter,
  ]);

  const pushCurrentStateToStack = useCallback(
    (stack: TextStateStack[]): TextStateStack[] => pushStateToStack(stack, cloneDeep(currentStackRef.current)),
    [],
  );

  const undo = useCallback((): boolean => {
    const { undoStack: currentUndoStack, redoStack: currentRedoStack } = stateRef.current;
    if (!currentUndoStack.length) {
      return false;
    }
    applyStack(getLastFromStack(currentUndoStack));
    setState({
      undoStack: removeLastFromStack(currentUndoStack),
      redoStack: pushCurrentStateToStack(currentRedoStack),
    });

    return true;
  }, [applyStack]);

  const redo = useCallback((): boolean => {
    const { undoStack: currentUndoStack, redoStack: currentRedoStack } = stateRef.current;
    if (!currentRedoStack.length) {
      return false;
    }
    applyStack(getLastFromStack(currentRedoStack));
    setState({
      undoStack: pushCurrentStateToStack(currentUndoStack),
      redoStack: removeLastFromStack(currentRedoStack),
    });

    return true;
  }, [applyStack]);

  const updateUndoRedoBeforeChange = useCallback((): void => {
    const { undoStack: stack } = stateRef.current;
    setState({
      undoStack: pushCurrentStateToStack(stack),
      redoStack: [],
    });
  }, [setState]);

  const undoStackMiddleware: UndoMiddleware = useCallback((callback, prop?) => ((...args: Parameters<typeof callback>) => {
    if (undoStackCancelInput.type) {
      // if we are here that means that user pressed ctrl+z/y
      const result = callback(...args);
      if (undoStackCancelInput.type === UndoInputType.HISTORY_UNDO) {
        undo();
      } else if (undoStackCancelInput.type === UndoInputType.HISTORY_REDO) {
        redo();
      }
      undoStackCancelInput.type = '';

      return result as ReturnType<typeof callback>;
    }
    if (!prop || !isEqual(args[0], currentStackRef.current)) {
      updateUndoRedoBeforeChange();

      return callback(...args) as ReturnType<typeof callback>;
    }

    return null;
  }), [undo, redo, updateUndoRedoBeforeChange]);

  const fillUndoStackIfEmpty = useCallback(() => {
    if (!stateRef.current.undoStack.length) {
      updateUndoRedoBeforeChange();
    }
  }, [updateUndoRedoBeforeChange]);

  const handleKeyCommand = useHandleKeyCommand(fillUndoStackIfEmpty, undo, redo);
  const undoRedoGlobalHandler = useCallback((e: KeyboardEvent): void => {
    handleKeyCommand(undoRedoKeyBindings(e));
  }, [handleKeyCommand]);

  const isMountedRef = useRef(false);

  useEffect(() => {
    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
    };
  }, [undoRedoGlobalHandler]);

  const { undoStack, redoStack } = state;

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    if (editMode) {
      eventEmitter.addListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
    } else {
      eventEmitter.removeListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
      setState({ undoStack: [], redoStack: [] });
    }
  }, [editMode]);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  return {
    isRedoDisabled: !redoStack.length,
    isUndoDisabled: !undoStack.length,
    undo,
    redo,
    undoStackMiddleware,
    updateUndoRedoBeforeChange,
    fillUndoStackIfEmpty,
  };
}
