import { RawDraftContentBlock } from 'draft-js';
import { SerializedCustomListItemNode } from 'modules/Lexical/nodes/CustomListItemNode';
import { SerializedCustomListNode, CustomListNode } from 'modules/Lexical/nodes/CustomListNode';
import * as Constants from 'const';
import * as inlineStylesUtils from 'utils/inlineStyles';

export function createListNode(
  contentBlock: RawDraftContentBlock,
  children: SerializedCustomListItemNode[],
): SerializedCustomListNode {
  const { inlineStyleRanges } = contentBlock;
  const styles = inlineStyleRanges.map(range => range.style);

  // bullet color
  const HEX = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.BULLET_COLOR);
  const name = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.BULLET_COLOR_NAME);
  const tint = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.BULLET_COLOR_TINT);

  return {
    direction: 'ltr',
    type: CustomListNode.getType(),
    indent: 1,
    version: 1,
    start: 1,
    format: '',
    listType: 'bullet',
    tag: 'ul',
    children,
    color: HEX ? { name, HEX, tint: tint ? parseInt(tint, 10) : undefined } : undefined,
  };
}
