import { $getRoot } from 'lexical';
import * as Constants from 'const';
import * as DocumentsModels from 'containers/Documents/models';
import * as Models from 'models';
import { Schemas } from 'services/ArtboardConverter/models';
import * as editorUtils from 'utils/editor';
import { createLexicalTextEditor, getLexicalStateFromTextComponent } from '../editors';
import { $applyBrandStyleForList } from '../Plugins/CustomListPlugin/brandStyle';
import { $applyBrandStyleForFont } from '../Plugins/FontBrandStylePlugin/brandStyle';
import { $applyBrandStyleForTextBlock } from '../Plugins/TextBlockPlugin/utils/brandStyle';
import { convertLexicalEditorState } from './convertLexicalEditorState';

type Context = {
  projectType: Constants.ProjectType;
  relationColors: Models.BrandColorsList;
  relationFonts: Models.BrandFontsList;
  relationBrandStyle: Models.BrandStyleMap | undefined;
  defaultBrandStyle: Models.BrandStyleMap | undefined;
  citationsOrderByDocuments: DocumentsModels.ReferenceCitationsOrderByDocumentsMap; // IN-PROGRESS
  surfaces: Models.ScreensOrderedMap;
};

export function createTextCellSchema(
  relation: Models.RegularRelationMap<Models.TextRelationStyles>,
  document: Models.TextComponentMap,
  context: Context,
): Schemas.ReferenceText & { isCellAutoHeight: boolean } {
  const {
    projectType,
    relationColors, relationFonts, relationBrandStyle, defaultBrandStyle,
    surfaces,
  } = context;

  const lexicalState = getLexicalStateFromTextComponent(document, relationColors, relationFonts);
  const editor = createLexicalTextEditor(lexicalState);
  const isCellAutoHeight = editor.read(() => editorUtils.hasToken($getRoot().getTextContent()));

  // IN-PROGRESS reference citation numbers should be applied

  const brandStyleChanged = relation.getIn(['styles', 'brandStyleChanged']);
  const brandStyleId = relation.getIn(['styles', 'brandStyleId']);
  if (!brandStyleChanged && brandStyleId) {
    const brandStyleToApply = !relationBrandStyle ? defaultBrandStyle : relationBrandStyle;
    editor.update(() => {
      $applyBrandStyleForTextBlock(brandStyleToApply);
      $applyBrandStyleForFont(brandStyleToApply, relationColors, relationFonts);
      $applyBrandStyleForList(brandStyleToApply, relationColors);
    }, { discrete: true });
  }

  // IN-PROGRESS the following logic is not clear
  // if (Constants.ProjectsConfig[projectType].applyFontStyleForBrandStyles) {
  //   editorState = editorUtils.applyFontStylesForBrandStyles(editorState, relationFonts);
  // }
  // editorState = editorUtils.applyFontStyles(editorState, relationFonts, projectType);

  const converterOptions = {
    brandProps: { brandStyle: relationBrandStyle, colors: relationColors, fonts: relationFonts },
    defaultLineHeight: Constants.TextLineHeightValue.ONE_POINT_FIVE,
    projectType,
    surfaces,
  };
  const schema = convertLexicalEditorState(editor.getEditorState(), converterOptions);

  return { ...schema, isCellAutoHeight };
}
