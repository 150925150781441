import Draft from 'draft-js';
import { DraftEntity } from 'const';

export function hasAbbreviations(rawContent: string): boolean {
  const editorState: Draft.RawDraftContentState = JSON.parse(rawContent);

  return Object.values(
    editorState.entityMap ?? {},
  ).some(({ type }) => type === DraftEntity.ABBREVIATION);
}
