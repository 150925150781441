import * as Constants from 'const';
import * as Models from 'models';
import { getFontFaceStringWrapper } from 'utils/brandStyles';

// IN-PROGRESS let's use BrandFontRef to indicate font from BrandFonts collection
// src\models\FontFamily.ts can be replaced by this
export type BrandFontRef = {
  fontName: string;
  characterStyleName: string | undefined;
};

export function convertBrandFontRefToFontFamily(
  brandFontRef: BrandFontRef,
): Models.FontFamily {
  const { fontName: fontFamily, characterStyleName } = brandFontRef;

  return { fontFamily, characterStyleName };
}

/**
 * Returns transformed fontFamily if the variation of the font is condensed, ultra condensed or extended
 * which is needed by browser to correctly select font
 * @param fontFamily - fontFamily value of the current font to be transformed
 * @param fontFileName - fontFileName value of the current font to be analyzed
 * @param fontName - name value of the current font to be used to make transformed fontFamily
 * @param isFontAliasInSystemFont - isFontAliasInSystemFont value of the current font to be used to make decision to transform fontFamily
 */
function transformFontFamily(
  fontFamily: string,
  fontFileName?: string,
  fontName?: string,
  isFontAliasInSystemFont?: boolean,
): string {
  if (isFontAliasInSystemFont) {
    return fontName ? `${fontFamily}-${fontName}` : fontFamily;
  }

  const stringToCheckForSpecialStyles = fontFileName || fontFamily; // NOSONAR

  for (const value of Object.values(Constants.FontFamily)) {
    if (stringToCheckForSpecialStyles.toLowerCase().includes(value.toLowerCase().replace(/\s/g, ''))) {
      return `${fontFamily} ${value}`;
    }
  }

  return fontFamily;
}

// IN-PROGRESS let's replace it with getBrandFontValueFontFamily
export function getCSSFontFamilyFromBrandFont(
  brandFontName: string | undefined,
  characterStyleName: string | undefined,
  brandFonts: Models.BrandFontsList,
  isExplicitFontFamilyTransformationNeeded = false,
): string {

  let fontFamily = Constants.DefaultCustomStyle.FONT_FAMILY as string;
  let fallbackFonts = '';

  const brandFont = brandFontName && brandFonts
    ? brandFonts.find(font => font?.get('name') === brandFontName)
    : undefined;

  if (brandFont) {
    fontFamily = brandFont.get('fontFamily');
    fallbackFonts = brandFont.get('fallbackFonts') ?? '';

    if (characterStyleName) {
      const characterStyle = brandFont.get('characterStyles')?.find(charStyle => charStyle?.get('name') === characterStyleName);
      const fontFileName = characterStyle?.get('fontFileName');

      if (fontFileName) {
        const isCharacterStyleOfSystemFont = Boolean(brandFont.get('fontColor'));
        const isFontAliasInSystemFont = isCharacterStyleOfSystemFont && Boolean(characterStyle?.get('if'));
        fontFamily = transformFontFamily(
          fontFamily,
          fontFileName,
          brandFont?.get('name'),
          isFontAliasInSystemFont,
        );
      } // we have to do this explicit font family transformation when we need it but we do not have a character style yet
    }
  }

  if (!characterStyleName && isExplicitFontFamilyTransformationNeeded) {
    fontFamily = transformFontFamily(fontFamily);
  }

  return [
    getFontFaceStringWrapper(fontFamily),
    fallbackFonts,
  ].filter(Boolean).join(', ');
}

export function getBrandFontValueFontFamily(
  fonts: Models.BrandFontsList,
  fontRef: BrandFontRef | undefined,
  isExplicitFontFamilyTransformationNeeded = false,
): string {
  const { fontName, characterStyleName } = fontRef || {};

  return getCSSFontFamilyFromBrandFont(
    fontName,
    characterStyleName,
    fonts,
    isExplicitFontFamilyTransformationNeeded,
  );
}

export function getBrandFontValueFontWeight(
  fonts: Models.BrandFontsList,
  fontRef: BrandFontRef | undefined,
): string | undefined {
  if (!fontRef) {
    return undefined;
  }
  const { fontName, characterStyleName } = fontRef;

  const brandFont = fonts.find(font => font?.get('name') === fontName);
  if (!brandFont) {
    return undefined;
  }

  const characterStyles = brandFont.get('characterStyles');
  if (characterStyleName && characterStyles) {
    const brandCharacterStyle = characterStyles.find(characterStyle => characterStyle?.get('name') === characterStyleName);

    return brandCharacterStyle?.get('fontWeight');
  }

  return brandFont.get('fontWeight');
}

export function getBrandFontValueFontStyle(
  fonts: Models.BrandFontsList,
  fontRef: BrandFontRef | undefined,
): string | undefined {
  if (!fontRef) {
    return undefined;
  }
  const { fontName, characterStyleName } = fontRef;

  const brandFont = fonts.find(font => font?.get('name') === fontName);
  if (!brandFont) {
    return undefined;
  }

  const characterStyles = brandFont.get('characterStyles');
  if (characterStyleName && characterStyles) {
    const brandCharacterStyle = characterStyles.find(characterStyle => characterStyle?.get('name') === characterStyleName);

    return brandCharacterStyle?.get('fontStyle');
  }

  return brandFont.get('fontStyle');
}

// IN-PROGRESS should we combine getBrandFontValueFontFamily, getBrandFontValueFontWeight, and getBrandFontValueFontStyle into single function
