import * as Models from 'models';
import { getColorFromBrandStyle } from './getColorFromBrandStyle';

export function getBackgroundColorFromBrandStyle(colors: Models.BrandColorsList, styles: Models.BrandStyleObjMap): Models.BrandColorMap {
  return colors.find(color => color.get('HEX') === getColorFromBrandStyle(styles.get(Models.BrandStyleProp.BACKGROUND_COLOR), colors));
}

export function getBulletColorFromBrandStyle(colors: Models.BrandColorsList, styles: Models.BrandStyleObjMap): string {
  return getColorFromBrandStyle('', colors, styles, null, true);
}

export function getDefaultColorFromBrandStyle(
  brandColors: Models.BrandColorsList,
  brandStyle: Models.BrandStyleObjMap | undefined,
): string | null {
  return getColorFromBrandStyle('', brandColors, brandStyle);
}

export function getFontColorFromBrandStyle(
  color: string,
  brandColors: Models.BrandColorsList,
  brandStyle: Models.BrandStyleObjMap,
  brandFonts: Models.BrandFontsList,
): string {
  return getColorFromBrandStyle(color, brandColors, brandStyle, brandFonts);
}
