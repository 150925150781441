import Draft from 'draft-js';
import * as draftjsUtils from 'modules/draftjs/utils';
import { EditorChangeType } from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { moveSelectionForward } from 'utils/editor/selection';

export function emulatePushOperation(editorState: Draft.EditorState, operation: Models.DraftEditorOperation): Draft.EditorState {
  const { blockList, type, selectionBefore, selectionAfter, text, reference } = operation;
  let contentState = editorState.getCurrentContent();

  switch (type) {
    case EditorChangeType.ADD_REFERENCE: {
      const blockKey = selectionBefore.getFocusKey();
      const offset = selectionBefore.getFocusOffset();
      contentState = draftjsUtils.createReferenceEntity(contentState, selectionBefore, blockKey, offset, reference);
      break;
    }
    case EditorChangeType.INSERT_CHARACTERS: {
      // get and apply styles of previous character
      const offset = selectionBefore.getFocusOffset();
      const block = contentState.getBlockForKey(selectionBefore.getFocusKey());
      const style = block.getInlineStyleAt(offset - 1);
      contentState = Draft.Modifier.replaceText(contentState, selectionBefore, '*', style);
      break;
    }
    case EditorChangeType.BACKSPACE_CHARACTER: {
      const selection = moveSelectionForward(selectionAfter, contentState, 1);
      contentState = Draft.Modifier.removeRange(contentState, selection, 'backward');
      break;
    }
    case EditorChangeType.DELETE_CHARACTER: {
      const selection = moveSelectionForward(selectionBefore, contentState, 1);
      contentState = Draft.Modifier.removeRange(contentState, selection, 'forward');
      break;
    }
    case EditorChangeType.REMOVE_RANGE: {
      contentState = Draft.Modifier.removeRange(contentState, selectionBefore, 'backward');
      break;
    }
    case EditorChangeType.INSERT_FRAGMENT: {
      const content = editorUtils.insertText(
        contentState,
        selectionBefore,
        text,
        {
          inlineStyles: editorState.getCurrentInlineStyle(),
          blockAlignment: editorUtils.getBlockType(editorState),
          blockLineHeight: editorUtils.getBlockLineHeight(editorState),
        },
      );

      const blocks = content.getBlocksAsArray().map((block, idx) => block.set('key', blockList[idx]) as Draft.ContentBlock);
      contentState = Draft.ContentState.createFromBlockArray(blocks);
      break;
    }
    case EditorChangeType.SPLIT_BLOCK: {
      const blockKey = selectionAfter.getEndKey();
      contentState = Draft.Modifier.splitBlock(contentState, selectionBefore);
      // since we use the standard library method, the keys of the blocks, that will be created during the split, will be different
      // therefore, replace the newly created block key with the desired one
      const focusKey = contentState.getSelectionAfter().getAnchorKey();
      const blocks = contentState.getBlocksAsArray().map((block) => {
        if (block.getKey() !== focusKey) {
          return block;
        }

        return block.set('key', blockKey);
      }) as Draft.ContentBlock[];
      contentState = Draft.ContentState.createFromBlockArray(blocks);
      break;
    }
    default: {
      // HACK: We don't save and don't emulate all possible operations, at the same time we process each undo/redo operation
      // as result for operations that won't be emulated we have to recreate a brand new content state but without real changes
      // just to allow DraftJS fill undo stack properly
      const blocks = contentState.getBlocksAsArray();
      contentState = Draft.ContentState.createFromBlockArray(blocks);
    }
  }

  // returns a new EditorState object with the specified ContentState applied as the new currentContent.
  // based on the changeType, this ContentState may be regarded as a boundary state for undo/redo behavior.
  return Draft.EditorState.push(editorState, contentState, type as Draft.EditorChangeType);
}
