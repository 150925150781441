import * as BrandDefinition from 'modules/BrandDefinition';
import { CSSProperties, MutableRefObject, useEffect, useMemo, useState } from 'react';
import * as Constants from 'const';
import {
  LayoutStylesState,
  LayoutStylesStateSetters,
  layoutStylesStateFromSource,
  layoutStylesStateSetters,
  layoutStylesStateToCSS,
} from 'hooks/layout/useLayoutStyles';
import * as Models from 'models';
import * as stylesUtils from 'utils/styles';
import { toPx } from 'utils/toPx';
import { AbbreviationsListRelationStylesMap } from '../../../types';

export type StylesState = LayoutStylesState & {
  fontSize?: number;
};

export type StylesStateSetters = LayoutStylesStateSetters & {
  fontSize: (value: number) => void;
};

export function stylesStateToRelationSource(
  styles: StylesState,
  source: AbbreviationsListRelationStylesMap,
): AbbreviationsListRelationStylesMap {
  return source.withMutations(
    values => values.set('fontSize', styles.fontSize),
  );
}

function stylesStateFromSource(
  layoutSource: Models.LayoutStylesMap,
  relationSource: AbbreviationsListRelationStylesMap,
  colors: Models.BrandColorsList | undefined,
): StylesState {
  return {
    ... layoutStylesStateFromSource(layoutSource, colors),
    fontSize: relationSource.get('fontSize'),
  };
}

type Context = {
  colors: Models.BrandColorsList | undefined;
  fonts: Models.BrandFontsList | undefined;
  images: Models.ImagesMap;
  containerRef: MutableRefObject<HTMLDivElement | undefined>;
};

type Config = {
  allowBackgroundOpacity: boolean;
};

export function useStyles(
  layoutSource: Models.LayoutStylesMap,
  relationSource: AbbreviationsListRelationStylesMap,
  context: Context,
  config: Config,
): [StylesState, StylesStateSetters, CSSProperties] {
  const { colors, fonts, images, containerRef } = context;

  const [styles, setStyles] = useState<StylesState>(
    () => stylesStateFromSource(layoutSource, relationSource, colors),
  );

  useEffect(() => {
    setStyles(stylesStateFromSource(layoutSource, relationSource, colors));
  }, [layoutSource, relationSource, colors]);

  const setters = useMemo<StylesStateSetters>(
    () => ({
      ...layoutStylesStateSetters(setStyles, containerRef),
      fontSize: fontSize => setStyles(state => ({
        ...state,
        fontSize,
      })),
      scrollable: scrollable => setStyles((state) => {
        const { height, ...prev } = state;

        return {
          ...prev,
          height: scrollable
            ? containerRef.current?.scrollHeight ?? state.minHeight
            : undefined,
          scrollable,
        };
      }),
    }),
    [],
  );

  const css = useMemo<CSSProperties>(
    () => ({
      ...layoutStylesStateToCSS(styles, images, config),
      ...stylesUtils.getFontSize(styles.fontSize),
      height: styles.scrollable && styles.height ? toPx(styles.height) : undefined,
      color: relationSource.get('fontColor'),
      ...(
        fonts
          ? stylesUtils.getFontFamily(BrandDefinition.getCSSFontFamilyFromBrandFont(
            relationSource.get('fontFamily'),
            relationSource.get('fontStyle'),
            fonts,
          ))
          : {}
      ),
      lineHeight: relationSource.get('lineHeight'),
      textAlign: relationSource.getIn(['alignment', Constants.AssetAlignmentDirection.HORIZONTAL]) as CSSProperties['textAlign'],
      verticalAlign:  relationSource.getIn(['alignment', Constants.AssetAlignmentDirection.VERTICAL]),
    }),
    [styles, relationSource, images, fonts, config],
  );

  return [styles, setters, css];
}
