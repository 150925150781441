import { createSelector } from 'reselect';
import { StateMap } from 'models/state';
import { EditorMode } from './reducer';

export const selectEditorMode = createSelector(
  (state: StateMap): EditorMode => state.get('editorMode'),
  mode => mode,
);

export const isLexicalMode = createSelector(
  selectEditorMode,
  mode => mode === EditorMode.LEXICAL,
);

export const isDraftjsMode = createSelector(
  selectEditorMode,
  mode => mode === EditorMode.DRAFTJS,
);

export const isBothMode = createSelector(
  selectEditorMode,
  mode => mode === EditorMode.BOTH,
);
