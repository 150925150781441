import { Modifier } from 'draft-js';
import { DraftEntity, DraftEntityMutability, REFERENCES_MARKER, ScriptType } from 'const';
import { ReferenceCitationMap } from 'models';

// TODO: provide updated selection instead of merging selection with block key and offset
export function createReferenceEntity(
  contentState: Draft.ContentState,
  selection: Draft.SelectionState,
  blockKey: string,
  offset: number,
  reference: ReferenceCitationMap,
): Draft.ContentState {
  const updatedSelection = selection.merge({
    focusOffset: offset,
    anchorOffset: offset,
    focusKey: blockKey,
    anchorKey: blockKey,
  }) as Draft.SelectionState;

  let contentStateWithEntity = contentState.createEntity(
    DraftEntity.REFERENCE,
    DraftEntityMutability.IMMUTABLE,
    { id: reference.get('id') },
  );

  const block = contentStateWithEntity.getBlockForKey(blockKey);
  // get styles of previous character or first character and add superscript style
  const styles = block.getInlineStyleAt(offset - 1).add(ScriptType.SUPERSCRIPT);

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  contentStateWithEntity = Modifier.insertText(
    contentStateWithEntity,
    updatedSelection,
    REFERENCES_MARKER,
    styles,
    entityKey,
  );

  return contentStateWithEntity;
}
