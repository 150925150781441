import Immutable from 'immutable';
import _ from 'lodash';
import { useCallback } from 'react';
import * as Models from 'models';
import { getExtraHeight } from 'utils/getExtraHeight';
import { StylesHook } from './useStyles';

export type ResetHeightHeightStyles = (paddingValue: Models.PaddingMap, borderValue?: Models.BorderMap, cb?: (maxHeight: number) => void) => void;

export const useResetHeightWithStyles = (stylesHook: StylesHook): ResetHeightHeightStyles => {
  const { styles, setters: stylesSetters } = stylesHook;

  const getMaxHeight = (currentPudding = styles.padding, currentBorder = styles.border): number => {
    const extraHeight = getExtraHeight(Immutable.fromJS({ border: currentBorder, padding: currentPudding }));

    return Math.max(styles.height - extraHeight, 0);
  };
  const height = styles.height;

  return useCallback((
    paddingValue: Models.PaddingMap,
    borderValue?: Models.BorderMap,
    cb?: (maxHeight: number) => void,
  ): void => {
    const maxHeight = getMaxHeight(paddingValue, borderValue);
    if (height > maxHeight) {
      if (_.isFunction(cb)) {
        cb(maxHeight);
      } else {
        stylesSetters.height(maxHeight);
      }
    }
  }, [stylesSetters, styles.height]);
};
