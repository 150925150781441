import { $generateHtmlFromNodes } from '@lexical/html';
import { html } from 'js-beautify';
import { EditorConfig, createEditor } from 'lexical';
import { editorConfig as callToActionEditorConfig } from 'modules/Lexical/components/LexicalCallToActionEditor';
import { editorConfig as textEditorConfig } from 'modules/Lexical/components/LexicalTextEditor';
import { EXPORT_TRANSLATION_PACKAGE_NODES } from '../nodes';

const convertLexicalStateToHtml = (serializedState: string | undefined, editorConfig: EditorConfig): string => {
  if (serializedState) {
    const editor = createEditor(editorConfig);
    const editorState = editor.parseEditorState(serializedState);
    editor.setEditorState(editorState);

    return editor.read(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);

      return html(htmlString, { indent_size: 2 });
    });
  }

  return '';
};

export const convertLexicalTextEditorStateToHtml = (serializedState: string | undefined): string => {
  const textEditorConfigForExport = { ...textEditorConfig, nodes: [...textEditorConfig.nodes, ...EXPORT_TRANSLATION_PACKAGE_NODES] };

  return convertLexicalStateToHtml(serializedState, textEditorConfigForExport);
};

export const convertLexicalCTAEditorStateToHtml = (serializedState: string | undefined): string => {
  const callToActionEditorConfigForExport = {
    ...callToActionEditorConfig,
    nodes: [...callToActionEditorConfig.nodes, ...EXPORT_TRANSLATION_PACKAGE_NODES],
  };

  return convertLexicalStateToHtml(serializedState, callToActionEditorConfigForExport);
};
