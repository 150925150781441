import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import {
  $isRangeSelection,
  $getSelection,
  COMMAND_PRIORITY_EDITOR,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  createCommand,
} from 'lexical';
import * as Constants from 'const';
import { RESET_NO_WRAP_STYLES_OBJECT, NO_WRAP_STYLES_OBJECT, NO_WRAP_VALUE, WHITE_SPACE_PROP } from './style';
import { $clearNodesFormatting } from './utils';

export const BASE_PROPS_COMMAND = {
  SCRIPT_STYLE: createCommand<Constants.ScriptType>(),
  TEXT_NOWRAP: createCommand(),
  CLEAR_FORMATTING: createCommand(),
} as const;

export function registerEditorPropsCommands(editor: LexicalEditor): () => void {
  return mergeRegister(

    editor.registerCommand(
      BASE_PROPS_COMMAND.SCRIPT_STYLE,
      (value) => {
        switch (value) {
          case Constants.ScriptType.SUPERSCRIPT:
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript');
            break;
          case Constants.ScriptType.SUBSCRIPT:
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');
            break;
          default:
            // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
            console.error(`unknown script type ${value || '-'}`);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      BASE_PROPS_COMMAND.TEXT_NOWRAP,
      () => {
        const selection = $getSelection();
        if (selection && $isRangeSelection(selection)) {
          const isNoWrapActive = $getSelectionStyleValueForProperty(selection, WHITE_SPACE_PROP) === NO_WRAP_VALUE;

          $patchStyleText(selection, isNoWrapActive ? RESET_NO_WRAP_STYLES_OBJECT : NO_WRAP_STYLES_OBJECT);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      BASE_PROPS_COMMAND.CLEAR_FORMATTING,
      () => {
        $clearNodesFormatting();

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),
  );
}
