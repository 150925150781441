import Draft from 'draft-js';
import _ from 'lodash';
import { TextComponentMap } from 'models';

export function replaceReferenceCitationIdOnComponent(
  textComponent: TextComponentMap,
  referenceId: string,
  newReferenceCitationId: string,
): TextComponentMap {
  const rawContent = textComponent.get('rawContent');
  if (!rawContent) {
    return textComponent;
  }

  const editorState: Draft.RawDraftContentState = JSON.parse(rawContent);
  const { entityMap } = editorState;
  _.forEach(entityMap, (entity) => {
    if (entity.data.id === referenceId) {
      entity.data = {
        id: newReferenceCitationId,
      };
    }
  });

  return textComponent.set('rawContent', JSON.stringify(editorState));
}
