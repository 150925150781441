import store from 'store/store';
import { StateMap } from 'models/state';
import { EditorMode, setEditorMode } from './reducer';

declare global {
  interface Window {
    GET_EDITOR_MODE: () => EditorMode;
    SET_EDITOR_MODE: (mode: string) => void;
  }
}

window.GET_EDITOR_MODE = (): EditorMode => {
  return (store.getState() as StateMap).get('editorMode');
};

window.SET_EDITOR_MODE = (mode: string): void => {
  if (!(Object.values(EditorMode) as string[]).includes(mode)) {
    // eslint-disable-next-line no-console
    console.error('wrong mode');

    return;
  }
  store.dispatch(setEditorMode(mode as EditorMode));
};
