import * as Constants from 'const';
import * as Models from 'models';
import { DefaultFontFamily } from 'services/ArtboardConverter/constants';
import { getDesktopBrandStyles, getFontFaceFromBrandStyle } from 'utils/brandStyles';
import { getFontColorFromBrandStyle } from 'utils/brandStyles/getColorTypeFromBrandStyle';
import { toPx } from 'utils/toPx';

export type ExportDefaultStyles = {
  fontSize?: string;
  color: string;
  fontFamily: string;
};

export function getInlineStylesForExport(
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): ExportDefaultStyles {
  let fontSize = toPx(Constants.DefaultCustomStyle.FONT_SIZE);
  let color: string = Constants.DefaultCustomStyle.FONT_COLOR;
  let fontFamily: string = DefaultFontFamily;

  if (brandStyle) {
    const desktopStyles = getDesktopBrandStyles(brandStyle);

    if (desktopStyles) {
      fontSize = toPx(desktopStyles.get(Models.BrandStyleProp.FONT_SIZE));
      color = getFontColorFromBrandStyle(desktopStyles.get(Models.BrandStyleProp.COLOR), colors, desktopStyles, fonts);
      fontFamily = getFontFaceFromBrandStyle(desktopStyles, fonts);
    }
  }

  return {
    fontSize,
    color,
    fontFamily,
  };
}
