import { EditorState, convertToRaw } from 'draft-js';
import _ from 'lodash';

import { Character, Layer } from 'const';
import { LayeredRegularRelations, LayeredRelations, TextComponents, TextRelationStyles } from 'models';
import * as editorUtils from 'utils/editor';
import { mergeObjectsDeep } from 'utils/mergeObjectsDeep';

const hasLineFeedCharacter = (editorState: EditorState): boolean => {
  const blockMap = editorState.getCurrentContent().getBlockMap();

  return blockMap.some(block => Boolean(block?.getText().includes(Character.LINE_FEED)));
};

const updateStyles = (
  styles: TextRelationStyles,
  initialEditorState: EditorState,
  targetEditorState: EditorState,
): TextRelationStyles => {
  const editorStateStyles = _.flow(
    editorState => editorUtils.applyEditorStateStyles(editorState, styles),
    editorState => editorUtils.replaceLineBreaks(editorState),
    // on this step, block keys are different between editorState and targetEditorState
    editorState => editorUtils.replaceBlocksKeys(editorState, targetEditorState),
    editorUtils.isolateEditorStateStyles,
  )(initialEditorState);

  return mergeObjectsDeep({}, styles, editorStateStyles);
};

export const validateTextComponents = (
  textComponents: TextComponents,
  layeredRelations: LayeredRegularRelations<TextRelationStyles>,
): { components: TextComponents; relations: LayeredRelations<TextRelationStyles> } => {
  return _.reduce(
    textComponents,
    (acc, textComponent, componentId) => {
      const componentRelations = _.pickBy(layeredRelations, relation => _.includes(relation.documentId, componentId));
      Object.assign(acc.relations, componentRelations);
      Object.assign(acc.components, textComponents);

      if (!textComponent) {
        return acc;
      }

      const editorState = editorUtils.convertTextComponentToRawEditorState(textComponent);

      if (!hasLineFeedCharacter(editorState)) {
        return acc;
      }

      const targetEditorState = editorUtils.replaceLineBreaks(editorState);
      const contentState = targetEditorState.getCurrentContent();
      const rawContent = JSON.stringify(convertToRaw(contentState));

      const component = Object.assign({}, textComponent, { rawContent });
      _.set(acc, ['components', componentId], component);

      _.forEach(componentRelations, (relation, relationId) => {
        const { styles: layeredStyles, documentId: layeredDocumentId } = relation;

        _.forEach(layeredStyles, (styles, layer: Layer) => {
          const documentId = layeredDocumentId[layer];

          if (documentId !== componentId) {
            return;
          }

          const updatedStyles = updateStyles(styles, editorState, targetEditorState);
          _.set(acc, ['relations', relationId, 'styles', layer], updatedStyles);
        });
      });

      return acc;
    },
    {
      components: {} as TextComponents,
      relations: {} as LayeredRelations<TextRelationStyles>,
    },
  );
};

