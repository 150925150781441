import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios';
import pako from 'pako';
import guid from 'uuid';

import { AxiosMethod, ResponseStatus } from 'const';
import { isMethod } from 'utils/isMethod';
import { uploadFile } from './api';

const promiseMethods = [AxiosMethod.POST, AxiosMethod.GET, AxiosMethod.PUT];
// for the methods getUri, request, post, get, put, delete, etc. the config parameter is the last one
const configParameterIndexes: Record<AxiosMethod, number> = {
  [AxiosMethod.GET]: 1,
  [AxiosMethod.POST]: 2,
  [AxiosMethod.PUT]: 2,
};
const bodyParameterIndexes: Record<AxiosMethod, number | null> = {
  [AxiosMethod.GET]: null,
  [AxiosMethod.POST]: 1,
  [AxiosMethod.PUT]: 1,
};

const delay = ms => new Promise(res => setTimeout(res, ms));

// this WS API used due to API Gateway integration timeout 29 sec (backend operations are could be too long)
// https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html
// backend lambda functions executes twice recursively
// on the first run lambda close API Gateway and invokes itself the second time
// on the second run lambda makes a call to REST API and sends the response back via WS
export const axiosWS = new Proxy(axios, {
  get(target, propKey, receiver) {
    if (!isMethod(target, propKey)) {
      return Reflect.get(target, propKey, receiver);
    }

    return new Proxy(target[propKey], {
      async apply(applyTarget: AxiosStatic[typeof propKey], thisArg: typeof target, args: Parameters<typeof applyTarget>) {
        if (!promiseMethods.includes(propKey as AxiosMethod)) {
          return Reflect.apply(applyTarget, thisArg, args);
        }

        // HACK: Do not use proxy functionality if backend url does not belong to AWS APIGateway
        // TODO: Remove it when we get rid of EC2 backend instances
        if (!(args[0] as string).includes('.com')) {
          return Reflect.apply(applyTarget, thisArg, args);
        }

        // upload request body to S3 due to invocation payload lambda limit 6mb
        // https://docs.aws.amazon.com/lambda/latest/dg/gettingstarted-limits.html
        let bodyS3Key = '';

        const bodyArgIndex = bodyParameterIndexes[propKey as AxiosMethod];
        if (bodyArgIndex !== null) {
          const body = args[bodyArgIndex];
          if (body) {
            const bodyFile = new File(
              [pako.gzip(JSON.stringify(body))],
              `${guid()}.gzip`,
              { type: 'application/json' },
            );

            bodyS3Key = await uploadFile(bodyFile, 'application/json', {
              'Content-Encoding': 'gzip',
            });

            args[bodyArgIndex] = {};
          }
        }

        const parameterIndex = configParameterIndexes[propKey as AxiosMethod];
        const config: AxiosRequestConfig = args[parameterIndex] = args[parameterIndex] || {};

        config.headers = {
          ...(config.headers || {}),
          ...(bodyS3Key && { 'merck-assembler-body-key': bodyS3Key }),
        };

        const response: AxiosResponse = await Reflect.apply(applyTarget, thisArg, args);
        const { responseUri, responseStatus, error } = response.data;

        if (responseStatus === ResponseStatus.FAILURE) {
          const { type, message } = error;

          return Promise.reject({ type, message });
        }

        if (!responseUri) {
          return response;
        }

        const url = new URL(responseUri);
        const Expires = Number(url.searchParams.get('Expires'));
        const attempts = Math.max(1, Math.ceil((Expires - Date.now() / 1000)));

        for (let i = 0; i < attempts; i++) {
          try {
            return await axios.get(responseUri, { headers: { 'ignore-request-interceptor': true } });
          } catch (_error) {
            if ((_error as AxiosError).status === 403) {
              await delay(1000);
            } else {
              throw _error;
            }
          }
        }

        return response;
      },
    });
  },
});
