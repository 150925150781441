import convert from 'color-convert';
import Values from 'values.js';
import { Layer } from 'const';
import {
  ArtboardMap,
  BrandColorMap,
  BrandColorsList,
  ExtendedSectionMap,
  LayeredRelationMap,
  LayoutMap,
  RelationMap,
  SectionMap,
} from 'models';
import { opacityToHexValue } from './opacity';

const OPACITY_MAX_VALUE = 100;

export const colorWithTint = (color: BrandColorMap, _tint?: number | string): BrandColorMap => {
  let tint = _tint;

  if (typeof tint === 'string') {
    tint = Number(tint);
  }

  if (!Number.isSafeInteger(tint)) {
    return color.set('tint', 0);
  }

  const colorValues = new Values(color.get('HEX'));
  const tintedColor = colorValues.tint(tint);
  const colorRGB = tintedColor.rgb.join(', ');
  const colorHEX = tintedColor.hexString();
  const colorCMYK = convert.hex.cmyk(colorHEX).join(', ');

  return color
    .set('CMYK', colorCMYK)
    .set('HEX', colorHEX)
    .set('RGB', colorRGB)
    .set('tint', tint);
};

export function colorFromSource(
  colors: BrandColorsList | undefined,
  color: string | undefined,
  tint?: number,
): BrandColorMap | undefined {
  const brandColor = colors && colors.find(item => item.get('name') === color) || null;
  if (!brandColor) {
    return undefined;
  }

  return tint === null || tint === undefined
    ? brandColor.set('tint', undefined)
    : colorWithTint(brandColor, tint);
}

export function colorToSource(color?: BrandColorMap): string | undefined {
  return color ? color.get('name') : undefined;
}

export function colorToSourceTint(color?: BrandColorMap): number | undefined {
  return color ? color.get('tint') : undefined;
}

const colorFromEntity = (...path: string[]) =>
  (
    colors: BrandColorsList,
    source: LayoutMap | LayeredRelationMap | RelationMap | SectionMap | ExtendedSectionMap | ArtboardMap,
  ): BrandColorMap | null => {
    if (!source) {
      return null;
    }
    const tintPath = [...path];
    tintPath[tintPath.length - 1] += 'Tint';

    return colorFromSource(colors, source.getIn(['styles', ...path]), source.getIn(['styles', ...tintPath]));
  };

export const backgroundColorFromRelation = colorFromEntity('backgroundColor');

export const outsideBackgroundColor = colorFromEntity('outsideBackgroundColor');

export const borderColorFromSource = colorFromEntity('border', 'color');
export const borderColorFromLayeredSource = (layer: Layer) => colorFromEntity(layer, 'border', 'color');

export const fontColorFromRelation = colorFromEntity('fontColor');
export const fontColorFromLayeredRelation = (layer: Layer) => colorFromEntity(layer, 'fontColor');

export const colorToTint = (color: BrandColorMap | null): number | null => color?.get('tint') || null;
export const colorToValue = (
  color: BrandColorMap | undefined,
  defaultColor?: string,
): string | undefined => color ? color.get('HEX') : defaultColor;

export function brandColorToValue(color: BrandColorMap, defaultColorHEX?: string, opacity?: number): string;
export function brandColorToValue(color: BrandColorMap | undefined, defaultColorHEX?: string, opacity?: number): string | undefined;
export function brandColorToValue(
  color: BrandColorMap | undefined,
  defaultColorHEX?: string,
  opacity = OPACITY_MAX_VALUE,
): string | undefined {
  const colorHEX = color?.get('HEX') || defaultColorHEX;
  if (!colorHEX) {
    return undefined;
  }

  const opacityHex = opacity >= OPACITY_MAX_VALUE || opacity === null || opacity === undefined
    ? ''
    : opacityToHexValue(opacity);

  return `${colorHEX}${opacityHex}`;
}
