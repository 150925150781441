import Draft from 'draft-js';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { DraftjsEditorStateSetter } from '../../useDraftjsEditorState';

export function getFontColor<T extends boolean | undefined>(
  isEnabled: T,
  editorState: Draft.EditorState,
  colors: T extends true ? Models.BrandColorsList : unknown,
): T extends true ? Models.BrandColorMap | undefined : undefined {
  if (!isEnabled) {
    return undefined;
  }

  const HEX = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.FONT_COLOR) ?? '';
  const tint = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.FONT_COLOR_TINT);
  const name = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.FONT_COLOR_NAME);
  const fontColor = BrandDefinition.findBrandColor(
    colors as Models.BrandColorsList,
    { name, HEX, tint: tint ? parseInt(tint, 10) : 0 },
  );

  return fontColor as T extends true ? Models.BrandColorMap | undefined : undefined;
}

type Setter<T> = T extends true ? ((color: Models.BrandColorMap) => void) : undefined;

export function createFontColorSetter<T extends boolean | undefined>(
  isEnabled: T,
  setEditorState: DraftjsEditorStateSetter,
  brandStyle: T extends true ? Models.BrandStyleMap | undefined : unknown, // brandStyle is not used for CTA
  colors: T extends true ? Models.BrandColorsList : unknown,
): Setter<T> {
  if (!isEnabled) {
    return undefined as Setter<T>;
  }

  const setter = (brandColor: Models.BrandColorMap | undefined): void => {
    const value = brandColor
      ? brandColor.toJS() as BrandDefinition.BrandColorRef
      : (
        brandStyle && BrandDefinition.getDefaultBrandColor(
          brandStyle as Models.BrandStyleMap,
          colors as Models.BrandColorsList,
        )
      );
    const { name, HEX, tint } = value || {};

    setEditorState((state) => {
      let nextEditorState = editorUtils.toggleCustonInlineStyleValue(
        state,
        Constants.StylePrefix.FONT_COLOR,
        HEX || Constants.DefaultCustomStyle.FONT_COLOR,
      );
      nextEditorState = editorUtils.toggleCustonInlineStyleValue(
        nextEditorState,
        Constants.StylePrefix.FONT_COLOR_NAME,
        name ?? '',
      );
      nextEditorState = editorUtils.toggleCustonInlineStyleValue(
        nextEditorState,
        Constants.StylePrefix.FONT_COLOR_TINT,
        tint ?? 0,
      );

      return nextEditorState;
    });
  };

  return setter as Setter<T>;
}
