import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createRangeSelection,
  $getRoot,
  $setSelection,
  RangeSelection,
  TextFormatType,
  TextNode,
} from 'lexical';
import { useEffect } from 'react';

type Props = {
  editMode: boolean;
};

const TEXT_FORMATS = {
  8: 'underline',
  32: 'subscript',
  64: 'superscript',
};

function $getLastNonEmptyTextNode(): TextNode | null {
  const root = $getRoot();
  const textNodes = root.getAllTextNodes();
  const nonEmptyTextNodes = textNodes.filter(node => node.getTextContent().trim() !== '');

  return nonEmptyTextNodes.at(-1) ?? null;
}

function $getRangeSelection(textNode: TextNode): RangeSelection {
  const rangeSelection = $createRangeSelection();
  const textNodeKey = textNode.getKey();
  const contentSize = textNode.getTextContentSize();
  rangeSelection.anchor.set(textNodeKey, contentSize, 'text');
  rangeSelection.focus.set(textNodeKey, contentSize, 'text');

  return rangeSelection;
}

export const CustomAutoFocusPlugin: React.FunctionComponent<Props> = ({ editMode }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editMode) {
      return;
    }
    editor.focus();
    editor.update(() => {
      const lastNonEmptyTextNode = $getLastNonEmptyTextNode();
      if (!lastNonEmptyTextNode) {
        return;
      }
      const rangeSelection = $getRangeSelection(lastNonEmptyTextNode);
      const currentFormat = lastNonEmptyTextNode.getFormat();

      Object.entries(TEXT_FORMATS).forEach(([bitmask, format]) => {
        if (currentFormat & Number(bitmask)) {
          rangeSelection.formatText(format as TextFormatType);
        }
      });

      rangeSelection.setStyle(lastNonEmptyTextNode.getStyle());

      $setSelection(rangeSelection);
    });

  }, [editMode]);

  return null;
};
