import classNames from 'classnames';
import { wrapperOnMouseDownHack } from 'modules/draftjs';
import { PREVENT_BLUR_ATTRIBUTE } from 'modules/Lexical/Plugins/BlurControlPlugin';
import React, { PropsWithChildren } from 'react';
import scssVariables from 'styles/variables.scss';
import Icon from 'components/Icon';
import { NAMESPACE } from 'components/Toolbar/constants';
import { intlGet } from 'utils/intlGet';
import { ControlState } from './constants';
import { ControlProps } from './models';
import styles from './styles.module.scss';

const Control: React.FunctionComponent<PropsWithChildren<ControlProps>> = (props) => {
  const {
    children,
    className,
    controlRef,
    controlState = ControlState, // TODO: get rid of controlState, use disabled prop only
    disableHover,
    disabled: isDisabled = false,
    onToggle,
    preventBlur,
    size,
    state,
    title,
    toggleControl = false,
    tooltipEnabled = true,
    type,
    iconTooltipClassName,
  } = props;
  const onMouseDown = (event: React.MouseEvent): void => {
    if (preventBlur) {
      // preventDefault() To save selection in Draft Editor when user toggle control
      wrapperOnMouseDownHack(event);
    }
  };
  const disabled = isDisabled || controlState.DISABLED === state;

  return (
    <div
      className={classNames(styles.Control, className, { [styles.disabled]: disabled })}
      data-title={`${title}@${NAMESPACE}`}
      ref={controlRef}
      onClick={toggleControl || !type ? onToggle : undefined}
      onMouseDown={onMouseDown}
      {...{ [PREVENT_BLUR_ATTRIBUTE]: true }}
    >
      {
        type &&
        <Icon
          color={state}
          disableHover={disableHover}
          isDisabled={disabled}
          disableColor={scssVariables.mediumGrey} // TODO: Create icon color enum and use it here
          hoverColor="primary-hover"
          hoverTooltipText={tooltipEnabled ? intlGet('EditorToolbar.Tooltip', title) : ''}
          onClick={!toggleControl ? onToggle : undefined}
          size={size}
          type={type}
          iconTooltipClassName={iconTooltipClassName}
        />
      }
      {children}
    </div>
  );
};

Control.defaultProps = {
  disableHover: false,
  preventBlur: true,
  size: 'badge',
};

export default React.forwardRef<HTMLDivElement, React.PropsWithChildren<ControlProps>>((props, ref) => (
  <Control controlRef={ref} {...props}>
    {props.children}
  </Control>
));
