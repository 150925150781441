import React, { ReactNode } from 'react';
import ResizeComponent from 'components/ResizeComponent';
import { MinCellWidth } from 'const';
import { useResizeContext } from '../context/Resize';

type Props = {
  children?: ReactNode;
};

const ResizableButton: React.FC<Props> = ({ children }): JSX.Element => {
  const {
    alignment,
    container,
    startResizing,
    finishResizing,
    onResize,
    width,
    height,
    minHeight,
  } = useResizeContext();

  return (
    <ResizeComponent
      alignment={alignment}
      container={container}
      startResizing={startResizing}
      finishResizing={finishResizing}
      isResponsive={false}
      onResize={onResize}
      width={width}
      minWidth={MinCellWidth.CTA}
      height={height}
      minHeight={minHeight}
    >
      {children}
    </ResizeComponent>
  );
};

export default ResizableButton;
