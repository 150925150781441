import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OrderedSet } from 'immutable';
import { useEffect } from 'react';
import * as Constants from 'const';
import { registerCommands } from './commands';
import { $getInlineStyle } from './utils';

export { REFERENCES_FONT_COMMAND } from './commands';

export type ReferencesFontProps = {
  inlineStyle: OrderedSet<Constants.ReferencesInlineStyle>;
};

type Props = {
  onChange: (props: ReferencesFontProps) => void;
};

export function ReferencesFontPlugin(props: Props): null {
  const { onChange } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => registerCommands(editor),
    [editor],
  );

  useEffect(() => {
    const $readAndNotifyChange = (): void => onChange({
      inlineStyle: OrderedSet($getInlineStyle()),
    });

    editor.getEditorState().read($readAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read($readAndNotifyChange);
    });
  }, [editor, onChange]);

  return null;
}
