import { Map } from 'immutable';
import { Styles } from 'components/ArtboardAssets/Text/utils/styles';
import * as Models from 'models';
import { getExtraHeight } from 'utils/getExtraHeight';

export const getContentMinHeight = (styles: Styles, clientHeight: number): number => {
  const { padding, border } = styles;
  const extraHeight = getExtraHeight(Map({ padding, border }) as Models.CommonStylesMap);

  return clientHeight + extraHeight;
};
