import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { FormControl } from 'react-bootstrap';
import { DragSource, DragSourceConnector, DragSourceSpec, DropTarget, DropTargetConnector, DropTargetSpec } from 'react-dnd';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Tooltip from 'components/Tooltip';
import * as Constants from 'const';
import * as Models from 'models';
import { getSpacerPosition } from 'utils/dragAndDrop';
import { intlGet } from 'utils/intlGet';
import { getValidScreenName, isScreenNameInvalid } from 'utils/screenNameValidator';
import ScreenFormatType from './components/ScreenFormatType';
import { ScreenNumber } from './components/ScreenNumber';
import ScreenType from './components/ScreenType';
import { IScreensPanelItemProps, IScreensPanelItemState } from './models';
import styles from './styles.module.scss';

const PanelItemDragSource: DragSourceSpec<IScreensPanelItemProps, Models.ScreenDragObject> = {
  beginDrag(props) {
    const { screen, position } = props;

    return {
      screen,
      position,
    };
  },
  endDrag(props) {
    const { toggleOverState } = props;
    toggleOverState(false);
  },
};

const PanelItemDropTarget: DropTargetSpec<IScreensPanelItemProps> = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }

    const { updateHotspotPosition, position: hoverIndex, isOver, toggleOverState } = props;
    const boundingClientRect = component.panelItemRef.current.getBoundingClientRect();
    const clientOffset = monitor.getClientOffset();
    const spacerPosition = getSpacerPosition(boundingClientRect, clientOffset, hoverIndex);
    updateHotspotPosition(spacerPosition);

    if (!isOver) {
      toggleOverState(true);
    }
  },

  drop(props, monitor, component) {
    if (!component) {
      return null;
    }

    const { moveScreen, position: hoverIndex } = props;
    const { screen, position: dragIndex } = monitor.getItem();
    const { panelItemRef: { current: dropElement } } = component.decoratedRef.current;
    const boundingClientRect = dropElement.getBoundingClientRect();
    const clientOffset = monitor.getClientOffset();
    const spacerPosition = getSpacerPosition(boundingClientRect, clientOffset, hoverIndex);
    const dragId = screen.get('id');
    if (
      dragIndex === hoverIndex
      || dragIndex === spacerPosition
      || dragIndex - spacerPosition === 1
    ) {
      return;
    }
    moveScreen(dragId, dragIndex < hoverIndex ? spacerPosition - 1 : spacerPosition);
  },
};

class ScreensPanelItem extends React.PureComponent<IScreensPanelItemProps, IScreensPanelItemState> {
  readonly state: IScreensPanelItemState = {
    editMode: false,
    screenName: this.props.screen.get('name'),
    screenEditMode: false,
    showTooltip: false,
    tooltipText: '',
    tooltipParent: null,
  };

  private readonly inputElementRef = React.createRef<any>();

  private readonly panelItemRef = React.createRef<HTMLDivElement>();

  private readonly screenTitleRef = React.createRef<HTMLSpanElement>();

  private readonly deleteIconRef = React.createRef<HTMLDivElement>();

  private readonly copyIconRef = React.createRef<HTMLDivElement>();

  private readonly settingsIconRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: IScreensPanelItemProps, prevState: IScreensPanelItemState) {
    const { current: inputElement } = this.inputElementRef;
    const { screen } = this.props;

    if (inputElement && prevState.editMode !== this.state.editMode) {
      inputElement.focus();
      inputElement.select();
      this.toggleTooltipWithOptions(true, this.inputElementRef, screen.get('name'));
    } else if (!inputElement && prevState.editMode !== this.state.editMode) {
      this.toggleTooltipWithOptions();
    }
  }

  private enableEditMode = (): void => {
    const {
      screen,
    } = this.props;

    this.setState({
      editMode: true,
      screenName: screen.get('name'),
    });
  };

  private onChangeScreenName: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newScreenName = event.target.value;

    if (isScreenNameInvalid(newScreenName)) {
      return;
    }

    this.setState({
      screenName: newScreenName,
    });
    this.toggleTooltipWithOptions(true, this.inputElementRef, newScreenName);
  };

  private saveScreenName = (): void => {
    const { screenName } = this.state;
    const { screen, updateScreen, screenNames } = this.props;
    const initialScreenName = screen.get('name');
    const name = getValidScreenName(screenName, screenNames, initialScreenName);

    if (name === initialScreenName) {
      this.setState({
        editMode: false,
      });

      return;
    }

    const id = screen.get('id');
    updateScreen({ id, name });

    this.setState({
      editMode: false,
      screenName: name,
    });
  };

  private onEnterPress = (event: React.KeyboardEvent): void => {
    if (event.key === Constants.KeyboardKey.ENTER) {
      this.saveScreenName();
    }
  };

  private showScreenMenu = _.debounce(
    () => {
      this.setState({
        screenEditMode: true,
      });
    },
    100,
  );

  private cancelShowingScreenMenu = (): void => {
    this.showScreenMenu.cancel();
  };

  private hideScreenMenu = _.debounce(
    () => {
      this.setState({
        screenEditMode: false,
      });
    },
    1000,
  );

  private cancelHidingScreenMenu = (): void => {
    this.hideScreenMenu.cancel();
  };

  private onMouseEnterMenu = (): void => {
    this.cancelHidingScreenMenu();
    this.showScreenMenu();
  };

  private onMouseLeaveMenu = (): void => {
    this.cancelShowingScreenMenu();
    this.hideScreenMenu();
  };

  private onScreenClick = (): void => {
    const { screen, changeActiveScreen } = this.props;
    const id = screen.get('id');

    changeActiveScreen(id);
  };

  private onDeleteScreenClick = (): void => {
    const { screen, deleteScreen } = this.props;
    const id = screen.get('id');

    deleteScreen(id);
  };

  private onDuplicateScreenClick = (): void => {
    const { screen, duplicateScreen } = this.props;
    const id = screen.get('id');

    duplicateScreen(id);
  };

  private onScreenSettingsClick = (): void => {
    const { screen, setScreenSettings } = this.props;
    const id = screen.get('id');

    setScreenSettings(id);
  };

  private getIcon = (): JSX.Element => {
    const { entityType } = this.props;

    switch (entityType) {
      case Constants.EntityType.TEXT:
        return <Icon type={IconType.TEXT} size="badge-sm" color="primary" />;
      case Constants.EntityType.IMAGE:
        return <Icon type={IconType.IMAGE} size="badge-sm" color="primary" />;
      case Constants.EntityType.REFERENCE_CITATION:
        return <Icon type={IconType.REFERENCE_CITATION} size="badge-sm" color="primary" />;
      case Constants.EntityType.LAYOUT:
      case Constants.EntityType.GROUP_LAYOUT:
        return <Icon type={IconType.LAYOUT} size="badge-sm" color="primary" />;

      default: return null;
    }
  };

  private onScreenFormatChange = (screenFormatType: Constants.ScreenFormatType): void => {
    const { screen, setScreenFormat } = this.props;
    const screenId = screen.get('id');

    // NOTE: from a screenPanelItem dropdown a user is able to select a predefined format type only
    setScreenFormat({ screenId, ...Constants.PredefinedScreenFormatByType[screenFormatType] });
  };

  private toggleTooltipWithOptions = (showTooltip = false, tooltipParent = null, tooltipText = '') => {
    this.setState({ showTooltip, tooltipParent, tooltipText });
  };

  render() {
    const { editMode, screenName, screenEditMode } = this.state;
    const {
      isActive,
      isDeletingDisabled,
      connectDragSource,
      connectDropTarget,
      position,
      projectType,
      screenDefinitions,
      showIconComponent,
      thumbnailsColor,
      updateScreenType,
      screen,
      artboards,
    } = this.props;
    const {
      areScreensResizable,
      allowScreenSettings,
      allowThumbnails,
    } = Constants.ProjectsConfig[projectType];

    connectDragSource(this.panelItemRef);
    connectDropTarget(this.panelItemRef);
    const id = screen.get('id');
    const name = screen.get('name');
    const thumbnail = screen.get('thumbnail');
    const thumbnailUrl = thumbnail.get('url');
    const thumbnailBorder = thumbnail.get('border');
    const thumbnailName = thumbnail.get('name');

    const formatType = screen.get('formatType');
    const artboardId = screen.get('artboardId');
    const artboardStyles = artboards.getIn([artboardId, 'styles']);
    const height = artboardStyles.get('height');
    const width = artboardStyles.get('width');

    return (
      <div
        ref={this.panelItemRef}
        key={id}
        onClick={this.onScreenClick}
        className={classNames(styles.ScreensPanelItem, {
          [styles.ScreensPanelItem__active]: isActive,
        })}
        style={{ order: position }}
      >
        <div className={styles.dragDots}>
          <Icon type={IconType.DRAG_DOTS} size="sm" color="grey" />
        </div>
        <div className={styles.screenInfo}>
          {
            allowThumbnails && (thumbnailName || thumbnailBorder || thumbnailUrl)
              ?
              <div className={styles.screenThumbnailPreviewWrapper}>
                <div className={styles.screenThumbnailBackground} style={{ backgroundImage: `url(${thumbnailUrl})` }}/>
                <div
                  className={classNames(styles.screenThumbnailPreview, { [styles.border]: thumbnailBorder })}
                  style={{ borderColor: thumbnailBorder && thumbnailsColor }}
                >
                  <div style={{ color: thumbnailsColor }} className={styles.screenPreviewText}>{thumbnailName}</div>
                </div>
              </div>
              :
              <div className={styles.screenPreview} />
          }

          <div className={styles.screenDescription}>
            <div className={styles.screenTitleBar}>
              {
                editMode
                  ?
                  <FormControl
                    className={styles.input}
                    maxLength={Constants.MAX_SCREEN_NAME_LENGTH}
                    value={screenName}
                    onChange={this.onChangeScreenName}
                    onBlur={this.saveScreenName}
                    onKeyDown={this.onEnterPress}
                    ref={this.inputElementRef}
                    onMouseEnter={() => this.toggleTooltipWithOptions(true, this.screenTitleRef, screenName)}
                    onMouseLeave={() => this.toggleTooltipWithOptions()}
                  />
                  :
                  <span
                    className={styles.screenDescription__title}
                    onDoubleClick={this.enableEditMode}
                    ref={this.screenTitleRef}
                    onMouseEnter={() => this.toggleTooltipWithOptions(true, this.screenTitleRef, name)}
                    onMouseLeave={() => this.toggleTooltipWithOptions()}
                  >
                    {name}
                  </span>
              }
              <div className={classNames(styles.screenMenu, { [styles.screenMenu__editMode]: screenEditMode })}>
                <div className={styles.screenMenuIcons} onMouseEnter={this.onMouseEnterMenu} onMouseLeave={this.onMouseLeaveMenu}>
                  {
                    screenEditMode
                      ?
                      <div className={styles.screenMenu__expanded}>
                        <div
                          className={styles.iconContainer}
                          ref={this.deleteIconRef}
                          onMouseEnter={() => this.toggleTooltipWithOptions(true, this.deleteIconRef, intlGet('ScreensPanel', 'Delete'))}
                          onMouseLeave={() => this.toggleTooltipWithOptions()}
                        >
                          <Icon
                            type={IconType.DELETE_BIN}
                            size="sm"
                            color="grey"
                            onClick={this.onDeleteScreenClick}
                            isDisabled={isDeletingDisabled}
                          />
                        </div>
                        <div
                          className={styles.iconContainer}
                          ref={this.copyIconRef}
                          onMouseEnter={() => this.toggleTooltipWithOptions(true, this.copyIconRef, intlGet('ScreensPanel', 'Duplicate'))}
                          onMouseLeave={() => this.toggleTooltipWithOptions()}
                        >
                          <Icon type={IconType.CONTENT_COPY} size="sm" color="grey" onClick={this.onDuplicateScreenClick} />
                        </div>
                        {allowScreenSettings && <div
                          className={styles.iconContainer}
                          ref={this.settingsIconRef}
                          onMouseOver={() => this.toggleTooltipWithOptions(true, this.settingsIconRef, intlGet('ScreensPanel', 'Settings'))}
                          onMouseLeave={() => this.toggleTooltipWithOptions()}
                        >
                          <Icon type={IconType.GEAR} size="sm" color="grey" onClick={this.onScreenSettingsClick} />
                        </div>}
                      </div>
                      :
                      <div className={styles.iconContainer}>
                        <Icon type={IconType.MENU} size="sm" color="grey" />
                      </div>
                  }
                </div>
              </div>
            </div>
            {
              areScreensResizable
                ?
                <ScreenFormatType
                  customHeight={height}
                  customWidth={width}
                  formatType={formatType}
                  onFormateChange={this.onScreenFormatChange}
                  isTooltipDisabled={false}
                  showCustomFormatOnlyWhenItIsActive={true}
                />
                :
                <ScreenType
                  screen={screen}
                  screenDefinitions={screenDefinitions}
                  updateScreenType={updateScreenType}
                />
            }
          </div>
          {
            showIconComponent &&
            <div className={styles.iconHighlight}>
              {this.getIcon()}
            </div>
          }
        </div>
        <Tooltip
          arrow={false}
          className={styles.tooltip}
          parent={this.state.tooltipParent}
          show={this.state.showTooltip}
          text={this.state.tooltipText}
        />
        { !showIconComponent && <ScreenNumber position={position}/>}
      </div>
    );
  }
}

const DropTargetWrapper = DropTarget(
  Constants.DragSourceType.SCREEN,
  PanelItemDropTarget,
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  }),
);
const DragSourceWrapper = DragSource(
  Constants.DragSourceType.SCREEN,
  PanelItemDragSource,
  (connect: DragSourceConnector) => ({
    connectDragSource: connect.dragSource(),
  }),
);

export default DropTargetWrapper(DragSourceWrapper(ScreensPanelItem));
