import _ from 'lodash';
import { useRef, useEffect } from 'react';
import * as Constants from 'const';
import { getActiveLayer } from 'hooks/useActiveLayer';
import { getContainerSize } from 'utils/getContainerSize';
import { toPx } from 'utils/toPx';
import { useModifiedStylesSetters } from './hooks/useModifiedStylesSetters';
import { SizeResetHook } from './hooks/useSizeReset';
import { StylesHook } from './hooks/useStyles';
import { CallToActionProps } from './models';

export const useCallToAction = (
  props: CallToActionProps,
  stylesHook: StylesHook,
  sizeResetHook: SizeResetHook,
  editorWrap: React.RefObject<HTMLDivElement>,
) => {
  const {
    editMode,
    layoutId,
    layoutRelations,
    relation,
    updateRelationsSilently,
    isAutoFitContent,
  } = props;

  const { resetHeightOnEffect, resetWidth } = sizeResetHook;

  const activeLayer = getActiveLayer();
  const relationId = relation.get('id');
  const container = useRef<HTMLDivElement>(null);

  const { styles, setters: stylesSetters, getMaxWidth } = stylesHook;
  const {
    assetPadding,
    border,
    fitToCell,
    height,
    padding,
    width,
  } = styles;

  useEffect(() => {
    if (editMode) {
      resetWidth(padding, border, (newWidth: number): void => {
        // if RL/GRL is placed into smaller width than it comes from,
        // the width should be adjusted silently, as it's done automatically it should not trigger update modal
        const updatedLayoutRelations = layoutRelations.setIn([relationId, 'styles', activeLayer, 'width'], newWidth);
        updateRelationsSilently(updatedLayoutRelations, layoutId);
      });
    }
  }, [editMode, width]);

  useEffect(() => {
    const { current } = editorWrap;

    if (current === null) {
      return;
    }

    const px = toPx(current?.scrollHeight);

    if (px === undefined) {
      return;
    }

    current.style.height = px;

  }, [editMode]);

  const onResize = (newHeight: number): void => {
    stylesSetters.fitToCell(false);
    stylesSetters.height(_.ceil(newHeight));
  };

  useEffect(
    () => {
      resetHeightOnEffect();
    },
    [
      height,
      width,
      padding,
      border,
      isAutoFitContent,
      fitToCell,
    ],
  );

  const finishResizing = (actualWidth: number, actualHeight: number): void => {
    stylesSetters.height(_.round(actualHeight));
    stylesSetters.width(_.round(actualWidth));
    stylesSetters.fitToCell(false);
  };

  const getMaxHeight = (): number => {
    if (!container.current) {
      return null;
    }

    return getContainerSize(container.current).height;
  };

  const getHeight = (): number => fitToCell ? getMaxHeight() : _.round(height);
  const getWidth = (): number => fitToCell ? getMaxWidth() : _.round(width);

  const assetVerticalPadding = assetPadding
    ? (assetPadding.get(Constants.BoxPropertySide.TOP) + assetPadding.get(Constants.BoxPropertySide.BOTTOM))
    : 0;
  const assetHorizontalPadding = assetPadding
    ? (assetPadding.get(Constants.BoxPropertySide.LEFT) + assetPadding.get(Constants.BoxPropertySide.RIGHT))
    : 0;

  const getResizeComponentHeight = (): number => assetVerticalPadding > getHeight() ? assetVerticalPadding : getHeight();
  const getResizeComponentMinHeight = (): number => assetVerticalPadding + (editorWrap.current ? editorWrap.current.scrollHeight : 0);
  const getResizeComponentWidth = (): number => assetHorizontalPadding > getWidth() ? assetHorizontalPadding : getWidth();

  const modifiedStylesSetters = useModifiedStylesSetters(stylesHook, sizeResetHook, getMaxHeight);

  return {
    container,

    modifiedStylesSetters,
    correctedHeight: getHeight(),
    correctedWidth: getWidth(),

    getResizeComponentHeight,
    getResizeComponentMinHeight,
    getResizeComponentWidth,

    finishResizing,
    onResize,
  };
};
