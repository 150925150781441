import { connectRouter } from 'connected-react-router/immutable';
import { History } from 'history';
import Immutable from 'immutable';
import textAbbreviations from 'modules/Abbreviations/store/reducer';
import reduceReducers from 'reduce-reducers';
import { Action, Reducer } from 'redux';

import app from 'containers/App/reducer';
import preview from 'containers/ArtboardPreview/reducer';
import artboards from 'containers/Artboards/reducer';
import brandDefinition from 'containers/BrandDefinition/reducer';
import countryAbbreviations from 'containers/CountryAbbreviations/reducer';
import documents from 'containers/Documents/reducer';
import documentTypes from 'containers/DocumentTypes/reducer';
import editorMode from 'containers/EditorToggle/reducer';
import layouts from 'containers/Layouts/reducer';
import modalManager from 'containers/ModalManager/reducer';
import project from 'containers/Project/reducer';
import projectPanel from 'containers/ProjectPanel/reducer';
import relations from 'containers/Relations/reducer';
import rootDocument from 'containers/RootDocument/reducer';
import screenDefinitions from 'containers/ScreenDefinitions/reducer';
import sections from 'containers/Sections/reducer';
import surfaces from 'containers/Surfaces/reducer';
import { undoable } from 'containers/UndoRedoControl/reducerEnhancer';
import * as Models from 'models';

export type ReducersMapObject<S, A extends Action = Action> = {
  [K in keyof S]: Models.Reducer<S[K], A> | Models.StateInjectedReducer<S[K], A>
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const combineReducers = <S = any>(reducers: ReducersMapObject<S>): Reducer<DeepIMap<S>> => {
  const reducerKeys = Object.keys(reducers);

  return (inputState = Immutable.Map() as DeepIMap<S>, action: Action) => inputState
    .withMutations((temporaryState) => {
      reducerKeys.forEach((reducerName: keyof S) => {
        const injectState = ['layouts', 'relations'].includes(reducerName);
        const reducer = reducers[reducerName];
        const currentDomainState = temporaryState.get(reducerName);
        const nextDomainState = reducer(currentDomainState, action, injectState ? temporaryState : undefined);

        temporaryState.set(reducerName, nextDomainState);
      });
    });
};

const rootReducer = (history: History) => reduceReducers<Models.AppState.StateMap>(
  combineReducers({
    router: connectRouter(history),
  }),
  undoable(combineReducers<Partial<Models.AppState.StateMapObject>>({
    app,
    artboards,
    brandDefinition,
    countryAbbreviations,
    textAbbreviations,
    documents,
    documentTypes,
    modalManager,
    preview,
    project,
    projectPanel,
    rootDocument,
    sections,
    surfaces,
    screenDefinitions,
    layouts,
    relations,
    editorMode,
  })),
);

export default rootReducer;
