import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EditorState } from 'lexical';
import { useEffect, useRef } from 'react';

type Props = {
  onStopEditing: (state: EditorState) => void;
};

export function EditorStopEditingPlugin(props: Props): null {
  const { onStopEditing } = props;
  const callbackRef = useRef(onStopEditing);
  callbackRef.current = onStopEditing;

  const [editor] = useLexicalComposerContext();
  const editable = editor.isEditable();

  useEffect(() => {
    return () => {
      callbackRef.current(editor.getEditorState());
    };
  }, [editable]);

  return null;
}
