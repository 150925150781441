import { DOMExportOutput } from 'lexical';
import { ReferenceCitationNode } from 'modules/Lexical/nodes/ReferenceCitationNode';

export const REFERENCE_CITATION_MARKER = '{r}';

export class ReferenceCitationNodeWithExportDom extends ReferenceCitationNode {
  static getType(): string {
    return super.getType() + '-export';
  }

  exportDOM(): DOMExportOutput {
    const sup = document.createElement('sup');
    sup.setAttribute('data-reference-id', this.getReferenceId());

    const innerSup = document.createElement('sup');
    innerSup.innerText = REFERENCE_CITATION_MARKER;
    sup.appendChild(innerSup);

    return { element: sup };
  }
}
