import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import * as Models from 'models';
import { HISTORY_TAGS } from '../HistoryWithExtraStatePlugin';
import { registerEditorPropsCommands } from './commands';
import { TextBlockProps } from './types';
import { $applyBrandStyleForTextBlock } from './utils/brandStyle';
import { $getHorizontalAlignmentBlockTypeFromSelection } from './utils/horizontal-alignment';
import { $getBlockLineHeightFromSelection } from './utils/line-height';

export type { TextBlockProps } from './types';
export { TEXT_BLOCK_COMMAND } from './commands';

type Props = {
  onChange: (props: TextBlockProps) => void;
  brandStyle?: Models.BrandStyleMap;
  brandStyleChanged?: boolean;
};

export function TextBlockPlugin(props: Props): null {
  const { onChange, brandStyle, brandStyleChanged } = props;

  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return registerEditorPropsCommands(editor);
  }, [editor]);

  useEffect(() => {
    editor.update(() => {
      if (brandStyleChanged) {
        return;
      }
      $applyBrandStyleForTextBlock(brandStyle);
    }, { tag: HISTORY_TAGS.MERGE, discrete: true });
  }, [editor, brandStyle, brandStyleChanged]);

  useEffect(() => {
    const readEditorStateAndNotifyChange = (): void => {
      const values = {
        blockLineHeight: $getBlockLineHeightFromSelection(),
        blockType: $getHorizontalAlignmentBlockTypeFromSelection(),
      };
      onChange(values);
    };

    editor.getEditorState().read(readEditorStateAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(readEditorStateAndNotifyChange);
    });
  }, [editor, onChange]);

  return null;
}
