import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $insertNodes } from 'lexical';
import { useEffect } from 'react';

type Props = {
  html: string | undefined;
};

export function HtmlContentPlugin(props: Props): null {
  const { html } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editor && html) {
      editor.update(() => {
        // Use the native DOMParser API to parse the HTML string
        const parser = new DOMParser();
        const dom = parser.parseFromString(html, 'text/html');

        // Generate LexicalNodes
        const nodes = $generateNodesFromDOM(editor, dom);

        // Remove automatically created paragraph
        const emptyParagraph = $getRoot().getFirstChild();
        if (emptyParagraph) {
          emptyParagraph.remove();
        }

        // Select the root
        $getRoot().select();

        // Insert LexicalNodes at a selection
        $insertNodes(nodes);
      });
    }
  }, [editor, html]);

  return null;
}
