import * as React from 'react';
import { Button, Form } from 'react-bootstrap';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import SelectItem from 'components/SelectItem';
import Spinner from 'components/Spinner';
import { DocumentClassification, DocumentClassificationMedcomms, isVeevaMedcomms, MAX_DOCUMENT_NAME_LENGTH } from 'const';
import { intlGet } from 'utils/intlGet';
import { SaveReusableLayoutProps } from './models';
import styles from './styles.module.scss';

const SaveLayout: React.FunctionComponent<SaveReusableLayoutProps> = ({
  initialClassification,
  documentClassifications,
  cancelPreviewGeneration,
  generatePreview,
  hideModal,
  initialLayoutName = '',
  layoutPreview,
  modalWindowId,
  saveLayout,
  updateAppState,
  title,
}) => {
  const defaultClassification = isVeevaMedcomms() ? DocumentClassificationMedcomms.HEADER : DocumentClassification.UNCLASSIFIED;
  const [classification, setClassification] = React.useState(initialClassification || defaultClassification);

  React.useEffect(
    () => {
      generatePreview();

      return () => {
        // since we store temporary preview info in redux, have to clean it up and cancel running task
        // todo: replace with separate action
        updateAppState({ tempLayoutPreview: { isBeingGenerated: false, s3Url: null, preloadedUrl: null } });
        cancelPreviewGeneration();
      };
    },
    [],
  );

  const [layoutName, toggleLayoutName] = React.useState(initialLayoutName);

  const onCancelClick = (): void => {
    // HACK: pass the second argument to allow us to recognize which option has been chosen
    // TODO: modal should be opened from the saga and that saga will be waiting for response from modal
    hideModal(modalWindowId, false);
  };
  const onSaveClick: React.MouseEventHandler = (): void => {
    // HACK: pass the second argument to allow us to recognize which option has been chosen
    // TODO: modal should be opened from the saga and that saga will be waiting for response from modal
    hideModal(modalWindowId, true);
    // NOTE: it's nessacary to dispatch 'saveLayout' action after 'hideModal'
    // since 'resetLayoutAssets' saga should be run before 'saveLayout' saga
    saveLayout(layoutName, classification);
  };

  const onNameChange: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleLayoutName(event.target.value);
  };

  const renderLayoutPreview = () => {
    const preloadedUrl = layoutPreview.get('preloadedUrl');

    return preloadedUrl
      ? <div className={styles.scrollableContainer}><img className={styles.image} src={preloadedUrl} /></div>
      : <Icon className={styles.placeholder} type={IconType.IMAGE} size="lg" color="disabled" />;
  };

  const changeDocumentClassification: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setClassification(event.target.value as DocumentClassification);
  };

  return (
    <div className={styles.SaveLayout}>
      <header className={styles.header}>
        <div>{title}</div>
        <Icon className={styles.closeIcon} onClick={onCancelClick} type={IconType.CLOSE} size="sm-md" color="primary" />
      </header>
      <main className={styles.main}>
        <section className={styles.preview}>
          {
            layoutPreview.get('isBeingGenerated')
              ? <Spinner className={styles.spinner} />
              : renderLayoutPreview()
          }
        </section>

        {documentClassifications && <h3 className={styles.selectTitle}>{intlGet('SaveReusableLayout.Form', 'NameLabel')}</h3>}
        <Form.Control
          className={styles.name}
          type="text"
          placeholder={intlGet('SaveReusableLayout.Form', 'Name')}
          maxLength={MAX_DOCUMENT_NAME_LENGTH}
          value={layoutName}
          onChange={onNameChange}
        />

        {documentClassifications &&
          <>
            <h3 className={styles.selectTitle}>{intlGet('SaveReusableLayout.Select', 'Classification')}</h3>
            <SelectItem
              className={styles.selectWrapper}
              onChange={changeDocumentClassification}
              value={classification}
              values={documentClassifications}
            />
          </>
        }
      </main>
      <footer className={styles.footer}>
        <Button onClick={onCancelClick} variant="secondary">{intlGet('SaveReusableLayout.Buttons', 'Cancel')}</Button>
        <Button onClick={onSaveClick} variant="primary" disabled={!layoutName}>
          {intlGet('SaveReusableLayout.Buttons', 'Save')}
        </Button>
      </footer>
    </div>
  );
};

export default SaveLayout;
