import * as Constants from 'const';
import { ListIndents, ParagraphType } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { convertIndentToPx } from 'services/ArtboardConverter/utils/getListIndent';
import { CustomListNode } from '../../nodes/CustomListNode';
import { CreateParagraphContext, getParagraphFontSize } from './Paragraph';

export function createParagraphForList(
  node: CustomListNode,
  context: CreateParagraphContext,
  paragraphs: Schemas.ListItemParagraph[],
): Schemas.ListParagraph {
  const { defaultStyles, projectType } = context;

  const firstTextNode = node.getAllTextNodes()[0];
  const fontSizeNumber = getParagraphFontSize(firstTextNode, defaultStyles, projectType);

  const { convertListIndentToPx } = Constants.ProjectsConfig[projectType];
  let indents = ListIndents[projectType];
  if (convertListIndentToPx) {
    indents = convertIndentToPx(indents, fontSizeNumber);
  }

  return {
    type: ParagraphType.UNORDERED_LIST,
    ...indents,
    paragraphs,
  };
}
