import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { $selectAll } from 'lexical';
import { useEffect } from 'react';

export const SellectAllAutoFocusPlugin: React.FunctionComponent = () => {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor);
  const editMode = editor.isEditable();

  const editorUpdateOptions = {
    onUpdate:(): void => {
      if (isEmpty && editMode) {
        editor.focus();
      }
    },
  };
  useEffect(() => {
    if (!editMode) {
      return;
    }
    editor.update($selectAll, editorUpdateOptions);

  }, [editMode, editor]);

  return null;
};
