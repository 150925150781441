import { $createRangeSelection, $setSelection, LexicalEditor } from 'lexical';
import { $createReferenceCitationNode } from '../../../nodes/ReferenceCitationNode';

function getRange(event: DragEvent): Range | null {
  // is fixed in TS v.5.7, remove after upgrade
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const caret = document.caretPositionFromPoint(event.clientX, event.clientY);
  if (caret) {
    const range = document.createRange();
    // is fixed in TS v.5.7, remove after upgrade
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    range.setStart(caret.offsetNode, caret.offset);

    return range;
  }

  return null;
}

export function createDropHandler(
  editor: LexicalEditor,
  saveHandler: () => void,
) {
  return (event: DragEvent): boolean => {
    event.preventDefault();
    const { referenceId } = JSON.parse(event.dataTransfer?.getData('text/plain') || '{}');
    if (!referenceId) {
      return false;
    }

    editor.update(() => {
      const range = getRange(event);
      if (!range) {
        return;
      }
      const selection = $createRangeSelection();
      selection.applyDOMRange(range);
      selection.insertNodes([$createReferenceCitationNode(referenceId)]);
      $setSelection(null);

    }, { discrete: true });

    saveHandler();

    return true;
  };
}
