import * as Models from 'models';
import { ReferenceCitationNode, SerializedReferenceCitationNode } from '../../nodes/ReferenceCitationNode';
import { DraftEntityData } from '../types';
import { getFormateCode, convertTextStyles } from '../utils';

export function createReferenceCitationNode(
  entityData: DraftEntityData,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): SerializedReferenceCitationNode {
  const { text, data, styles } = entityData;

  return {
    type: ReferenceCitationNode.getType(),
    referenceId: data?.id ?? '',
    text,
    format: getFormateCode(styles),
    style: convertTextStyles(styles, colors, fonts),
    detail: 0,
    version: 1,
  };
}
