import { isEqual, isFunction } from 'lodash';
import { useCallback, useState } from 'react';

type PropsModifier<T> = (prevProps: T) => Partial<T>;
type UpdateProps<T> = (propsOrPropsModifier: Partial<T> | PropsModifier<T>) => void;

type PropsUpdaterHook<T> = {
  updateProps: UpdateProps<T>;
  props: T;
  setProps: React.Dispatch<React.SetStateAction<T>>;
};

export const usePropsUpdater = <T>(initialState: T): PropsUpdaterHook<T> => {
  const [props, setProps] = useState<T>(initialState);

  const updateProps = useCallback(
    (propsOrPropsModifier: Partial<T> | PropsModifier<T>) => {
      setProps((prevProps) => {
        const newProps = isFunction(propsOrPropsModifier) ? propsOrPropsModifier(prevProps) : propsOrPropsModifier;
        const hasChanged = Object.keys(newProps).some(key => !(isEqual(prevProps[key], newProps[key])));
        if (hasChanged) {
          return { ...prevProps, ...newProps };
        }

        return prevProps;
      });
    }, [setProps],
  );

  return { updateProps, props, setProps };
};
