import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import * as inlineStylesUtils from 'utils/inlineStyles';
import { toPx } from 'utils/toPx';
import { FontPluginStyle } from '../Plugins/FontPlugin/style';
import { ConvertStylesEnum, LexicalFormatCodeEnum } from './types';

export function getFormateCode(styles: string[]): number {
  return styles.reduce((acc, curr) => {
    const isUnderline = curr.includes(ConvertStylesEnum.UNDERLINE) ? LexicalFormatCodeEnum.UNDERLINE : LexicalFormatCodeEnum.DEFAULT;
    const isSub = curr.includes(ConvertStylesEnum.SUB) ? LexicalFormatCodeEnum.SUB : LexicalFormatCodeEnum.DEFAULT;
    const isSup = curr.includes(ConvertStylesEnum.SUP) ? LexicalFormatCodeEnum.SUP : LexicalFormatCodeEnum.DEFAULT;

    return acc + isUnderline + isSup + isSub;
  }, 0);
}

export function convertTextStyles(
  styles: string[],
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): string {
  const result: string[] = [];

  const brandColorHEX = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_COLOR);
  if (brandColorHEX) {
    result.push(`${FontPluginStyle.BRAND_COLOR_HEX}: ${brandColorHEX}`);
    const brandColorName = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_COLOR_NAME);
    if (brandColorName) {
      result.push(`${FontPluginStyle.BRAND_COLOR_NAME}: ${brandColorName}`);
    }
    const barndColorTint = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_COLOR_TINT, 'number');
    if (barndColorTint) {
      result.push(`${FontPluginStyle.BRAND_COLOR_TINT}: ${barndColorTint}`);
    }
    const color = BrandDefinition.getBrandColorValueHEX(
      colors,
      { name: brandColorName, HEX: brandColorHEX, tint: barndColorTint },
    );
    result.push(`${FontPluginStyle.COLOR}: ${color}`);
  }

  const brandFontName = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_FAMILY);
  if (brandFontName) {
    result.push(`${FontPluginStyle.BRAND_FONT_NAME}: ${brandFontName}`);
    const characterStyleName = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.CHARACTER_STYLE_NAME);
    if (characterStyleName) {
      result.push(`${FontPluginStyle.BRAND_FONT_CHARACTER_STYLE_NAME}: ${characterStyleName}`);
    }
    const fontFamily = BrandDefinition.getCSSFontFamilyFromBrandFont(
      brandFontName,
      characterStyleName,
      fonts,
    );
    result.push(`${FontPluginStyle.FONT_FAMILY}: ${fontFamily}`);
  }

  const fontSize = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_SIZE);
  const fontSizePx = toPx(fontSize);
  if (fontSizePx) {
    result.push(`${FontPluginStyle.FONT_SIZE}: ${fontSizePx}`);
  }

  const fontStyle = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_STYLE);
  if (fontStyle) {
    result.push(`${FontPluginStyle.FONT_STYLE}: ${fontStyle}`);
  }

  const fontWeight = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_WEIGHT);
  if (fontWeight) {
    result.push(`${FontPluginStyle.FONT_WEIGHT}: ${fontWeight}`);
  }

  return result.join('; ').concat(';');
}
