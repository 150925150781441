import Draft from 'draft-js';
import _ from 'lodash';

import { DraftEntity } from 'const';
import * as Models from 'models';
import { toImmutable, toJS } from 'utils/immutable';
import { updateReferenceCitationsOnComponent } from './updateReferenceCitationsOnComponent';

function replaceReferencesInEntity(
  entity: Draft.RawDraftEntity,
  newByOldReferenceIds: Record<string, string>,
): Draft.RawDraftEntity {
  const updatedEntity: Draft.RawDraftEntity = entity;
  const oldReferenceId: string = entity.data.id;
  const newReferenceId = newByOldReferenceIds[oldReferenceId];

  if (entity.type === DraftEntity.REFERENCE && newReferenceId) {
    updatedEntity.data.id = newReferenceId;
  }

  return updatedEntity;
}


export function replaceReferenceCitationsWithinComponent(
  textComponent: Models.TextComponent,
  newByOldReferenceIds: Record<string, string>,
): Models.TextComponent {
  let updatedTextComponent = toImmutable(textComponent);
  const rawContent: Draft.RawDraftContentState = JSON.parse(textComponent.rawContent);

  rawContent.entityMap = _.mapValues(rawContent.entityMap, entity => replaceReferencesInEntity(entity, newByOldReferenceIds));

  updatedTextComponent = updatedTextComponent.set('rawContent', JSON.stringify(rawContent));

  return toJS(updateReferenceCitationsOnComponent(updatedTextComponent)) as unknown as Models.TextComponent;
}

export function replaceReferenceCitationWithinComponent(
  textComponent: Models.TextComponentMap,
  oldReferenceId: string,
  newReferenceId: string,
): Models.TextComponentMap {
  const rawContent: Draft.RawDraftContentState = JSON.parse(textComponent.get('rawContent'));

  rawContent.entityMap = _.mapValues(rawContent.entityMap, entity => replaceReferencesInEntity(entity, { [oldReferenceId]: newReferenceId }));

  const updatedTextComponent = textComponent.set('rawContent', JSON.stringify(rawContent));

  return updateReferenceCitationsOnComponent(updatedTextComponent);
}

