import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EditorState, $getRoot } from 'lexical';
import { $isCustomListNode } from 'modules/Lexical/nodes/CustomListNode';
import { $isCustomParagraphNode } from 'modules/Lexical/nodes/CustomParagraphNode';
import { SSIAnchorNode } from 'modules/Lexical/nodes/SSIAnchorNode';
import { useEffect, useCallback, useMemo } from 'react';
import { createDropHandler } from './utils/dropHandler';
import { $togglePlaceholder, $togglePlaceholderForList } from './utils/togglePlaceholder';
import { $updateSSIAnchorNodeOffset } from './utils/updateSSIAnchorNodes';

export interface SSIAnchorPluginProps {
  modifyDocument: (state: EditorState) => void;
  showPlaceholder: boolean;
}

export function SSIAnchorPlugin({ modifyDocument, showPlaceholder }: SSIAnchorPluginProps): null {
  const [editor] = useLexicalComposerContext();
  const nodesCache = useMemo<Set<string>>(() => new Set(), [editor]);

  const recomputeOffsets = useCallback(() => {
    editor.update(() => {
      for (const key of nodesCache) {
        $updateSSIAnchorNodeOffset(editor, key);
      }
    });
  }, [editor]);

  // 1 registerMutationListener
  useEffect(() => {
    return editor.registerMutationListener(SSIAnchorNode,
      (mutatedNodes) => {
        for (const [key, mutation] of mutatedNodes) {
          if (mutation === 'created') {
            nodesCache.add(key);
            editor.update(() => $updateSSIAnchorNodeOffset(editor, key));
          } else if (mutation === 'destroyed') {
            nodesCache.delete(key);
          }
        }
      },
      { skipInitialization: false },
    );
  }, [editor]);

  // 2 ResizeObserver
  useEffect(() => {
    const rootElement = editor.getRootElement();

    if (!rootElement) {
      return;
    }

    const observer = new ResizeObserver(recomputeOffsets);
    observer.observe(rootElement);

    return () => {
      observer.disconnect();
    };
  }, [editor, recomputeOffsets]);

  // 3 dropHandler
  useEffect(() => {
    const rootElement = editor.getRootElement();

    if (!rootElement) {
      return;
    }

    const dropHandler = createDropHandler(editor, modifyDocument);

    rootElement.addEventListener('drop', dropHandler);

    return () => {
      rootElement.removeEventListener('drop', dropHandler);
    };
  }, [editor, modifyDocument]);

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      const nodes = root.getChildren();

      nodes.forEach((node) => {
        if ($isCustomParagraphNode(node)) {
          $togglePlaceholder({ editor, node, showPlaceholder });
        }

        if ($isCustomListNode(node)) {
          $togglePlaceholderForList({ editor, node, showPlaceholder });
        }
      });
    });
  }, [showPlaceholder, editor]);

  return null;
}
