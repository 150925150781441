import React, { useContext } from 'react';
import Anchor from 'containers/Anchor';
import { SSIAnchorContext } from '../context/SSIAnchorContext';

type Props = {
  anchorBlockKey: string;
  offsetTop: number;
};

export const SSIAnchorWithContext = ({ anchorBlockKey, offsetTop }: Props): JSX.Element | null => {
  const value = useContext(SSIAnchorContext);

  if (!value.isVisible) {
    return null;
  }

  return (
    <Anchor
      anchorBlockKey={anchorBlockKey}
      offsetTop={offsetTop}
      documentId={value.documentId ?? ''}
      relationId={value.relationId ?? ''}
    />
  );
};
