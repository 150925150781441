import { $findMatchingParent } from '@lexical/utils';
import {
  $getSelection,
  $isElementNode, $isNodeSelection, $isRangeSelection,
  ElementFormatType, ElementNode, LexicalNode,
} from 'lexical';
import * as Constants from 'const';

// Constants.TextHorizontalAlignmentType.UNORDERED_LIST is controlled by the CustomListPlugin
export const elementFormatTypeToBlockTypeMap: {
  [key in ElementFormatType]?: Constants.TextHorizontalAlignmentType
} = {
  left: Constants.TextHorizontalAlignmentType.LEFT,
  center: Constants.TextHorizontalAlignmentType.CENTER,
  end: Constants.TextHorizontalAlignmentType.RIGHT,
  right: Constants.TextHorizontalAlignmentType.RIGHT,
  justify: Constants.TextHorizontalAlignmentType.JUSTIFY,
};

export function getFormatTypeForBlockType(
  blockType: Constants.TextHorizontalAlignmentType,
): ElementFormatType | undefined {
  const entry = Object.entries(elementFormatTypeToBlockTypeMap).find(([, v]) => v === blockType);

  return entry?.[0] as ElementFormatType | undefined;
}

function getBlockTypeForFormatType(
  formatType: ElementFormatType,
): Constants.TextHorizontalAlignmentType | undefined {
  return elementFormatTypeToBlockTypeMap[formatType];
}

export function $getHorizontalAlignmentBlockTypeFromSelection(): Constants.TextHorizontalAlignmentType | undefined {
  const selection = $getSelection();

  if (!$isRangeSelection(selection) && !$isNodeSelection(selection)) {
    return Constants.TextHorizontalAlignmentType.LEFT;
  }

  for (const node of selection.getNodes()) {
    const element = $findMatchingParent(
      node,
      (parentNode): parentNode is ElementNode => $isElementNode(parentNode) && !parentNode.isInline(),
    );
    if (element) {
      const type = element.getFormatType();
      const format = type && getBlockTypeForFormatType(type);
      if (format) {
        return format;
      }
    }
  }

  return undefined;
}

export function $applyHorizontalAlignment(
  node: LexicalNode,
  value: Constants.TextHorizontalAlignmentType | undefined,
): void {
  if (!value) {
    return;
  }
  const format = getFormatTypeForBlockType(value);
  if (!format) {
    return;
  }
  if ($isElementNode(node) && !node.isInline()) {
    node.setFormat(format);
  }
}
