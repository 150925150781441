import { LinkNode } from '@lexical/link';
import { DOMExportOutput, isHTMLElement, LexicalEditor } from 'lexical';

export class LinkNodeWithExportDom extends LinkNode {
  static getType(): string {
    return super.getType() + '-export';
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const linkAttributes = ['href', 'title', 'target'];
    const { element } = super.exportDOM(editor);

    if (isHTMLElement(element)) {
      element.setAttribute('title', this.__url);
      element.getAttributeNames().forEach((attr) => {
        if (!linkAttributes.includes(attr)) {
          element.removeAttribute(attr);
        }
      });
    }

    return { element };
  }
}
