import { $isListItemNode, $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { getCSSFromStyleObject, getStyleObjectFromCSS } from '@lexical/selection';
import { $getRoot, ElementFormatType, ParagraphNode, TextNode } from 'lexical';
import { useEffect } from 'react';
import variables from 'styles/variables.scss';
import { DefaultTextStyles } from 'const/Styles';
import { $createCustomParagraphNode, $isCustomParagraphNode, CustomParagraphNode } from '../nodes/CustomParagraphNode';

const { lineHeight, textAlign, fontSize } = DefaultTextStyles;
const fontFamily = variables.defaultFontFamily;

const createParagraphsFromList = (listNode: ListNode): ParagraphNode[] => {
  const paragraphs: ParagraphNode[] = [];
  const listItems = listNode.getChildren();

  listItems.forEach((listItemNode) => {
    if (!$isListItemNode(listItemNode)) {
      return;
    }
    const paragraph = $createCustomParagraphNode(lineHeight);
    const listItemChildren = listItemNode.getChildren();

    listItemChildren.forEach((node) => {
      if ($isListNode(node)) {
        paragraphs.push(...createParagraphsFromList(node));
      } else {
        paragraph.append(node);
      }
    });
    if (!paragraph.isEmpty()) {
      paragraphs.push(paragraph);
    }
  });

  return paragraphs;
};

const $removeListAndInsertParagraphs = (listNode: ListNode): void => {
  const paragraphs = createParagraphsFromList(listNode);
  paragraphs.forEach(paragraph => listNode.insertBefore(paragraph));
  listNode.remove();
};

const $resetTextAlignAndLineHeight = (node: CustomParagraphNode): void => {
  node.setLineHeight(lineHeight);
  node.setFormat(textAlign as ElementFormatType);
};

const $resetFont = (node: TextNode): void => {
  const styles = getStyleObjectFromCSS(node.getStyle());
  node.setStyle(getCSSFromStyleObject({
    ...styles,
    ['font-size']: `${fontSize}px`,
    ['font-family']: `${fontFamily}`,
  }));
};

export const ResetStylesPlugin: React.FunctionComponent = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      root.getAllTextNodes().forEach($resetFont);
      const children = root.getChildren();
      children.filter($isListNode).forEach($removeListAndInsertParagraphs);
      children.filter($isCustomParagraphNode).forEach($resetTextAlignAndLineHeight);
    });
  }, [editor]);

  return null;
};
