import { $getRoot } from 'lexical';
import { createLexicalTextEditor } from '../../../editors';
import { $getSSIAnchorNodes } from './getSSIAnchorNodes';

export function removeSSIAnchor(
  lexicalStateRaw: string,
  anchorBlockKey: string,
): string {
  const editor = createLexicalTextEditor(lexicalStateRaw);
  editor.update(() => {
    const root = $getRoot();

    const nodeToRemove = $getSSIAnchorNodes(root)
      .find(ssiAnchor => ssiAnchor.getId() === anchorBlockKey);

    if (nodeToRemove) {
      nodeToRemove.remove();
    }
  }, { discrete: true });

  return JSON.stringify(editor.getEditorState());
}
