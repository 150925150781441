import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $setSelection, BLUR_COMMAND, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { useEffect, useRef } from 'react';

export const PREVENT_BLUR_ATTRIBUTE = 'data-lexical-prevent-blur';

export const BlurControlPlugin: React.FunctionComponent = () => {
  const [editor] = useLexicalComposerContext();
  const isPreventBlur = useRef(false);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent): void => {
      const target = event.target as HTMLElement;
      if (target.closest(`[${PREVENT_BLUR_ATTRIBUTE}="true"]`)) {
        isPreventBlur.current = true;
        setTimeout(() => isPreventBlur.current = false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    editor.registerCommand(
      BLUR_COMMAND,
      () => {
        if (isPreventBlur.current) {
          return false;
        }

        editor.update(() => {
          $setSelection(null);
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [editor]);

  return null;
};
