import { LexicalReferencesEditor } from 'modules/Lexical/components/LexicalReferencesEditor';
import { generateHtml } from 'modules/Lexical/editorUtils';
import { useLexicalReferencesEditor } from 'modules/Lexical/hooks/useLexicalReferencesEditor';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Toolbar from 'components/Toolbar/ReferenceCitation';
import { Priority } from 'hooks/useClickOutside/constants';
import { cleanUpHtml } from 'utils/cleanUpHtml';
import { intlGet } from 'utils/intlGet';
import { AddReferenceCitationProps } from './models';
import styles from './styles.module.scss';

const AddReferenceCitation: React.FunctionComponent<AddReferenceCitationProps> = (props) => {
  const { addCitation, hideModal, layoutId } = props;
  const lexicalHook = useLexicalReferencesEditor();

  const onSaveClick = (): void => {
    const text = generateHtml(lexicalHook.ref.current);
    if (text) {
      addCitation(cleanUpHtml(text));
    }
  };

  const isSaveButtonDisabled = !lexicalHook.hasTextContent;

  return (
    <div className={styles.AddReferenceCitation}>
      <header className={styles.header}>
        <div>{intlGet('AddCitationModal', 'Title')}</div>
        <Icon className={styles.closeIcon} onClick={hideModal} type={IconType.CLOSE} size="sm-md" color="primary" />
      </header>
      <main className={styles.main}>
        <div className={styles.tip}>{intlGet('AddCitationModal', 'Tip')}</div>
        <div className={styles.content}>
          <Icon type={IconType.REFERENCE_CITATION} size="sm-md" color="primary" />
          <div className={styles.editor}>
            <Toolbar
              className={styles.toolbar}
              showTitle={false}
              clearFormatting={lexicalHook.clearFormatting}
              fontStyles={lexicalHook.fontStyles}
              toggleFontStyle={lexicalHook.toggleFontStyle}
              toggleScriptStyle={lexicalHook.toggleScriptStyle}
              disableLink={lexicalHook.disableLink}
              link={lexicalHook.link}
              toggleLink={lexicalHook.toggleLink}
              layoutId={layoutId}
              priority={Priority.MODAL_WINDOW_CONTROL}
              renderViaPortal={false}
            />
            <div className={styles.referencesEditor}>
              <LexicalReferencesEditor
                ref={lexicalHook.ref}
                editMode={true}
                onChange={lexicalHook.onChange}
              />
            </div>
          </div>
        </div>
      </main>
      <footer className={styles.footer}>
        <Button variant='secondary' onClick={hideModal}>{intlGet('AddCitationModal', 'Cancel')}</Button>
        <Button variant='primary' onClick={onSaveClick} disabled={isSaveButtonDisabled}>{intlGet('AddCitationModal', 'Save')}</Button>
      </footer>
    </div>
  );
};

export default AddReferenceCitation;
