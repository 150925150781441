import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

import NumericInput from 'components/NumericInput';
import { intlGet } from 'utils/intlGet';
import { HeightWidthProps } from './models';
import styles from './styles.module.scss';

const HeightWidth: React.FunctionComponent<HeightWidthProps> = (props) => {
  const {
    disableCellWidthEditing = false,
    height,
    isIncreasingWidthDisabled = false,
    maxHeight = Infinity,
    maxWidth = Infinity,
    minHeight = 1,
    minWidth = 1,
    toggleColumnWidth,
    toggleHeight,
    toggleWidth,
    transparentControls = true,
    width,
    isAutoHeight,
    isAutoFitContent,
    toggleAutoFitContent,
  } = props;

  const [widthState, setWidthState] = useState(width);
  const applyWidth = (newWidth: number): void => {
    let newValue: number;
    if (newWidth !== props.width && toggleWidth) {
      if (toggleColumnWidth) {
        newValue = toggleColumnWidth(newWidth);
      } else {
        newValue = _.clamp(newWidth, minWidth, maxWidth);
        toggleWidth(newValue);
      }
      setWidthState(newValue);
    }
  };

  const [heightState, setHeightState] = useState(height);
  const setHeightAndDisableAutoHeight = (newHeight: number): void => {
    if (isAutoFitContent && toggleAutoFitContent) {
      toggleAutoFitContent();
    }
    setHeightState(newHeight);
  };

  const applyHeight = (newHeight: number | undefined): void => {
    if (isAutoFitContent && toggleAutoFitContent) {
      toggleAutoFitContent();
    }
    const newValue = Math.round(_.clamp(newHeight, minHeight, maxHeight));
    if (newHeight !== props.height && toggleHeight) {
      toggleHeight(newValue);
      setHeightState(newValue);
    }
  };

  React.useEffect(
    () => {
      setWidthState(width);
      setHeightState(height);
    },
    [width, height],
  );

  return (
    <div className={classNames(styles.HeightWidth)}>
      <NumericInput
        className={classNames(styles.numInput)}
        value={widthState}
        disabled={disableCellWidthEditing || !width}
        isIncreasingWidthDisabled={isIncreasingWidthDisabled}
        onChange={setWidthState}
        onBlur={(): void => applyWidth(widthState)}
        onEnterPress={(): void => applyWidth(widthState)}
        onButtonClick={(newWidth): void => applyWidth(newWidth)}
        suffix={intlGet('EditorToolbar.Size', 'Width')}
        transfluentControls={transparentControls}
      />
      <NumericInput
        disableWhenValueAbsent={false}
        allowEmptyStringValue={true}
        className={classNames(styles.numInput)}
        value={heightState}
        isAutoHeight={isAutoHeight}
        disabled={isAutoHeight}
        onChange={setHeightAndDisableAutoHeight}
        onBlur={(): void => applyHeight(heightState)}
        onEnterPress={(): void => applyHeight(heightState)}
        onButtonClick={(newHeight): void => applyHeight(newHeight)}
        suffix={intlGet('EditorToolbar.Size', 'Height')}
        transfluentControls={transparentControls}
      />
    </div>
  );
};

export default HeightWidth;
