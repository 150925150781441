import {
  ParagraphNode,
  SerializedParagraphNode,
  LexicalNode,
  EditorConfig,
  NodeKey,
  $applyNodeReplacement,
  LexicalUpdateJSON,
} from 'lexical';

export type SerializedCustomParagraphNode = SerializedParagraphNode & { lineHeight?: number };

/**
* Custom Paragraph Node with dynamic line-height functionality
*/
export class CustomParagraphNode extends ParagraphNode {
  __lineHeight: number | undefined;

  constructor(lineHeight?: number, key?: NodeKey) {
    super(key);
    this.__lineHeight = lineHeight;
  }

  static getType(): string {
    return 'custom-paragraph';
  }

  static clone(node: CustomParagraphNode): CustomParagraphNode {
    return new CustomParagraphNode(node.__lineHeight, node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    if (this.__lineHeight) {
      element.style.lineHeight = this.__lineHeight.toString();
    }

    return element;
  }

  updateDOM(prevNode: CustomParagraphNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);

    const prevLineHeight = prevNode.__lineHeight;
    const nextLineHeight = this.__lineHeight;

    if (prevLineHeight !== nextLineHeight) {
      dom.style.lineHeight = nextLineHeight ? nextLineHeight.toString() : '';
    }

    return isUpdated;
  }

  updateFromJSON(
    serializedNode: LexicalUpdateJSON<SerializedCustomParagraphNode>,
  ): this {
    super.updateFromJSON(serializedNode);
    this.setLineHeight(serializedNode.lineHeight);

    return this;
  }

  static importJSON(
    serializedNode: SerializedCustomParagraphNode,
  ): CustomParagraphNode {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
    return $createCustomParagraphNode().updateFromJSON(serializedNode);
  }

  exportJSON(): SerializedCustomParagraphNode {
    const json = super.exportJSON();

    return {
      ...json,
      lineHeight: this.__lineHeight ?? undefined,
    };
  }

  setLineHeight(lineHeight: number | undefined): this {
    this.getWritable().__lineHeight = lineHeight;

    return this;
  }

  getLineHeight(): number | undefined {
    return this.getLatest().__lineHeight;
  }
}

export function $createCustomParagraphNode(lineHeight?: number): CustomParagraphNode {
  return $applyNodeReplacement(new CustomParagraphNode(lineHeight));
}

export function $isCustomParagraphNode(
  node: LexicalNode | null | undefined,
): node is CustomParagraphNode {
  return node instanceof CustomParagraphNode;
}
