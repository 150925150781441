import React from 'react';

import CharStyle from 'components/Toolbar/Controls/CharStyle';
import ClearFormatting from 'components/Toolbar/Controls/ClearFormatting';
import Link from 'components/Toolbar/Controls/Link';
import ScriptStyle from 'components/Toolbar/Controls/ScriptStyle';
import ControlGroup from 'components/Toolbar/Shared/ControlGroup';
import BaseToolbar from 'containers/Toolbar/BaseToolbar';
import { intlGet } from 'utils/intlGet';
import { ToolbarProps } from './models';

const Toolbar: React.FunctionComponent<ToolbarProps> = (props) => {
  const {
    className,
    disableLink,
    fontStyles,
    layoutId,
    link,
    showTitle = true,
    renderViaPortal = true,
    clearFormatting,
    toggleFontStyle,
    toggleScriptStyle,
    toggleLink,
    priority,
  } = props;
  const title = showTitle ? intlGet('EditorToolbar.Asset', 'ReferenceCitation') : undefined;

  return (
    <BaseToolbar className={className} title={title} layoutId={layoutId} renderViaPortal={renderViaPortal}>
      <ControlGroup>
        <CharStyle
          styles={fontStyles}
          toggleStyle={(value): void => {
            toggleFontStyle(value);
          }} />
        <ScriptStyle
          styles={fontStyles}
          toggleScriptStyle={(value): void => {
            toggleScriptStyle(value);
          }} />
        <ClearFormatting clearFormatting={clearFormatting} />
      </ControlGroup>
      <ControlGroup>
        <Link isDisabled={disableLink} link={link} toggleLink={toggleLink} enableInternalLinks={false} priority={priority} />
      </ControlGroup>
    </BaseToolbar>
  );
};

export default Toolbar;
