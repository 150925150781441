import { REDO_COMMAND, UNDO_COMMAND, LexicalEditor } from 'lexical';
import { isEqual } from 'lodash';
import { RefObject, useCallback, useState } from 'react';
import { HistoryPluginChangeHandler } from '../Plugins/HistoryWithExtraStatePlugin';

type Hook = {
  undo: () => void;
  redo: () => void;
  isRedoDisabled: boolean;
  isUndoDisabled: boolean;
  onChange: HistoryPluginChangeHandler;
};

type State = {
  isRedoDisabled: boolean;
  isUndoDisabled: boolean;
};

export function useLexicalUndo(editorRef: RefObject<LexicalEditor>): Hook {
  const [state, setState] = useState<State>({ isRedoDisabled: true, isUndoDisabled: true });

  const undo = useCallback(() => editorRef.current?.dispatchCommand(UNDO_COMMAND, undefined), []);
  const redo = useCallback(() => editorRef.current?.dispatchCommand(REDO_COMMAND, undefined), []);

  const onChange = useCallback<HistoryPluginChangeHandler>(options => setState((prevState) => {
    const { isUndoDisabled, isRedoDisabled } = prevState;
    const { canUndo, canRedo } = options;
    const newState = {
      isUndoDisabled: !(canUndo ?? !isUndoDisabled),
      isRedoDisabled: !(canRedo ?? !isRedoDisabled),
    };

    return isEqual(newState, prevState) ? prevState : newState;
  }), []);

  return { ...state, undo, redo, onChange };
}
