import Editor from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import _ from 'lodash';
import { applyEditorStateFontStylesForBrandStyles } from 'modules/draftjs';
import { DraftjsEditorState } from 'modules/draftjs/hooks/useDraftjsTextEditorState';
import { getEditorStateFromTextComponent } from 'modules/draftjs/utils';
import * as Constants from 'const';
import * as DocumentModels from 'containers/Documents/models';
import { BrandProps } from 'hooks/useBrandProps';
import * as Models from 'models';
import { getDesktopBrandStyles, getTextStylesFromBrandStyle } from 'utils/brandStyles';
import { getBulletColorFromBrandStyle } from 'utils/brandStyles/getColorTypeFromBrandStyle';
import * as editorUtils from 'utils/editor';
import { findBulletColorStyle } from 'utils/inlineStyles';
import { prioritizeLayeredRelation } from 'utils/prioritizeLayeredRelations';
import { GetPrioritizedRelationProps } from './relation';
import { Styles } from './styles';

function getColorForListBullets(
  editorState: Draft.EditorState,
  brandStyle: Models.BrandStyleMap | undefined,
  colors: Models.BrandColorsList,
): string {
  let bulletColor = '';

  const blockMap = editorState.getCurrentContent().getBlockMap();
  const contentBlock = blockMap.find(block => Boolean(block && findBulletColorStyle(block.getInlineStyleAt(0))));

  if (contentBlock) {
    const bulletColorStyle = findBulletColorStyle(contentBlock.getInlineStyleAt(0));
    bulletColor = editorUtils.getBulletColorFromFontColorStyle(bulletColorStyle, colors);
  }

  if (!bulletColor && !!brandStyle) {
    bulletColor = getBulletColorFromBrandStyle(colors, getDesktopBrandStyles(brandStyle));
  }

  return bulletColor || Constants.DefaultCustomStyle.FONT_COLOR as string;
}

// IN-PROGRESS draftjs features should be used to update HTML
export function setColorForListBullets(
  editor: Editor | null,
  editorState: Draft.EditorState,
  brandStyle: Models.BrandStyleMap | undefined,
  colors: Models.BrandColorsList,
): void {
  const editorElement = _.get(editor, 'editor.editor') as HTMLDivElement;

  if (!editorElement) {
    return;
  }

  const blocks: HTMLElement[] = Array.from(editorElement.querySelectorAll('li'));
  const bulletColor = getColorForListBullets(editorState, brandStyle, colors);

  _.forEach(blocks, block => block.style.color = bulletColor);
}

export function getLastOperation(
  newEditorState: Draft.EditorState,
  prevEditorState: Draft.EditorState,
): Models.DraftEditorOperation | undefined {
  // HACK: we need it due to EditorChangeType overloading: we add the custom types: ADD_REFERENCE, SELECT_RANGE, SET_FOCUS
  const lastChangeType = newEditorState.getLastChangeType() as Constants.EditorChangeType;
  const content = newEditorState.getCurrentContent();

  if (!lastChangeType || content === prevEditorState.getCurrentContent()) {
    return;
  }

  let operation: Models.DraftEditorOperation;

  switch (lastChangeType) {
    case Constants.EditorChangeType.REMOVE_RANGE:
    case Constants.EditorChangeType.INSERT_CHARACTERS:
    case Constants.EditorChangeType.BACKSPACE_CHARACTER:
    case Constants.EditorChangeType.DELETE_CHARACTER:
    case Constants.EditorChangeType.SPLIT_BLOCK: {
      operation = {
        type: lastChangeType,
        selectionBefore: content.getSelectionBefore(),
        selectionAfter: content.getSelectionAfter(),
      };

      break;
    }
    case Constants.EditorChangeType.UNDO:
    case Constants.EditorChangeType.REDO:
    default: {
      operation = { type: lastChangeType };
      break;
    }
  }

  return operation;
}

type GetEditorStateFromPropsProps = {
  editMode: boolean;
  document: Models.TextComponentMap;
  referenceOrder: DocumentModels.ReferenceCitationsOrderByDocumentsMap;
} & GetPrioritizedRelationProps;

function getEditorStateFromProps(
  props: GetEditorStateFromPropsProps,
  maybeBrandStyle: Models.BrandStyleMap | undefined,
  brandStyleChanged: boolean | undefined,
  brandProps: BrandProps,
): Draft.EditorState {
  const {
    relation,
    activeLayer,
    document,
    referenceOrder,
    editMode,
  } = props;

  // initial editor state
  // apply brand props (common - 11)
  let editorState = getEditorStateFromTextComponent(
    document,
    prioritizeLayeredRelation(relation, activeLayer),
    referenceOrder,
    editMode,
  );

  const { colors, fonts, brandStyles } = brandProps;
  const brandStyle = maybeBrandStyle || brandStyles.get(Constants.Styles.DefaultTextBrandStyle);

  if (!brandStyle || brandStyleChanged) {
    return editorState;
  }

  const textStyles = getTextStylesFromBrandStyle(brandStyle, colors, fonts);
  editorState = editorUtils.toggleBrandStyle(editorState, textStyles, colors, fonts);

  return editorState;
}

type GetDefaultStateProps = {
  projectType: Constants.ProjectType;
} & GetEditorStateFromPropsProps;

export function getInitialEditorState(
  props: GetDefaultStateProps,
  styles: Styles | undefined,
  brandProps: BrandProps,
): DraftjsEditorState {
  const { projectType } = props;
  const { fonts } = brandProps;
  let editorState = getEditorStateFromProps(props, styles?.brandStyle, styles?.brandStyleChanged, brandProps);
  editorState = applyEditorStateFontStylesForBrandStyles(editorState, projectType, fonts);
  const operations = [];
  const activeFontFamily = editorUtils.getActiveFontFamily(editorState);

  return { editorState, operations, activeFontFamily };
}
