import PluginEditor from '@draft-js-plugins/editor';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import { IList } from 'typings/DeepIMap';
import { BrandColor, BrandFont } from 'models';
import { UndoHook } from '../hooks/useUndo';

type ContextType = {
  editorRef: React.RefObject<PluginEditor>;
  id: string;
  cssButton: string;
  editMode: boolean;
  editorWrap: React.RefObject<HTMLDivElement>;
  editorState: Draft.EditorState; //IN-PROGRESS: Remove anything related to DraftJS after replacing it with Lexical
  onEditorChange: (editorState: Draft.EditorState) => void; //IN-PROGRESS: Remove anything related to DraftJS after replacing it with Lexical
  returnFocusToEditor: () => void;
  styles: React.CSSProperties;
  brandProps: {
    colors: IList<BrandColor>;
    fonts: IList<BrandFont>;
  };
  undoHook: UndoHook;
};

const Context = createContext<ContextType | null>(null);

export const useDraftjsCTAEditorContext = (): ContextType => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('must be used within a DraftjsCTAEditorProvider');
  }

  return context;
};

type Props = PropsWithChildren<{
  value: ContextType;
}>;

export const DraftjsCTAEditorProvider: React.FC<Props> = ({ children, value }) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
