import { LexicalEditor } from 'lexical';
import { LexicalReferencesEditor } from 'modules/Lexical/components/LexicalReferencesEditor';
import { generateHtml } from 'modules/Lexical/editorUtils';
import { useLexicalReferencesEditor } from 'modules/Lexical/hooks/useLexicalReferencesEditor';
import React from 'react';
import Toolbar from 'components/Toolbar/ReferenceCitation';
import { cleanUpHtml } from 'utils/cleanUpHtml';
import ReferenceCitationActions from '../ReferenceCitationActions';
import { IReferenceCitationEditorProps } from './models';
import styles from './styles.module.scss';

// TODO: rewrite it using PlainTextEditor
const ReferenceCitationEditor: React.FunctionComponent<IReferenceCitationEditorProps> = (props) => {
  const {
    deleteReferenceFromText,
    layoutId,
    reference,
    updateReference,
  } = props;

  const referenceHtml = cleanUpHtml(reference?.get('text') ?? '').trim();
  const lexicalHook = useLexicalReferencesEditor();

  // Current Lexical Editor
  const lexicalEditorRef = React.useRef<LexicalEditor | null>(null);
  lexicalEditorRef.current = lexicalHook.ref.current;

  const deleteReference = (): void => {
    const id = reference.get('id');
    deleteReferenceFromText(id);
  };

  React.useEffect(() => {
    return () => {
      const text = generateHtml(lexicalEditorRef.current);
      if (!text) {
        deleteReference();
      } else {
        const html = cleanUpHtml(text).trim();
        if (html !== referenceHtml) {
          updateReference(reference.get('id'), html);
        }
      }
    };
  }, []);

  return (
    <ReferenceCitationActions deleteReference={deleteReference}>
      <div className={styles.ReferenceCitationEditor}>
        <Toolbar
          clearFormatting={lexicalHook.clearFormatting}
          fontStyles={lexicalHook.fontStyles}
          toggleFontStyle={lexicalHook.toggleFontStyle}
          toggleScriptStyle={lexicalHook.toggleScriptStyle}
          disableLink={lexicalHook.disableLink}
          link={lexicalHook.link}
          toggleLink={lexicalHook.toggleLink}
          layoutId={layoutId}
        />
        <LexicalReferencesEditor
          ref={lexicalHook.ref}
          editMode={true}
          html={referenceHtml}
          onChange={lexicalHook.onChange}
        />
      </div>
    </ReferenceCitationActions>
  );
};

export default ReferenceCitationEditor;
