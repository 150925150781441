import { SerializedTextNode, TextNode } from 'lexical';
import { NO_WRAP_STYLES_OBJECT } from 'modules/Lexical/Plugins/BasePropsPlugin/style';
import * as Models from 'models';
import { DraftEntityData } from '../types';
import { getFormateCode, convertTextStyles } from '../utils';

export function createTextNode(
  data: DraftEntityData,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): SerializedTextNode {
  const { text, styles } = data;

  return {
    detail: 0,
    format: getFormateCode(styles),
    mode: 'normal',
    style: convertTextStyles(styles, colors, fonts),
    text,
    type: TextNode.getType(),
    version: 1,
  };
}

export function createNoWrapTextNode(
  data: DraftEntityData,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): SerializedTextNode {
  const textNode = createTextNode(data, colors, fonts);
  const noWrapStylesString = Object.entries(NO_WRAP_STYLES_OBJECT)
    .map(([key, value]) => `${key}: ${value};`).join(' ');

  textNode.style += noWrapStylesString;

  return textNode;
}
