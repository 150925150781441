import { MarkNode, SerializedMarkNode } from '@lexical/mark';
import { SerializedLexicalNode } from 'lexical/LexicalNode';
import { DraftEntityData } from '../types';

export function createMarkNode(
  entityData: DraftEntityData,
  children: SerializedLexicalNode[],
): SerializedMarkNode {
  const { data } = entityData;

  return {
    type: MarkNode.getType(),
    ids: data?.id ? [data?.id] : [],
    children,
    direction: 'ltr',
    format: '',
    version: 1,
    indent: 0,
  };
}
