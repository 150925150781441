import { $getNodeByKey, LexicalEditor } from 'lexical';
import { SSIAnchorNode } from 'modules/Lexical/nodes/SSIAnchorNode';

export function $updateSSIAnchorNodeOffset(
  editor: LexicalEditor,
  nodeKey: string,
): void {
  const node = $getNodeByKey<SSIAnchorNode>(nodeKey);

  const element = editor.getElementByKey(nodeKey) as HTMLElement;
  if (!element.parentElement) {
    return;
  }
  node?.setOffset(element.parentElement.offsetTop);
}
