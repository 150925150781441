import * as Constants from 'const';
import * as Models from 'models';
import { getDesktopBrandStyles } from 'utils/brandStyles';
import { getDefaultColorFromBrandStyle } from 'utils/brandStyles/getColorTypeFromBrandStyle';
import { colorWithTint } from 'utils/converters';

// indicates color from BrandColors collection
export type BrandColorRef = {
  name?: string; // name property may be empty for old style documents
  HEX: string;
  tint?: number;
};

export function findBrandColorByHex(
  colors: Models.BrandColorsList,
  colorHEX: string,
): Models.BrandColorMap | undefined {
  return colors.find((color: Models.BrandColorMap) => {
    const colorMappedByTints = Constants.colorTints
      .map((tint) => {
        return colorWithTint(color, tint);
      });

    return colorMappedByTints.some(colorMappedWithTint => colorMappedWithTint.get('HEX').toUpperCase() === colorHEX.toUpperCase());
  });
}

function findBrandColorByName(
  colors: Models.BrandColorsList,
  collorName: string,
): Models.BrandColorMap | undefined {
  return colors.find(color => color?.get('name') === collorName);
}

export function findBrandColor(
  colors: Models.BrandColorsList,
  colorRef: BrandColorRef | undefined,
): Models.BrandColorMap | undefined {
  if (!colorRef) {
    return undefined;
  }
  const { HEX, name, tint } = colorRef;
  const colorHexOrName = name ?? HEX;
  let baseColor: Models.BrandColorMap | undefined = colors.find(color => color?.get('name') === colorHexOrName);

  if (!baseColor) {
    baseColor = findBrandColorByHex(colors, colorHexOrName);
  }

  return baseColor && colorWithTint(baseColor, tint);
}

export function getDefaultBrandColor(
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
): BrandColorRef {
  const defaultBrandStyleColor = getDefaultColorFromBrandStyle(
    colors,
    getDesktopBrandStyles(brandStyle),
  );

  let brandColor: Models.BrandColorMap | undefined;
  if (defaultBrandStyleColor?.startsWith(Constants.EXTERNAL_REF_KEY)) {
    const brandColorId = defaultBrandStyleColor.split(Constants.ID_START_KEY)[1];
    brandColor = colors.find(color => color?.get('id') === brandColorId);
  } else if (defaultBrandStyleColor) {
    brandColor = findBrandColorByName(colors, defaultBrandStyleColor) ?? findBrandColorByHex(colors, defaultBrandStyleColor);
  }

  if (brandColor) {
    return {
      name: brandColor.get('name'),
      HEX: brandColor.get('HEX'),
      tint: brandColor.get('tint'),
    };
  }

  return {
    HEX: defaultBrandStyleColor,
    tint: 0,
  };
}

export function getBrandColorValueHEX(
  colors: Models.BrandColorsList,
  colorRef: BrandColorRef,
): string {
  const color = findBrandColor(colors, colorRef);

  return color ? color.get('HEX') : colorRef.HEX;
}

// will updated HEX value if there is actual color in the BrandColorsList
export function verifyBrandColorRefValueHEX(
  colors: Models.BrandColorsList,
  colorRef: BrandColorRef,
): BrandColorRef {
  const { name, HEX, tint } = colorRef;
  let brandColor: Models.BrandColorMap | undefined;
  if (name) {
    brandColor = findBrandColorByName(colors, name);
    brandColor = brandColor && colorWithTint(brandColor, tint);
  }

  return {
    name,
    HEX: brandColor?.get('HEX') ?? HEX,
    tint,
  };
}

// currently there are plaece where BrandColor name is missed and the HEX value is the only way to restore BrandColor
export function tryToGetBrandColorRefByHEX(
  colors: Models.BrandColorsList,
  HEX: string,
): BrandColorRef {
  const color = findBrandColorByHex(colors, HEX);

  return { name: color?.get('name'), HEX, tint: color?.get('tint') };
}

// IN-PROGRESS let's remove this function
export function createBrandColorRefFromHEX(HEX: string): BrandColorRef {
  return { HEX };
}

