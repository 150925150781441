import { getCSSFromStyleObject, getStyleObjectFromCSS } from '@lexical/selection';
import { TextNode } from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import { IList, IMap } from 'typings/DeepIMap';
import * as Constants from 'const';
import * as Models from 'models';
import { FontPluginStyle } from './style';

// Taken from current inplementaion of Draft.js. Probably we might need to extend this list.
// As alternative solution we could take the first word from style name.
const CHARACTER_STYLE_GROUPS = Object.values(Constants.FontFamily);

// Verify if charcter style corresponds with the font style and weight
function createCharacterStylePredicate(currentFontWeight: string, isItalic: boolean): (characterStyle: Models.CharacterStyleMap) => boolean {
  return (characterStyle: Models.CharacterStyleMap) => {
    const fontWeight = characterStyle?.get('fontWeight');
    const fontStyle = characterStyle?.get('fontStyle');

    return (currentFontWeight === fontWeight && (isItalic ?
      fontStyle === Constants.InlineFontStyleNoPrefix.ITALIC : fontStyle === Constants.InlineFontStyleNoPrefix.NORMAL));
  };
}

// Find the weight that is nearest to the target font weight.
function createCharacterStyleReducer(targetFontWeight: number):
(curr: Models.CharacterStyleMap, prev: Models.CharacterStyleMap) => Models.CharacterStyleMap {
  return (current, next) => {
    const currDiff = Math.abs(Number(current?.get('fontWeight')) - targetFontWeight);
    const nextDiff = Math.abs(Number(next?.get('fontWeight')) - targetFontWeight);

    return currDiff > nextDiff ? next : current;
  };
}

function findClosestCharacterStyle(
  characterStyleName: string,
  fontWeight: string,
  isItalic: boolean,
  allCharacterStyles: IList<Models.CharacterStyle>): IMap<Models.CharacterStyle> | undefined {
  const matchCharacterStyle = createCharacterStylePredicate(fontWeight, isItalic);
  const currentCharacterStyle = allCharacterStyles.find(cs => cs?.get('name') === characterStyleName);

  // The selected character style corresponds with the font style and weight.
  if (currentCharacterStyle && matchCharacterStyle(currentCharacterStyle)) {
    return currentCharacterStyle;
  }

  let resultCharacterStyle;

  const characterStyleReducer = createCharacterStyleReducer(Number(fontWeight ?? Constants.InlineFontStyleNoPrefix.REGULAR));
  // Based on the Draft.js implementation, character styles may be organized into groups such as condensed and extended.
  // Thus, we should first look within the group associated with the currently selected character style.
  const currentCharacterStylePrefix = CHARACTER_STYLE_GROUPS
    .find(prefix => characterStyleName.toLowerCase().includes(prefix.toLowerCase()));
  if (currentCharacterStylePrefix) {
    resultCharacterStyle = allCharacterStyles
      .filter(cs => !!cs?.get('name').toLowerCase().includes(currentCharacterStylePrefix.toLowerCase()))
      .filter(matchCharacterStyle)
      .reduce(characterStyleReducer);
  }

  // Then fallback to search through all character styles.
  if (!resultCharacterStyle) {
    resultCharacterStyle = allCharacterStyles
      .filter(matchCharacterStyle)
      .reduce(characterStyleReducer);
  }

  return resultCharacterStyle;
}

export function $switchCharacterStyle(node: TextNode, fonts: Models.BrandFontsList): void {
  const styleObj = getStyleObjectFromCSS(node.getStyle());
  const characterStyleName = styleObj[FontPluginStyle.BRAND_FONT_CHARACTER_STYLE_NAME];

  if (!characterStyleName) {
    return;
  }

  const fontName = styleObj[FontPluginStyle.BRAND_FONT_NAME];
  const brandFont = fonts.find(font => font?.get('name') === fontName);
  const allCharacterStyles = brandFont?.get('characterStyles');

  if (!allCharacterStyles?.size) {
    return;
  }

  const fontWeight = styleObj[FontPluginStyle.FONT_WEIGHT];
  const isItalic = styleObj[FontPluginStyle.FONT_STYLE] === Constants.InlineFontStyleNoPrefix.ITALIC;

  const nextCharacterStyle = findClosestCharacterStyle(characterStyleName, fontWeight, isItalic, allCharacterStyles);

  if (nextCharacterStyle?.get('name') === characterStyleName) {
    return;
  }

  const nextCharacterStyleName = nextCharacterStyle?.get('name') ?? '';
  const updateInlineCSS = getCSSFromStyleObject({
    ...styleObj,
    [FontPluginStyle.BRAND_FONT_CHARACTER_STYLE_NAME]: nextCharacterStyleName,
    [FontPluginStyle.FONT_FAMILY]: BrandDefinition.getBrandFontValueFontFamily(
      fonts,
      { fontName, characterStyleName: nextCharacterStyleName },
    ),
  });

  node.setStyle(updateInlineCSS);
}

