import { $isElementNode, LexicalNode } from 'lexical';
import { $isReferenceCitationNode, ReferenceCitationNode } from '../../../nodes/ReferenceCitationNode';

export const $getReferenceCitationNodes = (node: LexicalNode): ReferenceCitationNode[] => {

  if ($isReferenceCitationNode(node)) {
    return [node];
  }

  if (!$isElementNode(node)) {
    return [];
  }

  const nodes: ReferenceCitationNode[] = [];
  node.getChildren().forEach((child) => {
    nodes.push(...$getReferenceCitationNodes(child));
  });

  return nodes;
};
